import { compose, head, join, juxt, replace, tail, toUpper } from 'ramda';

import slugify from '../slugify';
import { isString } from '../type';

const capitalize = compose(join(''), juxt([compose(toUpper, head), tail]));

const normalizeLang = {
    all: /[^a-zа-яё-]/gi,
    en: /[^a-z-]/gi,
    ru: /[^а-яё-]/gi,
};

const normalizeLangWithSpaces = {
    all: /[^a-zа-яё\s-]/gi,
    en: /[^a-z\s-]/gi,
    ru: /[^а-яё\s-]/gi,
};

function normalizeName(value = '', { lang = 'all', withSpaces } = {}) {
    return capitalize(
        value
            .replace(
                (withSpaces ? normalizeLangWithSpaces : normalizeLang)[lang],
                '',
            )
            .replace(/--/g, '-')
            .replace(/-[a-zа-яё]/g, toUpper),
    );
}

const normalizeCyrillic = value => normalizeName(value, { lang: 'ru' });
const normalizeLatin = value => normalizeName(value, { lang: 'en' });

function normalizeNameWithSpaces(value, options = {}) {
    return normalizeName(value, { ...options, withSpaces: true });
}

/**
 *
 * @param num
 * @param words {JSX.Element[]}
 * @returns {string}
 */
function noun(num = 0, words = ['рубль', 'рубля', 'рублей']) {
    const number = Math.abs(num) % 100;
    const numberDecimal = number % 10;

    if (number > 10 && number < 20) {
        return words[2];
    }

    if (numberDecimal > 1 && numberDecimal < 5) {
        return words[1];
    }

    if (numberDecimal === 1) {
        return words[0];
    }

    return words[2];
}

function numberWithSpaces(value) {
    if (!value && value !== 0) {
        return '';
    }

    return value.toLocaleString().replace(/,/g, ' ');
}

const cardExpirationPattern = /^(\d{2})\/(\d{2})$/;

function formatCardExpiration(val) {
    if (isString(val) && cardExpirationPattern.test(val)) {
        return val.replace(cardExpirationPattern, '$1 / $2');
    }

    return val;
}

const normalizeSlugify = value =>
    // eslint-disable-next-line no-misleading-character-class,no-useless-escape
    slugify(value.replace(/[№\&🦄♥\_\^\[\]]/gi, ''), {
        decamelize: false,
        lowercase: false,
        mootSerparators: true,
    });

const normalizeSlugifyName = value => normalizeSlugify(normalizeName(value));

const normalizeCheckbox = v => !!v;
const normalizeNumber = replace(/[^0-9]/gi, '');
const normalizeSeries = replace(/[^A-Z0-9]/gi, '');

export {
    capitalize,
    normalizeName,
    normalizeNameWithSpaces,
    normalizeNumber,
    noun,
    numberWithSpaces,
    formatCardExpiration,
    normalizeCyrillic,
    normalizeLatin,
    normalizeSlugify,
    normalizeSlugifyName,
    normalizeSeries,
    normalizeCheckbox,
};
export { normalizePhone, replacePhoneCode, splitPhone } from './phone';
export { formatDocNumber } from './formatDocNumber';
