import { isClient } from './ssr';

export const scrollPageToTop = () => {
    if (isClient) {
        const { body, documentElement } = window.document;

        if (documentElement.scrollTop > 0) {
            documentElement.scrollTop = 0;
        } else if (body.scrollTop > 0) {
            body.scrollTop = 0;
        }
    }
};

function calculateWidth() {
    if (!isClient) {
        return undefined;
    }

    const outer = document.createElement('div');

    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.msOverflowStyle = 'scrollbar';

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;

    outer.style.overflow = 'scroll';

    const inner = document.createElement('div');

    inner.style.width = '100%';

    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

export const getScrollbarWidth = (() => {
    let scrollbarWidth = calculateWidth();

    return () => {
        if (scrollbarWidth) {
            return scrollbarWidth;
        }

        scrollbarWidth = calculateWidth();

        return scrollbarWidth;
    };
})();
