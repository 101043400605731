import { getIsDateCorrect } from 'utils';

import { RULE_MILITARY_CARD } from './allowFeature';
import { isOver14YearsOld, isOver18YearsOld } from './date';

import { documentType as doc } from '../data';

const docPref = 'com.profile.documents.docs.name';

const docs = [
    {
        name: doc.INTERNAL_PASSPORT,
        sort: 1,
        title: `${docPref}.passport`,
        value: doc.INTERNAL_PASSPORT,
    },
    {
        name: doc.INTERNATIONAL_PASSPORT,
        sort: 2,
        title: `${docPref}.outpassport`,
        value: doc.INTERNATIONAL_PASSPORT,
    },
    {
        name: doc.BIRTH_CERTIFICATE,
        sort: 4,
        title: `${docPref}.birth`,
        value: doc.BIRTH_CERTIFICATE,
    },
    {
        name: doc.OTHER,
        sort: 6,
        title: `${docPref}.other`,
        value: doc.OTHER,
    },
];

/**
 * Функция для получения списка документов в зависимости от параметров.
 *
 * Если в параметрах указать функцию onFilter, то остальные параметры будут игнорироваться
 *
 * @typedef {Object} params - параметры для фильтрации списка документов
 * @property {string} params.birthday - дата рождения, в формате DD.MM.YYYY
 * @property {boolean} params.visa
 * @property {boolean} params.militaryId
 * @property {function} params.onFilter - функция предикат для метода Array.prototype.filter
 *
 * @returns {[Object]}
 */
export function getDocListByParams(params = {}) {
    const { birthday, onFilter, visa, militaryId } = params;

    let docList = docs.slice();

    if (onFilter) {
        docList = docList.filter(onFilter);
    } else {
        if (visa) {
            docList.push({
                name: doc.VISA,
                sort: 3,
                title: `${docPref}.visa`,
                value: doc.VISA,
            });
        }

        if (RULE_MILITARY_CARD && militaryId) {
            docList.push({
                name: doc.MILITARY_CARD,
                sort: 5,
                title: `${docPref}.militaryid`,
                value: doc.MILITARY_CARD,
            });
        }

        if (getIsDateCorrect(birthday)) {
            docList = docList
                .filter(({ name }) => {
                    if (isOver14YearsOld(birthday)) {
                        return name !== doc.BIRTH_CERTIFICATE;
                    }

                    if (!isOver14YearsOld(birthday)) {
                        return name !== doc.INTERNAL_PASSPORT;
                    }

                    return true;
                })
                .filter(({ name }) => {
                    if (!isOver18YearsOld(birthday)) {
                        return name !== doc.MILITARY_CARD;
                    }

                    return true;
                });
        }
    }

    return docList;
}

export default docs;
