import React from 'react';
import { find } from 'ramda';

import { memberType } from '../../../data/membership';

/* eslint-disable react/prop-types */
export const getMembershipLeaveSuccessInfo = ({ name, dictionary } = {}) => (
    <>
        <p>
            {dictionary.t('com.profile.family-account.leave.success.part1')}{' '}
            <b>{name}.</b>
        </p>
        <p>{dictionary.t('com.profile.family-account.leave.success.part2')}</p>
    </>
);
/* eslint-enable react/prop-types */

export const findMembershipAdmin = find(
    member => member.type === memberType.HEAD,
);

const invitation = ({ dictionary }) => ({
    title: dictionary.t('com.profile.family-account.invitation.success'),
    type: 'success',
});

const activation = ({ admin, dictionary }) => ({
    title: (
        <>
            {dictionary.t('com.profile.family-account.accepted')}{' '}
            <b>{admin.name}</b>
        </>
    ),
    type: 'success',
});

const deletion = ({ admin, dictionary }) => ({
    title: getMembershipLeaveSuccessInfo({
        dictionary,
        name: admin.name,
    }),
    type: 'default',
});

export const notificationHandlers = {
    activation,
    deletion,
    invitation,
};

export const getMembershipInvitationEvent = type => ({
    event_name: 'view_screen',
    flow: 'companions',
    step: 'membership_invite',
    type,
});
