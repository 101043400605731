import { compose, evolve, indexBy, map, prop } from 'ramda';
import { capitalize } from 'utils/format';

import { formatDate } from './utils';

export const profileAdapter = evolve({
    birthday: formatDate,
    contacts: indexBy(prop('type')),
    names: compose(
        indexBy(prop('lang')),
        map(({ firstName, lastName, ...rest }) => ({
            firstName: firstName ? capitalize(firstName) : firstName,
            lastName: lastName ? capitalize(lastName) : lastName,
            ...rest,
        })),
    ),
});
