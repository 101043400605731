import axios from 'axios';

import authInterceptor from './auth-interceptor';
import errorInterceptor from './error-interceptor';
import requestInterceptor from './request-interceptor';

const instance = axios.create({
    baseURL: '/api/',
    crossDomain: true,
    headers: {
        'x-application-version': process.env.APP_VERSION,
        'x-platform': 'WEB',
    },
    withCredentials: true,
});

instance.interceptors.request.use(requestInterceptor);

export const configureApi = store => {
    instance.interceptors.response.use(null, errorInterceptor(store));
    instance.interceptors.response.use(null, authInterceptor(store));
};

export const apiService = instance;
