import React, { PureComponent } from 'react';
import { node, string } from 'prop-types';

import cx from './Wrapper.sss';

class Wrapper extends PureComponent {
    static propTypes = {
        children: node,
        className: string,
    };

    render() {
        return (
            <div className={cx('wrapper', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

export default Wrapper;
