import { isClient } from 'utils/ssr';

import { apiService } from './index';

import SessionService from '../session';

export default function interceptor() {
    return async error => {
        const errorCode = error?.response?.data?.error?.code || null;
        const isSSDCPExpired = errorCode === 'token.expired';
        const isInvalidToken =
            errorCode === 'invalid.token.signature' ||
            errorCode === 'token.invalid' ||
            errorCode === 'profile.bad.request.params';

        // Если токен протух, запрашиваем новый
        // и уже с ним повторно выполняем текущий запрос
        // если новый токен не получен - выходим из ЛК
        if (isSSDCPExpired) {
            return SessionService.updateTickets().then(token => {
                if (!token) {
                    SessionService.logout();

                    return Promise.reject(error);
                }

                error.config.headers['X-Token'] = token;

                return apiService(error.config);
            });
        }

        if (
            isInvalidToken ||
            (isClient &&
                error &&
                error.response &&
                error.response.status === 403)
        ) {
            SessionService.logout();
        }

        return Promise.reject(error);
    };
}
