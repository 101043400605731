export const milesActionError = {
    CHARITY_ATTEMPTS_LEFT: 'charity.attempts.left',
    INVALID_DESTINATION: 'miles.destination.status.invalid',
};

export const registrationError = {
    PHONE_DUPLICATE: 'phone.profile.contact.duplicate',
};

export const profileError = {
    ACCESS_DENIED: 'access.denied',
};
