import { COOKIES } from 'consts/cookies';

import { isClient } from './ssr';

import { cookieService } from '../services/cookies';

const avaliableLangs = ['ru', 'en', 'de', 'es', 'it', 'zh'];

export const getLanguage = () => {
    const lang = cookieService.get(COOKIES.LANG);

    return avaliableLangs.indexOf(lang) > 0 ? lang : avaliableLangs[0];
};

export const setLanguage = language => {
    cookieService.set(COOKIES.LANG, language);

    if (isClient) {
        document.documentElement.lang = language;
    }
};
