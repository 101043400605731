import { getSessionValue, setSessionValue, setSessionValues } from './storage';

const GEO_SESSION_KEY = 'geo';
const EXCLUDE_PATHS = ['/public'];
const GEO_IS_ANSWERED_KEY = 'geo_is_answered';

export const getGeo = () => getSessionValue(GEO_SESSION_KEY);

const getIsAnswered = () => getSessionValue(GEO_IS_ANSWERED_KEY);

const setGeo = ({ latitude, longitude }) => {
    setSessionValues({
        [GEO_IS_ANSWERED_KEY]: true,
        [GEO_SESSION_KEY]: `${latitude},${longitude}`,
    });
};

const setIsAnswered = () => setSessionValue(GEO_IS_ANSWERED_KEY, true);

const isAnswered = () =>
    !!getIsAnswered() ||
    EXCLUDE_PATHS.some(path => window.location.href.includes(path));

export const requestGeo = () => {
    if (
        !navigator.geolocation ||
        !navigator.geolocation.getCurrentPosition ||
        isAnswered()
    ) {
        return;
    }

    navigator.geolocation.getCurrentPosition(
        position => {
            setGeo(position.coords);
        },
        error => {
            if (
                [error.PERMISSION_DENIED, error.POSITION_UNAVAILABLE].includes(
                    error.code,
                )
            ) {
                setIsAnswered();
            }
        },
    );
};
