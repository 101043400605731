// German umlauts
module.exports = [
    ['ß', 'ss'],
    ['ä', 'ae'],
    ['Ä', 'Ae'],
    ['ö', 'oe'],
    ['Ö', 'Oe'],
    ['ü', 'ue'],
    ['Ü', 'Ue'],
];
