import { compose, filter, keys, length, lt } from 'ramda';
import { createSelector } from 'reselect';

import { isDevelop, isRest } from '../../../utils/stages';

export const isError = state =>
    compose(
        lt(0),
        length(),
        keys(),
        filter(item => item.isError),
    )(state.languages.loaded);

export const isLoading = state =>
    compose(
        lt(0),
        length(),
        keys(),
        filter(item => item.isLoading),
    )(state.languages.loaded) || state.languages.isLoading;

export const isLoadingLanguagesGlobal = state =>
    state.languages.loaded?.global?.isLoading;

export const getLoaded = createSelector(
    state => state.languages.loaded,
    loaded => keys(loaded),
);
export const getLoadedConfig = state => state.languages.loaded;

export const isLoadedConfig = createSelector(
    getLoadedConfig,
    loaded => config =>
        loaded[config] && !loaded[config].isLoading && !loaded[config].isError,
);

export const getDictionary = createSelector(
    state => state.languages.configs,
    state => state.languages.language,
    (configs, language) => {
        const lang = language || 'ru';
        const dictionary = configs[lang] || configs.en || configs.ru || {};

        return {
            ...dictionary,
            t: id => {
                return dictionary[id] || '';
            },
        };
    },
);

export const getLocale = (state, chooses = false) => {
    const lang = state.languages.language || 'ru';

    if (chooses) {
        return lang;
    }

    return lang;
};

export const getVisible = state =>
    (!isDevelop && !isRest) || state.languages.visible;

export const selectLocale = state => getLocale(state, true);
