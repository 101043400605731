import { compose, propEq, reject, values } from 'ramda';
import { createSelector } from 'reselect';

import { isFading } from '../../../utils/fadingTime';

export const getCards = createSelector(
    state => state.cards.itemsById,
    compose(reject(propEq('isDeleting', true)), values),
);
export const getCard = (id, state) => state.cards.itemsById[id];
export const isInitialized = state => state.cards.isInitialized;
export const isLoaded = state => isFading(state.cards.fadingTime);
export const isLoading = state => state.cards.isLoading;
export const isError = state => state.cards.isError;

export const getAllCards = createSelector(
    state => state.cards.allCards.itemsById,
    itemsById => Object.values(itemsById),
);
export const getCardFromAllCards = (id, state) =>
    state.cards.allCards.itemsById[id];
export const isAllCardsInitialized = state =>
    state.cards.allCards.isInitialized;
export const isAllCardsLoaded = state =>
    isFading(state.cards.allCards.fadingTime);
export const isAllCardsLoading = state => state.cards.allCards.isLoading;
export const isAllCardsError = state => state.cards.allCards.isError;
