import { createAction, createReducer } from 'redux-act';

const initialState = {
    addFormError: '',
    addFormShow: false,
    editFormError: '',
    error: '',
    hasMore: false,
    isLoaded: false,
    isLoading: false,
    items: {},
};

export const fetchPets = createAction('pets/fetch');
export const fetchPetsSuccess = createAction('pets/fetchSuccess');
export const fetchPetsFailure = createAction('pets/fetchFailure');
export const createPet = createAction('pets/create');
export const createPetSuccess = createAction('pets/createSuccess');
export const createPetFailure = createAction('pets/createFailure');
export const deletePet = createAction('pets/delete');
export const showPetAddForm = createAction('pets/showAddForm');
export const closePetAddForm = createAction('pets/closeAddForm');
export const updatePet = createAction('pets/update');
export const updatePetSuccess = createAction('pets/updateSuccess');
export const updatePetFailure = createAction('pets/updateFailure');

const handleCreatePetSuccess = state => ({
    ...state,
    addFormError: '',
    addFormShow: false,
});

const handleCreatePetFailure = (state, message) => ({
    ...state,
    addFormError: message,
});

const handleShowAddForm = state => ({
    ...state,
    addFormShow: true,
});

const handleCloseAddForm = state => ({
    ...state,
    addFormShow: false,
});

const handleFetchPets = state => ({
    ...state,
    isLoading: true,
});

const handleFetchPetsFailure = (state, message) => ({
    ...state,
    error: message,
    isLoading: false,
});

const handleFetchPetsSuccess = (state, payload) => ({
    ...state,
    ...payload,
    error: '',
    isLoaded: true,
    isLoading: false,
});

const handleUpdatePetFailure = (state, message) => ({
    ...state,
    editFormError: message,
});

const handleUpdatePetSuccess = state => ({
    ...state,
    editFormError: '',
});

export default createReducer(
    {
        [closePetAddForm]: handleCloseAddForm,
        [createPetFailure]: handleCreatePetFailure,
        [createPetSuccess]: handleCreatePetSuccess,
        [fetchPets]: handleFetchPets,
        [fetchPetsFailure]: handleFetchPetsFailure,
        [fetchPetsSuccess]: handleFetchPetsSuccess,
        [showPetAddForm]: handleShowAddForm,
        [updatePetFailure]: handleUpdatePetFailure,
        [updatePetSuccess]: handleUpdatePetSuccess,
    },
    initialState,
);

export * from './selectors';
