import {
    ALERT_B2B_USER,
    ALERT_PLACEMENT,
    ALERT_PROFILE_TYPE,
    ALERT_TYPE,
} from 'consts/alerts';
import { COOKIES } from 'consts/cookies';
import { PROFILE_AIRPORT_ANY, PROFILE_STATUS } from 'consts/profile';
import Router from 'next/router';
import { cookieService, SessionService } from 'services';

export const sortByWeight = (a, b) => {
    if (!b?.weight || !a?.weight) {
        return -1;
    }

    return b.weight - a.weight;
};

export const sortByType = (a, b) => {
    if (a.type === ALERT_TYPE.WARNING && b.type === ALERT_TYPE.WARNING) {
        return 0;
    }

    if (b.type === ALERT_TYPE.WARNING && a.type !== ALERT_TYPE.WARNING) {
        return 1;
    }

    return -1;
};

export const sortByCreateAt = (a, b) => {
    if (!a.publishedFrom || !b.publishedFrom) {
        return -1;
    }

    return (
        new Date(b.publishedFrom).getTime() -
        new Date(a.publishedFrom).getTime()
    );
};

const isIncludedPath = ({ urls, excludedUrls }) => {
    if (!urls) {
        return false;
    }

    const isSomeMatchUrl = urlLsit =>
        urlLsit
            .split('\r\n')
            .some(url => url && window.location.href.match(url));

    return (
        isSomeMatchUrl(urls) && !(excludedUrls && isSomeMatchUrl(excludedUrls))
    );
};

export const filterByPage = alert => {
    const webSettings = alert?.webSettings || {};

    if (webSettings.placement === ALERT_PLACEMENT.ALL_PAGES) {
        return true;
    }

    if (
        webSettings.placement === ALERT_PLACEMENT.MAIN_PAGE &&
        Router.pathname === '/'
    ) {
        return true;
    }

    if (
        webSettings.placement === ALERT_PLACEMENT.SPECIFIED_PAGES &&
        isIncludedPath(webSettings)
    ) {
        return true;
    }

    return false;
};

export const filterByProfileType = (alert, profileType) => {
    const isAuth = SessionService.isAuth();
    const profileTypes = alert?.targeting?.profileTypes;

    const profileTypesTransform = profileTypes.map(type => {
        if (type === ALERT_PROFILE_TYPE.FFP) {
            return PROFILE_STATUS.FULL;
        }

        if (type === ALERT_PROFILE_TYPE.SIMPLE) {
            return PROFILE_STATUS.SHORT;
        }

        return type;
    });

    return profileTypesTransform.includes(
        isAuth ? profileType : ALERT_PROFILE_TYPE.UNAUTHORIZED,
    );
};

export const filterByB2bUser = (alert, hasCompany) => {
    const b2bUser = alert?.targeting?.b2bUser;

    if (hasCompany && b2bUser === ALERT_B2B_USER.NOT_SUPPORTED) {
        return false;
    }

    return true;
};

export const filterByUserAirport = (alert, homeAirport) => {
    const userAirport = alert?.targeting?.userAirport;

    if (userAirport) {
        return userAirport.includes(homeAirport || PROFILE_AIRPORT_ANY);
    }

    return true;
};

export const filterByViewed = alert => {
    const viewedAlertIds = cookieService.get(COOKIES.ALERTS) || [];

    return !viewedAlertIds.includes(alert?.id);
};
