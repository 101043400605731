import { createAction, createReducer } from 'redux-act';

import { resetFading, setFading } from '../../../utils/fadingTime';

const initialState = {
    fadingTime: setFading(),
    isError: false,
    isInitialized: false,
    isLoading: false,
    items: [],
};

export const fetch = createAction('airlines/fetch');
export const fetchSuccess = createAction('airlines/fetchSuccess');
export const fetchFailure = createAction('airlines/fetchFailure');

const handleFetch = state => ({
    ...state,
    isError: false,
    isLoading: true,
});

const handleFetchSuccess = (state, payload) => ({
    ...state,
    fadingTime: resetFading(true),
    isError: false,
    isInitialized: true,
    isLoading: false,
    items: payload,
});

const handleFetchFailure = state => ({
    ...state,
    isError: true,
    isInitialized: true,
    isLoading: false,
});

export default createReducer(
    {
        [fetch]: handleFetch,
        [fetchFailure]: handleFetchFailure,
        [fetchSuccess]: handleFetchSuccess,
    },
    initialState,
);

export {
    isInitialized,
    isLoading,
    isLoaded,
    isError,
    getAirlines,
    getAirlinesForMilesRecover,
} from './selectors';
