import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
/* eslint-enable complexity */ import {
    compose,
    filter,
    indexBy,
    map,
    pickBy,
    prop,
} from 'ramda';

import { getTransactionPositive } from './helpers';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const DD_MMM_YYYY = DATE_FORMATS.DD.MMM.YYYY;
const YYYY = DATE_FORMATS.YYYY.INDEX;
const { MMM } = DATE_FORMATS;

const filerIds = (
    data,
    { type, time, month, year, dates = {}, categories = [] },
    getDates = false,
) => {
    let items = data;

    if (type || month || year || dates.start || dates.end) {
        items = Object.values(
            pickBy(val => {
                let valid = true;
                const partnerType = val ? val.partnerType : '';
                const date = moment(val ? val.date : Date(), YYYY_MM_DD).locale(
                    'en',
                );

                if (
                    type &&
                    ((type === 'in' && getTransactionPositive(val) === '-') ||
                        (type === 'out' && getTransactionPositive(val) === '+'))
                ) {
                    valid = false;
                }

                if (categories.length && categories.indexOf(partnerType) < 0) {
                    valid = false;
                }

                if (
                    (time === 'month' &&
                        month &&
                        date.format(MMM).toLowerCase() !== month) ||
                    (year > 1000 && date.format(YYYY) !== `${year}`)
                ) {
                    valid = false;
                }

                if (dates.start && date.isBefore(dates.start)) {
                    valid = false;
                }

                if (
                    dates.end &&
                    moment(dates.end).endOf('day').isBefore(date)
                ) {
                    valid = false;
                }

                return valid;
            }, data),
        );
    }

    if (getDates) {
        return indexBy(
            prop('id'),
            map(
                ({ id, date }) => ({
                    date: moment(date, YYYY_MM_DD).format(DD_MMM_YYYY),
                    id,
                }),
                items,
            ),
        );
    }

    if (categories.length === 0) {
        return map(({ id }) => id, items);
    }

    return compose(
        filter(Boolean),
        map(({ id, partnerType }) =>
            categories.includes(partnerType) ? id : null,
        ),
    )(items);
};

export default filerIds;
