import React from 'react';
import { Dictionary } from 'shared';

export const serviceError = {
    title: <Dictionary text="com.error.service-error.title" />,
    type: 'serviceError',
};

export const loadingError = {
    description: <Dictionary text="com.error.loading.description" />,
    title: <Dictionary text="com.error.loading.title" />,
};
