import React from 'react';
import ContentLoader from 'react-content-loader';
import { node, number, oneOfType, string } from 'prop-types';

export const ContentLoaderBase = ({ children, height, width, ...props }) => (
    <ContentLoader viewBox={`0 0 ${width} ${height}`} {...props}>
        {/**
         * Если children будет пустой, то будет отображаться предустановленный скелетон Facebook,
         * чтобы выводить пустой блок, добавлен вывод <rect />
         * https://github.com/danilowoz/react-content-loader/blob/master/src/web/ContentLoader.tsx#L7
         */}
        {children || <rect />}
    </ContentLoader>
);

ContentLoaderBase.propTypes = {
    children: node,
    height: oneOfType([string, number]),
    // uniqueKey обязателен при SSR, иначе он генерируется рандомно
    // и в таком случае будет отличаться на сервере и клиенте
    // https://github.com/danilowoz/react-content-loader#server-side-rendering-ssr---match-snapshot
    uniqueKey: string.isRequired,
    width: oneOfType([string, number]),
};
