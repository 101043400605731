import {
    assoc,
    assocPath,
    compose,
    dissoc,
    dissocPath,
    indexBy,
    keys,
    lensPath,
    over,
    pathOr,
    prop,
    set,
    view,
    without,
} from 'ramda';
import { createAction, createReducer } from 'redux-act';
import { resetFading, setFading } from 'utils/fadingTime';

import { adapter } from './adapter';

const initialCardsState = {
    fadingTime: setFading(),
    ids: [],
    isError: false,
    isInitialized: false,
    isLoading: false,
    itemsById: {},
};

const initialState = {
    allCards: {
        ...initialCardsState,
    },
    ...initialCardsState,
};

export const fetch = createAction('cards/fetch');
export const fetchSuccess = createAction('cards/fetchSuccess');
export const fetchFailure = createAction('cards/fetchFailure');

export const fetchAllCards = createAction('cards/fetchAllCards');
export const fetchAllCardsSuccess = createAction('cards/fetchAllCardsSuccess');
export const fetchAllCardsFailure = createAction('cards/fetchAllCardsFailure');

export const remove = createAction('cards/remove');
export const removeSuccess = createAction('cards/removeSuccess');
export const removeFailure = createAction('cards/removeFailure');
export const removeHideMessage = createAction('cards/removeHideMessage');

export const changeConfirmationRemove = createAction(
    'cards/changeConfirmationRemove',
);

export const edit = createAction('cards/edit');

const handleCards = cards => {
    if (!cards.length) {
        return {
            ...initialCardsState,
            fadingTime: resetFading(),
            isInitialized: true,
        };
    }

    const items = cards.map(adapter);
    const itemsById = indexBy(prop('id'), items);
    const ids = keys(itemsById);

    return {
        fadingTime: resetFading(),
        ids,
        isError: false,
        isInitialized: true,
        isLoading: false,
        itemsById,
    };
};

const handleFetch = state => ({
    ...state,
    isError: false,
    isLoading: true,
});

const handleFetchSuccess = (state, payload) => {
    const result = handleCards(payload?.cards || []);

    return {
        ...state,
        ...result,
    };
};

const handleFetchFailure = state => ({
    ...state,
    isError: true,
    isInitialized: true,
    isLoading: false,
});

const handleFetchAllCards = state => ({
    ...state,
    allCards: {
        ...state.allCards,
        isError: false,
        isLoading: true,
    },
});

const handleFetchAllCardsSuccess = (state, payload) => {
    const result = handleCards(payload?.cards || []);

    return {
        ...state,
        allCards: {
            ...state.allCards,
            ...result,
        },
    };
};

const handleFetchAllCardsFailure = state => ({
    ...state,
    allCards: {
        ...state.allCards,
        isError: true,
        isInitialized: true,
        isLoading: false,
    },
});

const handleRemove = (state, payload) => {
    const lensItem = lensPath(['itemsById', payload.id, 'removingStatus']);

    return compose(set(lensItem, 'pending'), data => ({
        ...data,
        fadingTime: resetFading(),
    }))(state);
};

const handleRemoveSuccess = (state, payload) => {
    const { id } = payload;
    const lensItem = lensPath(['itemsById', id]);

    const newItem = compose(
        dissoc('needConfirmForRemove'),
        assoc('removingStatus', 'success'),
    )(pathOr({}, ['itemsById', id], state));
    const newState = set(lensItem, newItem, state);

    return assocPath(['allCards', 'fadingTime'], setFading(), newState);
};

const handleRemoveFailure = (state, payload) => {
    const lensItem = lensPath(['itemsById', payload.id, 'removingStatus']);

    return set(lensItem, 'failure', state);
};

const handleRemoveHideMessage = (state, payload = {}) => {
    const { id, isError } = payload;
    const lensItem = lensPath(['ids']);

    if (isError) {
        return dissocPath(['itemsById', id, 'removingStatus'], state);
    }

    return compose(
        set(lensItem, without([id], view(lensItem))),
        dissocPath(['itemsById', id]),
    )(state);
};

const handleChangeNeedForConfirmRemove = (state, payload) =>
    assocPath(
        ['itemsById', payload.id, 'needConfirmForRemove'],
        payload.needConfirmForRemove,
        state,
    );

const handleEdit = (state, payload) => {
    const lensNeedConfirmForRemove = lensPath([
        'itemsById',
        payload.id,
        'needConfirmForRemove',
    ]);

    return compose(
        over(lensNeedConfirmForRemove, isConfirmForRemove =>
            isConfirmForRemove && !payload.edit ? false : isConfirmForRemove,
        ),
        assocPath(['itemsById', payload.id, 'edit'], payload.edit),
    )(state);
};

export default createReducer(
    {
        [changeConfirmationRemove]: handleChangeNeedForConfirmRemove,
        [edit]: handleEdit,
        [fetch]: handleFetch,
        [fetchAllCards]: handleFetchAllCards,
        [fetchAllCardsFailure]: handleFetchAllCardsFailure,
        [fetchAllCardsSuccess]: handleFetchAllCardsSuccess,
        [fetchFailure]: handleFetchFailure,
        [fetchSuccess]: handleFetchSuccess,
        [remove]: handleRemove,
        [removeFailure]: handleRemoveFailure,
        [removeHideMessage]: handleRemoveHideMessage,
        [removeSuccess]: handleRemoveSuccess,
    },
    initialState,
);

export * from './selectors';
