import { apiService } from './api';

export const statisticsService = {
    getAchievements: () =>
        apiService.get(
            '/service/statistics/api/profiles/%userId%/achievements',
        ),
    getStatistics: () =>
        apiService.get('/service/statistics/api/profiles/%userId%/statistics'),
};
