export const SocialEnum = {
    Apple: 'APPLE',
    Facebook: 'FACEBOOK',
    Google: 'GOOGLE',
    Instagram: 'INSTAGRAM',
    OneTap: 'oneTap',
    Tinkoff: 'TINKOFF',
    Vk: 'VK',
    Yandex: 'YANDEX',
};
