import { DATE_FORMATS } from 'consts/dateFormats';
import { documentType } from 'data';
import moment from 'moment';
import {
    compose,
    evolve,
    filter,
    findIndex,
    forEach,
    groupBy,
    indexBy,
    keys,
    map,
    mergeAll,
    pathOr,
    pick,
    prop,
    propEq,
    propOr,
    values as rValues,
} from 'ramda';
import { RULE_NOT_SEND_EXPIRE_PASSWORD } from 'utils/allowFeature';
import { validatePhone } from 'utils/validate';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const formatDate = value =>
    value ? moment(value, YYYY_MM_DD).format(DD_MM_YYYY) : '';
const formatDateBackend = value =>
    value ? moment(value, DD_MM_YYYY).format(YYYY_MM_DD) : '';

export const travelersAdapter = ({ travelers = {} }) => {
    const { content, page, size, totalElements, totalPage } = travelers;
    const nextPage = page + 1;

    const items = compose(
        indexBy(prop('id')),
        map(
            compose(
                evolve({
                    birthday: formatDate,
                    documents: map(
                        compose(
                            evolve({
                                dateOfIssue: formatDate,
                                expirationDate: formatDate,
                                startDate: formatDate,
                            }),
                        ),
                    ),
                    names: prop(0),
                }),
                item => ({
                    ...item,
                    raw: item,
                }),
            ),
        ),
    )(content);

    const list = keys(items);

    return {
        hasMore: nextPage < totalPage,
        items,
        list,
        page: nextPage,
        size,
        totalElements,
        totalPage,
    };
};

const documentFieldsByType = {
    [documentType.INTERNAL_PASSPORT]: [
        'dateOfIssue',
        'id',
        'number',
        'series',
        'type',
        'expirationDate',
    ],
    [documentType.INTERNATIONAL_PASSPORT]: [
        'dateOfIssue',
        'expirationDate',
        'id',
        'number',
        'series',
        'type',
    ],
    [documentType.BIRTH_CERTIFICATE]: [
        'dateOfIssue',
        'expirationDate',
        'id',
        'number',
        'series',
        'type',
    ],
    [documentType.MILITARY_CARD]: [
        'dateOfIssue',
        'expirationDate',
        'id',
        'number',
        'series',
        'type',
    ],
    [documentType.VISA]: [
        'birthCountry',
        'countryOfIssue',
        'placeOfIssue',
        'placeOfBirth',
        'dateOfIssue',
        'destinationCountry',
        'expirationDate',
        'id',
        'number',
        'schengen',
        'startDate',
        'type',
    ],
    [documentType.OTHER]: [
        'dateOfIssue',
        'countryOfIssue',
        'expirationDate',
        'id',
        'number',
        'type',
    ],
};

const cleanDocument = data => {
    if (
        data.type === documentType.INTERNAL_PASSPORT &&
        !RULE_NOT_SEND_EXPIRE_PASSWORD
    ) {
        data.expirationDate = moment(data.dateOfIssue, DD_MM_YYYY)
            .add(20, 'years')
            .format(DD_MM_YYYY);
    }

    if (data.type === documentType.MILITARY_CARD) {
        data.expirationDate = moment(data.dateOfIssue, DD_MM_YYYY)
            .add(70, 'years')
            .format(DD_MM_YYYY);
    }

    const document = evolve(
        {
            dateOfIssue: formatDateBackend,
            expirationDate: formatDateBackend,
            startDate: formatDateBackend,
        },
        data,
    );

    if (document.type === documentType.BIRTH_CERTIFICATE) {
        return pick(documentFieldsByType[document.type], {
            ...document,
            number: document.birthCertificate.number,
            series: document.birthCertificate.series,
        });
    }

    if (document.type === documentType.MILITARY_CARD) {
        return pick(documentFieldsByType[document.type], {
            ...document,
            number: document.militaryId.number,
            series: document.militaryId.series,
        });
    }

    return pick(documentFieldsByType[document.type], {
        ...document,
        number: document.number.replace(/\s/g, ''),
    });
};

export const prepareToSend = ({
    section,
    traveler = {},
    values,
    isNew,
    removeDocuments,
}) => {
    const data = [];
    const isPersonal = section === 'personal';
    const isContacts = section === 'contacts';
    const isLoyalty = section === 'loyalty';
    const isDocument = section === 'document';

    if (!section || isPersonal) {
        const { firstName, lastName, gender, birthday } = values;

        data.push({
            birthday: moment(birthday, DD_MM_YYYY).format(YYYY_MM_DD),
            gender,
            names: [
                {
                    firstName,
                    lang: 'en',
                    lastName,
                    middleName: null,
                    prefix: gender === 'MALE' ? 'MR' : 'MRS',
                },
            ],
        });
    }

    if (isNew || !section || isContacts) {
        const { email, phone } = values;
        const contacts = [];

        if (email) {
            contacts.push({
                type: 'EMAIL',
                value: email,
            });
        }

        if (phone && validatePhone(phone)) {
            contacts.push({
                type: 'PHONE',
                value: phone.replace(/[\s-()]/g, ''),
            });
        }

        data.push({ contacts });
    }

    const documents = propOr([], 'documents', traveler);

    if (isDocument) {
        const { id: documentId, ...document } = values;
        const documentsIndex = findIndex(propEq('id', documentId), documents);

        if (documentsIndex === -1) {
            documents.push(document);
        } else {
            documents[documentsIndex] = {
                id: documentId,
                ...document,
            };
        }

        if (
            document.type === documentType.BIRTH_CERTIFICATE &&
            document.number
        ) {
            const [series, number] = document.number.split(' ');

            document.birthCertificate = {
                number,
                series,
            };
        }

        data.push({ documents: map(cleanDocument, documents) });
    }

    if (isNew || !section) {
        /*
      Составляется масив документов на основе всех values.
      Имя документа имеет формат document_ID_FIELDNAME.
      Приводим обьект: {
        document_id1_name: val1,
        document_id2_name: val2,
        document_id1_name2: val3,
      }
      в масив обьектов
        [{
          id: id1,
          name: val1
          name2: val3
        },{
          id: id2,
          name: val2
        }]
    */

        data.push({
            documents: compose(
                map(fields => {
                    const documentId = fields[0].split('_')[1];

                    const documentsExist =
                        findIndex(propEq('id', documentId), documents) !== -1;
                    const doc = documentsExist
                        ? {
                              id: fields[0].split('_')[1],
                          }
                        : {};

                    forEach(field => {
                        doc[field.split('_')[2]] = values[field];
                    }, fields);

                    return cleanDocument(doc);
                }),
                rValues,
                groupBy(key => key.split('_')[1]),
                filter(
                    key =>
                        key.indexOf('document_') !== -1 &&
                        !removeDocuments.some(id => key.includes(id)),
                ),
                keys,
            )(values),
        });
    }

    if (isNew || !section || isLoyalty) {
        const { loyaltyAirline, loyaltyNumber, haveLoyalty } = values;

        if (haveLoyalty || isLoyalty) {
            const loyaltyId = pathOr('', ['loyaltyCards', 0, 'id'], traveler);

            data.push({
                loyaltyCards: [
                    {
                        ...(loyaltyId ? { id: loyaltyId } : {}),
                        airline: loyaltyAirline,
                        number: loyaltyNumber,
                    },
                ],
            });
        } else {
            data.push({ loyaltyCards: [] });
        }
    }

    return mergeAll(data);
};
