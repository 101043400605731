export default {
    BIRTH_CERTIFICATE: [
        'id',
        'countryOfIssue',
        'series',
        'number',
        'dateOfIssue',
    ],
    INTERNAL_PASSPORT: ['id', 'number', 'dateOfIssue'],
    INTERNATIONAL_PASSPORT: ['id', 'number', 'dateOfIssue', 'expirationDate'],
    MILITARY_CARD: ['id', 'series', 'number', 'dateOfIssue'],
    OTHER: ['id', 'countryOfIssue', 'number', 'expirationDate', 'dateOfIssue'],
    VISA: [
        'id',
        'number',
        'placeOfIssue',
        'countryOfIssue',
        'startDate',
        'dateOfIssue',
        'expirationDate',
        'multi',
        'destinationCountry',
        'birthCountry',
        'placeOfBirth',
    ],
};
