import React from 'react';
import { node, string } from 'prop-types';

import cx from './PaperContent.sss';

export const PaperContent = React.memo(function PaperContent(props) {
    const { children, className } = props;

    return <div className={cx('root', className)}>{children}</div>;
});

PaperContent.propTypes = {
    children: node,
    className: string,
};
