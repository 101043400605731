import { PROFILE_OPERATION_TYPE } from 'consts/profile';
import {
    assoc,
    assocPath,
    compose,
    concat,
    lensPath,
    over,
    pathOr,
    without,
} from 'ramda';
import { createAction, createReducer } from 'redux-act';

import { profileAdapter } from './adapter';

import { contactType } from '../../../data';
import { resetFading, setFading } from '../../../utils/fadingTime';
import { getErrorMessage } from '../../../utils/getErrorFromResponse';

const notificationState = {
    code: '',
    description: '',
    title: '',
};

const initialState = {
    approvals: [],
    contacts: {
        currentEditType: null,
        email: {
            isLoading: false,
            notification: notificationState,
            type: contactType.EMAIL,
        },
        isModalOpen: false,
        phone: {
            isLoading: false,
            isOpenInfoAboutFurtherChanges: false,
            notification: notificationState,
            type: contactType.PHONE,
        },
    },
    data: {
        address: {},
        emailConfirmed: 'true',
        telephone: {
            confirmed: 'true',
        },
    },
    fadingTime: setFading(),
    isEdit: [],
    isError: false,
    isInitialized: false,
    isLoading: false,
    meta: {
        isWeakPassword: false,
    },
    provider: null,
    status: '',
    publicIdAvaliable: true,
    publicIdCheckError: null,
    publickIdChecking: false,
    publicSettings: {
        mode: 'DENIED',
        publicId: '',
        publicName: '',
        publicPermissions: [],
        publicUrl: '',
    },
    search: {
        notification: null,
        result: {},
    },
    social: {
        error: null,
        isError: false,
        items: [],
        popup: {
            isOpen: false,
        },
    },
    unavailableOperations: Object.values(PROFILE_OPERATION_TYPE),
};

export const fetch = createAction('profile/fetch');
export const fetchSuccess = createAction('profile/fetchSuccess');
export const fetchFailure = createAction('profile/fetchFailure');

export const setEdit = createAction('profile/setEdit');

export const save = createAction('profile/savePassword');
export const saveSuccess = createAction('profile/savePasswordSuccess');
export const saveFailure = createAction('profile/savePasswordFailure');

export const update = createAction('profile/update');
export const updateSuccess = createAction('profile/updateSuccess');
export const updateFailure = createAction('profile/updateFailure');

export const updatePublic = createAction('profile/updatePublic');
export const updatePublicSuccess = createAction('profile/updatePublicSuccess');
export const updatePublicFailure = createAction('profile/updatePublicFailure');

export const updateContactSuccess = createAction(
    'profile/updateContactSuccess',
);

export const confirmContact = createAction('profile/confirmContact');
export const confirmContactSuccess = createAction(
    'profile/confirmContactSuccess',
);
export const confirmContactFailure = createAction(
    'profile/confirmContactFailure',
);

export const clearConfirmContact = createAction('profile/clearConfirmContact');
export const resetContactTimer = createAction('profile/resetContactTimer');
export const closeContactNotification = createAction(
    'profile/closeContactNotification',
);
export const openContactEditModal = createAction(
    'profile/openContactEditModal',
);
export const closeContactEditModal = createAction(
    'profile/closeContactEditModal',
);
export const changeIsOpenInfoAboutFurtherChanges = createAction(
    'profile/changeIsOpenInfoAboutFurtherChanges',
);

export const deleteB2bProfile = createAction('profile/deleteB2bProfile');
export const deleteB2bProfileSuccess = createAction(
    'profile/deleteB2bProfileSuccess',
);
export const deleteB2bProfileFailure = createAction(
    'profile/deleteB2bProfileFailure',
);

export const saveAvatar = createAction('profile/saveAvatar');
export const saveAvatarSuccess = createAction('profile/saveAvatarSuccess');
export const saveAvatarFailure = createAction('profile/saveAvatarFailure');

export const deleteAvatar = createAction('profile/deleteAvatar');
export const deleteAvatarSuccess = createAction('profile/deleteAvatarSuccess');
export const deleteAvatarFailure = createAction('profile/deleteAvatarFailure');

export const resetAvatarError = createAction('profile/resetAvatarError');

export const socialLink = createAction('profile/socialLink');
export const socialLinkFailure = createAction('profile/socialLinkFailure');

export const socialUnLink = createAction('profile/socialUnLink');

export const socialOpenPopup = createAction('profile/socialOpenPopup');
export const socialClosePopup = createAction('profile/socialClosePopup');

export const searchProfile = createAction('profile/searchProfile');
export const searchProfileSuccess = createAction(
    'profile/searchProfileSuccess',
);
export const searchProfileFailure = createAction(
    'profile/searchProfileFailure',
);

export const updateApprovals = createAction('profile/updateApprovals');
export const updateApprovalsSuccess = createAction(
    'profile/updateApprovalsSuccess',
);
export const updateApprovalsFailure = createAction(
    'profile/updateApprovalsFailure',
);

export const updatePreferences = createAction('profile/updatePreferences');
export const updatePreferencesSuccess = createAction(
    'profile/updatePreferencesSuccess',
);
export const updatePreferencesFailure = createAction(
    'profile/updatePreferencesFailure',
);

export const setContactsNotification = createAction(
    'profile/setContactsNotification',
);

export const checkPublicId = createAction('profile/checkPublicId');
export const resetCheckPublicId = createAction('profile/resetCheckPublicId');
export const checkingPublicId = createAction('profile/checkingPublicId');
export const checkPublicIdSuccess = createAction(
    'profile/checkPublicIdSuccess',
);
export const checkPublicIdFailure = createAction(
    'profile/checkPublicIdFailure',
);

export const b2bProfileSync = createAction('profile/b2bProfileSync');

export const updateProfileEmployeeAndCompanyData = createAction(
    'profile/updateProfileEmployeeAndCompanyData',
);

export const getEmployeeAndCompanyInfo = createAction(
    'profile/getEmployeeAndCompanyInfo',
);

const handleFetch = state => ({
    ...state,
    isError: false,
    isLoading: true,
});

const handleFetchSuccess = (state, payload) => {
    const profile = profileAdapter(payload.profile);
    const provider = profile.provider;
    const status = profile.status;
    const unavailableOperations = payload.unavailableOperations || [];

    return {
        ...state,
        approvals: pathOr(state.approvals, ['approvals'], profile),
        contacts: {
            ...state.contacts,
            email: {
                ...state.contacts.email,
                ...pathOr({}, ['contacts', 'EMAIL'], profile),
            },
            phone: {
                ...state.contacts.phone,
                ...pathOr({}, ['contacts', 'PHONE'], profile),
            },
        },
        data: profile,
        fadingTime: resetFading(),
        isError: false,
        isInitialized: true,
        isLoading: false,
        meta: {
            fromSocial: pathOr(false, ['fromSocial'], profile),
            isWeakPassword: pathOr(false, ['metaInf', 'weakPassword'], profile),
        },
        provider,
        status,
        publicId: pathOr(state.publicId, ['profile', 'publicId'], payload),
        publicSettings: pathOr(
            state.publicSettings,
            ['profile', 'preferences', 'publicSettings'],
            payload,
        ),
        raw: payload.profile,
        social: {
            ...state.social,
            items: pathOr([], ['socialAccounts'], profile),
        },
        unavailableOperations,
    };
};

const handleFetchFailure = state => ({
    ...state,
    isError: true,
    isInitialized: true,
    isLoading: false,
});

const handleSaveSuccess = (state, payload) => ({
    ...state,
    data: {
        ...state.data,
        ...payload.profile,
        ...profileAdapter(payload.profile),
    },
    fadingTime: setFading(),
});

const handleUpdateSuccess = (state, payload) => ({
    ...state,
    data: {
        ...state.data,
        ...payload.profile,
        ...profileAdapter(payload.profile),
    },
});

// Contact confirmation
const handleConfirmContact = (state, { type }) =>
    assocPath(['contacts', type.toLowerCase(), 'isLoading'], true, state);

const handleConfirmContactSuccess = (
    state,
    { type, notification = initialState, ...rest },
) => {
    return {
        ...state,
        contacts: {
            ...state.contacts,
            [type]: {
                ...(state.contacts[type] || {}),
                isLoading: false,
                notification,
                ...rest,
            },
        },
    };
};

const handleConfirmContactFailure = (state, { notification, type }) =>
    compose(
        assocPath(['contacts', type, 'notification'], notification),
        assocPath(['contacts', type, 'isLoading'], false),
    )(state);

const handleOpenContactEditModal = (state, type) =>
    compose(
        assocPath(['contacts', 'isModalOpen'], true),
        assocPath(['contacts', 'currentEditType'], type),
    )(state);

const handleCloseContactEditModal = (state, { isCancel, type }) => {
    let newState = compose(
        assocPath(['contacts', 'isModalOpen'], false),
        assocPath(['contacts', type, 'notification'], notificationState),
    )(state);

    if (isCancel) {
        newState = assocPath(['contacts', type, 'isSent'], false, newState);
    }

    return newState;
};

const handleResetContactTimer = (state, type) =>
    assocPath(['contacts', type, 'sentTime'], null, state);

const handleCloseContactNotification = (state, type) =>
    assocPath(['contacts', type, 'notification'], notificationState, state);

const handleClearConfirmContact = (state, type) =>
    compose(
        assocPath(['contacts', type, 'isSent'], false),
        assocPath(['contacts', type, 'codeRequestsAmount'], 0),
    )(state);

const handleChangeIsOpenInfoAboutFurtherChanges = (state, { type, isOpen }) =>
    assocPath(
        ['contacts', type, 'isOpenInfoAboutFurtherChanges'],
        isOpen,
        state,
    );
// Contact confirmation

const handleSetEdit = (state, payload) => {
    const { id, status, editableField } = payload;
    const lens = lensPath(['isEdit']);

    const newState = over(
        lens,
        editable => {
            if (status === true) {
                return concat([id], editable);
            }

            return without(id, editable);
        },
        state,
    );

    return {
        ...newState,
        contacts: {
            ...state.contacts,
            emailError: '',
            emailErrorValue: '',
            sessionId: status ? `${Date.now()}` : state.contacts.sessionId,
            telephoneError: '',
            telephoneErrorValue: '',
        },
        editableField,
    };
};

const handleSetContactsNotification = (state, notification) => ({
    ...state,
    contacts: {
        ...state.contacts,
        notification,
    },
});

const handleDeleteB2bProfileSuccess = state => ({
    ...state,
    data: {
        ...state.data,
        companyInfo: null,
    },
    fadingTime: setFading(),
});

const handleSaveAvatar = assocPath(['data', 'avatar', 'isSaving'], true);
const handleSaveAvatarSuccess = (state, avatar) =>
    assocPath(['data', 'avatar'], avatar, state);

const handleSaveAvatarFailure = (state, error) =>
    compose(
        assocPath(['data', 'avatar', 'error'], getErrorMessage(error)),
        assocPath(['data', 'avatar', 'isSaving'], false),
    )(state);

const handleDeleteAvatar = assocPath(['data', 'avatar', 'isDeleting'], true);
const handleDeleteAvatarSuccess = assocPath(['data', 'avatar'], null);

const handleDeleteAvatarFailure = (state, error) =>
    compose(
        assocPath(['data', 'avatar', 'error'], getErrorMessage(error)),
        assocPath(['data', 'avatar', 'isDeleting'], false),
    )(state);

const handleResetAvatarError = assocPath(['data', 'avatar', 'error'], '');

const handleSocialLink = state => state;
const handleSocialLinkFailure = (state, { error, social, name }) => ({
    ...state,
    social: {
        ...state.social,
        popup: {
            error,
            isOpen: true,
            name,
            social,
            type: 'error',
        },
    },
});

const handleSocialUnLink = state => state;

const handleSocialOpenPopup = (state, payload) => ({
    ...state,
    social: {
        ...state.social,
        popup: {
            isOpen: true,
            ...payload,
        },
    },
});

const handleSocialClosePopup = state => ({
    ...state,
    social: {
        ...state.social,
        popup: initialState.social.popup,
    },
});

const updateApproval = (array, updates) =>
    array.map(item =>
        item.clientId === updates.clientId ? { ...item, ...updates } : item,
    );

const handleUpdateApprovals = (state, { clientId }) => ({
    ...state,
    approvals: updateApproval(state.approvals, {
        clientId,
        error: null,
        isLoading: true,
    }),
});

const handleUpdateApprovalsSuccess = (state, approval) => ({
    ...state,
    approvals: updateApproval(state.approvals, {
        ...approval,
        isLoading: false,
    }),
});

const handleUpdateApprovalsFailure = (state, { clientId, error }) => ({
    ...state,
    approvals: updateApproval(state.approvals, {
        clientId,
        error,
        isLoading: false,
    }),
});

const handleUpdateContactSuccess = (state, { type, value, verified }) =>
    assocPath(['data', 'contacts', type], { type, value, verified }, state);

const handleSearchProfileSuccess = (state, { profile, key }) => ({
    ...state,
    search: {
        ...state.search,
        result: assoc(key, profile, state.search.result),
    },
});

const handleSearchProfileFailure = (state, { notification }) => ({
    ...state,
    search: {
        ...state.search,
        notification,
    },
});

const handleUpdatePreferencesSuccess = (state, preferences) => ({
    ...state,
    data: {
        ...state.data,
        preferences,
    },
});

const handleUpdatePreferencesFailure = (state, updates) => ({
    ...state,
    data: {
        ...state.data,
        preferences: {
            ...state.data.preferences,
            ...updates,
        },
    },
});
const handleUpdatePublicSuccess = (state, updates) => ({
    ...state,
    publicSettings: {
        ...state.publicSettings,
        ...updates,
    },
});

const handleCheckPublicId = state => ({
    ...state,
    publicIdAvaliable: true,
    publicIdCheckError: null,
    publickIdChecking: true,
});

const handleResetCheckPublicId = state => ({
    ...state,
    publicIdAvaliable: true,
    publicIdCheckError: null,
    publickIdChecking: false,
});

const handleCheckPublicIdChecking = state => ({
    ...state,
    publicIdAvaliable: true,
    publicIdCheckError: null,
    publickIdChecking: true,
});

const handleCheckPublicIdSuccess = state => ({
    ...state,
    publicIdAvaliable: true,
    publicIdCheckError: null,
    publickIdChecking: false,
});

const handleCheckPublicIdFailure = (state, error) => ({
    ...state,
    publicIdAvaliable: false,
    publicIdCheckError: error,
    publickIdChecking: false,
});

const handleB2bProfileSync = (state, syncEnabled) => ({
    ...state,
    data: {
        ...state.data,
        employee: {
            ...state.data.employee,
            profileSync: {
                ...state.data.employee.profileSync,
                enabled: syncEnabled,
            },
        },
    },
});

const handleUpdateProfileEmployeeAndCompanyData = (
    state,
    { employeeAndCompanyData },
) => ({
    ...state,
    data: {
        ...state.data,
        company: employeeAndCompanyData.company,
        employee: employeeAndCompanyData.employee,
    },
});

export default createReducer(
    {
        [b2bProfileSync]: handleB2bProfileSync,
        [changeIsOpenInfoAboutFurtherChanges]:
            handleChangeIsOpenInfoAboutFurtherChanges,
        [checkingPublicId]: handleCheckPublicIdChecking,
        [checkPublicId]: handleCheckPublicId,
        [checkPublicIdFailure]: handleCheckPublicIdFailure,
        [checkPublicIdSuccess]: handleCheckPublicIdSuccess,
        [clearConfirmContact]: handleClearConfirmContact,
        [closeContactEditModal]: handleCloseContactEditModal,
        [closeContactNotification]: handleCloseContactNotification,
        [confirmContact]: handleConfirmContact,
        [confirmContactFailure]: handleConfirmContactFailure,
        [confirmContactSuccess]: handleConfirmContactSuccess,
        [deleteAvatar]: handleDeleteAvatar,
        [deleteAvatarFailure]: handleDeleteAvatarFailure,
        [deleteAvatarSuccess]: handleDeleteAvatarSuccess,
        [deleteB2bProfileSuccess]: handleDeleteB2bProfileSuccess,
        [fetch]: handleFetch,
        [fetchFailure]: handleFetchFailure,
        [fetchSuccess]: handleFetchSuccess,
        [openContactEditModal]: handleOpenContactEditModal,
        [resetAvatarError]: handleResetAvatarError,
        [resetCheckPublicId]: handleResetCheckPublicId,
        [resetContactTimer]: handleResetContactTimer,
        [saveAvatar]: handleSaveAvatar,
        [saveAvatarFailure]: handleSaveAvatarFailure,
        [saveAvatarSuccess]: handleSaveAvatarSuccess,
        [saveSuccess]: handleSaveSuccess,
        [searchProfileFailure]: handleSearchProfileFailure,
        [searchProfileSuccess]: handleSearchProfileSuccess,
        [setContactsNotification]: handleSetContactsNotification,
        [setEdit]: handleSetEdit,
        [socialClosePopup]: handleSocialClosePopup,
        [socialLink]: handleSocialLink,
        [socialLinkFailure]: handleSocialLinkFailure,
        [socialOpenPopup]: handleSocialOpenPopup,
        [socialUnLink]: handleSocialUnLink,
        [updateApprovals]: handleUpdateApprovals,
        [updateApprovalsFailure]: handleUpdateApprovalsFailure,
        [updateApprovalsSuccess]: handleUpdateApprovalsSuccess,
        [updateContactSuccess]: handleUpdateContactSuccess,
        [updatePreferencesFailure]: handleUpdatePreferencesFailure,
        [updatePreferencesSuccess]: handleUpdatePreferencesSuccess,
        [updateProfileEmployeeAndCompanyData]:
            handleUpdateProfileEmployeeAndCompanyData,
        [updatePublicSuccess]: handleUpdatePublicSuccess,
        [updateSuccess]: handleUpdateSuccess,
    },
    initialState,
);

export * from './selectors';
