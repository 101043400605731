// Turkish
module.exports = [
    ['ş', 's'],
    ['Ş', 's'],
    ['ç', 'c'],
    ['Ç', 'c'],
    ['ğ', 'g'],
    ['Ğ', 'g'],
    ['ı', 'i'],
    ['İ', 'i'],
];
