import { apiService } from './api';

export const publicProfileService = {
    getMedia: publicId =>
        apiService.get(
            `/service/profiles/api/public/profiles/${publicId}/media`,
        ),
    getNearestFlight: publicId =>
        apiService.get(
            `/service/trips/api/public/profiles/${publicId}/nearest-flight`,
        ),
    getProfile: publicId =>
        apiService.get(
            `/service/profiles/api/public/profiles/${publicId}/data`,
        ),
};
