import { apiService } from './api';

const baseUrl = '/mbe/api/profiles/%userId%';

const getConversationHeaders = conversationId =>
    conversationId
        ? {
              'X-Conversation': conversationId,
          }
        : {};

export const tripsService = {
    deleteOrder: ({ tripId, orderId }) =>
        apiService.delete(`${baseUrl}/trips/${tripId}/orders/${orderId}`),
    deleteTrip: tripId => apiService.delete(`${baseUrl}/trips/${tripId}`),
    getConversationId: () => apiService.get('/conversationId'),
    getTrip: (id, conversationId) =>
        apiService.get(`${baseUrl}/trips/${id}`, {
            headers: getConversationHeaders(conversationId),
        }),
    searchTrips: (params, conversationId) =>
        apiService.get(`${baseUrl}/search-trips`, {
            headers: getConversationHeaders(conversationId),
            params,
        }),
    updateTrip: ({ id, data }) =>
        apiService.put(`${baseUrl}/trips/${id}`, { trip: data }),
};
