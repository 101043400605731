// '_dash' - ставим на конце, когда строка имеет формат 'DD-MM-YY'
// '_dot' - ставим на конце, когда строка имеет формат 'DD.MM.YY'
// '_slash' - ставим на конце, когда строка имеет формат 'DD/MM/YY'
// INDEX - называем свойство, если совпадает с путём вложенности

export const DATE_FORMATS = Object.freeze({
    D: {
        MMM: {
            dd: 'D MMM, dd',
            ddd: 'D MMM, ddd',
            INDEX: 'D MMM',
            YYYY: 'D MMM YYYY',
        },
        MMMM: {
            INDEX: 'D MMMM',
            YYYY: 'D MMMM YYYY',
        },
    },
    DD: {
        INDEX: 'DD',
        MM: {
            YY_dot: 'DD.MM.YY',
            YYYY_dash: 'DD-MM-YYYY',
            YYYY_dot: 'DD.MM.YYYY',
        },
        MMM: {
            YYYY: 'DD MMM YYYY',
        },
        MMMM: 'DD MMMM',
    },
    ddd: {
        D_MMM: {
            HH_mm: 'ddd, D MMM, HH:mm',
            INDEX: 'ddd, D MMM',
        },
    },
    HH_mm: 'HH:mm',
    ll: 'll',
    MM: 'MM',
    MM_YY_slash: 'MM/YY',
    MMM: 'MMM',
    mmm: 'mmm',
    YYYY: {
        INDEX: 'YYYY',
        MM: {
            DD_dash: 'YYYY-MM-DD',
            DD_dot: 'YYYY.MM.DD',
        },
    },
    Z: 'Z',
});
