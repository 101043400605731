import { compose, length, replace } from 'ramda';

import russian from './slugify/replacements/russian';

export const normalizeSeriesNumber = value => value.replace(/[^A-Z0-9]/gi, '');
export const normalizeNumber = value => value.replace(/[^0-9]/g, '');
export const normalizeCardNumber = compose(
    replace(/([0-9]{3})(?!$)/g, '$1 '),
    value => (length(value) > 9 ? value.slice(0, 9) : value),
    normalizeNumber,
);

const INVALID_SYMBOLS_REGEX = /[^A-ZА-ЯЁ]/g;

const rusToLatin = (value, customReplacements) => {
    const symbols = Object.fromEntries(russian);

    if (customReplacements) {
        Object.keys(customReplacements).forEach(key => {
            symbols[key] = customReplacements[key];
        });
    }

    return value.split('').reduce((acc, letter) => {
        const en = symbols[letter] ?? letter;

        return acc + en;
    }, '');
};

export const normalizeSeriesToLatin = (value = '') => {
    if (!value) {
        return '';
    }

    const valueUppercase = value
        .toLocaleUpperCase()
        .replace(INVALID_SYMBOLS_REGEX, '');

    const transliteration = rusToLatin(valueUppercase, { Х: 'X', Щ: 'SC' });

    return `${transliteration}`.toLocaleUpperCase();
};
