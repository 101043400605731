import { useEffect, useRef, useState } from 'react';

import { isClient } from '../utils';

const INTERVAL_DELAY = 500;

export const useAddThisLoaded = () => {
    const intervalId = useRef();
    const [isAddThisLoaded, setIsAddThisLoaded] = useState(false);

    const checkScriptLoading = () => {
        if (isClient && window.addthis) {
            setIsAddThisLoaded(true);
        }
    };

    useEffect(() => {
        intervalId.current = setInterval(checkScriptLoading, INTERVAL_DELAY);

        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current);
                intervalId.current = null;
            }
        };
    }, []);

    return isAddThisLoaded;
};
