const { isClient } = require('./ssr');

let publicRuntimeConfig = {};

try {
    // eslint-disable-next-line global-require
    const getConfig = require('next/config');

    if (isClient) {
        const { publicRuntimeConfig: runtimeConfig } = getConfig.default() || {
            publicRuntimeConfig: {
                NODE_STAGE: 'production',
            },
        };

        publicRuntimeConfig = runtimeConfig;
    }
} catch (e) {
    publicRuntimeConfig = { NODE_STAGE: 'qa' };
}

const env = {
    ...publicRuntimeConfig,
    ...process.env,
};

const isProduction = env.NODE_STAGE === 'production';
const isStage = env.NODE_STAGE === 'stage';
const isRest = env.NODE_STAGE === 'rest';
const isQa = env.NODE_STAGE === 'qa';
const isDev = env.NODE_STAGE === 'dev';
const buildId = process.env.BUILD_ID;
const isCDN = env.NODE_CDN === 'true';
const isDevelop = !isProduction && !isStage && !isRest && !isQa && !isDev;
const stage = isDevelop ? 'dev' : env.NODE_STAGE;
const sentryEnabled = env.SENTRY_ENABLED === 'true';
const prod = isProduction || isStage;
const CDNUrl = prod
    ? 'https://s3-myprofile.s7cdn.online/build'
    : 'https://s3-myprofile.s7cdn.online/qabuild';

module.exports = {
    buildId,
    CDNUrl,
    isCDN,
    isDev,
    isDevelop,
    isProduction,
    isQa,
    isRest,
    isStage,
    prod,
    sentryEnabled,
    stage,
};
