import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { isEmpty, last, mergeRight, pickBy } from 'ramda';
import { createSelector } from 'reselect';

import { isTripDateSatisfiesFilterDate } from './utils';

import { isFading } from '../../../utils/fadingTime';

const D_MMM = DATE_FORMATS.D.MMM.INDEX;
const YYYY = DATE_FORMATS.YYYY.INDEX;

const getTravels = state => state.travels;
const getBookings = state => getTravels(state).bookings.items;
const getArchives = state => getTravels(state).archive.items;
const getArchiveBookings = createSelector(getTravels, travels => {
    const { city, passenger, dates } = travels.archive.filters;

    const result = pickBy(
        ({ citiesMap, finishedAt, passengersMap, startedAt }) => {
            const cityFilter = !city || Boolean(citiesMap[city.value]);

            const passengerFilter =
                !passenger || Boolean(passengersMap[passenger.value]);
            const datesFilter =
                isEmpty(dates) ||
                isTripDateSatisfiesFilterDate([startedAt, finishedAt], dates);

            return cityFilter && passengerFilter && datesFilter;
        },
        travels.archive.items,
    );

    return result;
});

export const isLoadedBookings = state =>
    isFading(state.travels.fadingBookingsTime);
export const isLoadedArchive = state =>
    isFading(state.travels.fadingArchiveTime);

export const isFetchBookings = state => state.travels.isBookingsFetch;
export const isFetchArchive = state => state.travels.isArchiveFetch;

export const isLoadingBookings = state => state.travels.isBookingsLoading;
export const isLoadingArchive = state => state.travels.isArchiveLoading;

export const isErrorBookings = state => state.travels.isBookingsError;
export const isErrorArchive = state => state.travels.isArchiveError;

export const hasBookingsMore = state => state.travels.hasBookingsMore;
export const hasArchiveMore = state => state.travels.hasArchiveMore;

export const hasFilters = state => {
    const { filters } = state.travels.archive;

    return Object.keys(filters).some(
        key => filters[key] !== null && !isEmpty(filters[key]),
    );
};

export const getIsDeleting = (id, state) => state.travels.deleting.includes(id);
export const getIsUpdating = (id, state) => state.travels.updating.includes(id);

export const getBookingsOffset = state => ({
    page: state.travels.bookings.page,
    size: state.travels.bookings.size,
    totalElements: state.travels.bookings.totalElements,
});

export const getArchiveOffset = state => ({
    page: state.travels.archive.page,
    size: state.travels.archive.size,
    totalElements: state.travels.archive.totalElements,
});

export const getBookingsIds = createSelector(getBookings, items =>
    Object.keys(items),
);
export const getArchiveIds = createSelector(getArchiveBookings, items =>
    Object.keys(items),
);
export const hasArchive = createSelector(
    getArchives,
    items => Object.keys(items).length > 0,
);
export const hasArchiveFiltered = createSelector(
    getArchiveIds,
    ids => ids.length > 0,
);
export const hasBookings = createSelector(
    getBookingsIds,
    ids => ids.length > 0,
);

export const getBooking = (id, state) => state.travels.bookings.items[id];
export const getArchive = (id, state) => state.travels.archive.items[id];
export const getTrip = (id, state) =>
    state.travels.bookings.items[id] ||
    state.travels.archive.items[id] ||
    (state.travels.trip?.id === id && state.travels.trip);

export const getFirstBookingPnr = state => {
    const ids = getBookingsIds(state);

    let pnr = '';

    let travel = null;

    ids.some(id => {
        const booking = getBooking(id, state);

        if (booking.pnr) {
            ({ pnr } = booking);

            if (booking.flightsTo[0]) {
                const { departureCity } = booking.flightsTo[0];
                const cities = [departureCity];

                booking.flightsTo.forEach(({ arrivalCity }) => {
                    if (arrivalCity && cities.indexOf(arrivalCity) === -1) {
                        cities.push(arrivalCity);
                    }
                });

                travel = [departureCity, last(cities)].join(' - ');
            }

            return true;
        }

        return false;
    });

    return { pnr, travel };
};

export const getCertificates = state => state.travels.certificates;

export const getTravelDate = ({ startedAt, finishedAt }) => {
    const startMoment = moment(startedAt);
    const endMoment = moment(finishedAt);
    const startDay = startMoment.format(D_MMM);
    const endDay = endMoment.format(D_MMM);
    const startYear = startMoment.format(YYYY);
    const endYear = endMoment.format(YYYY);

    return `${startDay} ${
        startYear !== endYear ? startYear : ''
    } – ${endDay} ${endYear}`;
};

export const getArchiveTripsCities = createSelector(
    [getArchiveIds, state => state],
    (ids, state) => {
        const uniqueCities = ids.reduce((accum, id) => {
            const { citiesMap } = getTrip(id, state);

            return mergeRight(accum, citiesMap);
        }, []);

        return Object.entries(uniqueCities).map(([code, city]) => ({
            label: city,
            value: code,
        }));
    },
);

export const getArchiveTripsPassengers = createSelector(
    [getArchiveIds, state => state],
    (ids, state) => {
        const uniquePassangers = ids.reduce((accum, id) => {
            const { passengersMap } = getTrip(id, state);

            return mergeRight(accum, passengersMap);
        }, []);

        return Object.entries(uniquePassangers).map(([passengerId, data]) => ({
            data,
            passengerId,
        }));
    },
);

export const getArchiveFilters = state => state.travels.archive.filters;
export const getFilteredArchiveTripsCount = createSelector(
    getArchiveIds,
    ids => ids.length,
);
export const getIsTripLoading = state => state.travels.isTripLoading;
