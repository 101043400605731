import { createSelector } from 'reselect';

export const getPetsAddFormShow = state => state.pets.addFormShow;
export const getPetsAddFormError = state => state.pets.addFormError;
export const getPetsEditFormError = state => state.pets.editFormError;
export const getPetsError = state => state.pets.error;
export const getIsPetsLoading = state => state.pets.isLoading;
export const getIsPetsLoaded = state => state.pets.isLoaded;
export const getPets = createSelector(
    state => state.pets.items,
    items => Object.values(items),
);

export const getPetById = id => state => {
    if (state.pets?.items) {
        return state.pets?.items[id];
    }

    return null;
};
