import React from 'react';
import { DATE_FORMATS } from 'consts/dateFormats';
import { PROFILE_OPERATION_TYPE } from 'consts/profile';
import moment from 'moment';
import {
    compose,
    filter,
    indexBy,
    map,
    not,
    pathOr,
    prop,
    sort,
    uniq,
    values,
} from 'ramda';
import { createSelector } from 'reselect';
import {
    RULE_MILES_BUY,
    RULE_MILES_CERTIFICATE,
    RULE_MILES_CHARITY,
    RULE_MILES_RECOVERY,
    RULE_MILES_TRANSFER,
} from 'utils/allowFeature';

import { Text } from '@s7/ui-kit';
import { AircraftTakeOff } from '@s7/ui-kit/icons';

import {
    getMilesText,
    getNoun,
    getTimer,
    prepareMilesRecoveryFlight,
} from './helpers';

import { isNumber } from '../../../utils';
import { isFading } from '../../../utils/fadingTime';
import { getAirlinesForMilesRecover } from '../airlines';
import { getAirports } from '../countries';
import { getDictionary, getLocale } from '../languages';
import {
    getAvailableMilesOperations,
    getMiles as getMilesBalance,
} from '../loyalty';
import {
    getCustomerValue,
    getIgnoreCase,
    getProfileUnavailableOperations,
} from '../profile';

export const MILE = 'MILE';
export const RUB = 'RUB';
const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const YYYY = DATE_FORMATS.YYYY.INDEX;

export const getFull = state => state.miles;
export const getMiles = (id, state) =>
    (state.miles.milesById[id] || {}).miles || 0;
export const isLoaded = (id, state) =>
    // eslint-disable-next-line no-extra-boolean-cast
    !!state.miles.milesById[id]
        ? isFading(state.miles.milesById[id].fadingTime)
        : false;

export const getExpandedMileActions = state => state.miles.expandedMileActions;

const sortPackages = sort((a, b) => a.milesCount - b.milesCount);
const filterPackages = milesBalance =>
    filter(({ milesCount }) => milesCount <= milesBalance);
const mapPackages = formatTitle =>
    map(item => ({
        ...item,
        label: formatTitle(item),
        name: item.id,
        title: formatTitle(item),
        value: item.id,
    }));

export const getPackagesIsLoading = (state, type) =>
    pathOr(false, ['miles', type, 'packages', 'isLoading'], state);
export const getPackagesIsLoaded = (state, type) =>
    pathOr(false, ['miles', type, 'packages', 'isLoaded'], state);
export const getPackagesHasError = (state, type) =>
    pathOr(false, ['miles', type, 'packages', 'hasError'], state);
export const getPackagesNotification = (state, type) =>
    pathOr(null, ['miles', type, 'packages', 'notification'], state);
export const getPackagesItems = (state, type) =>
    pathOr(null, ['miles', type, 'packages', 'items'], state);

export const getTransferPackages = createSelector(
    getDictionary,
    getMilesBalance,
    state => pathOr([], ['miles', 'transfer', 'packages', 'items'], state),
    (dictionary, milesBalance, packages) => {
        const milesText = getMilesText(dictionary);

        return compose(
            sortPackages,
            mapPackages(
                ({ milesCount, price }) =>
                    `${milesCount} ${milesText} / ${price} ₽`,
            ),
            filterPackages(milesBalance),
        )(packages);
    },
);

export const getCharityPackages = createSelector(
    getDictionary,
    getMilesBalance,
    state => pathOr([], ['miles', 'charity', 'packages', 'items'], state),
    (dictionary, milesBalance, packages) => {
        const milesText = getMilesText(dictionary);

        return compose(
            sortPackages,
            mapPackages(({ milesCount }) => `${milesCount} ${milesText}`),
            filterPackages(milesBalance),
        )(packages);
    },
);

export const getActivateStatusPackage = pathOr({}, [
    'miles',
    'activateStatus',
    'packages',
    'items',
    0,
]);

export const getPurchasePackages = createSelector(
    getDictionary,
    state => pathOr([], ['miles', 'purchase', 'packages', 'items'], state),
    state => pathOr([], ['miles'], state),
    (dictionary, packages) => {
        const milesText = getMilesText(dictionary);

        return compose(
            sortPackages,
            mapPackages(
                ({ milesCount, price }) =>
                    `${milesCount} ${milesText} / ${price} ₽`,
            ),
        )(packages);
    },
);
// expiration packages
export const getExpirationPackages = createSelector(
    getDictionary,
    state => pathOr([], ['miles', 'expiration', 'packages', 'items'], state),
    state => pathOr([], ['miles'], state),
    (dictionary, packages) => {
        const milesText = getMilesText(dictionary);

        return compose(
            sortPackages,
            mapPackages(
                ({ milesCount, price }) =>
                    `${milesCount} ${milesText} / ${price} ₽`,
            ),
        )(packages);
    },
);

export const milesProlongationIsAvailableSelector = createSelector(
    getCustomerValue,
    getAvailableMilesOperations,
    getExpirationPackages,
    getProfileUnavailableOperations,
    (
        customerValue,
        availableMilesOperations,
        expirationPackages,
        unavailableOperations,
    ) =>
        Boolean(
            customerValue === 'FULL' &&
                availableMilesOperations.isExpiration &&
                expirationPackages.length &&
                !unavailableOperations.includes(
                    PROFILE_OPERATION_TYPE.MILES_EXPIRATION,
                ),
        ),
);
// expiration action
const getExpirationValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'expiration', value], state);

export const getExpirationAction = state =>
    getExpirationValue(state, 'isAction');
export const getMilesExpirationHasError = state =>
    getExpirationValue(state, 'hasError');
export const getMilesExpirationNotification = state =>
    getExpirationValue(state, 'notification');
export const getMilesExpirationTransactionId = state =>
    getExpirationValue(state, 'transactionId');
export const getMilesExpirationPackagesById = createSelector(
    getExpirationPackages,
    packages => indexBy(prop('id'), packages),
);
export const getMilesExpirationModal = state =>
    getExpirationValue(state, 'modal');

// charity accounts
const getCharityAccountsValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'charity', 'accounts', value], state);

export const getCharityAccountsHasError = state =>
    getCharityAccountsValue(state, 'hasError');
export const getCharityAccountsIsLoading = state =>
    getCharityAccountsValue(state, 'isLoading');
export const getCharityAccountsIsLoaded = state =>
    getCharityAccountsValue(state, 'isLoaded');
export const getCharityAccountsNotification = state =>
    getCharityAccountsValue(state, 'notification');
export const getCharityAccounts = createSelector(
    state => getCharityAccountsValue(state, 'items'),
    charities =>
        compose(
            map(({ id, description }) => ({
                label: description,
                name: id,
                title: description,
                value: id,
            })),
            sort((a, b) => {
                if (a.description > b.description) {
                    return 1;
                }
                if (a.description < b.description) {
                    return -1;
                }

                return 0;
            }),
        )(charities),
);

// milies loyaltyCard
const getLoyaltyCardOrdersValue = (state, value) =>
    pathOr(null, ['miles', 'loyaltyCardOrders', value], state);
const getLoyaltyCardValue = (state, value) =>
    pathOr(null, ['miles', 'loyaltyCard', value], state);

export const getLoyaltyCardOrdersIsLoading = state =>
    getLoyaltyCardOrdersValue(state, 'isLoading');
export const getLoyaltyCardOrdersIsLoaded = state =>
    getLoyaltyCardOrdersValue(state, 'isLoaded');
export const getLoyaltyCardOrdersIsError = state =>
    getLoyaltyCardOrdersValue(state, 'hasError');
export const getLoyaltyCardOrdersItems = state =>
    getLoyaltyCardOrdersValue(state, 'items');
export const getLoyaltyCardOrdersItem = id => state =>
    getLoyaltyCardOrdersItems(state).find(order => order.id === id);

export const getLoyaltyCardPackages = state =>
    state.miles.loyaltyCard.packages.items;
export const getLoyaltyCardTransactionId = state =>
    getLoyaltyCardValue(state, 'transactionId');
export const getLoyaltyCardIsShowModal = state =>
    getLoyaltyCardValue(state, 'isShowModal');
export const getLoyaltyCardNotification = state =>
    getLoyaltyCardValue(state, 'notification');
export const getLoyaltyCard3ds = state => getLoyaltyCardValue(state, '_3ds');
export const getLoyaltyCardIsProcessTransaction = state =>
    getLoyaltyCardValue(state, 'isProcessTransaction');
export const getLoyaltyCardIsSuccess = state =>
    getLoyaltyCardValue(state, 'isSuccess');

export const getLoyaltyCardRemainingTime = state => {
    const codeSentTime = getLoyaltyCardValue(state, 'codeSentTime');

    return getTimer(codeSentTime);
};

// milies profileAddresses
const getProfileAddressessValue = (state, value) =>
    pathOr(null, ['miles', 'addresses', value], state);

export const getProfileAddressesIsLoading = state =>
    getProfileAddressessValue(state, 'isLoading');
export const getProfileAddressesIsLoaded = state =>
    getProfileAddressessValue(state, 'isLoaded');
export const getProfileAddressesIsError = state =>
    getProfileAddressessValue(state, 'hasError');
export const getProfileAddressesItems = state =>
    getProfileAddressessValue(state, 'items');

// miles recover
const getRecoverValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'recover', value], state);

export const getIsOpenBoardingPass = state =>
    getRecoverValue(state, 'isOpenBoardingPass');
export const getMilesRecoverNotification = state =>
    getRecoverValue(state, 'notification');
export const getMilesRecoverInitialValues = state =>
    getRecoverValue(state, 'initialValues');
export const getMilesRecoverIsLoadingRequests = state =>
    getRecoverValue(state, 'isLoadingRequests');
export const getMilesRecoverIsLoadedRequests = state =>
    getRecoverValue(state, 'isLoadedRecovery');
export const getMilesRecoverIsOpenForm = state =>
    getRecoverValue(state, 'isOpenRecoverForm');
export const getMilesRecoverRequestsNotification = state =>
    getRecoverValue(state, 'requestsNotification');
export const getMilesRecoverNextPage = state =>
    getRecoverValue(state, 'nextPage');

export const getMilesRecoverIsAllLoadedPages = state => {
    const nextPage = getRecoverValue(state, 'nextPage');
    const totalPages = getRecoverValue(state, 'totalPages') - 1;

    return isNumber(totalPages) && totalPages >= 0 && nextPage > totalPages;
};

export const getMilesRecoverShouldShowPagination = state => {
    const totalPages = getRecoverValue(state, 'totalPages');

    const isAllPagesLoaded = getMilesRecoverIsAllLoadedPages(state);

    const isMoreThanOnePageExist = isNumber(totalPages) && totalPages > 1;

    return isMoreThanOnePageExist && !isAllPagesLoaded;
};

export const getMilesRecoverRequests = createSelector(
    state => state.miles.recover,
    recover => recover.requests,
);

export const getIsLoadingFlight = state => state.miles.recover.isLoadingFlight;

const AircraftIcon = <AircraftTakeOff size="md" />;

const generateFlightLabel = flight => (
    <>
        <Text weight="semibold" block>{`${flight.company} ${flight.fid}`}</Text>
        <Text color="N60" size="s" block>
            {flight.std}
        </Text>
    </>
);

export const getFlightNumbers = createSelector(
    state => state.miles.recover.flightNumbers,
    flights => {
        if (!flights) {
            return [];
        }

        const options = Object.values(flights).map(flightArr => {
            const [flight] = flightArr;

            return {
                label: generateFlightLabel(flight),
                left: AircraftIcon,
                value: flight.fid,
            };
        });

        return options;
    },
);

export const getFlightNumbersNotification = createSelector(
    state => state.miles.recover,
    recover => recover.flightNumbersNotification,
);

export const getFlight = createSelector(
    state => state.miles.recover.flight,
    getAirports,
    getAirlinesForMilesRecover,
    (flight, airports = [], airlines = []) =>
        prepareMilesRecoveryFlight({
            airlines,
            airports,
            flight,
        }),
);

export const getFlightNotification = createSelector(
    state => state.miles.recover,
    recover => recover.flightNotification,
);

// activate status
const getActivateStatusValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'activateStatus', value], state);

export const getActivateStatusHasError = state =>
    getActivateStatusValue(state, 'hasError');
export const getActivateStatusNotification = state =>
    getActivateStatusValue(state, 'notification');
export const getActivateStatusTransactionId = state =>
    getActivateStatusValue(state, 'transactionId');
export const getActivateStatus3ds = state =>
    getActivateStatusValue(state, '_3ds');

// miles buy
const getBuyValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'buy', value], state);

export const getMilesBuyHasError = state => getBuyValue(state, 'hasError');
export const getMilesBuyModal = state => getBuyValue(state, 'modal', {});
export const getMilesBuyNotification = state =>
    getBuyValue(state, 'notification');
export const getMilesBuyTransactionId = state =>
    getBuyValue(state, 'transactionId');
export const getMilesBuyPackagesById = createSelector(
    getPurchasePackages,
    packages => indexBy(prop('id'), packages),
);

// status packages
export const getStatusPackages = createSelector(
    getDictionary,
    state => pathOr([], ['miles', 'prolongation', 'packages', 'items'], state),
    (dictionary, packages) =>
        compose(
            sortPackages,
            mapPackages(({ quantity, price, type, currency }) => {
                let milesText = getNoun('statusmiles', dictionary);

                let payText = value => `${value} ₽`;

                if (type === 'SEGMENT') {
                    milesText = getNoun('serments.field', dictionary);
                }

                if (currency === MILE) {
                    payText = getNoun('miles', dictionary);
                }

                return `${milesText(quantity)} / ${payText(price)}`;
            }),
        )(packages),
);

export const getStatusPackagesMile = createSelector(
    getStatusPackages,
    packages => filter(({ currency }) => currency === MILE, packages),
);
export const getStatusPackagesRub = createSelector(
    getStatusPackages,
    packages => filter(({ currency }) => currency === RUB, packages),
);

export const getStatusPackagesById = createSelector(
    getStatusPackages,
    packages => indexBy(prop('id'), packages),
);

// upgrade packages
export const getUpgradePackages = createSelector(
    getDictionary,
    state => pathOr([], ['miles', 'upgrade', 'packages', 'items'], state),
    (dictionary, packages) =>
        compose(
            sortPackages,
            mapPackages(({ quantity, price, type, currency }) => {
                let milesText = getNoun('statusmiles', dictionary);

                let payText = value => `${value} ₽`;

                if (type === 'SEGMENT') {
                    milesText = getNoun('serments.field', dictionary);
                }

                if (currency === MILE) {
                    payText = getNoun('miles', dictionary);
                }

                return `${milesText(quantity)} / ${payText(price)}`;
            }),
        )(packages),
);

export const getUpgradeIsSuccessfullyFinished = state =>
    pathOr(false, ['miles', 'upgrade', 'isSuccessfullyFinished'], state);

export const getUpgradePackagesMile = createSelector(
    getUpgradePackages,
    packages => filter(({ currency }) => currency === MILE, packages),
);
export const getUpgradePackagesRub = createSelector(
    getUpgradePackages,
    packages => filter(({ currency }) => currency === RUB, packages),
);

export const getUpgradePackagesById = createSelector(
    getUpgradePackages,
    packages => indexBy(prop('id'), packages),
);

export const getAllowedUpgradeTypes = createSelector(
    getUpgradePackages,
    packages =>
        compose(
            uniq,
            map(val => val.type),
        )(packages),
);

// status prolongation
const getStatusProlongationValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'prolongation', value], state);

export const getStatusProlongationHasError = state =>
    getStatusProlongationValue(state, 'hasError');
export const getStatusProlongationNotification = state =>
    getStatusProlongationValue(state, 'notification');
export const getStatusProlongationIsSentCode = state =>
    getStatusProlongationValue(state, 'isSentCode');

export const getStatusProlongationRemainingTime = state => {
    const codeSentTime = getStatusProlongationValue(state, 'codeSentTime');

    return getTimer(codeSentTime);
};

export const getStatusProlongationTransactionId = state =>
    getStatusProlongationValue(state, 'transactionId');
export const getStatusProlongationIsProcessTransaction = state =>
    getStatusProlongationValue(state, 'isProcessTransaction');
export const getStatusProlongationAttemptsIsOver = state =>
    getStatusProlongationValue(state, 'attemptsLeft') <= 0; // temporary

export const getStatusProlongationModal = state =>
    getStatusProlongationValue(state, 'modal');

// status upgrade
const getStatusUpgradeValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'upgrade', value], state);

export const getStatusUpgradeHasError = state =>
    getStatusUpgradeValue(state, 'hasError');
export const getStatusUpgradeNotification = state =>
    getStatusUpgradeValue(state, 'notification');
export const getStatusUpgradeIsSentCode = state =>
    getStatusUpgradeValue(state, 'isSentCode');

export const getStatusUpgradeRemainingTime = state => {
    const codeSentTime = getStatusUpgradeValue(state, 'codeSentTime');

    if (not(codeSentTime)) {
        return 0;
    }

    return getTimer(codeSentTime);
};

export const getStatusUpgradeTransactionId = state =>
    getStatusUpgradeValue(state, 'transactionId');
export const getStatusUpgradeIsProcessTransaction = state =>
    getStatusUpgradeValue(state, 'isProcessTransaction');
export const getStatusUpgradeAttemptsIsOver = state =>
    getStatusUpgradeValue(state, 'attemptsLeft') <= 0; // temporary

export const getStatusUpgradeModal = state =>
    getStatusUpgradeValue(state, 'modal');

// miles transfer
const getMilesTransferValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'transfer', value], state);

export const getMilesTransferHasError = state =>
    getMilesTransferValue(state, 'hasError');
export const getMilesTransferModal = state =>
    getMilesTransferValue(state, 'modal', {});
export const getMilesTransferNotification = state =>
    getMilesTransferValue(state, 'notification');
export const getMilesTransferPackages = createSelector(
    getTransferPackages,
    packages => filter(item => item.price > 0, packages),
);
export const getMilesTransferPackagesById = createSelector(
    getTransferPackages,
    packages => indexBy(prop('id'), packages),
);
export const getMilesTransferIsSentCode = state =>
    getMilesTransferValue(state, 'isSentCode');

export const getMilesTransferRemainingTime = state => {
    const codeSentTime = getMilesTransferValue(state, 'codeSentTime');

    return getTimer(codeSentTime);
};

export const getMilesTransferTransactionId = state =>
    getMilesTransferValue(state, 'transactionId');
export const getMilesTransferIsProcessTransaction = state =>
    getMilesTransferValue(state, 'isProcessTransaction');

export const getMilesTransferIsEnoughForMinimalPackage = createSelector(
    getTransferPackages,
    getMilesBalance,
    (packages, balance) => {
        const minPackageValue = Math.min(
            ...packages.map(item => item.milesCount),
        );

        return balance >= minPackageValue;
    },
);

export const getMilesTransferAttemptsIsOver = state =>
    getMilesTransferValue(state, 'attemptsLeft') <= 0; // temporary

// miles charity
const getCharityValue = (state, value, fallback) =>
    pathOr(fallback || null, ['miles', 'charity', value], state);

export const getMilesCharityHasError = state =>
    getCharityValue(state, 'hasError');
export const getMilesCharityAttemptsLeft = state =>
    getCharityValue(state, 'attemptsLeft'); // temporary
export const getMilesCharityNotification = state =>
    getCharityValue(state, 'notification');
export const getMilesCharityPackagesById = createSelector(
    getCharityPackages,
    packages => indexBy(prop('id'), packages),
);
export const getMilesCharityIsSentCode = state =>
    getCharityValue(state, 'isSentCode');

export const getMilesCharityRemainingTime = state => {
    const codeSentTime = getCharityValue(state, 'codeSentTime');

    return getTimer(codeSentTime);
};

export const getMilesCharityTransactionId = state =>
    getCharityValue(state, 'transactionId');
export const getMilesCharityIsProcessTransaction = state =>
    getCharityValue(state, 'isProcessTransaction');
export const getMilesCharityIsEnoughForMinimalPackage = createSelector(
    getCharityPackages,
    getMilesBalance,
    (packages, balance) => {
        const minPackageValue = Math.min(
            ...packages.map(item => item.milesCount),
        );

        return balance >= minPackageValue;
    },
);

// miles certificate
const getMilesCertificateValue = (state, value) =>
    pathOr(null, ['miles', 'activateCertificate', value], state);

export const getMilesCertificateLoading = state =>
    getMilesCertificateValue(state, 'isLoading');
export const getMilesCertificateSuccess = state =>
    getMilesCertificateValue(state, 'isSuccess');
export const getMilesCertificateError = state =>
    getMilesCertificateValue(state, 'error');

export const getCardInfo = (state, card) => {
    const currentLocate = getLocale(state);
    const info = pathOr({}, ['miles', 'cardsById', card], state);

    if (info.names) {
        const names = indexBy(prop('lang'), info.names);
        const birthyear = moment(info.birthday, YYYY_MM_DD).format(YYYY);
        const ffpcard = info.memberId;
        const needLocate = currentLocate === 'ru' ? 'ru' : 'en';
        const getNamesByLocale = locale =>
            getIgnoreCase(names, [locale], ['ru', 'en']);

        const { firstName, lastName = '' } =
            getNamesByLocale(needLocate) || getNamesByLocale('en') || {};

        return {
            ...info,
            birthyear,
            ffpcard,
            name: `${firstName} ${lastName.charAt(0)}.`,
        };
    }

    return info;
};

const ERROR_CODES = {
    noExpirationMiles: 'no.expiration.miles',
    phoneNotVerified: 'miles.source.phone.not.verified',
    providerDisabled: 'provider.disabled',
};

export const getAllowedMilesActions = createSelector(
    state => getPackagesNotification(state, 'prolongation'),
    state => getPackagesHasError(state, 'prolongation'),
    state => getPackagesIsLoaded(state, 'prolongation'),
    getStatusProlongationNotification,
    getStatusProlongationHasError,
    state => getPackagesHasError(state, 'expiration'),
    state => getPackagesIsLoaded(state, 'expiration'),
    state => getPackagesNotification(state, 'expiration'),
    getMilesExpirationNotification,
    getMilesExpirationHasError,
    getStatusUpgradeNotification,
    getStatusUpgradeHasError,
    state => getPackagesHasError(state, 'upgrade'),
    state => getPackagesIsLoaded(state, 'upgrade'),
    getExpandedMileActions,
    getProfileUnavailableOperations,
    (
        prolongationNotification,
        prolongationHasError,
        prolongationIsLoaded,
        statusProlongationNotification,
        statusProlongationHasError,
        expirationError,
        expirationLoaded,
        expirationPackagesNotification,
        milesExpirationNotification,
        milesExpirationHasError,
        statusUpgradeNotification,
        statusUpgradeError,
        upgradeError,
        upgradeLoaded,
        expandedMilesActions,
        unavailableOperations,
    ) => {
        const notPhoneConfirm =
            prolongationNotification.code === ERROR_CODES.phoneNotVerified;
        const statusConfirmed =
            !!statusProlongationNotification.title &&
            !statusProlongationHasError;

        const hasStatusPackages =
            (!prolongationHasError && prolongationIsLoaded) ||
            notPhoneConfirm ||
            statusConfirmed;

        const noExpirationMiles =
            milesExpirationNotification.code === ERROR_CODES.noExpirationMiles;
        const expirationConfirmed =
            !!milesExpirationNotification.title &&
            !milesExpirationHasError &&
            !noExpirationMiles;
        const hasExpirationPackages =
            (!expirationError && expirationLoaded) || expirationConfirmed;

        const isExpiration =
            !unavailableOperations.includes(
                PROFILE_OPERATION_TYPE.MILES_EXPIRATION,
            ) &&
            (hasExpirationPackages ||
                expirationPackagesNotification.code ===
                    ERROR_CODES.providerDisabled ||
                expandedMilesActions.includes('expire'));

        const upgradeConfirmed =
            !!statusUpgradeNotification.title && !statusUpgradeError;
        const hasUpgradePackages =
            (!upgradeError && upgradeLoaded) ||
            notPhoneConfirm ||
            upgradeConfirmed;

        const isProlongation =
            hasStatusPackages || expandedMilesActions.includes('prolongation');

        const isUpgrade =
            hasUpgradePackages || expandedMilesActions.includes('upgrade');

        const result = {
            isBuy: RULE_MILES_BUY,
            isCertificate: RULE_MILES_CERTIFICATE,
            isCharity: RULE_MILES_CHARITY,
            isExpiration,
            isProlongation,
            isRecovery: RULE_MILES_RECOVERY,
            isTransfer: RULE_MILES_TRANSFER,
            isUpgrade,
        };

        return {
            ...result,
            isAllDisabled: values(result).every(action => !action),
        };
    },
);

export const getIsPaymentProcess = state => state.miles.isPayment;
