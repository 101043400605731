import { COOKIES } from 'consts';
import jwtDecode from 'jwt-decode';
import { length } from 'ramda';
import { isClient } from 'utils/ssr';
import { removeSessionValues } from 'utils/storage';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

import { authService } from './auth';
import { cookieService } from './cookies';

class SessionService {
    updating = null;

    session = null;

    cacheSsdcp = null;

    isAuth = cookies => {
        // TODO Временный фикс, убрать после решения проблем с CDN и Dynamic Routes NextJS
        let ssrCookies = cookies;

        if (ssrCookies && ssrCookies.cookies) {
            ssrCookies = ssrCookies.cookies;
        }

        const token =
            (ssrCookies
                ? ssrCookies[COOKIES.SSDCP]
                : cookieService.get(COOKIES.SSDCP)) || '';

        return !!token && length(token) !== 32;
    };

    logout = ({ redirect = '/login' } = {}) => {
        this.closeSession();

        if (redirect && isClient) {
            window.location.href = redirect;
        }
    };

    updateTickets = async () => {
        if (this.updating) {
            return this.updating.then(({ data }) => data.ticket.token);
        }

        const ssdcp = cookieService.get(COOKIES.SSDCP);

        try {
            this.updating = authService.login({ id: ssdcp });
            const { data } = await this.updating;
            const { token } = data.ticket;

            cookieService.setValues({ [COOKIES.SSDCP]: token });

            this.updateSession();

            return token;
        } catch (error) {
            return null;
        } finally {
            this.updating = null;
        }
    };

    updateSession = () => {
        if (this.isAuth()) {
            try {
                const ssdcp = cookieService.get(COOKIES.SSDCP);

                this.session = jwtDecode(ssdcp);
            } catch (error) {
                this.closeSession();
            }
        } else {
            this.closeSession();
        }
    };

    checkSession = () => {
        const ssdcp = cookieService.get(COOKIES.SSDCP);

        if (ssdcp !== this.cacheSsdcp) {
            this.updateSession();
        }
    };

    closeSession = () => {
        cookieService.removeValues([
            COOKIES.AUTH,
            COOKIES.SSDCP,
            COOKIES.SSDGE,
            COOKIES.SSDKL,
            COOKIES.SSDMB,
            COOKIES.SSDWS,
            COOKIES.SSDZQ,
        ]);

        removeSessionValues([
            'ssdcp',
            'isAuth',
            'b2b-email',
            'conversationId',
            'business-company',
        ]);

        this.cacheSsdcp = null;
        this.session = null;
    };

    getDevice = () => {
        let ssdkl = cookieService.get(COOKIES.SSDKL);

        if (!ssdkl) {
            if (navigator) {
                const { userAgent } = navigator;

                ssdkl = uuidv5(userAgent, uuidv4()).replace(/-/gi, '');
            } else {
                ssdkl = uuidv4().replace(/-/gi, '');
            }

            cookieService.set(COOKIES.SSDKL, ssdkl);
        }

        return ssdkl;
    };

    getResourceId = () => {
        this.checkSession();

        if (this.session) {
            return this.session.resource;
        }

        return null;
    };
}

export default new SessionService();
