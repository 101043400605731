import { createAction, createReducer } from 'redux-act';
import { resetFading, setFading } from 'utils/fadingTime';

const initialState = {
    active: [],
    available: [],
    buyError: '',
    fadingTime: setFading(),
    is3dsProcessing: false,
    isError: false,
    isLoading: false,
    items: {},
};

export const fetchPromo = createAction('promo/fetchPromo');
export const fetchPromoSuccess = createAction('promo/fetchPromoSuccess');
export const fetchPromoFailure = createAction('promo/fetchPromoFailure');

export const buySubscription = createAction('promo/buySubscription');
export const buySubscriptionSuccess = createAction(
    'promo/buySubscriptionSuccess',
);
export const buySubscriptionFailure = createAction(
    'promo/buySubscriptionFailure',
);

export const clearBuyError = createAction('promo/clearBuyError');

export const buySubscription3ds = createAction('promo/buySubscription3ds');

export const prolongationSubscription = createAction(
    'promo/prolongationSubscription',
);

const handleFetchPromo = state => ({
    ...state,
    isLoading: true,
});

const handleFetchPromoSuccess = (state, { active, available, items }) => ({
    ...state,
    active,
    available,
    fadingTime: resetFading(),
    isLoading: false,
    items,
});

const handleFetchPromoFailure = state => ({
    ...state,
    active: [],
    available: [],
    isError: true,
    isLoading: false,
    items: {},
});

const handleBuySubscription = state => ({
    ...state,
    buyError: '',
    is3dsProcessing: false,
});

const handleBuySubscription3ds = state => ({
    ...state,
    buyError: '',
    is3dsProcessing: true,
});

const handleBuySubscriptionFailure = (state, buyError) => ({
    ...state,
    buyError,
    is3dsProcessing: false,
});

const handleClearBuyError = state => ({
    ...state,
    buyError: '',
});

export default createReducer(
    {
        [buySubscription]: handleBuySubscription,
        [buySubscription3ds]: handleBuySubscription3ds,
        [buySubscriptionFailure]: handleBuySubscriptionFailure,
        [buySubscriptionSuccess]: handleBuySubscription,
        [clearBuyError]: handleClearBuyError,
        [fetchPromo]: handleFetchPromo,
        [fetchPromoFailure]: handleFetchPromoFailure,
        [fetchPromoSuccess]: handleFetchPromoSuccess,
    },
    initialState,
);

export * from './selectors';
export * from './helpers';
