export const ALERT_PLACEMENT = {
    ALL_PAGES: 'ALL_PAGES',
    MAIN_PAGE: 'MAIN_PAGE',
    SPECIFIED_PAGES: 'SPECIFIED_PAGES',
};

export const ALERT_PROFILE_TYPE = {
    // Полный профиль
    FFP: 'FFP',
    // Простой
    SIMPLE: 'SIMPLE',
    // Неавторизованный
    UNAUTHORIZED: 'UNAUTHORIZED',
};

export const ALERT_TYPE = {
    DEFAULT: 'DEFAULT',
    WARNING: 'WARNING',
};

export const ALERT_B2B_USER = {
    ANY: 'ANY',
    NOT_SUPPORTED: 'NOT_SUPPORTED',
    SUPPORTED: 'SUPPORTED',
};
