import { config } from './config';

import { stage } from '../stages';

function checkFlag(featureFlag) {
    if (typeof featureFlag === 'function') {
        return featureFlag();
    }

    if (typeof featureFlag === 'string') {
        return (
            typeof window !== 'undefined' &&
            window.localStorage.getItem(featureFlag) === 'true'
        );
    }

    return featureFlag;
}

export function isAllow(
    feature,
    featuresConfig = config,
    featureStage = stage,
) {
    const featureConfig = featuresConfig[feature];

    if (typeof featureConfig === 'object') {
        const featureFlagByStage = featureConfig[featureStage];

        return checkFlag(featureFlagByStage);
    }

    return checkFlag(featureConfig);
}
