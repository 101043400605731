import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import {
    compose,
    find,
    forEachObjIndexed,
    lensProp,
    not,
    pathOr,
    propOr,
    set,
    view,
} from 'ramda';

import { noun } from '../../../utils/format';

const DD_MM_YY = DATE_FORMATS.DD.MM.YY_dot;
const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;
const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const D_MMM_DD = DATE_FORMATS.D.MMM.dd;
const DD_MMM_YYYY = DATE_FORMATS.DD.MMM.YYYY;

const defineCardType = number => {
    let result = 'UNKNOWN';
    const cards = [
        {
            pattern: /^4[\d]{15}$/,
            type: 'VISA',
        },
        {
            pattern: /^220[0-4][\d]{12,15}$/,
            type: 'MIR',
        },
        {
            pattern: /^(50|(5[6-9])|60|63|67)[\d]{14,16}$/,
            type: 'MAESTRO',
        },
        {
            pattern:
                /(^(5[1-5])[\d]{14}$)|(^(222[1-8][0-9]{2}|2229[0-8][0-9]|22299[0-9]|22[3-9][0-9]{3}|2[3-6][0-9]{4}|27[01][0-9]{3}|2720[0-8][0-9]|27209[0-9])[\d]{10,13}$)/,
            type: 'MASTERCARD',
        },
        {
            pattern: /^35(28|29|[3-8][0-9])[0-9]{12}$/,
            type: 'JCB',
        },
    ];

    cards.forEach(card => {
        if (card.pattern.test(number)) {
            result = card.type;
        }
    });

    return result;
};

export const preparePaymentDetails = payload => {
    const { cardNumber, cardHolder, cvc, cardExpiration } = payload;

    if (!cardNumber || !cardExpiration) {
        return {};
    }

    const [month, year] = cardExpiration.split('/');
    const expirationDate = moment(`01.${month}.${year}`, DD_MM_YY);
    const card = cardNumber.replace(/[^\d]/g, '');

    let expirationMonth = expirationDate.get('month') + 1;

    if (expirationMonth < 10) {
        expirationMonth = `0${expirationMonth}`;
    }

    return {
        cardholder: cardHolder,
        cardNumber: card,
        cvc,
        expirationMonth: `${expirationMonth}`,
        expirationYear: `${expirationDate.get('year')}`,
        maskedPan: card.slice(-4).padStart(card.length, '*'),
        type: defineCardType(card),
    };
};

export function prepareMilesRecovery(fields) {
    const flightDateLens = lensProp('flightDate');

    return set(
        flightDateLens,
        moment(view(flightDateLens, fields), DD_MM_YYYY).format(YYYY_MM_DD),
        fields,
    );
}

export const prepareMilesRecoveryFlight = ({
    flight,
    airports = [],
    airlines = [],
}) => {
    if (!flight) {
        return null;
    }

    const departure = compose(
        data => {
            const [departureDate, departureTime] = (data.std || '').split(' ');
            const airline = pathOr('S7', ['company'], data);

            return {
                airline,
                airlineLabel: (
                    find(item => item.value === airline, airlines) || {}
                ).labelShort,
                departureAirportIata: data.dc,
                departureCity: (
                    find(airport => airport.value === data.dc, airports) || {}
                ).city,
                departureDate,
                departureDateFormatted: moment(
                    departureDate,
                    DD_MM_YYYY,
                ).format(D_MMM_DD),
                departureTime,
                flightNumber: `${pathOr(
                    '',
                    ['carrier', 'flightNumber'],
                    data,
                )}`,
            };
        },
        data => (Array.isArray(data) ? data[0] : data),
    )(flight);

    const arrival = compose(
        data => {
            const [arrivalDate, arrivalTime] = (data.sta || '').split(' ');

            return {
                arrivalAirportIata: data.ac,
                arrivalCity: (
                    find(airport => airport.value === data.ac, airports) || {}
                ).city,
                arrivalDate,
                arrivalDateFormatted: moment(arrivalDate, DD_MM_YYYY).format(
                    D_MMM_DD,
                ),
                arrivalTime,
            };
        },
        data => (Array.isArray(data) ? data[data.length - 1] : data),
    )(flight);

    return {
        ...arrival,
        ...departure,
    };
};

export function formatMilesRecoverySuccessDescription({
    dictionary,
    airlines = [],
    airports = [],
    fields = {},
}) {
    const airline = propOr(
        '',
        'title',
        airlines.find(e => e.name === fields.airline),
    );
    const date = moment(fields.flightDate, DD_MM_YYYY).format(DD_MMM_YYYY);
    const flightName = dictionary.t('com.miles.recovery.flight');
    const from = propOr(
        '',
        'title',
        airports.find(e => e.name === fields.departureAirportIata),
    );
    const to = propOr(
        '',
        'title',
        airports.find(e => e.name === fields.arrivalAirportIata),
    );

    return `${date} / ${flightName} ${fields.flightNumber} (${from} - ${to}), ${airline}`;
}

export const getMilesText = dictionary => dictionary.t('com.units.miles.mult');

export const getNoun = (type, dictionary) => value => {
    const text = noun(value, [
        dictionary.t(`com.units.${type}.one`),
        dictionary.t(`com.units.${type}.once`),
        dictionary.t(`com.units.${type}.mult`),
    ]);

    return `${value} ${text}`;
};

export const getTimer = codeSentTime => {
    if (not(codeSentTime)) {
        return 0;
    }

    const seconds = moment
        .duration(moment(codeSentTime).add(60, 'seconds').diff(moment()))
        .seconds();

    return seconds > 0 ? seconds : 0;
};

export const setSessionDetails = (sessionStorageItems = []) => {
    sessionStorageItems.forEach(({ name, value }) => {
        sessionStorage.setItem(name, value);
    });
};

export const getBrowserData = () => {
    return {
        height: window.innerHeight,
        lang: navigator.language,
        timeZone: new Date().getTimezoneOffset(),
        width: window.innerWidth,
    };
};

export const remove3dsElements = helpers => {
    helpers.forEach(element => element.parentNode.removeChild(element));
};

const createIframe = name => {
    const container = document.body;
    const iframe = document.createElement('iframe');

    iframe.id = name;
    iframe.name = name;
    iframe.style = 'position: absolute; width:0; height:0; border:0;';

    container.appendChild(iframe);

    return iframe;
};

export const create3dsForm = ({ action, target, fields }) => {
    const container = document.body;
    const form = document.createElement('form');

    form.method = 'post';
    form.style = {
        left: -10000,
        position: 'fixed',
        top: -10000,
    };

    if (action) {
        form.action = action;
    }

    if (target) {
        form.target = target;
    }

    forEachObjIndexed((value, name) => {
        const input = document.createElement('input');

        input.type = 'hidden';
        input.id = name;
        input.name = name;
        input.value = value;

        form.appendChild(input);
    }, fields);

    container.appendChild(form);

    return form;
};

export const call3dsV2 = ({ name, url, data }) => {
    const iframe = createIframe(name);
    const form = create3dsForm({
        action: url,
        fields: data,
        target: name,
    });

    form.submit();

    return [iframe, form];
};
