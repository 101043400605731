export const getType = value =>
    Object.prototype.toString.call(value).slice(8, -1);

export const isFunc = value => typeof value === 'function';
export const isBoolean = value => typeof value === 'boolean';
export const isString = value => typeof value === 'string';
export const isNumber = value => typeof value === 'number';
export const isUndefined = value => typeof value === 'undefined';
export const isSymbol = value => typeof value === 'symbol';

export const isObject = value => getType(value) === 'Object';
export const isArray = value => Array.isArray(value);
export const isNull = value => getType(value) === 'Null';
export const isDate = value => getType(value) === 'Date';

export const toArray = value => {
    if (isArray(value)) {
        return value;
    }

    return value !== undefined ? [value] : [];
};
