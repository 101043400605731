import React, { useRef } from 'react';
import { arrayOf, func, node, object, string } from 'prop-types';

import { useClickOutside } from '@s7/ui-kit/hooks';

export const ClickOutside = ({
    children,
    onClickOutside,
    ignoreRefs = [],
    className,
    ...props
}) => {
    const ref = useRef(null);

    useClickOutside({
        active: true,
        handler: onClickOutside,
        ignoreClicksInsideRefs: [ref, ...ignoreRefs],
    });

    return (
        <div ref={ref} className={className} {...props}>
            {children}
        </div>
    );
};

ClickOutside.propTypes = {
    children: node,
    className: string,
    ignoreRefs: arrayOf(object),
    onClickOutside: func.isRequired,
};
