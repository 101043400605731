import { scroller as baseScroller } from 'react-scroll';

export function scrollerTo(to = '', options = {}) {
    baseScroller.scrollTo(to, {
        delay: 100,
        duration: 1500,
        offset: -100,
        ...options,
    });
}
