import { isClient } from './ssr';

const detection = {
    isIOS: false,
    isIPad: false,
    isIPhone: false,
    isIPod: false,
    isMac: false,
    isOSX: false,
};

const SAFARI_MAC_IPHONE_IPOD_REGEXP =
    /(?:(?:iPhone|iPod)(?!.*(?:CriOS|FxiOS))|Macintosh(?!.*Chrome))(?=.*Safari)/i;

if (isClient) {
    detection.isOSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    detection.isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    detection.isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    detection.isIPhone = navigator.platform === 'iPhone';
    detection.isIPod = navigator.platform === 'iPod';
    detection.isIPad =
        navigator.platform === 'iPad' ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    // Отображать кнопку для добавления в Apple Wallet если текущее устройство
    // iPhone, iPod или Mac текущий браузер Safari
    detection.isAppleWallet =
        SAFARI_MAC_IPHONE_IPOD_REGEXP.test(navigator.userAgent) &&
        !detection.isIPad;
}

Object.freeze(detection);

export { detection };
