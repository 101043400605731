import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import {
    assocPath,
    compose,
    difference,
    keys,
    mergeDeepLeft,
    omit,
} from 'ramda';
import { RULE_NOT_SEND_EXPIRE_PASSWORD } from 'utils/allowFeature';

import { documentType } from '../../../data';

const DURATION = { unit: 'days', value: 2 };

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const YYYY_MM_DD_DOT = DATE_FORMATS.YYYY.MM.DD_dot;
const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

export const shouldShowWarning = val =>
    moment().isBefore(moment(val).add(DURATION.value, DURATION.unit));

export const getWarningKey = warning => `${Math.max(...keys(warning))}`;

export const warningDuration = {
    EXPIRE: '100',
    ONE_MONTH: '80',
    ONE_WEEK: '90',
    SIX_MONTHS: '50',
};

const initialize = () => ({ isClosed: false, shownAt: moment().valueOf() });
const isAfterWeek = val =>
    moment().add(1, 'weeks').isAfter(moment(val, YYYY_MM_DD_DOT));
const isAfterSixMonth = val =>
    moment().add(6, 'months').isAfter(moment(val, YYYY_MM_DD_DOT));
const isAfterMonth = val =>
    moment().add(1, 'months').isAfter(moment(val, YYYY_MM_DD_DOT));

const getWarning = (acc, doc) => {
    if (doc.status === 'EXPIRED') {
        return assocPath([doc.id, warningDuration.EXPIRE], initialize(), acc);
    }

    if (isAfterWeek(doc.expirationDate)) {
        return assocPath([doc.id, warningDuration.ONE_WEEK], initialize(), acc);
    }

    if (doc.type === 'VISA' && isAfterMonth(doc.expirationDate)) {
        return assocPath(
            [doc.id, warningDuration.ONE_MONTH],
            initialize(),
            acc,
        );
    }

    if (isAfterSixMonth(doc.expirationDate) && doc.type !== 'VISA') {
        return assocPath(
            [doc.id, warningDuration.SIX_MONTHS],
            initialize(),
            acc,
        );
    }

    return acc;
};

export function refreshSingleWarning(doc, docsFromCookies) {
    const warning = [doc].reduce(getWarning, {});

    return mergeDeepLeft(docsFromCookies, warning);
}

export function refreshWarnings(docs = [], docsFromCookies) {
    const warnings = docs.reduce(getWarning, {});
    const keysForRemove = difference(
        keys(docsFromCookies),
        docs.map(doc => doc.id),
    );

    return compose(
        omit(keysForRemove),
        mergeDeepLeft(docsFromCookies),
    )(warnings);
}

const DOCUMENTS_SORTING = {
    BIRTH_CERTIFICATE: 3,
    INTERNAL_PASSPORT: 1,
    INTERNATIONAL_PASSPORT: 2,
    OTHER: 4,
    VISA: 1,
};
const EXPIRED_SORTING = {
    BIRTH_CERTIFICATE: 8,
    INTERNAL_PASSPORT: 6,
    INTERNATIONAL_PASSPORT: 7,
    OTHER: 9,
    VISA: 5,
};
const HIGHLIGHTED_IF_EXPIRES_SOON = [
    documentType.INTERNATIONAL_PASSPORT,
    documentType.OTHER,
    documentType.VISA,
];

const addYears = (date, years) =>
    moment(date, DD_MM_YYYY).add(years, 'years').format(DD_MM_YYYY);

export const prepareDocumentData = (item, { birthday, isShortProfile }) => {
    if (!item) {
        return null;
    }

    const rusCountryCode = 'RU';
    const { type = '', countryOfIssue, dateOfIssue, expirationDate } = item;
    const isRussian = countryOfIssue === rusCountryCode;
    const isRussianPassport = type === documentType.INTERNAL_PASSPORT;

    let expire = expirationDate;

    if (isRussian) {
        const isRussianBirthCertificate =
            type === documentType.BIRTH_CERTIFICATE;

        if (isRussianBirthCertificate) {
            const issueDate =
                isShortProfile && !birthday ? dateOfIssue : birthday;

            expire = addYears(issueDate, 14);
        } else if (
            isRussianPassport &&
            !expirationDate &&
            !RULE_NOT_SEND_EXPIRE_PASSWORD
        ) {
            expire = addYears(dateOfIssue, 20);
        }
    }

    if (expire) {
        const expiration = moment(expire, DD_MM_YYYY);

        item.isExpired = expiration.isBefore(moment());
        // Определяет - исчетёт ли данный документ в течении следующего месяца для
        // загранпаспортов, виз или документов с типом "Другое", необходимо для
        // подсвечивания дат таких документов
        item.willExpireWithinMonth =
            !item.isExpired &&
            HIGHLIGHTED_IF_EXPIRES_SOON.includes(type) &&
            moment().isAfter(moment(expiration).subtract(1, 'months'));
    } else {
        item.isExpired = false;
        item.willExpireWithinMonth = false;
    }

    item.sort = item.isExpired
        ? EXPIRED_SORTING[type]
        : DOCUMENTS_SORTING[type];
    item.expireTimestamp = moment(expire, DD_MM_YYYY).unix() || moment().unix();

    if ((!isRussianPassport || !RULE_NOT_SEND_EXPIRE_PASSWORD) && expire) {
        item.expire = expire;
    }

    return item;
};

export const prepareDocumentDates = doc => ({
    ...doc,
    dateOfIssue: moment(doc.dateOfIssue, YYYY_MM_DD).format(DD_MM_YYYY),
    expirationDate: doc.expirationDate
        ? moment(doc.expirationDate, YYYY_MM_DD).format(DD_MM_YYYY)
        : '',
    startDate: doc.startDate
        ? moment(doc.startDate, YYYY_MM_DD).format(DD_MM_YYYY)
        : '',
});
