export const BROWSER_MAP = {
    amazon_silk: 'Amazon Silk',
    android: 'Android Browser',
    bada: 'Bada',
    blackberry: 'BlackBerry',
    chrome: 'Chrome',
    chromium: 'Chromium',
    edge: 'Microsoft Edge',
    electron: 'Electron',
    epiphany: 'Epiphany',
    firefox: 'Firefox',
    focus: 'Focus',
    generic: 'Generic',
    google_search: 'Google Search',
    googlebot: 'Googlebot',
    ie: 'Internet Explorer',
    k_meleon: 'K-Meleon',
    maxthon: 'Maxthon',
    mz: 'MZ Browser',
    naver: 'NAVER Whale Browser',
    opera: 'Opera',
    opera_coast: 'Opera Coast',
    phantomjs: 'PhantomJS',
    puffin: 'Puffin',
    qq: 'QQ Browser',
    qqlite: 'QQ Browser Lite',
    qupzilla: 'QupZilla',
    safari: 'Safari',
    sailfish: 'Sailfish',
    samsung_internet: 'Samsung Internet for Android',
    seamonkey: 'SeaMonkey',
    sleipnir: 'Sleipnir',
    swing: 'Swing',
    tizen: 'Tizen',
    uc: 'UC Browser',
    vivaldi: 'Vivaldi',
    webos: 'WebOS Browser',
    wechat: 'WeChat',
    yandex: 'Yandex Browser',
};

export const OS_MAP = {
    Android: 'Android',
    Bada: 'Bada',
    BlackBerry: 'BlackBerry',
    ChromeOS: 'Chrome OS',
    iOS: 'iOS',
    Linux: 'Linux',
    MacOS: 'macOS',
    PlayStation4: 'PlayStation 4',
    Roku: 'Roku',
    Tizen: 'Tizen',
    WebOS: 'WebOS',
    Windows: 'Windows',
    WindowsPhone: 'Windows Phone',
};
