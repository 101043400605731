import { createAction, createReducer } from 'redux-act';

import { resetFading, setFading } from '../../../utils/fadingTime';

const initialState = {
    achievements: {
        fadingTime: setFading(),
        groups: [],
        isError: false,
        isLoading: false,
    },
    statistics: {
        fadingTime: setFading(),
        isError: false,
        isLoading: false,
        items: [],
    },
};

export const fetchStatistics = createAction('statistics/fetchStatistics');
export const fetchStatisticsSuccess = createAction(
    'statistics/fetchStatisticsSuccess',
);
export const fetchStatisticsFailure = createAction(
    'statistics/fetchStatisticsFailure',
);

export const fetchAchievements = createAction('statistics/fetchAchievements');
export const fetchAchievementsSuccess = createAction(
    'statistics/fetchAchievementsSuccess',
);
export const fetchAchievementsFailure = createAction(
    'statistics/fetchAchievementsFailure',
);

export const checkAchievementEnd = createAction(
    'statistics/checkAchievementEnd',
);
export const setNewAchievement = createAction('statistics/setNewAchievement');
export const clearNewAchievement = createAction(
    'statistics/clearNewAchievement',
);

const handleFetchStatistics = state => ({
    ...state,
    statistics: { ...state.statistics, isLoading: true },
});

const handleFetchStatisticsSuccess = (state, items) => ({
    ...state,
    statistics: {
        ...state.statistics,
        fadingTime: resetFading(),
        isError: false,
        isLoading: false,
        items,
    },
});

const handleFetchStatisticsFailure = state => ({
    ...state,
    statistics: { ...state.statistics, isError: true, isLoading: false },
});

const handleFetchAchievements = state => ({
    ...state,
    achievements: { ...state.achievements, isLoading: true },
});

const handleFetchAchievementsSuccess = (state, groups) => ({
    ...state,
    achievements: {
        ...state.achievements,
        fadingTime: resetFading(false, 10),
        groups,
        isError: false,
        isLoading: false,
    },
});

const handleFetchAchievementsFailure = state => ({
    ...state,
    achievements: { ...state.achievements, isError: true, isLoading: false },
});

const handleСlearNewAchievement = state => ({ ...state, newAchievement: null });
const handleSetNewAchievement = (state, newAchievement) => ({
    ...state,
    newAchievement,
});

export default createReducer(
    {
        [clearNewAchievement]: handleСlearNewAchievement,
        [fetchAchievements]: handleFetchAchievements,
        [fetchAchievementsFailure]: handleFetchAchievementsFailure,
        [fetchAchievementsSuccess]: handleFetchAchievementsSuccess,
        [fetchStatistics]: handleFetchStatistics,
        [fetchStatisticsFailure]: handleFetchStatisticsFailure,
        [fetchStatisticsSuccess]: handleFetchStatisticsSuccess,
        [setNewAchievement]: handleSetNewAchievement,
    },
    initialState,
);

export * from './selectors';
