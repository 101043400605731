import React from 'react';
import { bool, number, oneOf, oneOfType, string } from 'prop-types';

import cx from './Icon.sss';

const Icon = React.memo(function Icon({
    width,
    height,
    iconContent,
    color,
    newIcon,
    className,
    icon,
}) {
    let IconComponent;

    if (iconContent) {
        IconComponent = iconContent;
    } else {
        try {
            // eslint-disable-next-line import/no-dynamic-require, global-require
            IconComponent = require(`./${
                newIcon ? 'icons_new' : 'icons'
            }/${icon}.svg`).default;
        } catch (err) {
            return null;
        }
    }

    return (
        <span
            className={cx('icon', { [`color_${color}`]: color }, className)}
            style={{ height, width }}
        >
            <IconComponent />
        </span>
    );
});

export const colors = [
    'white',
    'red',
    'black',
    'gray',
    'green',
    'dark-green',
    'currentColor',
    'yellow',
];

Icon.propTypes = {
    className: string,
    color: oneOf(colors),
    height: number,
    icon: string,
    iconContent: string,
    newIcon: bool,
    width: oneOfType([number, string]),
};

Icon.defaultProps = {
    height: 20,
    newIcon: false,
    width: 20,
};

export default Icon;
