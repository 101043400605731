import React, { forwardRef, memo } from 'react';
import { node } from 'prop-types';

import cx from './Paper.sss';

const BasePaper = forwardRef((props, ref) => {
    const { children, ...rest } = props;

    return (
        <div ref={ref} className={cx('root')} {...rest}>
            {children}
        </div>
    );
});

BasePaper.propTypes = {
    children: node,
};

export const Paper = memo(BasePaper);
