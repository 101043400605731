import { createAction, createReducer } from 'redux-act';

import { getLanguage } from '../../../utils/lang';

const initialState = {
    configs: {},
    isLoading: true,
    language: getLanguage(),
    loaded: {},
    visible: true,
};

export const fetch = createAction('languages/fetch');
export const change = createAction('languages/change');
export const fetchSuccess = createAction('languages/fetchSuccess');
export const fetchFailure = createAction('languages/fetchFailure');

export const toggleVisible = createAction('languages/toggleVisible');

const handleFetch = (state, config) => ({
    ...state,
    isLoading: true,
    loaded: {
        ...state.loaded,
        [config]: {
            isError: false,
            isLoading: true,
        },
    },
});

const handleChange = (state, language) => ({
    ...state,
    isLoading: true,
    language,
});

const handleFetchSuccess = (state, payload) => ({
    ...state,
    configs: {
        ...state.configs,
        [payload.lang]: {
            ...(state.configs[payload.lang || 'ru'] || {}),
            ...payload.data,
        },
    },
    isLoading: false,
    loaded: {
        ...state.loaded,
        [payload.config]: {
            isError: false,
            isLoading: false,
        },
    },
});

const handleFetchFailure = (state, payload) => ({
    ...state,
    isLoading: false,
    loaded: {
        ...state.loaded,
        [payload.config]: {
            isError: true,
            isLoading: false,
        },
    },
});

const handleToggleVisible = state => ({
    ...state,
    visible: !state.visible,
});

export default createReducer(
    {
        [change]: handleChange,
        [fetch]: handleFetch,
        [fetchFailure]: handleFetchFailure,
        [fetchSuccess]: handleFetchSuccess,
        [toggleVisible]: handleToggleVisible,
    },
    initialState,
);

export {
    isError,
    isLoading,
    isLoadingLanguagesGlobal,
    getLoaded,
    getLoadedConfig,
    isLoadedConfig,
    getDictionary,
    getLocale,
    getVisible,
    selectLocale,
} from './selectors';
