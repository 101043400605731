import React from 'react';
import { bool, node, number } from 'prop-types';

import cx from './Column.sss';

const Column = ({
    children,
    zindex: zIndex,
    zindexInitial,
    auto,
    autoZIndex,
    smallMargin,
    desktop,
    mobile,
    ...props
}) => {
    return (
        <div
            className={cx('column', {
                column_auto_zindex: autoZIndex,
                column_smallMargin: smallMargin,
                column_zindex_initial: zindexInitial,
                [`width_desktop_${desktop}`]: desktop,
                [`width_mobile_${mobile}`]: mobile,
            })}
            style={{
                zIndex,
            }}
            {...props}
        >
            {children}
        </div>
    );
};

Column.propTypes = {
    auto: bool,
    autoZIndex: bool,
    children: node,
    desktop: number,
    mobile: number,
    smallMargin: bool,
    zindex: number,
    zindexInitial: bool,
};

Column.defaultProps = {
    auto: false,
    autoZIndex: true,
    desktop: 100,
    mobile: 100,
    zindexInitial: false,
};

export default React.memo(Column);
