import moment from 'moment';
import {
    assoc,
    assocPath,
    compose,
    filter,
    find,
    map,
    pathOr,
    propEq,
    propOr,
} from 'ramda';
import { createAction, createReducer } from 'redux-act';

import { findMembershipAdmin } from './helpers';
import { formatMember } from './selectors';

import { memberStatus, memberType } from '../../../data/membership';
import { RULE_BUSINESS_UPGRADE } from '../../../utils/allowFeature';
import { resetFading, setFading } from '../../../utils/fadingTime';

const initialState = {
    account: {
        createdAt: null,
        status: 'CLOSED',
        updatedAt: null,
    },
    availableMilesOperations: [],
    balances: {
        bonus: 0,
        business: 0,
        businessUpgrade: null,
        economy: 0,
        flights: 0,
        qualifying: 0,
        redemption: 0,
    },
    burningPoints: [],
    email: null,
    errorCode: '',
    expiringMiles: 0,
    fadingTime: setFading(),
    isClosedMilesNotification: false,
    isError: false,
    isInitialized: false,
    isLoading: false,
    isSync: false,
    jwt: {
        isError: false,
        isInitialized: false,
        isLoading: false,
        token: '',
    },
    memberDeleting: {
        id: null,
        status: false,
    },
    memberId: null,
    membership: {
        cardInfo: null,
        isConfirming: false,
        notification: null,
    },
    nextLevelRequirements: {
        flights: 0,
        miles: 0,
    },
    phoneNumber: null,
    status: 'CLASSIC',
    statusExpiration: null,
    // data: {
    //   subAccountBalance: [],
    // },
    // mileageLimit: {},
};

export const fetch = createAction('loyalty/fetch');
export const fetchSuccess = createAction('loyalty/fetchSuccess');
export const fetchFailure = createAction('loyalty/fetchFailure');

export const fetchMiles = createAction('loyalty/fetchMiles');
export const fetchMilesSuccess = createAction('loyalty/fetchMilesSuccess');
export const fetchMilesFailure = createAction('loyalty/fetchMilesFailure');

export const fetchSync = createAction('loyalty/fetchSync');
export const fetchSyncSuccess = createAction('loyalty/fetchSuccessSync');
export const fetchSyncFailure = createAction('loyalty/fetchFailureSync');

export const fetchJwt = createAction('loyalty/fetchJwt');
export const fetchJwtSuccess = createAction('loyalty/fetchJwtSuccess');
export const fetchJwtFailure = createAction('loyalty/fetchJwtFailure');

export const changeLanguage = createAction('loyalty/changeLanguage');

export const closeMilesNotification = createAction(
    'loyalty/closeMilesNotification',
);

export const sendMembershipInvitation = createAction(
    'loyalty/sendMembershipInvitation',
);
export const sendMembershipInvitationSuccess = createAction(
    'loyalty/sendMembershipInvitationSuccess',
);
export const sendMembershipInvitationFailure = createAction(
    'loyalty/sendMembershipInvitationFailure',
);

export const activateInMembership = createAction(
    'loyalty/activateInMembership',
);
export const activateInMembershipSuccess = createAction(
    'loyalty/activateInMembershipSuccess',
);
export const activateInMembershipFailure = createAction(
    'loyalty/activateInMembershipFailure',
);

export const declineInMembership = createAction('loyalty/declineInMembership');
export const declineInMembershipSuccess = createAction(
    'loyalty/declineInMembershipSuccess',
);
export const declineInMembershipFailure = createAction(
    'loyalty/declineInMembershipFailure',
);

export const deleteFromMembership = createAction(
    'loyalty/deleteFromMembership',
);
export const deleteFromMembershipSuccess = createAction(
    'loyalty/deleteFromMembershipSuccess',
);
export const deleteFromMembershipFailure = createAction(
    'loyalty/deleteFromMembershipFailure',
);

export const confirmDeletionFromMembership = createAction(
    'loyalty/confirmDeletionFromMembership',
);
export const confirmDeletionFromMembershipSuccess = createAction(
    'loyalty/confirmDeletionFromMembershipSuccess',
);
export const confirmDeletionFromMembershipFailure = createAction(
    'loyalty/confirmDeletionFromMembershipFailure',
);

export const rejectDeletionFromMembership = createAction(
    'loyalty/rejectDeletionFromMembership',
);
export const rejectDeletionFromMembershipSuccess = createAction(
    'loyalty/rejectDeletionFromMembershipSuccess',
);
export const rejectDeletionFromMembershipFailure = createAction(
    'loyalty/rejectDeletionFromMembershipFailure',
);

export const changeMembershipConfirmationCode = createAction(
    'loyalty/changeMembershipConfirmationCode',
);
export const clearNotification = createAction('loyalty/clearNotification');

export const changeMemberDeleting = createAction(
    'loyalty/changeMemberDeleting',
);

export const checkCard = createAction('miles/checkCard');
export const checkCardSuccess = createAction('miles/checkCardSuccess');
export const checkCardReset = createAction('miles/checkCardReset');
export const checkCardFailure = createAction('miles/checkCardFailure');

const handleFetch = state => ({
    ...state,
    errorCode: '',
    isError: false,
    isLoading: true,
});

// const handleFetchSync = state => ({
//   ...state,
//   isSync: true,
//   isLoading: false,
//   isError: false,
//   fadingTime: resetFading(),
// })

const getBalanceContainerGetter = profile => value => {
    return compose(
        propOr(value === 'BUSINESS_UPGRADE' ? null : 0, 'value'),
        find(propEq('type', value)),
        propOr([], 'balancesContainer'),
    )(profile);
};

const handleFetchSuccess = (
    state,
    { profile, isClosedMilesNotification, locale },
) => {
    const memberId = propOr(null, 'memberId', profile);
    const email = propOr(null, 'email', profile);
    const phoneNumber = propOr(null, 'phoneNumber', profile);
    const status = pathOr('classic', ['eliteTier', 'level'], profile);
    const statusExpiration = pathOr(
        null,
        ['eliteTier', 'expirationDate'],
        profile,
    );
    const createdAt = propOr(null, 'registrationDate', profile);
    const updatedAt = pathOr(null, ['provider', 'updatedAt'], profile);
    const accountStatus = pathOr('CLOSED', ['provider', 'status'], profile);
    const nextLevelRequirements = pathOr(
        {},
        ['eliteTier', 'nextLevelRequirements'],
        profile,
    );
    const balancesGetter = getBalanceContainerGetter(profile);
    const burningPoints = pathOr([], ['burningPoints'], profile);

    const members = compose(
        map(formatMember(locale)),
        filter(member => member.status !== memberStatus.DELETED),
    )(profile.membership?.members || []);

    return {
        ...state,
        account: {
            createdAt: (createdAt ? moment(createdAt) : moment()).format(),
            status: accountStatus,
            updatedAt,
        },
        availableMilesOperations: propOr(
            [],
            'availableMilesOperations',
            profile,
        ),
        balances: {
            bonus: balancesGetter('BONUS'),
            business: balancesGetter('BUSINESS'),
            businessUpgrade: RULE_BUSINESS_UPGRADE
                ? balancesGetter('BUSINESS_UPGRADE')
                : null,
            economy: balancesGetter('ECONOMY'),
            flights: balancesGetter('FLIGHTS'),
            qualifying: balancesGetter('QUALIFYING'),
            redemption: balancesGetter('REDEMPTION'),
        },
        burningPoints,
        email,
        fadingTime: resetFading(),
        isClosedMilesNotification,
        isError: false,
        isInitialized: true,
        isLoading: false,
        isSync: false,
        memberId,
        membership: {
            ...profile.membership,
        },
        membershipAdmin: findMembershipAdmin(members),
        membershipCreationAvailable: profile.membershipCreationAvailable,
        nextLevelRequirements,
        phoneNumber,
        status,
        statusExpiration,
    };
};

const handleFetchFailure = (state, errorCode) => ({
    ...state,
    errorCode,
    isError: true,
    isInitialized: true,
    isLoading: false,
    isSync: false,
});

const handleFetchJwt = state => {
    return {
        ...state,
        jwt: {
            ...state.jwt,
            isError: false,
            isLoading: true,
        },
    };
};

const handleFetchJwtSuccess = (state, payload) => {
    return {
        ...state,
        jwt: {
            ...state.jwt,
            isError: false,
            isInitialized: true,
            isLoading: false,
            token: payload.jwt,
        },
    };
};

const handleFetchJwtFailture = state => {
    return {
        ...state,
        jwt: {
            ...state.jwt,
            isError: true,
            isInitialized: false,
            isLoading: false,
            token: '',
        },
    };
};

const handleFetchMiles = state => ({
    ...state,
    isSync: true,
});

const handleFetchMilesSuccess = (state, { profile }) => {
    const balancesGetter = getBalanceContainerGetter(profile);

    return {
        ...state,
        balances: {
            bonus: balancesGetter('BONUS'),
            business: balancesGetter('BUSINESS'),
            businessUpgrade: RULE_BUSINESS_UPGRADE
                ? balancesGetter('BUSINESS_UPGRADE')
                : null,
            economy: balancesGetter('ECONOMY'),
            flights: balancesGetter('FLIGHTS'),
            qualifying: balancesGetter('QUALIFYING'),
            redemption: balancesGetter('REDEMPTION'),
        },
        isSync: false,
    };
};

const handleFetchMilesFailure = state => ({
    ...state,
    isSync: false,
});

const handleCloseMilesNotification = state =>
    assoc('isClosedMilesNotification', true, state);

const handleClearNotification = state =>
    assocPath(['membership', 'notification'], null, state);

const handleSendMembershipInvitationSuccess = (
    state,
    { notification, membership },
) => ({
    ...state,
    membership: { ...membership, notification },
});
const handleSendMembershipInvitationFailure = (state, { notification }) => ({
    ...state,
    membership: { ...state.membership, notification },
});

const handleActivateInMembership = state => ({
    ...state,
    membership: { ...state.membership, isConfirming: true },
});
const handleActivateInMembershipSuccess = (
    state,
    { notification, membership },
) => ({
    ...state,
    membership: { ...membership, isConfirming: false, notification },
});
const handleActivateInMembershipFailure = (state, { notification }) => ({
    ...state,
    membership: { ...state.membership, isConfirming: false, notification },
});

const handleDeclineInMembership = state => ({
    ...state,
    membership: { ...state.membership, isDeclining: true },
});
const handleDeclineInMembershipSuccess = (state, { membership }) => ({
    ...state,
    membership: { ...membership, isDeclining: false },
});
const handleDeclineInMembershipFailure = (state, { notification }) => ({
    ...state,
    membership: { ...state.membership, isDeclining: false, notification },
});

const membershipAdminPredicate = member => member.type === memberType.HEAD;

const getMembersByActionType = (candidateId, members, isHead) =>
    isHead
        ? members.map(member =>
              member.id === candidateId
                  ? { ...member, status: 'DELETION_PENDING' }
                  : member,
          )
        : members.filter(membershipAdminPredicate);

const handleDeleteFromMembershipSuccess = (
    state,
    { notification, membership, isHead, memberId },
) => {
    const currentMembership = membership || state.membership;

    return {
        ...state,
        memberDeleting: { status: false },
        membership: {
            ...currentMembership,
            members: getMembersByActionType(
                memberId,
                currentMembership.members,
                isHead,
            ),
            notification,
        },
    };
};

const handleDeleteFromMembershipFailure = (state, { notification }) => ({
    ...state,
    memberDeleting: { status: false },
    membership: { ...state.membership, notification },
});

const handleConfirmDeletionFromMembershipSuccess = (
    state,
    { notification },
) => ({
    ...state,
    membership: {
        ...initialState.membership,
        members: state.membership.members.filter(membershipAdminPredicate),
        notification,
    },
});

const handleConfirmDeletionFromMembershipFailure = (
    state,
    { notification },
) => ({
    ...state,
    membership: { ...state.membership, notification },
});

const handleRejectDeletionFromMembershipSuccess = (state, { memberId }) => {
    const { members } = state.membership;
    const newMembers = members.map(member =>
        member.id === memberId
            ? {
                  ...member,
                  status: memberStatus.ACTIVE,
              }
            : member,
    );

    return {
        ...state,
        membership: { ...state.membership, members: newMembers },
    };
};

const handleRejectDeletionFromMembershipFailure = (
    state,
    { notification },
) => ({
    ...state,
    membership: { ...state.membership, notification },
});

const handleChangeMembershipConfirmationCode = (state, { code }) => ({
    ...state,
    membershipConfirmationCode: code,
});

const handleChangeMemberDeleting = (state, { status, id }) => ({
    ...state,
    memberDeleting: { id, status },
});

const handleFetchCardInfo = state => ({
    ...state,
    membership: {
        ...state.membership,
        cardInfo: null,
    },
});

const handleFetchCardInfoSuccess = (state, { name }) => ({
    ...state,
    membership: {
        ...state.membership,
        cardInfo: {
            errorMessage: null,
            name,
        },
    },
});

const handleFetchCardInfoFailure = (state, { errorMessage }) => ({
    ...state,
    membership: { ...state.membership, cardInfo: { errorMessage } },
});

export default createReducer(
    {
        [activateInMembership]: handleActivateInMembership,
        [activateInMembershipFailure]: handleActivateInMembershipFailure,
        [activateInMembershipSuccess]: handleActivateInMembershipSuccess,
        [changeMemberDeleting]: handleChangeMemberDeleting,
        [changeMembershipConfirmationCode]:
            handleChangeMembershipConfirmationCode,
        [checkCard]: handleFetchCardInfo,
        [checkCardFailure]: handleFetchCardInfoFailure,
        [checkCardReset]: handleFetchCardInfo,
        [checkCardSuccess]: handleFetchCardInfoSuccess,
        [clearNotification]: handleClearNotification,
        [closeMilesNotification]: handleCloseMilesNotification,
        [confirmDeletionFromMembershipFailure]:
            handleConfirmDeletionFromMembershipFailure,
        [confirmDeletionFromMembershipSuccess]:
            handleConfirmDeletionFromMembershipSuccess,
        [declineInMembership]: handleDeclineInMembership,
        [declineInMembershipFailure]: handleDeclineInMembershipFailure,
        [declineInMembershipSuccess]: handleDeclineInMembershipSuccess,
        [deleteFromMembershipFailure]: handleDeleteFromMembershipFailure,
        [deleteFromMembershipSuccess]: handleDeleteFromMembershipSuccess,
        [fetch]: handleFetch,
        [fetchFailure]: handleFetchFailure,
        [fetchJwt]: handleFetchJwt,
        [fetchJwtFailure]: handleFetchJwtFailture,
        [fetchJwtSuccess]: handleFetchJwtSuccess,
        [fetchMiles]: handleFetchMiles,
        [fetchMilesFailure]: handleFetchMilesFailure,
        [fetchMilesSuccess]: handleFetchMilesSuccess,
        [fetchSuccess]: handleFetchSuccess,
        [rejectDeletionFromMembershipFailure]:
            handleRejectDeletionFromMembershipFailure,
        [rejectDeletionFromMembershipSuccess]:
            handleRejectDeletionFromMembershipSuccess,
        [sendMembershipInvitationFailure]:
            handleSendMembershipInvitationFailure,
        [sendMembershipInvitationSuccess]:
            handleSendMembershipInvitationSuccess,
        // [fetchSync]: handleFetchSync,
        // [fetchSyncFailure]: handleFetchFailure,
        // [fetchSyncSuccess]: handleFetchSuccess,
    },
    initialState,
);

export * from './selectors';
