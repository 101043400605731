import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';

import { isFading } from '../../../utils/fadingTime';
import { getDictionary } from '../languages';

const purchaseStatusTexts = (status, dictionary) => {
    if (status === 'completed') {
        return dictionary.t('com.profile.purchases.filter.statuses.delivered');
    }

    if (status === 'cancelled' || status === 'cancelling') {
        return dictionary.t('com.profile.purchases.filter.statuses.canceled');
    }

    return dictionary.t('com.profile.purchases.filter.statuses.confirmed');
};

export const getIsPurchasesError = state => state.purchases.isError;
export const getIsPurchasesLoading = state => state.purchases.isLoading;
export const getIsPurchasesLoaded = state =>
    isFading(state.purchases.fadingTime);
export const getIsPurchasesEmpty = state => isEmpty(state.purchases.items);

export const getPurchases = state =>
    state.purchases.items.reduce((acc, cur) => {
        cur.products = cur.products.filter(
            ({ itemCode }) => itemCode !== 'delivery',
        );

        return [...acc, cur];
    }, []);

export const getPurchaseStatuses = createSelector(
    [getPurchases, getDictionary],
    (purchases, dictionary) =>
        purchases
            .reduce((acc, cur) => {
                return acc.includes(cur.status) ? acc : [...acc, cur.status];
            }, [])
            .map(status => ({
                label: purchaseStatusTexts(status?.toLowerCase(), dictionary),
                value: status?.toLowerCase(),
            })),
);
