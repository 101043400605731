/* eslint-disable max-len */
import moment from 'moment';
import {
    append,
    assoc,
    assocPath,
    compose,
    contains,
    differenceWith,
    evolve,
    F,
    identity,
    lensPath,
    prop,
    propOr,
    set,
    uniqBy,
    view,
    without,
} from 'ramda';
import { createAction, createReducer } from 'redux-act';
import { toArray } from 'utils';

import { milesActionError } from '../../../data/errorCodes';
import { resetFading } from '../../../utils/fadingTime';

const notificationState = {
    code: '',
    description: '',
    title: '',
};
const packagesState = {
    hasError: false,
    isLoaded: false,
    isLoading: false,
    items: [],
    notification: { ...notificationState },
};
const initialState = {
    activateCertificate: {
        error: null,
        isLoading: false,
        isSuccess: false,
    },
    activateStatus: {
        hasError: false,
        modal: {
            data: null,
            type: '',
        },
        notification: { ...notificationState },
        packages: { ...packagesState },
        transactionId: null,
    },
    buy: {
        hasError: false,
        modal: {
            data: null,
            type: '',
        },
        notification: { ...notificationState },
        packages: { ...packagesState },
        transactionId: null,
    },
    cardsById: {},
    charity: {
        accounts: { ...packagesState },
        attemptsLeft: 5,
        codeSentTime: null,
        hasError: false,
        isProcessTransaction: false,
        isSentCode: false,
        notification: { ...notificationState },
        packages: { ...packagesState },
        transactionId: null,
    },
    expandedMileActions: [],
    expiration: {
        hasError: false,
        isAction: false,
        modal: {
            data: null,
            type: '',
        },
        notification: { ...notificationState },
        packages: { ...packagesState },
        transactionId: null,
    },
    isPayment: false,
    loyaltyCard: {
        hasError: false,
        transactionId: null,
    },
    loyaltyCardOrders: {
        isShowModal: false,
        ...packagesState,
    },
    milesById: {},
    profileAddresses: { ...packagesState },
    prolongation: {
        attemptsLeft: 5,
        codeSentTime: null,
        hasError: false,
        isProcessTransaction: false,
        isSentCode: false,
        modal: {
            data: null,
            type: '',
        },
        notification: { ...notificationState },
        packages: {
            ...packagesState,
            notification: {
                ...notificationState,
                code: 'qualifying.miles.no.packages',
            },
        },
        transactionId: null,
    },
    recover: {
        flight: null,
        flightNotification: { ...notificationState },
        flightNumbers: [],
        flightNumbersNotification: { ...notificationState },
        initialValues: { airline: 'S7', isKnowFlightNumber: true },
        isLoadedRecovery: false,
        isLoadingFlight: false,
        isLoadingRequests: false,
        isOpenBoardingPass: false,
        isOpenRecoverForm: false,
        nextPage: 0,
        notification: { ...notificationState },
        requests: [],
        requestsNotification: { ...notificationState },
        totalPages: null,
    },
    transfer: {
        attemptsLeft: 5,
        codeSentTime: null,
        hasError: false,
        isProcessTransaction: false,
        isSentCode: false,
        modal: {
            data: null,
            type: '',
        },
        notification: { ...notificationState },
        packages: { ...packagesState },
        transactionId: null,
    },
    upgrade: {
        attemptsLeft: 5,
        codeSentTime: null,
        hasError: false,
        isProcessTransaction: false,
        isSentCode: false,
        isSuccessfullyFinished: false,
        modal: {
            data: null,
            type: '',
        },
        notification: { ...notificationState },
        packages: { ...packagesState },
        transactionId: null,
    },
};

export const fetch = createAction('miles/fetch');
export const fetchSuccess = createAction('miles/fetchSuccess');
export const fetchFailure = createAction('miles/fetchFailure');

export const recover = createAction('miles/recover');
export const recoverSuccess = createAction('miles/recoverSuccess');
export const recoverFailure = createAction('miles/recoverFailure');
export const recoverClear = createAction('miles/recoverClear');
export const recoverInitialize = createAction('miles/recoverInitialize');
export const clearRecoverNotification = createAction(
    'miles/clearRecoverNotification',
);

export const fetchRecover = createAction('miles/fetchRecover');
export const fetchRecoverSuccess = createAction('miles/fetchRecoverSuccess');
export const fetchRecoverFailure = createAction('miles/fetchRecoverFailure');
export const clearRecoverRequestsNotification = createAction(
    'miles/clearRecoverRequestsNotification',
);
export const changeIsOpenRecoverForm = createAction(
    'miles/changeIsOpenRecoverForm',
);

export const openBoardingPass = createAction('miles/openBoardingPass');
export const closeBoardingPass = createAction('miles/closeBoardingPass');

export const searchFlightNumbers = createAction('miles/searchFlightNumbers');
export const searchFlightNumbersFailure = createAction(
    'miles/searchFlightNumbersFailure',
);
export const searchFlightNumbersSuccess = createAction(
    'miles/searchFlightNumbersSuccess',
);
export const clearFlightNumbers = createAction('miles/clearFlightNumbers');

export const searchFlight = createAction('miles/searchFlight');
export const searchFlightFailure = createAction('miles/searchFlightFailure');
export const searchFlightSuccess = createAction('miles/searchFlightSuccess');
export const clearFlight = createAction('miles/clearFlight');

export const changeExpandedMileActions = createAction(
    'miles/changeExpandedMileActions',
);
export const closeExpandedMileActions = createAction(
    'miles/closeExpandedMileActions',
);

export const setupRestoreMiles = createAction('miles/setupRestoreMiles');

export const buy = createAction('miles/buy');
export const buySuccess = createAction('miles/buySuccess');
export const buyFailure = createAction('miles/buyFailure');
export const buyClear = createAction('miles/buyClear');
export const buyCloseModal = createAction('miles/buyCloseModal');

export const expiration = createAction('miles/expiration');
export const expirationSuccess = createAction('miles/expirationSuccess');
export const expirationFailure = createAction('miles/expirationFailure');
export const expirationClear = createAction('miles/expirationClear');
export const expirationCloseModal = createAction('miles/expirationCloseModal');

export const transfer = createAction('miles/transfer');
export const transferSuccess = createAction('miles/transferSuccess');
export const transferFailure = createAction('miles/transferFailure');
export const transferClear = createAction('miles/transferClear');
export const transferCloseModal = createAction('miles/transferCloseModal');

export const cancelTransfer = createAction('miles/cancelTransfer');
export const removeTransferTimer = createAction('miles/removeTransferTimer');
export const changeTransferIsProcessTransaction = createAction(
    'miles/changeTransferIsProcessTransaction',
);

export const prolongation = createAction('miles/prolongation');
export const prolongationSuccess = createAction('miles/prolongationSuccess');
export const prolongationFailure = createAction('miles/prolongationFailure');
export const prolongationClear = createAction('miles/prolongationClear');
export const cancelProlongation = createAction('miles/cancelProlongation');
export const removeProlongationTimer = createAction(
    'miles/removeProlongationTimer',
);
export const changeProlongationIsProcessTransaction = createAction(
    'miles/changeProlongationIsProcessTransaction',
);
export const prolongationCloseModal = createAction(
    'miles/prolongationCloseModal',
);

export const upgrade = createAction('miles/upgrade');
export const upgradeSuccess = createAction('miles/upgradeSuccess');
export const upgradeFailure = createAction('miles/upgradeFailure');
export const upgradeClear = createAction('miles/upgradeClear');
export const cancelUpgrade = createAction('miles/cancelUpgrade');
export const removeUpgradeTimer = createAction('miles/removeProlongationTimer');
export const changeUpgradeIsProcessTransaction = createAction(
    'miles/changeUpgradeIsProcessTransaction',
);
export const upgradeCloseModal = createAction('miles/upgradeCloseModal');
export const upgradeFinished = createAction('miles/upgradeFinished');

export const donate = createAction('miles/donate');
export const donateSuccess = createAction('miles/donateSuccess');
export const donateFailure = createAction('miles/donateFailure');
export const donateClear = createAction('miles/donateClear');
export const cancelDonation = createAction('miles/cancelDonation');
export const removeDonationTimer = createAction('miles/removeDonationTimer');
export const changeCharityIsProcessTransaction = createAction(
    'miles/changeCharityIsProcessTransaction',
);

export const claimLoyaltyCardCloseModal = createAction(
    'miles/claimLoyaltyCardCloseModal',
);
export const claimLoyaltyCardRemoveTimer = createAction(
    'miles/claimLoyaltyCardTimer',
);
export const claimLoyaltyCardCancel = createAction(
    'miles/claimLoyaltyCardCancel',
);
export const claimLoyaltyCardSuccess = createAction(
    'miles/claimLoyaltyCardSuccess',
);
export const claimLoyaltyCardFailure = createAction(
    'miles/claimLoyaltyCardFailure',
);
export const claimLoyaltyCardIsProcessTransaction = createAction(
    'miles/claimLoyaltyCardIsProcessTransaction',
);

export const fetchCharityAccounts = createAction('miles/fetchCharityAccounts');
export const fetchCharityAccountsSuccess = createAction(
    'miles/fetchCharityAccountsSuccess',
);
export const fetchCharityAccountsFailure = createAction(
    'miles/fetchCharityAccountsFailure',
);

export const activateCertificate = createAction('miles/activateCertificate');
export const activateCertificateSuccess = createAction(
    'miles/activateCertificateSuccess',
);
export const activateCertificateFailure = createAction(
    'miles/activateCertificateFailure',
);
export const changeCertificate = createAction('miles/changeCertificate');

export const activateStatus = createAction('miles/activateStatus');
export const activateStatusSuccess = createAction(
    'miles/activateStatusSuccess',
);
export const activateStatusFailure = createAction(
    'miles/activateStatusFailure',
);
export const activateStatusClear = createAction('miles/activateStatusClear');

export const fetchActivateStatusPackages = createAction(
    'miles/fetchActivateStatusPackages',
);
export const fetchActivateStatusPackagesSuccess = createAction(
    'miles/fetchActivateStatusPackagesSuccess',
);
export const fetchActivateStatusPackagesFailure = createAction(
    'miles/fetchActivateStatusPackagesFailure',
);

export const fetchTransferPackages = createAction(
    'miles/fetchTransferPackages',
);
export const fetchTransferPackagesSuccess = createAction(
    'miles/fetchTransferPackagesSuccess',
);
export const fetchTransferPackagesFailure = createAction(
    'miles/fetchTransferPackagesFailure',
);

export const fetchPurchasePackages = createAction(
    'miles/fetchPurchasePackages',
);
export const fetchPurchasePackagesSuccess = createAction(
    'miles/fetchPurchasePackagesSuccess',
);
export const fetchPurchasePackagesFailure = createAction(
    'miles/fetchPurchasePackagesFailure',
);

export const fetchExpirationPackages = createAction(
    'miles/fetchExpirationPackages',
);
export const fetchExpirationPackagesSuccess = createAction(
    'miles/fetchExpirationPackagesSuccess',
);
export const fetchExpirationPackagesFailure = createAction(
    'miles/fetchExpirationPackagesFailure',
);

export const fetchStatusPackages = createAction('miles/fetchStatusPackages');
export const fetchStatusPackagesSuccess = createAction(
    'miles/fetchStatusPackagesSuccess',
);
export const fetchStatusPackagesFailure = createAction(
    'miles/fetchStatusPackagesFailure',
);

export const fetchUpgradePackages = createAction('miles/fetchUpgradePackages');
export const fetchUpgradePackagesSuccess = createAction(
    'miles/fetchUpgradePackagesSuccess',
);
export const fetchUpgradePackagesFailure = createAction(
    'miles/fetchUpgradePackagesFailure',
);

export const fetchCardInfo = createAction('miles/fetchCardInfo');
export const fetchCardInfoSuccess = createAction('miles/fetchCardInfoSuccess');
export const fetchCardInfoFailure = createAction('miles/fetchCardInfoFailure');

export const expirationAction = createAction('miles/expirationAction');

export const changeIsPaymentProcess = createAction(
    'miles/changeIsPaymentProcess',
);

export const buyLoyaltyCard = createAction('miles/buyLoyaltyCard');
export const fetchPackagesLoyaltyCard = createAction(
    'miles/fetchPackagesLoyaltyCard',
);
export const fetchPackagesLoyaltyCardSuccess = createAction(
    'miles/fetchPackagesLoyaltyCardSuccess',
);
export const fetchPackagesLoyaltyCardFailure = createAction(
    'miles/fetchPackagesLoyaltyCardFailure',
);

export const fetchLoyaltyCardOrders = createAction(
    'miles/fetchLoyaltyCardOrders',
);
export const fetchLoyaltyCardOrdersSuccess = createAction(
    'miles/fetchLoyaltyCardOrdersSuccess',
);
export const fetchLoyaltyCardOrdersFailure = createAction(
    'miles/fetchLoyaltyCardOrdersFailure',
);

export const fetchProfileAddresses = createAction(
    'milies/fetchProfileAddresses',
);
export const fetchProfileAddressesSuccess = createAction(
    'milies/fetchProfileAddressesSuccess',
);
export const fetchProfileAddressesFailure = createAction(
    'milies/fetchProfileAddressesFailure',
);

const handleFetchSuccess = (state, payload) => {
    const { milesById } = state;

    milesById[payload.ffpcard] = {
        fadingTime: resetFading(),
        miles: payload.Miles,
    };

    return {
        ...state,
        milesById,
    };
};

/* ----------------- mile actions ----------------- */
const expandProp = 'expandedMileActions';
const handleCloseExpandedMilesAction = (state, { id }) =>
    assoc(expandProp, without([id], prop(expandProp, state)), state);

const handleChangeExpandedMilesAction = (
    state,
    { id, activateOnly = false },
) => {
    const expandedItems = prop(expandProp, state);

    if (contains(id, expandedItems) && !activateOnly) {
        return {
            ...assoc(expandProp, without([id], expandedItems), state),
            activateCertificate: initialState.activateCertificate,
        };
    }

    return {
        ...assoc(expandProp, append(id, expandedItems), state),
        activateCertificate: initialState.activateCertificate,
    };
};

/* ----------------- recover ----------------- */
const recoverProp = 'recover';
const recoverNotifLens = lensPath([recoverProp, 'notification']);
const recoverRequestNotifLens = lensPath([recoverProp, 'requestsNotification']);
const recoverIsOpenForm = lensPath([recoverProp, 'isOpenRecoverForm']);
const recoverInitialValuesLens = lensPath([recoverProp, 'initialValues']);

const handleRecover = state =>
    compose(set(recoverNotifLens, notificationState))(state);

const handleRecoverSuccess = (state, payload) =>
    set(recoverNotifLens, payload.notification, state);

const handleRecoverFailure = (state, { notification }) =>
    set(recoverNotifLens, notification, state);

const handleRecoverInitialize = (state, payload) =>
    set(recoverInitialValuesLens, payload.initialValues, state);

const handleRecoverClear = state =>
    compose(
        set(recoverNotifLens, { ...notificationState }),
        set(recoverRequestNotifLens, { ...notificationState }),
        set(recoverIsOpenForm, false),
        set(recoverInitialValuesLens, initialState.recover.initialValues),
    )(state);

const handleClearRecoverNotification = state =>
    assocPath(['recover', 'notification'], { ...notificationState }, state);

const handleChangeBoardingPass = openState => state =>
    assocPath(['recover', 'isOpenBoardingPass'], openState, state);

const handleFetchRecover = (state, payload) =>
    compose(
        assocPath(
            ['recover', 'requests'],
            payload.isRefresh ? [] : state.recover.requests,
        ),
        assocPath(['recover', 'isLoadingRequests'], true),
    )(state);

const handleFetchRecoverSuccess = (state, payload) => {
    const { isRefresh, page } = payload;

    let { requests } = state.recover;

    if (isRefresh && Array.isArray(page.content)) {
        if (!requests.length) {
            requests = page.content;
        } else {
            const comparator = (x, y) => prop('id', x) === prop('id', y);
            const diff = differenceWith(comparator, page.content, requests);

            requests = diff.length ? [...diff, ...requests] : requests;
        }
    } else {
        requests = uniqBy(prop('id'), [
            ...state.recover.requests,
            ...toArray(page.content),
        ]);
    }

    return compose(
        assocPath(['recover', 'requests'], requests),
        assocPath(['recover', 'nextPage'], state.recover.nextPage + 1),
        assocPath(['recover', 'isLoadingRequests'], false),
        assocPath(['recover', 'isLoadedRecovery'], !!requests.length),
        assocPath(['recover', 'totalPages'], payload.page.totalPage),
    )(state);
};

const handleFetchRecoverFailure = (state, { notification }) =>
    compose(
        assocPath(['recover', 'requestsNotification'], notification),
        assocPath(['recover', 'isLoadingRequests'], false),
    )(state);

const handleClearRecoverRequestNotification = state =>
    assocPath(
        ['recover', 'requestsNotification'],
        { ...notificationState },
        state,
    );

const handleChangeIsOpenRecoverForm = (state, isOpen) =>
    assocPath(['recover', 'isOpenRecoverForm'], isOpen, state);

const handleSearchFlight = state =>
    assocPath(['recover', 'isLoadingFlight'], true, state);

const handleSearchFlightNumbersSuccess = (state, data) =>
    evolve(
        {
            recover: {
                flightNumbers: () => data,
                flightNumbersNotification: () => ({}),
            },
        },
        state,
    );

const handleSearchFlightNumbersFailure = (state, { notification }) =>
    evolve(
        {
            recover: {
                flightNumbers: () => ({}),
                flightNumbersNotification: () => notification,
            },
        },
        state,
    );

const handleSearchFlightSuccess = (state, data) =>
    evolve(
        {
            recover: {
                flight: () => data,
                flightNotification: () => ({}),
                isLoadingFlight: F,
            },
        },
        state,
    );

const handleSearchFlightFailure = (state, { notification }) =>
    evolve(
        {
            recover: {
                flight: () => null,
                flightNotification: () => notification,
                isLoadingFlight: F,
            },
        },
        state,
    );

const handleClearFlight = state =>
    evolve(
        {
            recover: {
                flight: () => null,
                flightNotification: () => ({}),
            },
        },
        state,
    );

const handleClearFlightNumbers = state =>
    evolve(
        {
            recover: {
                flightNumbers: () => ({}),
                flightNumbersNotification: () => ({}),
            },
        },
        state,
    );

/* ----------------- activate status ----------------- */
const activateStatusProp = 'activateStatus';
const activateStatusNotifLens = lensPath([activateStatusProp, 'notification']);
const activateStatusHasErrorLens = lensPath([activateStatusProp, 'hasError']);
const activateStatusModalLens = lensPath([activateStatusProp, 'modal']);
const activateStatusTransactionIdLens = lensPath([
    activateStatusProp,
    'transactionId',
]);
const handleActivateStatus = identity;

const handleActivateStatusSuccess = (state, payload) => {
    const { step1, step3, transactionId, boundCardSuccess } = payload;
    const milesCount =
        sessionStorage.getItem('milesBuyCount') || payload.milesCount;

    if (step3) {
        sessionStorage.removeItem('paymentType');
        sessionStorage.removeItem('paymentDetail');
        sessionStorage.removeItem('milesBuyCount');
        sessionStorage.removeItem('milesBuyPrice');
    }

    return compose(
        step3 || boundCardSuccess
            ? set(activateStatusModalLens, {
                  data: { milesCount },
                  type: 'success',
              })
            : identity,
        set(activateStatusNotifLens, propOr({}, 'notification', payload)),
        step1 ? set(activateStatusTransactionIdLens, transactionId) : identity,
        set(activateStatusHasErrorLens, false),
    )(state);
};

const handleActivateStatusFailure = (state, payload) => {
    sessionStorage.removeItem('paymentType');
    sessionStorage.removeItem('paymentDetail');
    sessionStorage.removeItem('milesBuyCount');

    return compose(
        set(activateStatusModalLens, { data: null, type: 'failure' }),
        set(activateStatusHasErrorLens, true),
        set(activateStatusNotifLens, propOr({}, 'notification', payload)),
    )(state);
};

const handleActivateStatusClear = state => {
    return compose(
        set(activateStatusNotifLens, {}),
        set(activateStatusHasErrorLens, false),
        set(activateStatusTransactionIdLens, null),
    )(state);
};

/* ----------------- buy ----------------- */
const buyProp = 'buy';
const buyNotifLens = lensPath([buyProp, 'notification']);
const buyHasErrorLens = lensPath([buyProp, 'hasError']);
const buyModalLens = lensPath([buyProp, 'modal']);
const buyTransactionIdLens = lensPath([buyProp, 'transactionId']);
const handleBuy = identity;

const handleBuySuccess = (state, payload) => {
    const { step1, step3, transactionId, boundCardSuccess } = payload;
    const milesCount =
        sessionStorage.getItem('milesBuyCount') || payload.milesCount;

    if (step3) {
        sessionStorage.removeItem('paymentType');
        sessionStorage.removeItem('paymentDetail');
        sessionStorage.removeItem('milesBuyCount');
        sessionStorage.removeItem('milesBuyPrice');
    }

    return compose(
        step3 || boundCardSuccess
            ? set(buyModalLens, { data: { milesCount }, type: 'success' })
            : identity,
        set(buyNotifLens, propOr({}, 'notification', payload)),
        step1 ? set(buyTransactionIdLens, transactionId) : identity,
        set(buyHasErrorLens, false),
    )(state);
};

const handleBuyFailure = (state, payload) => {
    sessionStorage.removeItem('paymentType');
    sessionStorage.removeItem('paymentDetail');
    sessionStorage.removeItem('milesBuyCount');

    return compose(
        set(buyModalLens, { data: null, type: 'failure' }),
        set(buyHasErrorLens, true),
        set(buyNotifLens, propOr({}, 'notification', payload)),
    )(state);
};

const handleBuyClear = state => {
    return compose(
        set(buyNotifLens, {}),
        set(buyHasErrorLens, false),
        set(buyTransactionIdLens, null),
    )(state);
};

const handleBuyCloseModal = state => {
    return compose(
        set(buyModalLens, {
            data: null,
            type: '',
        }),
    )(state);
};

/* ----------------- expiration ----------------- */
const expirationProp = 'expiration';
const expirationNotifLens = lensPath([expirationProp, 'notification']);
const expirationActionLens = lensPath([expirationProp, 'isAction']);
const expirationHasErrorLens = lensPath([expirationProp, 'hasError']);
const expirationTransactionIdLens = lensPath([expirationProp, 'transactionId']);
const expirationModalLens = lensPath([expirationProp, 'modal']);

const handleExpiration = state => {
    return compose(
        set(expirationNotifLens, notificationState),
        set(expirationHasErrorLens, false),
    )(state);
};

const handleExpirationSuccess = (state, payload) => {
    const { step1, step2, step3, transactionId, success, boundCardSuccess } =
        payload;

    if (step3) {
        sessionStorage.removeItem('paymentType');
        sessionStorage.removeItem('paymentDetail');
    }

    return compose(
        step3 || (success && step2) || boundCardSuccess
            ? set(expirationModalLens, { type: 'success' })
            : identity,
        step3 || (success && step2)
            ? set(expirationNotifLens, propOr({}, 'notification', payload))
            : identity,
        step1 ? set(expirationTransactionIdLens, transactionId) : identity,
        set(expirationHasErrorLens, false),
    )(state);
};

const handleExpirationFailure = (state, payload) => {
    sessionStorage.removeItem('paymentType');
    sessionStorage.removeItem('paymentDetail');

    return compose(
        set(expirationModalLens, { data: null, type: 'failure' }),
        set(expirationHasErrorLens, true),
        set(expirationNotifLens, propOr({}, 'notification', payload)),
    )(state);
};

const handleExpirationClear = state => {
    return compose(
        view(expirationHasErrorLens, state)
            ? set(expirationNotifLens, {})
            : identity,
        view(expirationHasErrorLens, state)
            ? set(expirationHasErrorLens, false)
            : identity,
        set(expirationTransactionIdLens, null),
    )(state);
};

const handleExpirationAction = (state, payload) =>
    set(expirationActionLens, payload, state);

const handleExpirationCloseModal = state => {
    return compose(
        set(expirationModalLens, {
            data: null,
            type: '',
        }),
    )(state);
};

/* ----------------- transfer ----------------- */
const transferProp = 'transfer';
const transferNotifLens = lensPath([transferProp, 'notification']);
const transferHasErrorLens = lensPath([transferProp, 'hasError']);
const transferIsSentCodeLens = lensPath([transferProp, 'isSentCode']);
const transferCodeSentTimeLens = lensPath([transferProp, 'codeSentTime']);
const transferAttemptsLeftLens = lensPath([transferProp, 'attemptsLeft']);
const transferTransactionIdLens = lensPath([transferProp, 'transactionId']);
const transferModalLens = lensPath([transferProp, 'modal']);

const handleTransfer = identity;

const handleTransferSuccess = (state, payload) => {
    const attemptsLeft = view(transferAttemptsLeftLens, state);
    const { step1, step2, step3, step4, transactionId, boundCardSuccess } =
        payload;
    const milesCount =
        sessionStorage.getItem('milesBuyCount') || payload.milesCount;
    const transferFfpCard =
        sessionStorage.getItem('transferFfpCard') || payload.destination;

    if (step4) {
        sessionStorage.removeItem('paymentType');
        sessionStorage.removeItem('paymentDetail');
        sessionStorage.removeItem('milesBuyCount');
        sessionStorage.removeItem('transferFfpCard');
    }

    return compose(
        step3 || step4 || boundCardSuccess
            ? set(transferModalLens, {
                  data: { ffpcard: transferFfpCard, milesCount },
                  type: 'success',
              })
            : identity,
        step3 || step4 ? set(transferTransactionIdLens, null) : identity,
        step3 || step4 ? set(transferAttemptsLeftLens, 5) : identity,
        step3 || step4 ? set(transferIsSentCodeLens, null) : identity,
        step2 ? set(transferCodeSentTimeLens, null) : identity,
        step1 ? set(transferCodeSentTimeLens, moment().format()) : identity,
        step1 ? set(transferTransactionIdLens, transactionId) : identity,
        step1 ? set(transferAttemptsLeftLens, attemptsLeft - 1) : identity,
        set(transferNotifLens, payload.notification || {}),
        set(transferHasErrorLens, false),
        set(transferIsSentCodeLens, !step3),
    )(state);
};

const handleTransferFailure = (state, payload) => {
    const { code, serviceError, serviceUnavailable } = payload.notification;

    const hasMilesActionError = [
        milesActionError.CHARITY_ATTEMPTS_LEFT,
        milesActionError.INVALID_DESTINATION,
    ].includes(code);
    const getIsSentSetter =
        serviceError || serviceUnavailable || hasMilesActionError
            ? set(transferIsSentCodeLens, false)
            : identity;

    sessionStorage.removeItem('paymentType');
    sessionStorage.removeItem('paymentDetail');
    sessionStorage.removeItem('transferFfpCard');
    sessionStorage.removeItem('milesBuyCount');

    return compose(
        set(transferModalLens, { data: null, type: 'failure' }),
        set(transferNotifLens, payload.notification),
        set(transferHasErrorLens, true),
        getIsSentSetter,
    )(state);
};

const handleTransferClear = state =>
    compose(
        set(transferNotifLens, {}),
        set(transferHasErrorLens, false),
        set(transferAttemptsLeftLens, 5), // temp
        set(transferIsSentCodeLens, false),
        set(transferTransactionIdLens, null),
    )(state);

const handleCancelTransfer = state =>
    compose(
        set(transferAttemptsLeftLens, 5), // temp
        set(transferIsSentCodeLens, false),
        set(transferTransactionIdLens, null),
    )(state);

const handleChangeTransferIsProcessTransaction = (state, isProcess) =>
    assocPath([transferProp, 'isProcessTransaction'], isProcess, state);

const handleRemoveTransferTimer = state =>
    set(transferCodeSentTimeLens, null, state);

const handleTransferCloseModal = state => {
    return compose(
        set(transferModalLens, {
            data: null,
            type: '',
        }),
    )(state);
};

/* ----------------- prolongation ----------------- */
const prolongationProp = 'prolongation';
const prolongationNotifLens = lensPath([prolongationProp, 'notification']);
const prolongationHasErrorLens = lensPath([prolongationProp, 'hasError']);
const prolongationIsSentCodeLens = lensPath([prolongationProp, 'isSentCode']);
const prolongationCodeSentTimeLens = lensPath([
    prolongationProp,
    'codeSentTime',
]);
const prolongationAttemptsLeftLens = lensPath([
    prolongationProp,
    'attemptsLeft',
]);
const prolongationTransactionIdLens = lensPath([
    prolongationProp,
    'transactionId',
]);
const prolongationModalLens = lensPath([prolongationProp, 'modal']);

const handleProlongation = identity;

const handleProlongationSuccess = (state, payload) => {
    const attemptsLeft = view(prolongationAttemptsLeftLens, state);
    const {
        step1,
        step2,
        step3,
        step4,
        isPayment,
        transactionId,
        boundCardSuccess,
    } = payload;

    if (step4) {
        sessionStorage.removeItem('paymentType');
        sessionStorage.removeItem('paymentDetail');
    }

    return compose(
        step3 || step4 || boundCardSuccess
            ? set(prolongationModalLens, { type: 'success' })
            : identity,
        step3 || step4 ? set(prolongationTransactionIdLens, null) : identity,
        step3 || step4 ? set(prolongationAttemptsLeftLens, 5) : identity,
        step3 || step4 ? set(prolongationIsSentCodeLens, null) : identity,
        step2 ? set(prolongationCodeSentTimeLens, null) : identity,
        step1 ? set(prolongationCodeSentTimeLens, moment().format()) : identity,
        step1 || (step2 && isPayment)
            ? set(prolongationTransactionIdLens, transactionId)
            : identity,
        step1 ? set(prolongationAttemptsLeftLens, attemptsLeft - 1) : identity,
        set(prolongationNotifLens, payload.notification || {}),
        set(prolongationHasErrorLens, false),
        set(prolongationIsSentCodeLens, !step3 && !isPayment),
    )(state);
};

const handleProlongationFailure = (state, payload) => {
    const isAttemptsLeft =
        payload.notification.code === 'charity.attempts.left'; // temp
    const getIsSentSetter =
        payload.notification.serviceError || isAttemptsLeft
            ? set(prolongationIsSentCodeLens, false)
            : identity;

    sessionStorage.removeItem('paymentType');
    sessionStorage.removeItem('paymentDetail');

    return compose(
        set(prolongationModalLens, { data: null, type: 'failure' }),
        set(prolongationNotifLens, payload.notification),
        set(prolongationHasErrorLens, true),
        getIsSentSetter,
    )(state);
};

const handleProlongationClear = state =>
    compose(
        view(prolongationHasErrorLens, state)
            ? set(prolongationNotifLens, {})
            : identity,
        view(prolongationHasErrorLens, state)
            ? set(prolongationHasErrorLens, false)
            : identity,
        set(prolongationAttemptsLeftLens, 5), // temp
        set(prolongationIsSentCodeLens, false),
        set(prolongationTransactionIdLens, null),
    )(state);

const handleCancelProlongation = state =>
    compose(
        view(prolongationHasErrorLens, state)
            ? set(prolongationNotifLens, {})
            : identity,
        view(prolongationHasErrorLens, state)
            ? set(prolongationHasErrorLens, false)
            : identity,
        set(prolongationAttemptsLeftLens, 5), // temp
        set(prolongationIsSentCodeLens, false),
        set(prolongationTransactionIdLens, null),
    )(state);

const handleChangeProlongationIsProcessTransaction = (state, isProcess) =>
    assocPath([prolongationProp, 'isProcessTransaction'], isProcess, state);

const handleRemoveProlongationTimer = state =>
    set(prolongationCodeSentTimeLens, null, state);

const handleProlongationCloseModal = state => {
    return compose(
        set(prolongationModalLens, {
            data: null,
            type: '',
        }),
    )(state);
};

/* ----------------- upgrade ----------------- */
const upgradeProp = 'upgrade';
const upgradeNotifLens = lensPath([upgradeProp, 'notification']);
const upgradeHasErrorLens = lensPath([upgradeProp, 'hasError']);
const upgradeIsSentCodeLens = lensPath([upgradeProp, 'isSentCode']);
const upgradeCodeSentTimeLens = lensPath([upgradeProp, 'codeSentTime']);
const upgradeAttemptsLeftLens = lensPath([upgradeProp, 'attemptsLeft']);
const upgradeTransactionIdLens = lensPath([upgradeProp, 'transactionId']);
const upgradeModalLens = lensPath([upgradeProp, 'modal']);
const upgradeSuccessfullyFinishedLens = lensPath([
    upgradeProp,
    'isSuccessfullyFinished',
]);

const handleUpgrade = identity;

const handleUpgradeSuccess = (state, payload) => {
    const attemptsLeft = view(upgradeAttemptsLeftLens, state);
    const {
        step1,
        step2,
        step3,
        step4,
        isPayment,
        transactionId,
        boundCardSuccess,
        confirmed,
    } = payload;

    if (step4) {
        sessionStorage.removeItem('paymentType');
        sessionStorage.removeItem('paymentDetail');
    }

    return compose(
        step3 || step4 || boundCardSuccess
            ? set(upgradeModalLens, { type: 'success' })
            : identity,
        step3 || step4 ? set(upgradeTransactionIdLens, null) : identity,
        step3 || step4 ? set(upgradeAttemptsLeftLens, 5) : identity,
        step3 || step4 ? set(upgradeIsSentCodeLens, null) : identity,
        step2 ? set(upgradeCodeSentTimeLens, null) : identity,
        step1 ? set(upgradeCodeSentTimeLens, moment().format()) : identity,
        step1 || (step2 && isPayment)
            ? set(upgradeTransactionIdLens, transactionId)
            : identity,
        step1 ? set(upgradeAttemptsLeftLens, attemptsLeft - 1) : identity,
        set(upgradeNotifLens, payload.notification || {}),
        set(upgradeHasErrorLens, false),
        set(upgradeIsSentCodeLens, step1 && !confirmed && !step3 && !isPayment),
    )(state);
};

const handleUpgradeFailure = (state, payload) => {
    const isAttemptsLeft =
        payload.notification.code === 'charity.attempts.left'; // temp
    const getIsSentSetter =
        payload.notification.serviceError || isAttemptsLeft
            ? set(upgradeIsSentCodeLens, false)
            : identity;

    sessionStorage.removeItem('paymentType');
    sessionStorage.removeItem('paymentDetail');

    return compose(
        set(upgradeModalLens, { data: null, type: 'failure' }),
        set(upgradeNotifLens, payload.notification),
        set(upgradeHasErrorLens, true),
        set(upgradeSuccessfullyFinishedLens, false),
        getIsSentSetter,
    )(state);
};

const handleUpgradeClear = state =>
    compose(
        view(upgradeHasErrorLens, state) ? set(upgradeNotifLens, {}) : identity,
        view(upgradeHasErrorLens, state)
            ? set(upgradeHasErrorLens, false)
            : identity,
        set(upgradeAttemptsLeftLens, 5), // temp
        set(upgradeIsSentCodeLens, false),
        set(upgradeTransactionIdLens, null),
        set(upgradeSuccessfullyFinishedLens, false),
    )(state);

const handleUpgradeFinished = state =>
    compose(set(upgradeSuccessfullyFinishedLens, true))(state);

const handleCancelUpgrade = state =>
    compose(
        view(upgradeHasErrorLens, state) ? set(upgradeNotifLens, {}) : identity,
        view(upgradeHasErrorLens, state)
            ? set(upgradeHasErrorLens, false)
            : identity,
        set(upgradeAttemptsLeftLens, 5), // temp
        set(upgradeIsSentCodeLens, false),
        set(upgradeTransactionIdLens, null),
        set(upgradeSuccessfullyFinishedLens, false),
    )(state);

const handleChangeUpgradeIsProcessTransaction = (state, isProcess) =>
    assocPath([upgradeProp, 'isProcessTransaction'], isProcess, state);

const handleRemoveUpgradeTimer = state =>
    set(upgradeCodeSentTimeLens, null, state);

const handleUpgradeCloseModal = state => {
    return compose(
        set(upgradeModalLens, {
            data: null,
            type: '',
        }),
    )(state);
};

/* ----------------- charity ----------------- */
const charityProp = 'charity';
const charityNotifLens = lensPath([charityProp, 'notification']);
const charityHasErrorLens = lensPath([charityProp, 'hasError']);
const charityIsSentCodeLens = lensPath([charityProp, 'isSentCode']);
const charityCodeSentTimeLens = lensPath([charityProp, 'codeSentTime']);
const charityAttemptsLeftLens = lensPath([charityProp, 'attemptsLeft']);
const charityTransactionIdLens = lensPath([charityProp, 'transactionId']);

const handleDonate = identity;

const handleDonateSuccess = (state, payload) => {
    const attemptsLeft = view(charityAttemptsLeftLens, state); // temp
    const { step1, step2, step3, transactionId } = payload;

    return compose(
        step3 ? set(charityNotifLens, payload.notification || {}) : identity,
        step2 ? set(charityCodeSentTimeLens, null) : identity,
        step1 ? set(charityCodeSentTimeLens, moment().format()) : identity,
        step1 ? set(charityTransactionIdLens, transactionId) : identity,
        step1 ? set(charityAttemptsLeftLens, attemptsLeft - 1) : identity,
        set(charityHasErrorLens, false),
        set(charityIsSentCodeLens, !step3),
    )(state);
};

const handleDonateFailure = (state, payload) => {
    const isAttemptsLeft =
        payload.notification.code === 'charity.attempts.left'; // temp
    const getIsSentSetter =
        payload.notification.serviceError || isAttemptsLeft
            ? set(charityIsSentCodeLens, false)
            : identity;

    return compose(
        set(charityNotifLens, payload.notification),
        set(charityHasErrorLens, true),
        getIsSentSetter,
    )(state);
};

const handleDonateClear = state =>
    compose(
        set(charityNotifLens, {}),
        set(charityHasErrorLens, false),
        set(charityAttemptsLeftLens, 5), // temp
        set(charityIsSentCodeLens, false),
    )(state);

const handleCancelDonation = state =>
    compose(
        set(charityIsSentCodeLens, false),
        set(charityNotifLens, {}),
        set(charityHasErrorLens, false),
    )(state);

/* ----------------- loyaltyCardOrders ----------------- */
const loyaltyCard = 'loyaltyCard';
const loyaltyCardNotifLens = lensPath([loyaltyCard, 'notification']);
const loyaltyCardTransactionIdLens = lensPath([loyaltyCard, 'transactionId']);
const loyaltyCardModalLens = lensPath([loyaltyCard, 'isShowModal']);
const loyaltyCardСodeSentTimeLens = lensPath([loyaltyCard, 'codeSentTime']);
const loyaltyCardIsProcessTransactionLens = lensPath([
    loyaltyCard,
    'isProcessTransaction',
]);
const loyaltyCardIsSuccessLens = lensPath([loyaltyCard, 'isSuccess']);

const handleClaimLoyaltyCardCloseModal = state => {
    return compose(
        set(loyaltyCardModalLens, false),
        set(loyaltyCardIsSuccessLens, false),
    )(state);
};

const handleClaimLoyaltyCardSuccess = (state, payload) => {
    const { transactionId, showConfirmModal, notification, step2, success } =
        payload;

    return compose(
        set(loyaltyCardModalLens, showConfirmModal),
        set(loyaltyCardTransactionIdLens, transactionId),
        set(loyaltyCardNotifLens, notification),
        set(loyaltyCardIsSuccessLens, success),
        step2 ? set(loyaltyCardСodeSentTimeLens, null) : identity,
        showConfirmModal
            ? set(loyaltyCardСodeSentTimeLens, moment().format())
            : identity,
    )(state);
};

const handleClaimLoyaltyCardFailure = (state, payload) =>
    set(loyaltyCardNotifLens, payload.notification, state);

const handleClaimLoyaltyCardRemoveTimer = state =>
    set(loyaltyCardСodeSentTimeLens, null, state);

const handleClaimLoyaltyCardIsProcessTransaction = (state, isProcess) =>
    set(loyaltyCardIsProcessTransactionLens, isProcess, state);

const handleChangeCharityIsProcessTransaction = (state, isProcess) =>
    assocPath([charityProp, 'isProcessTransaction'], isProcess, state);

const handleClaimLoyaltyCancel = set(loyaltyCardIsSuccessLens, null);

const handleLoyaltyCardOrders = state =>
    compose(
        assocPath(['loyaltyCardOrders', 'isLoading'], true),
        assocPath(['loyaltyCardOrders', 'isLoaded'], false),
        assocPath(['loyaltyCardOrders', 'hasError'], false),
    )(state);

const handleLoyaltyCardOrdersFailure = state =>
    compose(
        assocPath(['loyaltyCardOrders', 'isLoading'], false),
        assocPath(['loyaltyCardOrders', 'hasError'], true),
    )(state);

const handleLoyaltyCardOrdersSuccess = (state, payload) =>
    compose(
        assocPath(['loyaltyCardOrders', 'isLoading'], false),
        assocPath(['loyaltyCardOrders', 'hasError'], false),
        assocPath(['loyaltyCardOrders', 'isLoaded'], true),
        assocPath(['loyaltyCardOrders', 'items'], payload.orders || []),
    )(state);

const handleRemoveDonationTimer = state =>
    set(charityCodeSentTimeLens, null, state);

const handleFetchPackages = entity => state =>
    assocPath([entity, 'packages', 'isLoading'], true)(state);
const handleFetchPackagesSuccess = (entity, packagesKey) => (state, payload) =>
    compose(
        assocPath([entity, 'packages', 'isLoading'], false),
        assocPath([entity, 'packages', 'isLoaded'], true),
        assocPath([entity, 'packages', 'items'], payload[packagesKey]),
        assocPath([entity, 'notification'], payload.notification || {}),
    )(state);

const handleFetchPackagesFailure = entity => (state, payload) =>
    compose(
        assocPath([entity, 'packages', 'isLoading'], false),
        assocPath([entity, 'packages', 'hasError'], true),
        assocPath([entity, 'packages', 'notification'], payload.notification),
        assocPath([entity, 'notification'], payload.notification),
    )(state);

const handleFetchTransferPackages = state =>
    compose(
        handleFetchPackages('transfer'),
        handleFetchPackages('charity'),
    )(state);

const handleFetchTransferPackagesSuccess = (state, payload) =>
    handleFetchPackagesSuccess('charity', 'milesCharityPackages')(
        handleFetchPackagesSuccess('transfer', 'milesTransferPackages')(
            state,
            payload,
        ),
        payload,
    );

const handleFetchTransferPackagesFailure = (state, payload) =>
    handleFetchPackagesFailure('charity')(
        handleFetchPackagesFailure('transfer')(state, payload),
        payload,
    );

/* ----------------- charity accounts ----------------- */
const handleFetchCharityAccounts = state =>
    assocPath(['charity', 'accounts', 'isLoading'], true, state);
const handleFetchCharityAccountsSuccess = (state, payload) =>
    compose(
        assocPath(['charity', 'accounts', 'isLoading'], false),
        assocPath(['charity', 'accounts', 'hasError'], false),
        assocPath(['charity', 'accounts', 'isLoaded'], true),
        assocPath(['charity', 'accounts', 'items'], payload.charities),
        assocPath(
            ['charity', 'accounts', 'notification'],
            payload.notification || {},
        ),
    )(state);
const handleFetchCharityAccountsFailure = (state, payload) =>
    compose(
        assocPath(['charity', 'accounts', 'isLoading'], false),
        assocPath(['charity', 'accounts', 'hasError'], true),
        assocPath(
            ['charity', 'accounts', 'notification'],
            payload.notification,
        ),
    )(state);

const handleActivateCertificate = state => ({
    ...state,
    activateCertificate: {
        error: null,
        isLoading: true,
        isSuccess: false,
    },
});

const handleActivateCertificateSuccess = state => ({
    ...state,
    activateCertificate: {
        error: null,
        isLoading: false,
        isSuccess: true,
    },
});

const handleActivateCertificateFailure = (state, payload) => ({
    ...state,
    activateCertificate: {
        error: payload,
        isLoading: false,
        isSuccess: false,
    },
});

const handleCertificateChange = state => ({
    ...state,
    activateCertificate: {
        error: null,
        isLoading: false,
        isSuccess: false,
    },
});

const handleFetchCardInfo = (state, { card }) => {
    if (state.cardsById[card]) {
        return {
            ...state,
        };
    }

    return assocPath(
        ['cardsById', card],
        {
            card,
            isLoaded: false,
        },
        state,
    );
};

const handleFetchCardInfoSuccess = (state, payload) => {
    return compose(
        set(transferHasErrorLens, false),
        set(transferNotifLens, {}),
        assocPath(['cardsById', payload.card], {
            isLoaded: true,
            ...payload,
        }),
    )(state);
};

const handleFetchCardInfoFailure = (state, { card, notification = {} }) => {
    return compose(
        set(transferNotifLens, notification),
        set(transferHasErrorLens, true),
        assocPath(['cardsById', card], {
            isLoaded: false,
        }),
    )(state);
};

const handleChangeIsPaymentProcess = (state, isPayment) => ({
    ...state,
    isPayment: !!isPayment,
});

const handleFetchProfileAddresses = state =>
    compose(
        assocPath(['addresses', 'isLoading'], true),
        assocPath(['addresses', 'isLoaded'], false),
        assocPath(['addresses', 'hasError'], false),
    )(state);

const handleFetchProfileAddressesSuccess = (state, payload) =>
    compose(
        assocPath(['addresses', 'isLoading'], false),
        assocPath(['addresses', 'hasError'], false),
        assocPath(['addresses', 'isLoaded'], true),
        assocPath(['addresses', 'items'], payload.addresses || []),
    )(state);

const handleFetchProfileAddressesFailure = state =>
    compose(
        assocPath(['addresses', 'isLoading'], false),
        assocPath(['addresses', 'hasError'], true),
    )(state);

export default createReducer(
    {
        [activateCertificate]: handleActivateCertificate,
        [activateCertificateFailure]: handleActivateCertificateFailure,
        [activateCertificateSuccess]: handleActivateCertificateSuccess,
        [activateStatus]: handleActivateStatus,
        [activateStatusClear]: handleActivateStatusClear,
        [activateStatusFailure]: handleActivateStatusFailure,
        [activateStatusSuccess]: handleActivateStatusSuccess,
        [buy]: handleBuy,
        [buyClear]: handleBuyClear,
        [buyCloseModal]: handleBuyCloseModal,
        [buyFailure]: handleBuyFailure,
        [buyLoyaltyCard]: handleBuy,
        [buySuccess]: handleBuySuccess,
        [cancelDonation]: handleCancelDonation,
        [cancelProlongation]: handleCancelProlongation,
        [cancelTransfer]: handleCancelTransfer,
        [cancelUpgrade]: handleCancelUpgrade,
        [changeCertificate]: handleCertificateChange,
        [changeCharityIsProcessTransaction]:
            handleChangeCharityIsProcessTransaction,
        [changeExpandedMileActions]: handleChangeExpandedMilesAction,
        [changeIsOpenRecoverForm]: handleChangeIsOpenRecoverForm,
        [changeIsPaymentProcess]: handleChangeIsPaymentProcess,
        [changeProlongationIsProcessTransaction]:
            handleChangeProlongationIsProcessTransaction,
        [changeTransferIsProcessTransaction]:
            handleChangeTransferIsProcessTransaction,
        [changeUpgradeIsProcessTransaction]:
            handleChangeUpgradeIsProcessTransaction,
        [claimLoyaltyCardCancel]: handleClaimLoyaltyCancel,
        [claimLoyaltyCardCloseModal]: handleClaimLoyaltyCardCloseModal,
        [claimLoyaltyCardFailure]: handleClaimLoyaltyCardFailure,
        [claimLoyaltyCardIsProcessTransaction]:
            handleClaimLoyaltyCardIsProcessTransaction,
        [claimLoyaltyCardRemoveTimer]: handleClaimLoyaltyCardRemoveTimer,
        [claimLoyaltyCardSuccess]: handleClaimLoyaltyCardSuccess,
        [clearFlight]: handleClearFlight,
        [clearFlightNumbers]: handleClearFlightNumbers,
        [clearRecoverNotification]: handleClearRecoverNotification,
        [clearRecoverRequestsNotification]:
            handleClearRecoverRequestNotification,
        [closeBoardingPass]: handleChangeBoardingPass(false),
        [closeExpandedMileActions]: handleCloseExpandedMilesAction,
        [donate]: handleDonate,
        [donateClear]: handleDonateClear,
        [donateFailure]: handleDonateFailure,
        [donateSuccess]: handleDonateSuccess,
        [expiration]: handleExpiration,
        [expirationAction]: handleExpirationAction,
        [expirationClear]: handleExpirationClear,
        [expirationCloseModal]: handleExpirationCloseModal,
        [expirationFailure]: handleExpirationFailure,
        [expirationSuccess]: handleExpirationSuccess,
        [fetchActivateStatusPackages]: handleFetchPackages('activateStatus'),
        [fetchActivateStatusPackagesFailure]:
            handleFetchPackagesFailure('activateStatus'),
        [fetchActivateStatusPackagesSuccess]: handleFetchPackagesSuccess(
            'activateStatus',
            'purchaseStatusPackages',
        ),
        [fetchCardInfo]: handleFetchCardInfo,
        [fetchCardInfoFailure]: handleFetchCardInfoFailure,
        [fetchCardInfoSuccess]: handleFetchCardInfoSuccess,
        [fetchCharityAccounts]: handleFetchCharityAccounts,
        [fetchCharityAccountsFailure]: handleFetchCharityAccountsFailure,
        [fetchCharityAccountsSuccess]: handleFetchCharityAccountsSuccess,
        [fetchExpirationPackages]: handleFetchPackages('expiration'),
        [fetchExpirationPackagesFailure]:
            handleFetchPackagesFailure('expiration'),
        [fetchExpirationPackagesSuccess]: handleFetchPackagesSuccess(
            'expiration',
            'expirationMilesPackages',
        ),
        [fetchLoyaltyCardOrders]: handleLoyaltyCardOrders,
        [fetchLoyaltyCardOrdersFailure]: handleLoyaltyCardOrdersFailure,
        [fetchLoyaltyCardOrdersSuccess]: handleLoyaltyCardOrdersSuccess,
        [fetchPackagesLoyaltyCard]: handleFetchPackages('loyaltyCard'),
        [fetchPackagesLoyaltyCardFailure]:
            handleFetchPackagesFailure('loyaltyCard'),
        [fetchPackagesLoyaltyCardSuccess]: handleFetchPackagesSuccess(
            'loyaltyCard',
            'packages',
        ),
        [fetchProfileAddresses]: handleFetchProfileAddresses,
        [fetchProfileAddressesFailure]: handleFetchProfileAddressesFailure,
        [fetchProfileAddressesSuccess]: handleFetchProfileAddressesSuccess,
        [fetchPurchasePackages]: handleFetchPackages('purchase'),
        [fetchPurchasePackagesFailure]: handleFetchPackagesFailure('purchase'),
        [fetchPurchasePackagesSuccess]: handleFetchPackagesSuccess(
            'purchase',
            'milesPurchasePackages',
        ),
        [fetchRecover]: handleFetchRecover,
        [fetchRecoverFailure]: handleFetchRecoverFailure,
        [fetchRecoverSuccess]: handleFetchRecoverSuccess,
        [fetchStatusPackages]: handleFetchPackages('prolongation'),
        [fetchStatusPackagesFailure]:
            handleFetchPackagesFailure('prolongation'),
        [fetchStatusPackagesSuccess]: handleFetchPackagesSuccess(
            'prolongation',
            'qualifyingMilesPackages',
        ),
        [fetchSuccess]: handleFetchSuccess,
        [fetchTransferPackages]: handleFetchTransferPackages,
        [fetchTransferPackagesFailure]: handleFetchTransferPackagesFailure,
        [fetchTransferPackagesSuccess]: handleFetchTransferPackagesSuccess,
        [fetchUpgradePackages]: handleFetchPackages('upgrade'),
        [fetchUpgradePackagesFailure]: handleFetchPackagesFailure('upgrade'),
        [fetchUpgradePackagesSuccess]: handleFetchPackagesSuccess(
            'upgrade',
            'qualifyingMilesPackages',
        ),
        [openBoardingPass]: handleChangeBoardingPass(true),
        [prolongation]: handleProlongation,
        [prolongationClear]: handleProlongationClear,
        [prolongationCloseModal]: handleProlongationCloseModal,
        [prolongationFailure]: handleProlongationFailure,
        [prolongationSuccess]: handleProlongationSuccess,
        [recover]: handleRecover,
        [recoverClear]: handleRecoverClear,
        [recoverFailure]: handleRecoverFailure,
        [recoverInitialize]: handleRecoverInitialize,
        [recoverSuccess]: handleRecoverSuccess,
        [removeDonationTimer]: handleRemoveDonationTimer,
        [removeProlongationTimer]: handleRemoveProlongationTimer,
        [removeTransferTimer]: handleRemoveTransferTimer,
        [removeUpgradeTimer]: handleRemoveUpgradeTimer,
        [searchFlight]: handleSearchFlight,
        [searchFlightFailure]: handleSearchFlightFailure,
        [searchFlightNumbersFailure]: handleSearchFlightNumbersFailure,
        [searchFlightNumbersSuccess]: handleSearchFlightNumbersSuccess,
        [searchFlightSuccess]: handleSearchFlightSuccess,
        [setupRestoreMiles]: state => state,
        [transfer]: handleTransfer,
        [transferClear]: handleTransferClear,
        [transferCloseModal]: handleTransferCloseModal,
        [transferFailure]: handleTransferFailure,
        [transferSuccess]: handleTransferSuccess,
        [upgrade]: handleUpgrade,
        [upgradeClear]: handleUpgradeClear,
        [upgradeCloseModal]: handleUpgradeCloseModal,
        [upgradeFailure]: handleUpgradeFailure,
        [upgradeFinished]: handleUpgradeFinished,
        [upgradeSuccess]: handleUpgradeSuccess,
    },
    initialState,
);

export * from './selectors';
