import { apiService } from './api';

const proxyUrl = '/service_b2b/employee';
const baseUrl = `${proxyUrl}/api/employees`;

export const employeeService = {
    confirmation: (id, syncWithProfile = true) =>
        apiService.put(`${baseUrl}/${id}/confirmation`, {
            syncWithProfile,
        }),
    delete: id => apiService.delete(`${baseUrl}/${id}`),
    disableShowInvitation: ({ employeeId }) =>
        apiService.delete(
            `${proxyUrl}/api/external/employees/${employeeId}/show-invitation`,
        ),
    getEmployeeAndCompanyInfo: ({ employeeId }) =>
        apiService.get(`${baseUrl}/${employeeId}?withCompany=true`),
    getEmployeeInvitation: ({ invitationId }) =>
        apiService.get(`${baseUrl}/${invitationId}`),
    getShowInvitation: ({ profileId }) =>
        apiService.get(
            `${proxyUrl}/api/external/employees/${profileId}/show-invitation`,
        ),
    profileSync: (id, enabled = false) =>
        apiService.put(`${baseUrl}/${id}/profile-sync`, {
            enabled,
        }),
};
