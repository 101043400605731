import { cond, equals, find, identity, not, startsWith, T } from 'ramda';

import { phoneMasks } from '../../data';

export const normalizePhone = value => {
    if (!value) {
        return value;
    }

    const phone = value.replace(/[^\d]/g, '');
    // eslint-disable-next-line no-shadow
    const code = find(theCode => phone.search(theCode.test) === 0)(phoneMasks);

    if (!code) {
        // eslint-disable-next-line no-useless-escape
        return value
            .replace(/[^\d\+\-\(\)]/g, '')
            .replace(/^\+{0,1}7\({0,1}0/g, '+7(');
    }

    let i = 0;

    return (
        code.mask
            // eslint-disable-next-line no-plusplus, no-unused-vars, no-useless-escape
            .replace(/(\#)/gi, num => phone[code.count + i++] || '')
    );
};

export const splitPhone = value => {
    if (!value) {
        return null;
    }

    const telephone = normalizePhone(value);

    if (!telephone) {
        return null;
    }

    const [countryCode, areaCode, ...number] = telephone.match(/(\d+)/gi);

    return {
        areaCode,
        countryCode,
        number: number.join(''),
        telephone,
    };
};

const isSpecialCode = value => {
    const twoSymbolCode = /8[1246]/.test(value.substring(0, 2));
    const threeSymbolCode = /8(5[2356]|7[0-4]|8[06])/.test(
        value.substring(0, 3),
    );

    return twoSymbolCode || threeSymbolCode;
};

const replace10 = val => (val[0] === '9' ? `7${val}` : val);
const replace11 = val =>
    val[0] === '8' && not(isSpecialCode(val)) ? `7${val.substring(1)}` : val;
const replace13 = value =>
    cond([
        [startsWith('007'), str => str.substring(2)],
        [
            str =>
                startsWith('008', str) && not(isSpecialCode(str.substring(2))),
            str => `7${str.substring(3)}`,
        ],
        [T, identity],
    ])(value);

const lengthIs = val => str => equals(val, str.length);

export const replacePhoneCode = value =>
    cond([
        [lengthIs(10), replace10],
        [lengthIs(11), replace11],
        [lengthIs(13), replace13],
        [T, identity],
    ])(value);
