import axios from 'axios';

const instance = axios.create({
    baseURL: '/api',
});

export const unicornService = {
    getOffers: data => instance.post('/unicorn', data),
    getStandings: () => instance.get('/unicorn/standings'),
};
