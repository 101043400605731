import moment from 'moment';

export const fadingTime = 300;
export const fadingLongTime = 3000;
export const isFading = time => moment().isBefore(time, 'second');
export const resetFading = (isLong = false, time = fadingTime) =>
    moment()
        .add(isLong ? fadingLongTime : time, 'seconds')
        .format();
export const setFading = () =>
    moment().subtract(fadingTime, 'seconds').format();
