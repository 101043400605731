import { createSelector } from 'reselect';

export const getIsQRCodesLoading = state => state.qrcodes.isLoading;
export const getIsQRCodesLoaded = state => state.qrcodes.isLoaded;
export const getQRCodesError = state => state.qrcodes.error;

export const getQRCodeById = (state, id) => state.qrcodes.items[id];
export const getQRCodeStatusById = (state, id) => state.qrcodes.processing[id];

const getQRCodeItems = state => state.qrcodes.items;

export const getQRCodesIds = createSelector(getQRCodeItems, items =>
    Object.keys(items),
);
