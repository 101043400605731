import { getDictionary } from '../../store/modules/languages';

export default function interceptor(store) {
    return error => {
        if (
            error &&
            (error.message === 'Network Error' ||
                (error.response && error.response.status === 504))
        ) {
            const dictionary = getDictionary(store.getState());
            const message = dictionary.t('com.error.timeout');

            error.response = {
                data: {
                    error: {
                        code: 'timeout',
                        message,
                    },
                },
                status: 504,
            };
        }

        if (!error.response) {
            error.response = {
                data: {
                    error: {
                        code: 'internal.service.error',
                    },
                },
                status: '500',
            };
        }

        return Promise.reject(error);
    };
}
