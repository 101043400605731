import { COOKIES } from 'consts';

import { apiService } from './api';
import { cookieService } from './cookies';

const authUrl = '/service/auth/api/profiles';
const authUrlB2B = '/service_b2b/auth/api/profiles';

export const authService = {
    // Update password for profile.
    changePassword: json =>
        apiService.put(`${authUrl}/%userId%/password`, json),

    // Trying to find ticket by parameters, if ticket not found, responses ticket not found.
    checkConfirmationCode: json =>
        apiService.put(`${authUrl}/tickets/check`, json),

    // Confirm auth details for profile.
    confirmAuthDetailsForProfile: ({ id, resourceId, secret }) =>
        apiService.put(`${authUrl}/confirmation`, { id, resourceId, secret }),

    confirmChangeContact: ({ id, resourceId, secret }) =>
        apiService.put(`${authUrl}/%userId%/update-credentials-tickets`, {
            id,
            resourceId,
            secret,
        }),

    confirmPasswordRecovery: json =>
        apiService.put(`${authUrl}/credentials`, json),

    // Confirm registration for auth details.
    confirmRegistration: json =>
        apiService.put(`${authUrl}/registration/confirmation`, json),

    // Login into profile.
    login: (json, b2b = false) =>
        apiService.post(`${b2b ? authUrlB2B : authUrl}/tickets`, json),

    // Logout from profile.
    logout: () =>
        apiService.delete(`${authUrl}/%userId%/tickets`, {
            data: { token: cookieService.get(COOKIES.SSDCP) },
        }),

    // Update profile authorization details.
    relogin: json => apiService.put(`${authUrl}/tickets`, json),

    // Resend registration confirmation ticket.
    resendConfirmationCode: json =>
        apiService.post(`${authUrl}/registration/confirmation`, json),
    resendConfirmationTicket: ({ id, resourceId }) =>
        apiService.post(`${authUrl}/confirmation`, { id, resourceId }),

    sendChangeContactConfirmCode: ({ id }) =>
        apiService.post(`${authUrl}/%userId%/update-credentials-tickets`, {
            id,
        }),

    // Set password for profile with token.
    setPassword: json => apiService.post(`${authUrl}/password`, json),

    // Login by social account.
    socialLogin: json => apiService.post(`${authUrl}/social-tickets`, json),

    // Send recovery ticket.
    startPasswordRecovery: json =>
        apiService.post(`${authUrl}/credentials`, json),
    updateApproval: approval =>
        apiService.put('/service/auth/api/oauth/profiles/%userId%/approvals', {
            approval,
        }),
};
