import React from 'react';
import { Dictionary } from 'shared';

export const UNITS_MILES = [
    <Dictionary text="com.units.miles.one" />,
    <Dictionary text="com.units.miles.once" />,
    <Dictionary text="com.units.miles.mult" />,
];

export const UNITS_MEDIA_MILES = [
    <Dictionary text="com.media.user.miles-balance" />,
    <Dictionary text="com.media.user.miles-balance" />,
    <Dictionary text="com.media.user.miles-balance" />,
];

export const UNITS_STATUS_MILES = [
    <Dictionary text="com.units.status-miles.one" />,
    <Dictionary text="com.units.status-miles.once" />,
    <Dictionary text="com.units.status-miles.mult" />,
];

export const UNITS_STATUS_MILES_BUY = [
    <Dictionary text="com.units.status-miles.buy.one" />,
    <Dictionary text="com.units.status-miles.buy.once" />,
    <Dictionary text="com.units.status-miles.buy.mult" />,
];

export const UNITS_BONUS_MILES = [
    <Dictionary text="com.units.bonus-miles.one" />,
    <Dictionary text="com.units.bonus-miles.once" />,
    <Dictionary text="com.units.bonus-miles.mult" />,
];

export const UNITS_STATUS_FLIGHTS = [
    <Dictionary text="com.units.status-flights.one" />,
    <Dictionary text="com.units.status-flights.once" />,
    <Dictionary text="com.units.status-flights.mult" />,
];

export const UNITS_BUSINESS_UPGRADE = [
    <Dictionary text="com.units.business.upgrade.one" />,
    <Dictionary text="com.units.business.upgrade.once" />,
    <Dictionary text="com.units.business.upgrade.mult" />,
];

export const UNITS_SEGMENT_FLIGHTS = [
    <Dictionary text="com.units.serments.field.one" />,
    <Dictionary text="com.units.serments.field.once" />,
    <Dictionary text="com.units.serments.field.mult" />,
];

export const UNITS_SEGMENTS_PAYMENT = [
    <Dictionary text="com.units.segments.payment.one" />,
    <Dictionary text="com.units.segments.payment.once" />,
    <Dictionary text="com.units.segments.payment.mult" />,
];

export const UNITS_KILOMETER = [
    <Dictionary text="com.units.kilometer.one" />,
    <Dictionary text="com.units.kilometer.once" />,
    <Dictionary text="com.units.kilometer.mult" />,
];

export const UNITS_HOUR = [
    <Dictionary text="com.units.hour.one" />,
    <Dictionary text="com.units.hour.once" />,
    <Dictionary text="com.units.hour.mult" />,
];

export const UNITS_COUNTRY = [
    <Dictionary text="com.units.country.one" />,
    <Dictionary text="com.units.country.once" />,
    <Dictionary text="com.units.country.mult" />,
];

export const UNITS_CITY = [
    <Dictionary text="com.units.city.one" />,
    <Dictionary text="com.units.city.once" />,
    <Dictionary text="com.units.city.mult" />,
];

export const UNITS_AIRPORT = [
    <Dictionary text="com.units.airport.one" />,
    <Dictionary text="com.units.airport.once" />,
    <Dictionary text="com.units.airport.mult" />,
];
