import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { evolve, toLower } from 'ramda';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const MM_YY = DATE_FORMATS.MM_YY_slash;

export const adapter = evolve({
    cardholder: value => {
        const splited = value.split(' ');

        if (splited.length !== 2) {
            return value;
        }

        const [left, right] = splited;

        return `${left[0]}. ${right}`;
    },
    expirationDate: value => moment(value, YYYY_MM_DD).format(MM_YY),
    number: value => value.replace(/(\d{4}).+(\d{4})/, '$1 **** **** $2'),
    type: toLower,
});
