import { apiService } from './api';

const baseUrl = '/service/invitation/api/invitations';

export const invitationService = {
    confirmB2BInvitation: invitationId =>
        apiService.post(`${baseUrl}/${invitationId}/confirmation`),
    getInvitation: ({ invitationId }) =>
        apiService.get(`${baseUrl}/${invitationId}`),
};
