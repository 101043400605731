import React from 'react';
import { bool, node, string } from 'prop-types';

import cx from './Columns.sss';

const Columns = ({ children, smallMargin, className }) => (
    <div
        className={cx(
            'columns',
            {
                columns_smallMargin: smallMargin,
            },
            className,
        )}
    >
        {children}
    </div>
);

Columns.propTypes = {
    children: node,
    className: string,
    smallMargin: bool,
};

export default React.memo(Columns);
