/* eslint-disable */

'use strict'

var _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? function(obj) {
        return typeof obj
      }
    : function(obj) {
        return obj &&
          typeof Symbol === 'function' &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj
      }

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = []
    var _n = true
    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value)
        if (i && _arr.length === i) break
      }
    } catch (err) {
      throw err
    } finally {
        if (!_n && _i['return']) _i['return']()
    }
    return _arr
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i)
    } else {
      throw new TypeError('Invalid attempt to destructure non-iterable instance')
    }
  }
})()

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i]
    }
    return arr2
  } else {
    return Array.from(arr)
  }
}

var deburr = require('lodash.deburr')
var escapeStringRegexp = require('escape-string-regexp')
var builtinReplacements = require('./replacements')
var builtinOverridableReplacements = require('./overridable-replacements')

var decamelize = function decamelize(string) {
  return string.replace(/([A-Z])/g, ' $1').trim()
}

var doCustomReplacements = function doCustomReplacements(string, replacements) {
  var _iteratorNormalCompletion = true

  try {
    for (
      var _iterator = replacements[Symbol.iterator](), _step;
      !(_iteratorNormalCompletion = (_step = _iterator.next()).done);
      _iteratorNormalCompletion = true
    ) {
      var _step$value = _slicedToArray(_step.value, 2),
        key = _step$value[0],
        value = _step$value[1]

      string = string.replace(new RegExp(escapeStringRegexp(key), 'g'), value)
    }
  } catch (err) {
    throw err
  } finally {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return()
      }
  }

  return string
}

var removeMootSeparators = function removeMootSeparators(string, separator) {
  return string
    .replace(new RegExp(separator + '{2,}', 'g'), separator)
    .replace(new RegExp('^' + separator + '|' + separator + '$', 'g'), '')
}

module.exports = function(string, options) {
  if (typeof string !== 'string') {
    throw new TypeError(
      'Expected a string, got `' +
        (typeof string === 'undefined' ? 'undefined' : _typeof(string)) +
        '`'
    )
  }

  options = Object.assign(
    {
      separator: '-',
      mootSerparators: false,
      lowercase: true,
      decamelize: true,
      customReplacements: [],
    },
    options
  )

  var separator = escapeStringRegexp(options.separator)
  var customReplacements = new Map(
    [].concat(
      _toConsumableArray(builtinOverridableReplacements),
      _toConsumableArray(options.customReplacements),
      _toConsumableArray(builtinReplacements)
    )
  )

  string = doCustomReplacements(string, customReplacements)
  string = deburr(string)
  if (string.normalize) {
    string = string.normalize('NFKD')
  }

  if (options.decamelize) {
    string = decamelize(string)
  }

  var patternSlug = /[^a-zA-Z\d]+/g

  if (options.lowercase) {
    string = string.toLowerCase()
    patternSlug = /[^a-z\d]+/g
  }

  string = string.replace(patternSlug, separator)
  string = string.replace(/\\/g, '')

  if (!options.mootSerparators) {
    string = removeMootSeparators(string, separator)
  }

  return string
}
