// Vietnamese
module.exports = [
    ['à', 'a'],
    ['À', 'A'],
    ['á', 'a'],
    ['Á', 'A'],
    ['â', 'a'],
    ['Â', 'A'],
    ['ã', 'a'],
    ['Ã', 'A'],
    ['è', 'e'],
    ['È', 'E'],
    ['é', 'e'],
    ['É', 'E'],
    ['ê', 'e'],
    ['Ê', 'E'],
    ['ì', 'i'],
    ['Ì', 'I'],
    ['í', 'i'],
    ['Í', 'I'],
    ['ò', 'o'],
    ['Ò', 'O'],
    ['ó', 'o'],
    ['Ó', 'O'],
    ['ô', 'o'],
    ['Ô', 'O'],
    ['õ', 'o'],
    ['Õ', 'O'],
    ['ù', 'u'],
    ['Ù', 'U'],
    ['ú', 'u'],
    ['Ú', 'U'],
    ['ý', 'y'],
    ['Ý', 'Y'],
    ['ă', 'a'],
    ['Ă', 'A'],
    ['Đ', 'D'],
    ['đ', 'd'],
    ['ĩ', 'i'],
    ['Ĩ', 'I'],
    ['ũ', 'u'],
    ['Ũ', 'U'],
    ['ơ', 'o'],
    ['Ơ', 'O'],
    ['ư', 'u'],
    ['Ư', 'U'],
    ['ạ', 'a'],
    ['Ạ', 'A'],
    ['ả', 'a'],
    ['Ả', 'A'],
    ['ấ', 'a'],
    ['Ấ', 'A'],
    ['ầ', 'a'],
    ['Ầ', 'A'],
    ['ẩ', 'a'],
    ['Ẩ', 'A'],
    ['ẫ', 'a'],
    ['Ẫ', 'A'],
    ['ậ', 'a'],
    ['Ậ', 'A'],
    ['ắ', 'a'],
    ['Ắ', 'A'],
    ['ằ', 'a'],
    ['Ằ', 'A'],
    ['ẳ', 'a'],
    ['Ẳ', 'A'],
    ['ẵ', 'a'],
    ['Ẵ', 'A'],
    ['ặ', 'a'],
    ['Ặ', 'A'],
    ['ẹ', 'e'],
    ['Ẹ', 'E'],
    ['ẻ', 'e'],
    ['Ẻ', 'E'],
    ['ẽ', 'e'],
    ['Ẽ', 'E'],
    ['ế', 'e'],
    ['Ế', 'E'],
    ['ề', 'e'],
    ['Ề', 'E'],
    ['ể', 'e'],
    ['Ể', 'E'],
    ['ễ', 'e'],
    ['Ễ', 'E'],
    ['ệ', 'e'],
    ['Ệ', 'E'],
    ['ỉ', 'i'],
    ['Ỉ', 'I'],
    ['ị', 'i'],
    ['Ị', 'I'],
    ['ọ', 'o'],
    ['Ọ', 'O'],
    ['ỏ', 'o'],
    ['Ỏ', 'O'],
    ['ố', 'o'],
    ['Ố', 'O'],
    ['ồ', 'o'],
    ['Ồ', 'O'],
    ['ổ', 'o'],
    ['Ổ', 'O'],
    ['ỗ', 'o'],
    ['Ỗ', 'O'],
    ['ộ', 'o'],
    ['Ộ', 'O'],
    ['ớ', 'o'],
    ['Ớ', 'O'],
    ['ờ', 'o'],
    ['Ờ', 'O'],
    ['ở', 'o'],
    ['Ở', 'O'],
    ['ỡ', 'o'],
    ['Ỡ', 'O'],
    ['ợ', 'o'],
    ['Ợ', 'O'],
    ['ụ', 'u'],
    ['Ụ', 'U'],
    ['ủ', 'u'],
    ['Ủ', 'U'],
    ['ứ', 'u'],
    ['Ứ', 'U'],
    ['ừ', 'u'],
    ['Ừ', 'U'],
    ['ử', 'u'],
    ['Ử', 'U'],
    ['ữ', 'u'],
    ['Ữ', 'U'],
    ['ự', 'u'],
    ['Ự', 'U'],
    ['ỳ', 'y'],
    ['Ỳ', 'Y'],
    ['ỵ', 'y'],
    ['Ỵ', 'Y'],
    ['ỷ', 'y'],
    ['Ỷ', 'Y'],
    ['ỹ', 'y'],
    ['Ỹ', 'Y'],
];
