import { map, path } from 'ramda';

import { loadingError, serviceError } from '../../language/errors';

const INERTNAL_SERVICE_ERROR = 'internal.service.error';

const getValueByPaths = (value = '', source) => {
    const paths = [
        ['response', 'data', 'error', value],
        ['data', 'error', value],
        ['error', value],
        [value],
    ];

    let result = null;

    paths.forEach(key => {
        if (path(key, source) != null && !result) {
            result = path(key, source);
        }
    });

    return result;
};

const getErrorMessage = response => getValueByPaths('message', response);
const getErrorCode = response => getValueByPaths('code', response);
const getErrorDetails = response => getValueByPaths('details', response) || [];

const getErrorDetailsString = response => {
    const details = getValueByPaths('details', response);

    return Array.isArray(details) && details.length > 0
        ? details.map(({ message }) => message).join('; ')
        : null;
};

const getErrorWithCode = response => {
    const code = getErrorCode(response);

    return {
        code: getErrorCode(response),
        ...(code === INERTNAL_SERVICE_ERROR
            ? {
                  ...serviceError,
                  serviceError: true,
              }
            : {
                  description: getErrorDetailsString(response),
                  title: getErrorMessage(response),
              }),
    };
};

export default function getErrorFromResponse(response) {
    const error = getValueByPaths('message', response) || 'Неизвестная ошибка';
    const details = getValueByPaths('details', response) || [];
    const code = getErrorCode(response);

    if (details.length) {
        // const text = `${error}:`
        const messages = map(({ message }) => message, details);

        return {
            _error: `${error}: ${messages.join('; ')}`,
        };
    }

    return {
        _error: code === INERTNAL_SERVICE_ERROR ? loadingError.title : error,
    };
}

export { getErrorWithCode, getErrorCode, getErrorMessage, getErrorDetails };
