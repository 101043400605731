export const PROFILE_STATUS = {
    FULL: 'FULL',
    SHORT: 'SHORT',
};

export const PROFILE_AIRPORT_ANY = 'ANY';

export const PROFILE_OPERATION_TYPE = {
    // Семейный счет
    MEMBERSHIP: 'MEMBERSHIP',
    // Благотворительность
    MILES_CHARITY: 'MILES_CHARITY',
    // Сгорание миль
    MILES_EXPIRATION: 'MILES_EXPIRATION',
    // Продлить мили
    MILES_PROLONGATION: 'MILES_PROLONGATION',
    // Покупка миль
    MILES_PURCHASE: 'MILES_PURCHASE',
    // Активация статуса
    MILES_PURCHASE_STATUS: 'MILES_PURCHASE_STATUS',
    // Восстановление миль
    MILES_RECOVERY: 'MILES_RECOVERY',
    // Мильные транзакции
    MILES_TRANSACTIONS: 'MILES_TRANSACTIONS',
    // Перевод миль
    MILES_TRANSFER: 'MILES_TRANSFER',
    // Повышение статуса
    MILES_UPGRADE: 'MILES_UPGRADE',
    // Сертификат
    MILES_VOUCHER: 'MILES_VOUCHER',
    // Заказ карты
    PHYSICAL_CARD: 'PHYSICAL_CARD',
};

export const PROFILE_EMPLOYEE_ROLES = {
    ADMIN: 'ADMIN',
    EMPLOYEE: 'EMPLOYEE',
    PASSENGER: 'NONE',
    TRAVEL_MANAGER: 'TRAVEL_MANAGER',
};
