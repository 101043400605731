import React, { useMemo } from 'react';
import cn from 'classnames';
import { shape, string } from 'prop-types';

import { Text as DSText } from '@s7/ui-kit';

import cx from './Text.sss';

const checkIsExtraSize = size => size === 'xxl';

export const Text = ({ classes = {}, size, ...props }) => {
    const isExtraSize = checkIsExtraSize(size);
    const finalClasses = useMemo(
        () =>
            isExtraSize
                ? {
                      ...classes,
                      root: cn(classes.root, cx('root', { [`${size}`]: size })),
                  }
                : classes,
        [classes, isExtraSize, size],
    );

    return (
        <DSText
            classes={finalClasses}
            size={isExtraSize ? undefined : size}
            {...props}
        />
    );
};

Text.propTypes = {
    classes: shape({ root: string }),
    size: string,
};
