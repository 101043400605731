import React, { ReactElement, ReactHTML } from 'react';
import { useSelector } from 'react-redux';
import createDOMPurify from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { getDictionary, getVisible } from '../../store/modules/languages';
import { capitalize as doCapitalize } from '../../utils/format';

interface DictionaryProps {
    asText?: boolean;
    capitalize?: boolean;
    html?: boolean;
    parseOptions?: HTMLReactParserOptions;
    sanitize?: boolean;
    tag?: keyof ReactHTML;
    text: string;
    transform?: (a: string, b: Record<string, unknown>) => string;
}

const Dictionary = ({
    text,
    asText,
    tag = 'span',
    html,
    transform,
    sanitize,
    capitalize,
    parseOptions,
    ...props
}: DictionaryProps): ReactElement => {
    const dictionary = useSelector(getDictionary);

    const visible = useSelector(getVisible);

    let textValue = visible ? dictionary.t(text) : text;

    if (asText) {
        textValue = text;
    }

    if (transform) {
        textValue = transform(textValue, dictionary);
    }

    if (capitalize) {
        textValue = doCapitalize(textValue);
    }

    if (parseOptions) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return parse(textValue, parseOptions);
    }

    if (html) {
        const Component = tag;

        return (
            <Component
                dangerouslySetInnerHTML={{
                    __html: sanitize
                        ? createDOMPurify.sanitize(textValue)
                        : textValue,
                }}
                {...(tag ? props : {})}
            />
        );
    }

    return textValue;
};

export default React.memo(Dictionary);
