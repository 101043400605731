import { apiService } from './api';

const baseUrl = '/service/profiles/api/profiles/%userId%/contacts';

export const contactsService = {
    addContact: ({ type, value, verified }) =>
        apiService.post(baseUrl, {
            contact: { type, value, verified },
        }),
    updateContact: ({ type, value, verified }) =>
        apiService.put(baseUrl, {
            contact: { type, value, verified },
        }),
};
