import { compose, map, path, pathOr, sort } from 'ramda';
import { createSelector } from 'reselect';

import { isFading } from '../../../utils/fadingTime';

export const getAirlines = state => state.airlines.items;
export const getAirlineOptions = createSelector(getAirlines, airlines =>
    Object.values(airlines).reduce((prev, curr) => {
        const newValue = prev;

        if (curr.ffpPartner) {
            newValue.push({
                label: curr.ffpProgram,
                value: curr.code,
            });
        }

        return newValue;
    }, []),
);

export const isInitialized = state => state.airlines.isInitialized;
export const isLoaded = state => isFading(state.airlines.fadingTime);
export const isLoading = state => state.airlines.isLoading;
export const isError = state => state.airlines.isError;

export const getAirlinesForMilesRecover = createSelector(
    getAirlines,
    state => pathOr('en', ['App', 'lang'], state),
    (airlines, lang) => {
        const listAirlines = Object.values(airlines);

        return compose(
            sort(({ title: a }, { title: b }) => {
                if (a > b) {
                    return 1;
                }
                if (a === b) {
                    return 0;
                }

                return -1;
            }),
            map(item => ({
                ffpPartner: item.ffpPartner,
                ffpProgram: item.ffpProgram,
                label: `${path(['names', lang], item)} (${item.code})`,
                labelShort: path(['names', lang], item),
                name: item.code,
                title: `${path(['names', lang], item)} (${item.code})`,
                value: item.code,
            })),
        )(listAirlines);
    },
);
