import React, { PureComponent } from 'react';
import { oneOf } from 'prop-types';

import cx from './Loader.sss';

export default class AddCard extends PureComponent {
    static propTypes = {
        size: oneOf(['large', 'default']),
        theme: oneOf(['green', 'default']),
    };

    static defaultProps = {
        size: 'default',
        theme: 'default',
    };

    render() {
        const { theme, size } = this.props;

        return (
            <span
                className={cx('loader', {
                    [`loader_theme_${theme}`]: theme,
                    [`loader_size_${size}`]: size,
                })}
            />
        );
    }
}
