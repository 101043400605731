import moment from 'moment';
import { flatten, pathOr, pluck } from 'ramda';
import { createSelector } from 'reselect';

import { isFading } from '../../../utils/fadingTime';

const statisticsPath = ['statistics', 'statistics', 'items', 0];
const achievementsPath = ['statistics', 'achievements', 'groups'];

export const getDistance = pathOr(0, [
    ...statisticsPath,
    'flightStatistics',
    'distance',
    'amount',
]);

export const getDuration = pathOr(0, [
    ...statisticsPath,
    'flightStatistics',
    'duration',
    'amount',
]);

export const getDurationHours = createSelector(getDuration, minutes =>
    Math.round(moment.duration(minutes, 'minutes').asHours()),
);

export const getCountriesAmount = pathOr(0, [
    ...statisticsPath,
    'locationStatistics',
    'countryStatistics',
    'uniqueAmount',
]);

export const getCities = pathOr(
    [],
    [...statisticsPath, 'locationStatistics', 'cityStatistics', 'locations'],
);

export const getCitiesAmount = pathOr(0, [
    ...statisticsPath,
    'locationStatistics',
    'cityStatistics',
    'uniqueAmount',
]);

export const getAirportsAmount = pathOr(0, [
    ...statisticsPath,
    'locationStatistics',
    'airportStatistics',
    'uniqueAmount',
]);

export const getAchievementsGroups = pathOr([], achievementsPath);

export const getAchievementByCode = achievementCode => state => {
    const groups = getAchievementsGroups(state);
    const achievements = flatten(pluck('achievements', groups));

    return achievements.find(({ code }) => code === achievementCode);
};

export const isLoadedStatistics = state =>
    isFading(state.statistics.statistics.fadingTime);
export const getIsStatisticsLoading = state =>
    state.statistics.statistics.isLoading;
export const getIsStatisticsError = state =>
    state.statistics.statistics.isError;

export const isLoadedAchievements = state =>
    isFading(state.statistics.achievements.fadingTime);
export const getIsAchievementsLoading = state =>
    state.statistics.achievements.isLoading;
export const getIsAchievementsError = state =>
    state.statistics.achievements.isError;

export const getNewAchievement = state => state.statistics.newAchievement;
