import { apiService } from './api';

export const promoService = {
    buySubscription: ({ subscriptionId, ...data }) =>
        apiService.post(
            `/service/promo/api/profiles/%userId%/subscriptions/${subscriptionId}/payment`,
            data,
        ),
    confirmation3ds: ({ subscriptionId, ...data }) =>
        apiService.put(
            `/service/promo/api/profiles/%userId%/subscriptions/${subscriptionId}/confirmation3ds`,
            data,
        ),
    getPromo: () => apiService.get('/service/promo/api/profiles/%userId%'),
    prolongation: ({ subscriptionId, prolongation }) =>
        apiService.put(
            `/service/promo/api/profiles/%userId%/subscriptions/${subscriptionId}/prolongation`,
            null,
            {
                params: {
                    value: String(!!prolongation),
                },
            },
        ),
};
