import moment from 'moment';
import { sort } from 'ramda';
import { createAction, createReducer } from 'redux-act';

import { resetFading, setFading } from '../../../utils/fadingTime';

const initialState = {
    fadingTime: setFading(),
    isError: false,
    isLoading: false,
    items: [],
};

export const fetchPurchases = createAction('purchases/fetchPurchases');
export const fetchPurchasesSuccess = createAction(
    'purchases/fetchPurchasesSuccess',
);
export const fetchPurchasesFailure = createAction(
    'purchases/fetchPurchasesFailure',
);

const handleFetchPurchases = state => ({
    ...state,
    isLoading: true,
});

const handleFetchPurchasesSuccess = (state, data) => ({
    ...state,
    fadingTime: resetFading(true),
    isLoading: false,
    items: sort((a, b) => moment(b.createdAt) - moment(a.createdAt), data),
});

const handleFetchPurchasesFailure = state => ({
    ...state,
    isError: true,
    isLoading: false,
});

export default createReducer(
    {
        [fetchPurchases]: handleFetchPurchases,
        [fetchPurchasesFailure]: handleFetchPurchasesFailure,
        [fetchPurchasesSuccess]: handleFetchPurchasesSuccess,
    },
    initialState,
);

export * from './selectors';
