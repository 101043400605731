import browserParsersList from './parser-browsers';
import osParsersList from './parser-os';
import Utils from './utils';

class Parser {
    /**
     * Create instance of Parser
     *
     * @param {String} UA User-Agent string
     * @param {Boolean} [skipParsing=false] parser can skip parsing in purpose of performance
     * improvements if you need to make a more particular parsing
     * like {@link Parser#parseBrowser}
     *
     * @throw {Error} in case of empty UA String
     *
     * @constructor
     */
    constructor(UA, skipParsing = false) {
        // eslint-disable-next-line no-void
        if (UA === void 0 || UA === null || UA === '') {
            throw new Error("UserAgent parameter can't be empty");
        }

        // eslint-disable-next-line no-underscore-dangle
        this._ua = UA;

        /**
         * @typedef ParsedResult
         * @property {Object} browser
         * @property {String|undefined} [browser.name]
         * Browser name, like `"Chrome"` or `"Internet Explorer"`
         * @property {String|undefined} [browser.version] Browser version as a String `"12.01.45334.10"`
         * @property {Object} os
         * @property {String|undefined} [os.name] OS name, like `"Windows"` or `"macOS"`
         * @property {String|undefined} [os.version] OS version, like `"NT 5.1"` or `"10.11.1"`
         * @property {String|undefined} [os.versionName] OS name, like `"XP"` or `"High Sierra"`
         * @property {Object} platform
         * @property {String|undefined} [platform.type]
         * platform type, can be either `"desktop"`, `"tablet"` or `"mobile"`
         * @property {String|undefined} [platform.vendor] Vendor of the device,
         * like `"Apple"` or `"Samsung"`
         * @property {String|undefined} [platform.model] Device model,
         * like `"iPhone"` or `"Kindle Fire HD 7"`
         * @property {Object} engine
         * @property {String|undefined} [engine.name]
         * Can be any of this: `WebKit`, `Blink`, `Gecko`, `Trident`, `Presto`, `EdgeHTML`
         * @property {String|undefined} [engine.version] String version of the engine
         */
        this.parsedResult = {};

        if (skipParsing !== true) {
            this.parse();
        }
    }

    /**
     * Get UserAgent string of current Parser instance
     * @return {String} User-Agent String of the current <Parser> object
     *
     * @public
     */
    getUA() {
        // eslint-disable-next-line no-underscore-dangle
        return this._ua;
    }

    /**
     * Test a UA string for a regexp
     * @param {RegExp} regex
     * @return {Boolean}
     */
    test(regex) {
        // eslint-disable-next-line no-underscore-dangle
        return regex.test(this._ua);
    }

    /**
     * Get parsed browser object
     * @return {Object}
     */
    parseBrowser() {
        this.parsedResult.browser = {};

        const browserDescriptor = Utils.find(browserParsersList, _browser => {
            if (typeof _browser.test === 'function') {
                return _browser.test(this);
            }

            if (_browser.test instanceof Array) {
                return _browser.test.some(condition => this.test(condition));
            }

            throw new Error("Browser's test function is not valid");
        });

        if (browserDescriptor) {
            this.parsedResult.browser = browserDescriptor.describe(
                this.getUA(),
            );
        }

        return this.parsedResult.browser;
    }

    /**
     * Get parsed browser object
     * @return {Object}
     *
     * @public
     */
    getBrowser() {
        if (this.parsedResult.browser) {
            return this.parsedResult.browser;
        }

        return this.parseBrowser();
    }

    /**
     * Get browser's name
     * @return {String} Browser's name or an empty string
     *
     * @public
     */
    getBrowserName(toLowerCase) {
        if (toLowerCase) {
            return String(this.getBrowser().name).toLowerCase() || '';
        }

        return this.getBrowser().name || '';
    }

    /**
     * Get OS
     * @return {Object}
     *
     * @example
     * this.getOS();
     * {
     *   name: 'macOS',
     *   version: '10.11.12'
     * }
     */
    getOS() {
        if (this.parsedResult.os) {
            return this.parsedResult.os;
        }

        return this.parseOS();
    }

    /**
     * Parse OS and save it to this.parsedResult.os
     * @return {*|{}}
     */
    parseOS() {
        this.parsedResult.os = {};

        const os = Utils.find(osParsersList, _os => {
            if (typeof _os.test === 'function') {
                return _os.test(this);
            }

            if (_os.test instanceof Array) {
                return _os.test.some(condition => this.test(condition));
            }

            throw new Error("Browser's test function is not valid");
        });

        if (os) {
            this.parsedResult.os = os.describe(this.getUA());
        }

        return this.parsedResult.os;
    }

    /**
     * Get OS name
     * @param {Boolean} [toLowerCase] return lower-cased value
     * @return {String} name of the OS — macOS, Windows, Linux, etc.
     */
    getOSName(toLowerCase) {
        const { name } = this.getOS();

        if (toLowerCase) {
            return String(name).toLowerCase() || '';
        }

        return name || '';
    }

    /**
     * Parse full information about the browser
     * @returns {Parser}
     */
    parse() {
        this.parseBrowser();
        this.parseOS();

        return this;
    }

    /**
     * Get parsed result
     * @return {ParsedResult}
     */
    getResult() {
        return Utils.assign({}, this.parsedResult);
    }

    /**
     * Check if the browser name equals the passed string
     * @param browserName The string to compare with the browser name
     * @param [includingAlias=false] The flag showing whether alias will be included into comparison
     * @returns {boolean}
     */
    isBrowser(browserName, includingAlias = false) {
        const defaultBrowserName = this.getBrowserName().toLowerCase();
        let browserNameLower = browserName.toLowerCase();
        const alias = Utils.getBrowserTypeByAlias(browserNameLower);

        if (includingAlias && alias) {
            browserNameLower = alias.toLowerCase();
        }

        return browserNameLower === defaultBrowserName;
    }

    isOS(osName) {
        return this.getOSName(true) === String(osName).toLowerCase();
    }

    /**
     * Is anything? Check if the browser is called "anything",
     * the OS called "anything" or the platform called "anything"
     * @param {String} anything
     * @param [includingAlias=false] The flag showing whether alias will be included into comparison
     * @returns {Boolean}
     */
    is(anything, includingAlias = false) {
        return this.isBrowser(anything, includingAlias) || this.isOS(anything);
    }

    /**
     * Check if any of the given values satisfies this.is(anything)
     * @param {String[]} anythings
     * @returns {Boolean}
     */
    some(anythings = []) {
        return anythings.some(anything => this.is(anything));
    }
}

export default Parser;
