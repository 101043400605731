import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import { compose, forEach, indexBy, pathOr, prop, propOr, reduce } from 'ramda';
import { createAction, createReducer } from 'redux-act';

import filterIds from './filter';

import { resetFading, setFading } from '../../../utils/fadingTime';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;
const YYYY = DATE_FORMATS.YYYY.INDEX;
const { MMM } = DATE_FORMATS;

const startVisible = 10;
const getInitialDates = () => ({
    start: moment().subtract(1, 'years').format(),
});

const initialState = {
    categories: [],
    categoriesByCode: {},
    dates: getInitialDates(),
    errorMessage: '',
    fadingTime: setFading(),
    ids: [],
    isError: false,
    isFetch: false,
    isInitialized: false,
    isLoaded: false,
    isLoading: false,
    isOpenFilter: false,
    isPreload: false,
    items: [],
    itemsById: {},
    itemsMemory: {},
    loadedDate: [],
    month: '',
    time: '',
    timeMobileOpen: false,
    timestampMax: moment().format(),
    timestampMin: moment('1957-05-01', YYYY_MM_DD).format(),
    transactionsDiagram: {},
    type: '',
    visible: startVisible,
    year: '',
};

export const fetch = createAction('transactions/fetch');
export const reload = createAction('transactions/reload');
export const fetchSuccess = createAction('transactions/fetchSuccess');
export const fetchFailure = createAction('transactions/fetchFailure');

export const showMore = createAction('transactions/showMore');
export const toggleFilter = createAction('transactions/toggleFilter');
export const resetFilter = createAction('transactions/resetFilter');
export const changeType = createAction('transactions/changeType');
export const changeTime = createAction('transactions/changeTime');
export const changeMonth = createAction('transactions/changeMonth');
export const changeYear = createAction('transactions/changeYear');
export const changeDate = createAction('transactions/changeDate');
export const changeDateFrom = createAction('transactions/changeDateFrom');
export const changeDateTo = createAction('transactions/changeDateTo');
export const changeCategories = createAction('transactions/changeCategories');
export const closeMobile = createAction('transactions/closeMobile');
export const changeLanguage = createAction('transactions/changeLanguage');

const handleFetch = state => ({
    ...state,
    errorMessage: '',
    isError: false,
    isFetch: true,
    isLoading: true,
});

const handleReload = state => ({
    ...state,
    errorMessage: '',
    isError: false,
    isFetch: true,
    isLoading: false,
});

const getCategoriesMap = compose(
    indexBy(prop('code')),
    reduce((acc, item) => {
        const categorySetter = ({ code, description }) => {
            acc.push({ code, name: description });
        };

        forEach(categorySetter, pathOr([], ['redemption', 'childItems'], item));
        forEach(categorySetter, pathOr([], ['accrual', 'childItems'], item));

        return acc;
    }, []),
);

const handleFetchSuccess = (state, payload) => {
    const { type, time, month, year, dates } = state;
    const isPreload = propOr([], 'isPreload', payload);
    const transactions = propOr([], 'transactions', payload);
    const transactionsDiagram = propOr({}, 'transactionsDiagram', payload);
    const loadedDate = propOr({}, 'dates', payload);

    const itemsById = indexBy(prop('id'), transactions);

    const ids = filterIds(transactions, {
        dates,
        month,
        time,
        type,
        year,
    });

    return {
        ...state,
        categoriesByCode: {
            ...state.categoriesByCode,
            ...getCategoriesMap(propOr([], 'items', transactionsDiagram)),
        },
        fadingTime: resetFading(),
        ids,
        isError: false,
        isFetch: false,
        isInitialized: true,
        isLoaded: true,
        isLoading: false,
        isPreload,
        items: transactions,
        itemsById,
        itemsMemory: { ...state.itemsMemory, ...itemsById },
        loadedDate: [...state.loadedDate, loadedDate],
        transactionsDiagram,
    };
};

const handleFetchFailure = (state, errorMessage) => ({
    ...state,
    errorMessage,
    isError: true,
    isInitialized: true,
    isLoading: false,
});

const handleShowMore = state => {
    const { visible } = state;

    const setVisible = visible + 10;

    return {
        ...state,
        visible: setVisible,
    };
};

const handleToggleFilter = state => ({
    ...state,
    isOpenFilter: !state.isOpenFilter,
});

const handleResetFilter = state => ({
    ...state,
    categories: [],
    dates: getInitialDates(),
    isFetch: true,
    month: '',
    time: '',
    timeMobileOpen: false,
    type: '',
    visible: startVisible,
    year: '',
});

const handleChangeType = (state, payload) => {
    const { type } = payload;

    return {
        ...state,
        type,
        visible: startVisible,
    };
};

const handleChangeTime = (state, payload) => {
    const { time, isMobile, timeMobileOpen } = payload;

    return {
        ...state,
        dates: time !== '' ? {} : getInitialDates(),
        isFetch: true,
        month:
            time === 'month'
                ? moment().locale('en').format(MMM).toLowerCase()
                : '',
        time,
        timeMobileOpen: !isMobile
            ? state.timeMobileOpen
            : time !== '' || timeMobileOpen,
        year: time === 'month' ? moment().format(YYYY) : '',
    };
};

const handleCloseMobile = state => {
    return {
        ...state,
        isFetch: true,
        timeMobileOpen: false,
    };
};

const handleChangeMonth = (state, payload) => {
    const { month, year } = payload;

    return {
        ...state,
        month,
        time: month && year ? 'month' : '',
        visible: startVisible,
        year,
    };
};

const handleChangeYear = (state, payload) => {
    const { year } = payload;

    return {
        ...state,
        isFetch: year > 1000,
        visible: startVisible,
        year,
    };
};

const handleChangeDate = (state, payload) => {
    const { start, end } = payload;

    return {
        ...state,
        dates:
            start && end
                ? {
                      end,
                      start,
                  }
                : getInitialDates(),
        isFetch: true,
        month: '',
        time: '',
        visible: startVisible,
    };
};

const handleChangeDateFrom = (state, payload) => {
    const { start } = payload;
    const { dates } = state;

    dates.start = start;

    return {
        ...state,
        dates,
        isFetch: true,
        visible: startVisible,
    };
};

const handleChangeDateTo = (state, payload) => {
    const { end } = payload;
    const { dates } = state;

    dates.end = end;

    return {
        ...state,
        dates,
        isFetch: true,
        visible: startVisible,
    };
};

const handleChangeCategories = (state, payload) => ({
    ...state,
    categories: payload,
    visible: startVisible,
});

const handleChangeLanguage = state => ({
    ...state,
    dates: getInitialDates(),
    isError: false,
    isFetch: true,
    isLoaded: false,
    isLoading: true,
    month: '',
    time: '',
    type: '',
    year: '',
});

export default createReducer(
    {
        [changeCategories]: handleChangeCategories,
        [changeDate]: handleChangeDate,
        [changeDateFrom]: handleChangeDateFrom,
        [changeDateTo]: handleChangeDateTo,
        [changeLanguage]: handleChangeLanguage,
        [changeMonth]: handleChangeMonth,
        [changeTime]: handleChangeTime,
        [changeType]: handleChangeType,
        [changeYear]: handleChangeYear,
        [closeMobile]: handleCloseMobile,
        [fetch]: handleFetch,
        [fetchFailure]: handleFetchFailure,
        [fetchSuccess]: handleFetchSuccess,
        [reload]: handleReload,
        [resetFilter]: handleResetFilter,
        [showMore]: handleShowMore,
        [toggleFilter]: handleToggleFilter,
    },
    initialState,
);

export * from './selectors';
