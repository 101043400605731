import { isClient } from '../../utils';

const eventsQueue = [];

const executeEvents = () => {
    if (isClient && window.amplitude) {
        while (eventsQueue.length > 0) {
            const callback = eventsQueue.shift();

            callback();
        }
    }
};

const waitAmplitudeScript = () => {
    const amplitudeLoader = document.querySelector('#amplitudeLoader');

    if (amplitudeLoader) {
        amplitudeLoader.onload = executeEvents;
    }
};

if (isClient) {
    window.addEventListener('load', () => {
        if (window.amplitude) {
            executeEvents();
        } else {
            waitAmplitudeScript();
        }
    });
}

const logEvent = (event, eventProperties, callback) => {
    if (isClient) {
        eventsQueue.push(() => {
            window.amplitude.logEvent(event, eventProperties, callback);
        });

        if (window.amplitude) {
            executeEvents();
        }
    }
};

export const amplitudeService = {
    // Открытие страницы с короткой формой регистрации для привязки аккаунта к
    // профилю компании
    firstStepRegistration: () => logEvent('first_step_registration'),

    // Логгирование ошибки, при запросе на создание короткого профиля при
    // привязке аккаунта пользователя к профилю компании
    firstStepRegistrationError: errorType =>
        logEvent('first_step_registration', { error_type: errorType }),

    // Определение типа ошибки по ответу сервера
    getErrorType: error =>
        error?.response?.data?.error?.code === 'validation.error'
            ? 'validation'
            : 'other',

    // Привязка аккаунта пользователя к компании при переходе по ссылке из письма
    // с приглашением
    linkAccount: () => logEvent('link_account'),

    // Ошибка при привязке аккаунта пользователя к компании после перехода по
    // ссылке из письма с приглашением
    linkAccountError: errorType =>
        logEvent('link_account_error', { error_type: errorType }),

    // Привязка пользователя к компании по кнопке “Да“ в приглашении
    linkAccountFromInvitation: () => logEvent('link_account_from_lk'),

    // Ошибка привязки пользователя к компании по кнопке “Да“ в приглашении
    linkAccountFromInvitationError: errorType =>
        logEvent('link_account_from_lk_error', { error_type: errorType }),

    // Привязка аккаунта пользователя к профилю компании через форму авторизации
    loginPriorityLinkAccount: () => logEvent('login_priority_link_account'),

    // Открытие страницы авторизации при переходе по ссылке-приглашению
    // присоеденится к профилю компании
    loginPriorityOpen: () => logEvent('login_priority_open'),

    // Отображение приглашения в ЛК b2c
    refuseInvitation: () => logEvent('refuse_an_invitation'),

    // Открытие страницы с полной формой регистрации для привязки аккаунта к
    // профилю компании
    secondStepRegistration: () => logEvent('second_step_registration'),

    // Логгирование ошибки, при запросе на создание полного профиля при
    // привязке аккаунта пользователя к профилю компании
    secondStepRegistrationError: errorType =>
        logEvent('second_step_registration_error', { error_type: errorType }),

    // Отображение приглашения в ЛК b2c
    showInvitation: () => logEvent('show_invitation_in_lk'),
};
