import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';
import {
    compose,
    forEach,
    map,
    max,
    pathOr,
    prop,
    slice,
    sortBy,
    uniq,
    values,
} from 'ramda';
import { createSelector } from 'reselect';

import filterIds from './filter';

import { isFading } from '../../../utils/fadingTime';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;

export const getFull = createSelector(
    state => state.transactions,
    state => state,
);

export const getTransactionsIds = createSelector(
    state => state.transactions,
    transactions => {
        const { categories, dates, items, month, time, type, visible, year } =
            transactions;
        const ids = filterIds(items, {
            categories,
            dates,
            month,
            time,
            type,
            year,
        });

        return slice(0, visible, ids);
    },
);

export const getHasHidden = createSelector(
    state => state.transactions,
    transactions => {
        const { categories, dates, items, month, time, type, visible, year } =
            transactions;
        const ids = filterIds(items, {
            categories,
            dates,
            month,
            time,
            type,
            year,
        });

        return ids.length > visible;
    },
);

export const getIsOpenFilter = state => state.transactions.isOpenFilter;
export const getHasFilter = state =>
    !!pathOr(false, ['transactions', 'type'], state) ||
    !!pathOr(false, ['transactions', 'time'], state) ||
    !!pathOr(false, ['transactions', 'categories', 'length'], state);
export const getType = state => state.transactions.type;
export const getTime = state => state.transactions.time;
export const getTimeMobileOpen = state => state.transactions.timeMobileOpen;
export const getMonth = state => state.transactions.month;
export const getYear = state => state.transactions.year;
export const getDates = state => state.transactions.dates;
export const getDateRagne = state => ({
    max: state.transactions.timestampMax,
    min: state.transactions.timestampMin,
});

export const getCategories = state => state.transactions.categories;

const baseDiagramBar = {
    accrual: {
        amount: 0,
        childItems: [],
        code: 'ACCRUAL',
        percent: 0,
    },
    redemption: {
        amount: 0,
        childItems: [],
        code: 'REDEMPTION',
        percent: 0,
    },
};

export const getDiagramSummary = createSelector(
    pathOr([], ['transactions', 'transactionsDiagram', 'items']),
    state => state.transactions.type,
    (items, type) => {
        const months = [];

        let lastMonth;

        let maxAmmount = 0;

        const completeData = date => {
            lastMonth = months.length
                ? moment(months[months.length - 1].from)
                : moment().subtract(12, 'months').startOf('month');
            const isNext = date.isSame(
                moment(lastMonth).add(1, 'months'),
                'month',
            );

            return months.length < 12 && !isNext;
        };

        const pushEmptyMonths = date => {
            while (completeData(date)) {
                months.push({
                    ...baseDiagramBar,
                    from: lastMonth
                        .add(1, 'months')
                        .startOf('month')
                        .format(YYYY_MM_DD),
                    to: lastMonth
                        .add(1, 'months')
                        .endOf('month')
                        .format(YYYY_MM_DD),
                });
            }
        };

        const modules = ammount => {
            if (ammount < 1) {
                return ammount * 8;
            }

            if (ammount < 5) {
                return ammount * 3;
            }

            if (ammount < 15) {
                return ammount * 2;
            }

            if (ammount < 30) {
                return ammount * 1.5;
            }

            return ammount;
        };

        items.map(item => {
            const {
                accrual: { amount: accrualAmount },
                from,
                redemption: { amount: redemptionAmount },
            } = item;
            const date = moment(from, YYYY_MM_DD);

            switch (type) {
                case 'in':
                    maxAmmount = max(maxAmmount, accrualAmount);

                    break;

                case 'out':
                    maxAmmount = max(maxAmmount, redemptionAmount * -1);

                    break;

                default:
                    maxAmmount = max(
                        maxAmmount,
                        max(accrualAmount, redemptionAmount * -1),
                    );

                    break;
            }

            if (
                date.isBetween(
                    moment().subtract(1, 'years'),
                    moment(),
                    'month',
                    '(]',
                )
            ) {
                pushEmptyMonths(date);

                months.push(item);
            }

            return item;
        });

        pushEmptyMonths(moment().add(1, 'month'));

        return map(item => {
            const {
                accrual: { amount: accrualAmount, ...restAccrual },
                redemption: { amount: redemptionAmount, ...restRedemption },
            } = item;

            const percentAccrual = (100 / maxAmmount) * accrualAmount;
            const percentRedemption =
                (100 / maxAmmount) * redemptionAmount * -1;

            return {
                ...item,
                accrual: {
                    ...restAccrual,
                    amount: accrualAmount,
                    percent: modules(percentAccrual),
                },
                redemption: {
                    ...restRedemption,
                    amount: redemptionAmount,
                    percent: modules(percentRedemption),
                },
            };
        }, months);
    },
);

export const getTransactionsCount = createSelector(
    state => state.transactions,
    transactions => {
        const { categories, dates, items, month, time, type, year } =
            transactions;
        const ids = filterIds(items, {
            categories,
            dates,
            month,
            time,
            type,
            year,
        });

        return ids.length;
    },
);

export const getDiagramCategories = createSelector(
    pathOr([], ['transactions', 'transactionsDiagram', 'items']),
    pathOr([], ['transactions', 'categories']),
    pathOr({}, ['transactions', 'categoriesByCode']),
    (transactions, selectedCategories, categoriesByCode) => {
        const categories = [];
        const pickCatergory = ({ code, description }) =>
            categories.push({ code, name: description });

        transactions.map(item => {
            compose(
                forEach(pickCatergory),
                pathOr([], ['redemption', 'childItems']),
            )(item);
            compose(
                forEach(pickCatergory),
                pathOr([], ['accrual', 'childItems']),
            )(item);

            return item;
        });

        selectedCategories.forEach(categoryCode => {
            categories.push(categoriesByCode[categoryCode]);
        });

        return compose(sortBy(prop('name')), uniq)(categories);
    },
);

export const getTransaction = (id, state) => state.transactions.itemsById[id];

export const getTransactionDates = createSelector(
    state => state.transactions,
    transactions => {
        const { categories, dates, items, month, time, type, year } =
            transactions;
        const ids = filterIds(
            items,
            {
                categories,
                dates,
                month,
                time,
                type,
                year,
            },
            true,
        );

        return ids;
    },
);

export const getTransactionDescription = data => data.description;

export const isInitialized = state => state.transactions.isInitialized;
export const isLoaded = state => isFading(state.transactions.fadingTime);
export const isFetch = state => state.transactions.isFetch;
export const getIsPreload = state => state.transactions.isPreload;
export const isLoading = state => state.transactions.isLoading;
export const isError = state => state.transactions.isError;
export const getErrorMessage = state => state.transactions.errorMessage;

export const getFilter = state => {
    const {
        transactions: {
            dates: { end, start } = { end: '', start: '' },
            month,
            time,
            timeMobileOpen,
            year,
        },
    } = state;

    if (time === '' || time === 'days') {
        return {
            end: end ? moment(end).format(YYYY_MM_DD) : '',
            start: start ? moment(start).format(YYYY_MM_DD) : '',
            wait: timeMobileOpen || (!start && !end),
        };
    }

    if (time === 'month') {
        const startOf = moment()
            .locale('en')
            .set({ month, year })
            .startOf('month');
        const endOf = moment().locale('en').set({ month, year }).endOf('month');

        return {
            end: endOf ? endOf.format(YYYY_MM_DD) : '',
            start: startOf ? startOf.format(YYYY_MM_DD) : '',
            wait: timeMobileOpen || year <= 1000,
        };
    }

    return {
        end: null,
        start: null,
        wait: timeMobileOpen,
    };
};

export const isEmptyTransaction = state =>
    values(pathOr({}, ['transactions', 'itemsMemory'], state)).length === 0;
