import { buildId, CDNUrl, isCDN } from 'utils/stages';

import { apiService } from './api';

const withCDN = isCDN && buildId;
const getFunction = withCDN ? fetch : apiService.get;

const createFetcher = (name, nonCDNUrl) => async payload => {
    const url = withCDN
        ? `${CDNUrl}/${buildId}/_next/static/json/hermes/${name}.min.json`
        : nonCDNUrl;
    const params = withCDN ? { mode: 'cors' } : payload;

    const response = await getFunction(url, params);

    return withCDN ? response.json() : response.data;
};

const searchFlightNumbers = async payload => {
    const { airline, arrivalAirportIata, departureAirportIata, flightDate } =
        payload;

    const response = await apiService.get(
        `hermes/route/${airline}/${departureAirportIata}/${arrivalAirportIata}/${flightDate};lang=ru`,
    );

    return response.data;
};

const searchFlight = async payload => {
    const { airline, flightNumber, flightDate } = payload;

    const response = await apiService.get(
        `hermes/flight/${airline}/${flightNumber}/${flightDate};lang=ru;onlyOne=true`,
    );

    return response.data;
};

export const hermesService = {
    getAirlines: createFetcher(
        'airlines',
        'hermes/airlineExtended/filter;lang=ru;ffpPartner=true',
    ),
    getCountries: createFetcher(
        'countries',
        'hermes/location/all;gaprouts=false;extended=true',
    ),
    searchFlight,
    searchFlightNumbers,
};
