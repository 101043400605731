import { Component } from 'react';
import { node } from 'prop-types';

export class ErrorBoundary extends Component {
    static propTypes = {
        children: node,
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        return !this.state.hasError && this.props.children;
    }
}
