import {
    ACTION_STATUS_CONFIRMATION,
    ACTION_STATUS_EDITING,
    ACTION_STATUS_FAILURE,
    ACTION_STATUS_OPENED,
    ACTION_STATUS_PENDING,
} from 'consts/actionStatus';
import { compose, filter, lensPath, set, uniq } from 'ramda';
import { createAction, createReducer } from 'redux-act';
import { resetFading, setFading } from 'utils/fadingTime';

import { travelersAdapter } from './adapter';
import { setTravelerStatusRemove, setTravelerStatusView } from './utils';

const initialState = {
    errors: [],
    fadingTime: setFading(),
    isError: false,
    isFetch: false,
    isInitialized: false,
    isLoading: false,
    items: {},
    list: [],
    navigation: {
        hasMore: true,
        page: 0,
        size: 1,
        totalElements: 0,
        totalPage: 1,
    },
    statusRemove: {},
    statusView: {},
};

export const fetchTravelers = createAction('travelers/fetch');
export const fetchTravelersSuccess = createAction('travelers/fetchSuccess');
export const fetchTravelersFailure = createAction('travelers/fetchFailure');

export const fetchMoreTravelers = createAction('travelers/fetchMore');
export const fetchMoreTravelersSuccess = createAction(
    'travelers/fetchMoreSuccess',
);
export const fetchMoreTravelersFailure = createAction(
    'travelers/fetchMoreFailure',
);

export const removeTraveler = createAction('travelers/remove');
export const removeTravelerCancel = createAction(
    'travelers/removeConfirmation',
);
export const removeTravelerConfirmation = createAction(
    'travelers/removeConfirmation',
);
export const removeTravelerSuccess = createAction('travelers/removeSuccess');
export const removeTravelerFailure = createAction('travelers/removeFailure');

export const removeTravelerContacts = createAction('travelers/removeContacts');
export const removeTravelerContactsSuccess = createAction(
    'travelers/removeContactsSuccess',
);
export const removeTravelerDocument = createAction('travelers/removeDocument');
export const removeTravelerDocumentSuccess = createAction(
    'travelers/removeDocumentSuccess',
);
export const removeTravelerLoyalty = createAction('travelers/removeLoyalty');
export const removeTravelerLoyaltySuccess = createAction(
    'travelers/removeLoyaltySuccess',
);

export const saveTraveler = createAction('travelers/save');
export const saveTravelerSuccess = createAction('travelers/saveSuccess');
export const saveTravelerFailure = createAction('travelers/saveFailure');

export const closeTraveler = createAction('travelers/close');
export const editTraveler = createAction('travelers/edit');
export const openTraveler = createAction('travelers/open');

const handleFetch = state => ({
    ...state,
    hasMore: false,
    isError: false,
    isFetch: true,
    isLoading: true,
    items: {},
    list: [],
    page: 0,
    size: 5,
    statusRemove: {},
    statusView: {},
    totalElements: 0,
    totalPage: 1,
});

const handleFetchFailure = () => ({
    ...initialState,
    isError: true,
    isInitialized: true,
});

const handleFetchSuccess = (state, { items, list, ...navigation }) => ({
    ...state,
    fadingTime: resetFading(true),
    isError: false,
    isFetch: false,
    isInitialized: true,
    isLoading: false,
    items: {
        ...state.items,
        ...items,
    },
    list: uniq([...state.list, ...list]),
    ...navigation,
});

const handleFetchMore = state => ({
    ...state,
    hasMore: false,
    isFetch: true,
});

const handleSaveFailure = (state, payload) => ({
    ...state,
    errors: payload,
});

const handleSaveSuccess = (state, payload) => {
    const { items, list } = travelersAdapter({
        travelers: {
            content: [payload],
        },
    });

    return {
        ...state,
        errors: [],
        items: {
            ...state.items,
            ...items,
        },
        list: uniq([...state.list, ...list]),
    };
};

const handleRemove = setTravelerStatusRemove(ACTION_STATUS_PENDING);
const handleRemoveCancel = setTravelerStatusRemove('');
const handleRemoveConfirmation = setTravelerStatusRemove(
    ACTION_STATUS_CONFIRMATION,
);
const handleRemoveFailure = setTravelerStatusRemove(ACTION_STATUS_FAILURE);

const handleRemoveSuccess = (state, { id }) => ({
    ...state,
    list: filter(item => item !== id, state.list),
});

const handleEdit = setTravelerStatusView(ACTION_STATUS_EDITING);
const handleOpen = setTravelerStatusView(ACTION_STATUS_OPENED);
const handleClose = setTravelerStatusView('');

const handleRemoveTravelerContactsSuccess = (state, id) =>
    compose(
        set(lensPath(['items', id, 'contacts']), []),
        set(lensPath(['items', id, 'raw', 'contacts']), []),
    )(state);

const handleRemoveTravelerDocumentSuccess = (state, { id, document }) => {
    const traveler = state.items[id];
    const documents = filter(doc => doc.id !== document, traveler.documents);
    const documentsRaw = filter(
        doc => doc.id !== document,
        traveler.raw.documents,
    );

    return {
        ...state,
        items: {
            ...state.items,
            [id]: {
                ...traveler,
                documents,
                raw: {
                    ...traveler.raw,
                    documents: documentsRaw,
                },
            },
        },
    };
};

const handleRemoveTravelerLoyaltySuccess = (state, id) =>
    compose(
        set(lensPath(['items', id, 'loyaltyCards']), []),
        set(lensPath(['items', id, 'raw', 'loyaltyCards']), []),
    )(state);

export default createReducer(
    {
        [closeTraveler]: handleClose,
        [editTraveler]: handleEdit,
        [fetchMoreTravelers]: handleFetchMore,
        [fetchMoreTravelersFailure]: handleFetchFailure,
        [fetchMoreTravelersSuccess]: handleFetchSuccess,
        [fetchTravelers]: handleFetch,
        [fetchTravelersFailure]: handleFetchFailure,
        [fetchTravelersSuccess]: handleFetchSuccess,
        [openTraveler]: handleOpen,
        [removeTraveler]: handleRemove,
        [removeTravelerCancel]: handleRemoveCancel,
        [removeTravelerConfirmation]: handleRemoveConfirmation,
        [removeTravelerContactsSuccess]: handleRemoveTravelerContactsSuccess,
        [removeTravelerDocumentSuccess]: handleRemoveTravelerDocumentSuccess,
        [removeTravelerFailure]: handleRemoveFailure,
        [removeTravelerLoyaltySuccess]: handleRemoveTravelerLoyaltySuccess,
        [removeTravelerSuccess]: handleRemoveSuccess,
        [saveTravelerFailure]: handleSaveFailure,
        [saveTravelerSuccess]: handleSaveSuccess,
    },
    initialState,
);

export * from './selectors';
