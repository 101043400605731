const german = require('./german');
const vietnamese = require('./vietnamese');
const arabic = require('./arabic');
const persianFarsi = require('./persianFarsi');
const pashto = require('./pashto');
const urdu = require('./urdu');
const russian = require('./russian');
const romanian = require('./romanian');
const turkish = require('./turkish');

module.exports = [
    // German umlauts
    ...german,

    // Vietnamese
    ...vietnamese,

    // Arabic
    ...arabic,

    // Persian / Farsi
    ...persianFarsi,

    // Pashto
    ...pashto,

    // Urdu
    ...urdu,

    // Russian
    ...russian,

    // Romanian
    ...romanian,

    // Turkish
    ...turkish,
];
