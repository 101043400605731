import moment from 'moment';
import { extendMoment } from 'moment-range';
import { map } from 'ramda';

import { DATE_FORMATS } from '../../../consts/dateFormats';

const YYYY_MM_DD = DATE_FORMATS.YYYY.MM.DD_dash;

const momentRange = extendMoment(moment);

const getDatesRange = range => {
    const [formDate, toDate] = range.toDate();

    return {
        from: moment(formDate).format(YYYY_MM_DD),
        to: moment(toDate).format(YYYY_MM_DD),
    };
};

export const getLoadedDates = (dates = []) => {
    let compactRanges = [];

    dates.forEach(currentDate => {
        const { from: currentFrom, to: currentTo } = currentDate;
        const currentRange = momentRange(
            moment(currentFrom),
            moment(currentTo),
        );

        let isJoined = false;

        compactRanges = map(({ from, to }) => {
            const compactRange = momentRange(moment(from), moment(to));
            const joinedRange = compactRange.add(currentRange);

            if (joinedRange) {
                isJoined = true;

                return getDatesRange(joinedRange);
            }

            return getDatesRange(compactRange);
        }, compactRanges);

        if (!isJoined) {
            compactRanges.push(currentDate);
        }
    });

    return compactRanges;
};

export const getTransactionPositive = transaction => {
    const points = parseInt(transaction.totalValue, 10);

    if (points > 0) {
        return '+';
    }

    if (points < 0) {
        return '-';
    }

    return '';
};
