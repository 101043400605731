import React, { Fragment, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { bool, func, node, object, oneOf, shape, string } from 'prop-types';
import { Dictionary, Portal } from 'shared';

import { Button as DSButton, StatusMessage, Text, Title } from '@s7/ui-kit';
import { useMediaQuery } from '@s7/ui-kit/hooks';
import { CloseLinear } from '@s7/ui-kit/icons';

import cx from './UserEdit.sss';

import Button from '../../ui-kit/atoms/Button';
import Loader from '../../ui-kit/atoms/Loader';

const errors = {
    'invalid.confirmation.secret': 'Неправильный код подтверждения',
};

const testIdsShape = shape({
    cancelButton: object,
    closeButton: object,
    removePopup: object,
    removeSuccessPopup: object,
    root: object,
    saveButton: object,
});

export const UserEdit = ({
    onReset,
    hasSubmit,
    submitting,
    onSubmit,
    title,
    error,
    needConfirmForRemove,
    isContacts,
    children,
    isTraveler,
    hideReset,
    hideSave,
    cancelText,
    saveText,
    onSave,
    onCloseConfirmedRemove,
    onConfirmedRemove,
    confirmationRemoveText,
    confirmationRemoveConfirmText,
    confirmationRemoveCancelText,
    removingStatus,
    removingMessage,
    testIds,
    buttons,
    notification: NotificationComponent,
    routeHash,
}) => {
    const breakpoints = useMediaQuery('xs', 'smMin', 'mdMax');
    const router = useRouter();

    const renderNeedConfirmForRemoveDialog = () => {
        const base = 'confirm_for_remove';

        return (
            <>
                <div
                    className={cx(base, {
                        [`${base}_success`]: removingStatus === 'success',
                    })}
                    {...(removingStatus === 'success'
                        ? testIds.removeSuccessPopup
                        : testIds.removePopup)}
                >
                    <div className={cx(`${base}_content`)}>
                        <span className={cx(`${base}_message`)}>
                            <Text color="W100" size="l">
                                {removingStatus
                                    ? removingMessage
                                    : confirmationRemoveText}
                            </Text>
                        </span>
                        {removingStatus === 'pending' && (
                            <div className={cx(`${base}_loader`)}>
                                <Loader />
                            </div>
                        )}
                    </div>
                    {!removingStatus && (
                        <div className={cx(`${base}_actions`)}>
                            <div className={cx(`${base}_actions_confirm`)}>
                                <Button
                                    borderRadius="small"
                                    size="slim"
                                    theme="danger"
                                    width="full"
                                    onClick={onConfirmedRemove}
                                    {...testIds.removeConfirm}
                                >
                                    <span
                                        className={cx(`${base}_actions_text`)}
                                    >
                                        <Text
                                            color="W100"
                                            size="s"
                                            weight="semibold"
                                        >
                                            {confirmationRemoveConfirmText}
                                        </Text>
                                    </span>
                                </Button>
                            </div>
                            <div className={cx(`${base}_actions_cancel`)}>
                                <Button
                                    borderRadius="small"
                                    size="slim"
                                    theme="dark"
                                    width="full"
                                    onClick={onCloseConfirmedRemove}
                                    {...testIds.removeCancel}
                                >
                                    <span
                                        className={cx(`${base}_actions_text`)}
                                    >
                                        <Text
                                            color="W100"
                                            size="s"
                                            weight="semibold"
                                        >
                                            {confirmationRemoveCancelText}
                                        </Text>
                                    </span>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                {removingStatus !== 'success' && (
                    <div className={cx(`${base}_overlay`)} />
                )}
            </>
        );
    };

    const getButtons = () => {
        if (!needConfirmForRemove && !removingStatus && buttons) {
            return (
                <div className={cx('buttons')}>
                    {React.Children.map(buttons, child => {
                        if (!child) {
                            return false;
                        }

                        return <div className={cx('button')}>{child}</div>;
                    })}
                </div>
            );
        }

        return null;
    };

    const onHashChange = ({
        currentTarget: {
            location: { hash },
        },
    }) => {
        if (hash.indexOf(routeHash) === -1) {
            onReset();
        }
    };

    useEffect(() => {
        if (window.addEventListener && routeHash && breakpoints.xs) {
            window.location.hash = routeHash;

            setTimeout(() => {
                window.addEventListener('hashchange', onHashChange);
            }, 0);
        }

        return () => {
            if (window.removeEventListener && routeHash) {
                if (window.location.hash === `#${routeHash}`) {
                    Router.push(router);
                }

                window.removeEventListener('hashchange', onHashChange);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isRemoveSuccess = removingStatus === 'success';

    const Tag = hasSubmit ? 'form' : 'div';
    const tagProps = hasSubmit
        ? {
              onSubmit,
          }
        : {};
    const Wrapper = breakpoints.mdMax ? Portal : Fragment;

    return (
        <Wrapper>
            <Tag
                className={cx('UserEdit', {
                    UserEdit_removeSuccess: isRemoveSuccess,
                    UserEdit_submitting: submitting,
                })}
                {...tagProps}
                {...testIds.root}
            >
                <div
                    className={cx('box', {
                        box_removeSuccess: isRemoveSuccess,
                    })}
                >
                    <div className={cx('heading')}>
                        <div className={cx('title')}>
                            <Title level={5}>{title}</Title>
                        </div>
                        {!isRemoveSuccess && (
                            <button
                                className={cx('close')}
                                type="button"
                                onClick={onReset}
                                {...testIds.closeButton}
                            >
                                <CloseLinear />
                            </button>
                        )}
                    </div>

                    {NotificationComponent && (
                        <div className={cx('notification')}>
                            <StatusMessage>
                                <p className={cx('notification_content')}>
                                    {NotificationComponent}
                                </p>
                            </StatusMessage>
                        </div>
                    )}

                    {error && (
                        <div className={cx('error')}>
                            <StatusMessage view="error">
                                {errors[error] || error}
                            </StatusMessage>
                        </div>
                    )}

                    {(needConfirmForRemove || !!removingStatus) &&
                        renderNeedConfirmForRemoveDialog()}

                    {getButtons()}

                    {!isRemoveSuccess && (
                        <>
                            <div
                                className={cx('content', {
                                    content_contacts: isContacts,
                                    content_traveler: isTraveler,
                                })}
                                {...testIds.content}
                            >
                                {React.Children.map(children, child => {
                                    if (!child) {
                                        return false;
                                    }

                                    return (
                                        <div
                                            className={cx('item')}
                                            {...testIds.item}
                                        >
                                            {child}
                                        </div>
                                    );
                                })}
                            </div>
                            {(!hideReset || !hideSave) && (
                                <div className={cx('actions')}>
                                    {!hideReset && (
                                        <div className={cx('action')}>
                                            <DSButton
                                                disabled={submitting}
                                                size="large"
                                                theme="secondary"
                                                block
                                                onClick={onReset}
                                                {...testIds.cancelButton}
                                            >
                                                <Text size="l">
                                                    {cancelText}
                                                </Text>
                                            </DSButton>
                                        </div>
                                    )}
                                    {!hideSave && (
                                        <div className={cx('action')}>
                                            <DSButton
                                                loading={submitting}
                                                size="large"
                                                theme="b2c"
                                                type="submit"
                                                block
                                                {...testIds.saveButton}
                                            >
                                                <Text color="W100" size="l">
                                                    {saveText}
                                                </Text>
                                            </DSButton>
                                        </div>
                                    )}
                                </div>
                            )}

                            {!hideSave && (
                                <div className={cx('save')}>
                                    <DSButton
                                        loading={submitting}
                                        size="large"
                                        theme="b2c"
                                        type={hasSubmit ? 'submit' : 'button'}
                                        block
                                        onClick={onSave}
                                    >
                                        {saveText}
                                    </DSButton>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Tag>
        </Wrapper>
    );
};

UserEdit.propTypes = {
    buttons: node,
    cancelText: node,
    children: node,
    confirmationRemoveCancelText: node,
    confirmationRemoveConfirmText: node,
    confirmationRemoveText: node,
    error: node,
    hasSubmit: bool,
    hideReset: bool,
    hideSave: bool,
    isContacts: bool,
    isTraveler: bool,
    needConfirmForRemove: bool,
    notification: node,
    onCloseConfirmedRemove: func,
    onConfirmedRemove: func,
    onReset: func,
    onSave: func,
    onSubmit: func,
    removingMessage: node,
    removingStatus: oneOf(['pending', 'success', 'failure']),
    routeHash: string,
    router: object,
    saveText: node,
    submitting: bool,
    testIds: testIdsShape,
    title: node,
};

UserEdit.defaultProps = {
    cancelText: <Dictionary text="com.action.cancel" />,
    hasSubmit: true,
    onReset: () => null,
    onSave: () => null,
    onSubmit: () => null,
    routeHash: '',
    saveText: <Dictionary text="com.action.save" />,
    testIds: {
        cancelButton: {},
        closeButton: {},
        removePopup: {},
        removeSuccessPopup: {},
        root: {},
        saveButton: {},
    },
    title: <Dictionary text="com.title.edit" />,
};
