import { useEffect, useState } from 'react';

const isClient = typeof window === 'object';

function getSize() {
    return {
        height: isClient ? window.innerHeight : undefined,
        isDesktop: isClient ? window.innerWidth > 1280 : false,
        isMobile: isClient ? window.innerWidth < 768 : false,
        isTablet: isClient
            ? window.innerWidth >= 768 && window.innerWidth <= 1280
            : false,
        width: isClient ? window.innerWidth : undefined,
    };
}

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        let timeoutId;

        function handleResize() {
            clearTimeout(timeoutId);

            timeoutId = window.setTimeout(() => setWindowSize(getSize()), 150);
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
