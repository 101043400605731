import { isObject } from './type';

type ValueType<T> = string | Record<string, T> | T[];

const isStringifyObject = (value?: string | null): value is string =>
    Boolean(value && (value.startsWith('[') || value.startsWith('{')));

const noop = () => undefined;

const getStorageApi = (storageName: 'localStorage' | 'sessionStorage') => {
    if (typeof window !== 'object') {
        return {
            getValue: noop,
            getValues: noop,
            setValue: noop,
            setValues: noop,
            removeValue: noop,
            removeValues: noop,
        };
    }

    const storage =
        storageName === 'localStorage' ? localStorage : sessionStorage;

    const setValue = <T>(key: string, value: ValueType<T>) => {
        try {
            const settableValue =
                typeof value === 'object' ? JSON.stringify(value) : value;

            storage.setItem(key, settableValue);
        } catch (error) {
            if ((error as Error).name !== 'QuotaExceededError') {
                throw error;
            }
        }
    };

    const getValue = (key: string) => {
        const value = storage.getItem(key);

        return isStringifyObject(value) ? JSON.parse(value) : value;
    };

    const removeValue = (key: string) => {
        storage.removeItem(key);
    };
    //TODO: remove any
    const setValues = (values: Record<string, any>) => {
        Object.entries(values).forEach(([key, value]) => {
            setValue(key, isObject(value) ? value.value : value);
        });
    };
    //TODO: remove any
    const getValues = (keys: any[]) => {
        return keys.reduce((acc, key) => {
            acc[key] = getValue(isObject(keys[key]) ? keys[key].value : key);

            return acc;
        }, {});
    };
    //TODO: remove any
    const removeValues = (keys: any[]) => {
        keys.forEach(key => {
            removeValue(isObject(keys[key]) ? keys[key].value : key);
        });
    };

    return {
        getValue,
        getValues,
        removeValue,
        removeValues,
        setValue,
        setValues,
    };
};

const {
    getValue: getLocalValue,
    getValues: getLocalValues,
    setValue: setLocalValue,
    setValues: setLocalValues,
    removeValue: removeLocalValue,
    removeValues: removeLocalValues,
} = getStorageApi('localStorage');
const {
    getValue: getSessionValue,
    getValues: getSessionValues,
    setValue: setSessionValue,
    setValues: setSessionValues,
    removeValue: removeSessionValue,
    removeValues: removeSessionValues,
} = getStorageApi('sessionStorage');

export {
    getLocalValue,
    getLocalValues,
    getSessionValue,
    getSessionValues,
    removeLocalValue,
    removeLocalValues,
    removeSessionValue,
    removeSessionValues,
    setLocalValue,
    setLocalValues,
    setSessionValue,
    setSessionValues,
};
