import React, { PureComponent } from 'react';
import { bool, node, oneOf } from 'prop-types';

import cx from './Text.sss';

export default class Text extends PureComponent {
    static propTypes = {
        children: node,
        color: oneOf(['black', 'gray', 'green', 'red', 'white']),
        decoration: oneOf(['blink', 'line-through', 'overline', 'underline']),
        disabled: bool,
        mobileDemi: bool,
        overflow: oneOf(['ellipsis']),
        size: oneOf([
            'smallest',
            'small',
            'semi',
            'demi',
            'large',
            'largest',
            'title',
            'subtitle',
        ]),
        weight: oneOf(['semibold', 'bold']),
    };

    render() {
        const {
            children,
            color,
            size,
            weight,
            overflow,
            decoration,
            disabled,
            mobileDemi,
            ...props
        } = this.props;

        return (
            <div
                className={cx('Text', {
                    Text_disabled: disabled,
                    [`color_${color}`]: color,
                    [`Text_size_${size}`]: size,
                    [`Text_weight_${weight}`]: weight,
                    [`Text_overflow_${overflow}`]: overflow,
                    [`decoration_${decoration}`]: decoration,
                    ['Text_mobileDemi']: mobileDemi,
                })}
                {...props}
            >
                {children}
            </div>
        );
    }
}
