import { compose, lensPath, set } from 'ramda';

const REMOVE_STATUS = 'statusRemove';
const VIEW_STATUS = 'statusView';

export const setTravelerStatusView = status => (state, payload) =>
    compose(
        set(lensPath([VIEW_STATUS, payload.id]), {
            item: payload.item || '',
            name: status,
            section: payload.section || '',
        }),
        set(lensPath([REMOVE_STATUS, payload.id]), ''),
    )(state);

export const setTravelerStatusRemove = status => (state, payload) =>
    set(lensPath([REMOVE_STATUS, payload.id]), status, state);
