import { apiService } from './api';

const pref = '/service/profiles/api/profiles';

export const profileService = {
    checkPublicId: publicId =>
        apiService.post(
            '/service/profiles/api/profiles/%userId%/publicId-check',
            { publicId },
        ),
    claimLoyaltyCard: ({ comment, company, deliveryAddress, deliveryType }) =>
        apiService.post(
            '/service/profiles/api/profiles/%userId%/loyalty-card/claim',
            {
                comment,
                company,
                deliveryAddress,
                deliveryType,
            },
        ),
    createProfile: profile => {
        const { autoConfirm } = profile;

        return apiService.post(
            `/service${autoConfirm ? '_b2b' : ''}/profiles/api/profiles`,
            {
                profile,
            },
        );
    },
    deleteAvatar: () =>
        apiService.delete('/service/profiles/api/profiles/%userId%/avatars'),
    deleteBarcode: id =>
        apiService.delete(
            `/service/profiles/api/profiles/%userId%/barcodes/${id}`,
        ),
    deleteCard: cardId =>
        apiService.delete(
            `/service/profiles/api/profiles/%userId%/cards/${cardId}`,
        ),
    deleteCompany: () =>
        apiService.delete('/service/profiles/api/profiles/%userId%/company'),
    deleteDocument: documentId =>
        apiService.delete(
            `/service/profiles/api/profiles/%userId%/documents/${documentId}`,
        ),
    findProfiles: memberId =>
        apiService.get('/profile/findProfiles', {
            params: {
                memberId,
            },
        }),
    getAddresses: () =>
        apiService.get('/service/profiles/api/profiles/%userId%/addresses'),
    // Загружает все карты, даже с отличным payment gateway от того который используется при покупке миль
    getAllCards: () =>
        apiService.get(
            '/service/profiles/api/profiles/%userId%/cards?allCards=true',
        ),
    getBarcodes: () =>
        apiService.get('/service/profiles/api/profiles/%userId%/barcodes'),
    getCards: () =>
        apiService.get('/service/profiles/api/profiles/%userId%/cards'),
    getDoctorDictionary: () =>
        apiService.get('/service/profiles/api/dictionaries/doctor'),
    getDocument: () =>
        apiService.get('/service/profiles/api/profiles/%userId%/documents'),
    getDraftProfile: draftProfileId =>
        apiService.get(
            `/service/profiles/api/draft-profiles/${draftProfileId}`,
        ),
    getProfile: () => apiService.get('/service/profiles/api/profiles/%userId%'),
    linkSocialAccount: ({ token, type }) =>
        apiService.put(
            '/service/profiles/api/profiles/%userId%/social-accounts',
            { token, type },
        ),
    saveAvatar: formData =>
        apiService.post(
            '/service/profiles/api/profiles/%userId%/avatars',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                maxContentLength: Infinity,
                timeout: 0,
            },
        ),
    saveBarcode: formData =>
        apiService.post(
            '/service/profiles/api/profiles/%userId%/barcodes',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        ),
    saveDocument: doc =>
        apiService.post('/service/profiles/api/profiles/%userId%/documents', {
            document: doc,
        }),
    searchProfile: params =>
        apiService.post(`${pref}/search`, { searchParameters: params }),
    unlinkSocialAccount: type =>
        apiService.delete(
            '/service/profiles/api/profiles/%userId%/social-accounts',
            {
                data: { type },
            },
        ),
    updateBirthday: birthday =>
        apiService.put('/service/profiles/api/profiles/%userId%/birthday', {
            birthday,
        }),
    updateDocument: ({ document: doc, documentId }) =>
        apiService.put(
            `/service/profiles/api/profiles/%userId%/documents/${documentId}`,
            {
                document: doc,
            },
        ),
    updateGender: gender =>
        apiService.put('/service/profiles/api/profiles/%userId%/gender', {
            gender,
        }),
    updateName: ({ firstName, lang, lastName }) =>
        apiService.put('/service/profiles/api/profiles/%userId%/names', {
            name: { firstName, lang, lastName },
        }),
    updatePreferences: preferences =>
        apiService.put('/service/profiles/api/profiles/%userId%/preferences', {
            preferences,
        }),
    updateProfile: profile =>
        apiService.patch('/service/profiles/api/profiles/%userId%', {
            profile,
        }),
    updatePublic: publicSettings =>
        apiService.put(
            '/service/profiles/api/profiles/%userId%/preferences/public-settings',
            {
                publicSettings,
            },
        ),
    upgradeProfile: profile =>
        apiService.put('/service/profiles/api/profiles/%userId%', { profile }),
};
