import { apiService } from './api';

const baseUrl = '/service/travelers/api/profiles';
const travelersUrl = `${baseUrl}/%userId%/travelers`;
const petsUrl = `${baseUrl}/%userId%/pets`;

export const travelersService = {
    contactsDelete: (travelerId, type) =>
        apiService.delete(`${travelersUrl}/${travelerId}/contacts/${type}`),
    createPet: ({ data }) => apiService.post(`${petsUrl}`, data),
    createTraveler: traveler => apiService.post(travelersUrl, { traveler }),
    deletePet: ({ petId }) => apiService.delete(`${petsUrl}/${petId}`),
    deletePetAvatar: ({ petId }) =>
        apiService.delete(`${petsUrl}/${petId}/avatars`),
    deleteTraveler: id => apiService.delete(`${travelersUrl}/${id}`),
    documentDelete: (travelerId, documentId) =>
        apiService.delete(
            `${travelersUrl}/${travelerId}/documents/${documentId}`,
        ),
    getPets: params => apiService.get(`${petsUrl}`, { params }),
    getTravelers: params => apiService.get(travelersUrl, { params }),
    loyaltyDelete: (travelerId, loyaltyCardId) =>
        apiService.delete(
            `${travelersUrl}/${travelerId}/loyalty-cards/${loyaltyCardId}`,
        ),
    updatePet: ({ petId, data }) => apiService.put(`${petsUrl}/${petId}`, data),
    updateTraveler: (traveler, removeDocuments) =>
        apiService.put('/travelers/update', { removeDocuments, traveler }),
    // updateTraveler: traveler => apiService.put(`${travelersUrl}/${traveler.id}`, { traveler }),
};
