import { last } from 'ramda';

import { isFading } from '../../../utils/fadingTime';

const emptySubscription = {};

export const isLoadedPromo = state => isFading(state.promo.fadingTime);
export const isLoadingPromo = state => state.promo.isLoading;
export const isErrorPromo = state => state.promo.isError;

export const getActiveSubscriptions = state => state.promo.active;
export const getAvailableSubscriptions = state => state.promo.available;

export const getHasActiveSubscriptions = state => state.promo.active.length > 0;
export const getSubscription = (id, state) =>
    state.promo.items[id] || emptySubscription;

export const getActiveSubscription = state =>
    getHasActiveSubscriptions(state)
        ? state.promo.items[last(state.promo.active)]
        : emptySubscription;

export const getBuyError = state => state.promo.buyError;
