const COOKIES = {
    ALERTS: 'alerts',
    AUTH: 'auth',
    DEVICE_ID: 'deviceId',
    IN_APP: 'inApp',
    LANG: 'lang',
    SSDCP: 'ssdcp',
    SSDGE: 'ssdge',
    SSDKL: 'ssdkl',
    SSDMB: 'ssdmb',
    SSDTI: 'ssdti',
    SSDTP: 'ssdtp',
    SSDWS: 'ssdws',
    SSDZQ: 'ssdzq',
};

module.exports = {
    COOKIES,
};
