import { OS_MAP } from './constants';
import Utils from './utils';

export default [
    /* Roku */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(/Roku\/DVP-(\d+\.\d+)/i, ua);

            return {
                name: OS_MAP.Roku,
                version,
            };
        },
        test: [/Roku\/DVP/],
    },

    /* Windows Phone */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /windows phone (?:os)?\s?(\d+(\.\d+)*)/i,
                ua,
            );

            return {
                name: OS_MAP.WindowsPhone,
                version,
            };
        },
        test: [/windows phone/i],
    },

    /* Windows */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /Windows ((NT|XP)( \d\d?.\d)?)/i,
                ua,
            );
            const versionName = Utils.getWindowsVersionName(version);

            return {
                name: OS_MAP.Windows,
                version,
                versionName,
            };
        },
        test: [/windows /i],
    },

    /* Firefox on iPad */
    {
        describe(ua) {
            const result = {
                name: OS_MAP.iOS,
            };
            const version = Utils.getSecondMatch(/(Version\/)(\d[\d.]+)/, ua);

            if (version) {
                result.version = version;
            }

            return result;
        },
        test: [/Macintosh(.*?) FxiOS(.*?)\//],
    },

    /* macOS */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /mac os x (\d+(\.?_?\d+)+)/i,
                ua,
            ).replace(/[_\s]/g, '.');
            const versionName = Utils.getMacOSVersionName(version);

            const os = {
                name: OS_MAP.MacOS,
                version,
            };

            if (versionName) {
                os.versionName = versionName;
            }

            return os;
        },
        test: [/macintosh/i],
    },

    /* iOS */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /os (\d+([_\s]\d+)*) like mac os x/i,
                ua,
            ).replace(/[_\s]/g, '.');

            return {
                name: OS_MAP.iOS,
                version,
            };
        },
        test: [/(ipod|iphone|ipad)/i],
    },

    /* Android */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /android[\s/-](\d+(\.\d+)*)/i,
                ua,
            );
            const versionName = Utils.getAndroidVersionName(version);

            const os = {
                name: OS_MAP.Android,
                version,
            };

            if (versionName) {
                os.versionName = versionName;
            }

            return os;
        },
        test(parser) {
            const notLikeAndroid = !parser.test(/like android/i);
            const butAndroid = parser.test(/android/i);

            return notLikeAndroid && butAndroid;
        },
    },

    /* WebOS */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /(?:web|hpw)[o0]s\/(\d+(\.\d+)*)/i,
                ua,
            );
            const os = {
                name: OS_MAP.WebOS,
            };

            if (version && version.length) {
                os.version = version;
            }

            return os;
        },
        test: [/(web|hpw)[o0]s/i],
    },

    /* BlackBerry */
    {
        describe(ua) {
            const version =
                Utils.getFirstMatch(/rim\stablet\sos\s(\d+(\.\d+)*)/i, ua) ||
                Utils.getFirstMatch(/blackberry\d+\/(\d+([_\s]\d+)*)/i, ua) ||
                Utils.getFirstMatch(/\bbb(\d+)/i, ua);

            return {
                name: OS_MAP.BlackBerry,
                version,
            };
        },
        test: [/blackberry|\bbb\d+/i, /rim\stablet/i],
    },

    /* Bada */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(/bada\/(\d+(\.\d+)*)/i, ua);

            return {
                name: OS_MAP.Bada,
                version,
            };
        },
        test: [/bada/i],
    },

    /* Tizen */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(/tizen[/\s](\d+(\.\d+)*)/i, ua);

            return {
                name: OS_MAP.Tizen,
                version,
            };
        },
        test: [/tizen/i],
    },

    /* Linux */
    {
        describe() {
            return {
                name: OS_MAP.Linux,
            };
        },
        test: [/linux/i],
    },

    /* Chrome OS */
    {
        describe() {
            return {
                name: OS_MAP.ChromeOS,
            };
        },
        test: [/CrOS/],
    },

    /* Playstation 4 */
    {
        describe(ua) {
            const version = Utils.getFirstMatch(
                /PlayStation 4[/\s](\d+(\.\d+)*)/i,
                ua,
            );

            return {
                name: OS_MAP.PlayStation4,
                version,
            };
        },
        test: [/PlayStation 4/],
    },
];
