/* eslint-disable sort-keys */
export const phoneMasks = [
    {
        mask: '+850-191-###-####',
        test: /850191(\d{0,7})/g,
        valid: /^850191(\d{7})$/g,
        count: 6,
        code: '+850-191',
        country: 'KP',
        name: 'Корейская НДР',
    },
    {
        mask: '+39-6-698-#####',
        test: /396698(\d{0,5})/g,
        valid: /^396698(\d{5})$/g,
        count: 6,
        code: '+39-6-698',
        country: 'VA',
        name: 'Ватикан',
    },
    {
        mask: '+975-17-###-###',
        test: /97517(\d{0,6})/g,
        valid: /^97517(\d{6})$/g,
        count: 5,
        code: '+975-17',
        country: 'BT',
        name: 'Бутан',
    },
    {
        mask: '+856(20##)###-###',
        test: /85620(\d{0,8})/g,
        valid: /^85620(\d{8})$/g,
        count: 5,
        code: '+856(20)',
        country: 'LA',
        name: 'Лаос',
    },
    {
        mask: '+218-21-###-####',
        test: /21821(\d{0,7})/g,
        valid: /^21821(\d{7})$/g,
        count: 5,
        code: '+218-21',
        country: 'LY',
        name: 'Ливия',
    },
    {
        mask: '+670-77#-#####',
        test: /67077(\d{0,6})/g,
        valid: /^67077(\d{6})$/g,
        count: 5,
        code: '+670-77',
        country: 'TL',
        name: 'Восточный Тимор',
    },
    {
        mask: '+670-78#-#####',
        test: /67078(\d{0,6})/g,
        valid: /^67078(\d{6})$/g,
        count: 5,
        code: '+670-78',
        country: 'TL',
        name: 'Восточный Тимор',
    },
    {
        mask: '+688-90####',
        test: /68890(\d{0,4})/g,
        valid: /^68890(\d{4})$/g,
        count: 5,
        code: '+688-90',
        country: 'TV',
        name: 'Тувалу',
    },
    {
        mask: '+971-5#-###-####',
        test: /9715(\d{0,8})/g,
        valid: /^9715(\d{8})$/g,
        count: 4,
        code: '+971-5',
        country: 'AE',
        name: 'Объединенные Арабские Эмираты',
    },
    {
        mask: '+1(268)###-####',
        test: /1268(\d{0,7})/g,
        valid: /^1268(\d{7})$/g,
        count: 4,
        code: '+1(268)',
        country: 'AG',
        name: 'Антигуа и Барбуда',
    },
    {
        mask: '+1(264)###-####',
        test: /1264(\d{0,7})/g,
        valid: /^1264(\d{7})$/g,
        count: 4,
        code: '+1(264)',
        country: 'AI',
        name: 'Ангилья',
    },
    {
        mask: '+599-9###-####',
        test: /5999(\d{0,7})/g,
        valid: /^5999(\d{7})$/g,
        count: 4,
        code: '+599-9',
        country: 'AN',
        name: 'Нидерландские Антильские острова',
    },
    {
        mask: '+672-1##-###',
        test: /6721(\d{0,5})/g,
        valid: /^6721(\d{5})$/g,
        count: 4,
        code: '+672-1',
        country: 'AQ',
        name: 'Австралийская антарктическая база',
    },
    {
        mask: '+1(684)###-####',
        test: /1684(\d{0,7})/g,
        valid: /^1684(\d{7})$/g,
        count: 4,
        code: '+1(684)',
        country: 'AS',
        name: 'Американское Самоа',
    },
    {
        mask: '+1(246)###-####',
        test: /1246(\d{0,7})/g,
        valid: /^1246(\d{7})$/g,
        count: 4,
        code: '+1(246)',
        country: 'BB',
        name: 'Барбадос',
    },
    {
        mask: '+1(441)###-####',
        test: /1441(\d{0,7})/g,
        valid: /^1441(\d{7})$/g,
        count: 4,
        code: '+1(441)',
        country: 'BM',
        name: 'Бермудские острова',
    },
    {
        mask: '+1(242)###-####',
        test: /1242(\d{0,7})/g,
        valid: /^1242(\d{7})$/g,
        count: 4,
        code: '+1(242)',
        country: 'BS',
        name: 'Багамские Острова',
    },
    {
        mask: '+1(767)###-####',
        test: /1767(\d{0,7})/g,
        valid: /^1767(\d{7})$/g,
        count: 4,
        code: '+1(767)',
        country: 'DM',
        name: 'Доминика',
    },
    {
        mask: '+1(809)###-####',
        test: /1809(\d{0,7})/g,
        valid: /^1809(\d{7})$/g,
        count: 4,
        code: '+1(809)',
        country: 'DO',
        name: 'Доминиканская Республика',
    },
    {
        mask: '+1(829)###-####',
        test: /1829(\d{0,7})/g,
        valid: /^1829(\d{7})$/g,
        count: 4,
        code: '+1(829)',
        country: 'DO',
        name: 'Доминиканская Республика',
    },
    {
        mask: '+1(849)###-####',
        test: /1849(\d{0,7})/g,
        valid: /^1849(\d{7})$/g,
        count: 4,
        code: '+1(849)',
        country: 'DO',
        name: 'Доминиканская Республика',
    },
    {
        mask: '+1(473)###-####',
        test: /1473(\d{0,7})/g,
        valid: /^1473(\d{7})$/g,
        count: 4,
        code: '+1(473)',
        country: 'GD',
        name: 'Гренада',
    },
    {
        mask: '+1(671)###-####',
        test: /1671(\d{0,7})/g,
        valid: /^1671(\d{7})$/g,
        count: 4,
        code: '+1(671)',
        country: 'GU',
        name: 'Гуам',
    },
    {
        mask: '+972-5#-###-####',
        test: /9725(\d{0,8})/g,
        valid: /^9725(\d{8})$/g,
        count: 4,
        code: '+972-5',
        country: 'IL',
        name: 'Израиль',
    },
    {
        mask: '+1(876)###-####',
        test: /1876(\d{0,7})/g,
        valid: /^1876(\d{7})$/g,
        count: 4,
        code: '+1(876)',
        country: 'JM',
        name: 'Ямайка',
    },
    {
        mask: '+1(869)###-####',
        test: /1869(\d{0,7})/g,
        valid: /^1869(\d{7})$/g,
        count: 4,
        code: '+1(869)',
        country: 'KN',
        name: 'Сент-Китс и Невис',
    },
    {
        mask: '+1(345)###-####',
        test: /1345(\d{0,7})/g,
        valid: /^1345(\d{7})$/g,
        count: 4,
        code: '+1(345)',
        country: 'KY',
        name: 'Каймановы острова',
    },
    {
        mask: '+1(758)###-####',
        test: /1758(\d{0,7})/g,
        valid: /^1758(\d{7})$/g,
        count: 4,
        code: '+1(758)',
        country: 'LC',
        name: 'Сент-Люсия',
    },
    {
        mask: '+1(670)###-####',
        test: /1670(\d{0,7})/g,
        valid: /^1670(\d{7})$/g,
        count: 4,
        code: '+1(670)',
        country: 'MP',
        name: 'Северные Марианские острова Сайпан',
    },
    {
        mask: '+1(664)###-####',
        test: /1664(\d{0,7})/g,
        valid: /^1664(\d{7})$/g,
        count: 4,
        code: '+1(664)',
        country: 'MS',
        name: 'Монтсеррат',
    },
    {
        mask: '+265-1-###-###',
        test: /2651(\d{0,6})/g,
        valid: /^2651(\d{6})$/g,
        count: 4,
        code: '+265-1',
        country: 'MW',
        name: 'Малави',
    },
    {
        mask: '+672-3##-###',
        test: /6723(\d{0,5})/g,
        valid: /^6723(\d{5})$/g,
        count: 4,
        code: '+672-3',
        country: 'NF',
        name: 'Норфолк (остров)',
    },
    {
        mask: '+966-5-####-####',
        test: /9665(\d{0,8})/g,
        valid: /^9665(\d{8})$/g,
        count: 4,
        code: '+966-5',
        country: 'SA',
        name: 'Саудовская Аравия',
    },
    {
        mask: '+1(721)###-####',
        test: /1721(\d{0,7})/g,
        valid: /^1721(\d{7})$/g,
        count: 4,
        code: '+1(721)',
        country: 'SX',
        name: 'Синт-Маартен',
    },
    {
        mask: '+1(649)###-####',
        test: /1649(\d{0,7})/g,
        valid: /^1649(\d{7})$/g,
        count: 4,
        code: '+1(649)',
        country: 'TC',
        name: 'Тёркс и Кайкос',
    },
    {
        mask: '+1(868)###-####',
        test: /1868(\d{0,7})/g,
        valid: /^1868(\d{7})$/g,
        count: 4,
        code: '+1(868)',
        country: 'TT',
        name: 'Тринидад и Тобаго',
    },
    {
        mask: '+688-2####',
        test: /6882(\d{0,4})/g,
        valid: /^6882(\d{4})$/g,
        count: 4,
        code: '+688-2',
        country: 'TV',
        name: 'Тувалу',
    },
    {
        mask: '+1(784)###-####',
        test: /1784(\d{0,7})/g,
        valid: /^1784(\d{7})$/g,
        count: 4,
        code: '+1(784)',
        country: 'VC',
        name: 'Сент-Винсент и Гренадины',
    },
    {
        mask: '+1(284)###-####',
        test: /1284(\d{0,7})/g,
        valid: /^1284(\d{7})$/g,
        count: 4,
        code: '+1(284)',
        country: 'VG',
        name: 'Британские Виргинские острова',
    },
    {
        mask: '+1(340)###-####',
        test: /1340(\d{0,7})/g,
        valid: /^1340(\d{7})$/g,
        count: 4,
        code: '+1(340)',
        country: 'VI',
        name: 'Американские Виргинские острова',
    },
    {
        mask: '+247-####',
        test: /247(\d{0,4})/g,
        valid: /^247(\d{4})$/g,
        count: 3,
        code: '+247',
        country: 'AC',
        name: 'Остров Вознесения',
    },
    {
        mask: '+376-###-###',
        test: /376(\d{0,6})/g,
        valid: /^376(\d{6})$/g,
        count: 3,
        code: '+376',
        country: 'AD',
        name: 'Андорра',
    },
    {
        mask: '+971-#-###-####',
        test: /971(\d{0,8})/g,
        valid: /^971(\d{8})$/g,
        count: 3,
        code: '+971',
        country: 'AE',
        name: 'Объединенные Арабские Эмираты',
    },
    {
        mask: '+355(###)###-###',
        test: /355(\d{0,9})/g,
        valid: /^355(\d{9})$/g,
        count: 3,
        code: '+355',
        country: 'AL',
        name: 'Албания',
    },
    {
        mask: '+374-##-###-###',
        test: /374(\d{0,8})/g,
        valid: /^374(\d{8})$/g,
        count: 3,
        code: '+374',
        country: 'AM',
        name: 'Армения',
    },
    {
        mask: '+599-###-####',
        test: /599(\d{0,7})/g,
        valid: /^599(\d{7})$/g,
        count: 3,
        code: '+599',
        country: 'AN',
        name: 'Карибские Нидерланды',
    },
    {
        mask: '+599-###-####',
        test: /599(\d{0,7})/g,
        valid: /^599(\d{7})$/g,
        count: 3,
        code: '+599',
        country: 'AN',
        name: 'Нидерландские Антильские острова',
    },
    {
        mask: '+244(###)###-###',
        test: /244(\d{0,9})/g,
        valid: /^244(\d{9})$/g,
        count: 3,
        code: '+244',
        country: 'AO',
        name: 'Ангола',
    },
    {
        mask: '+297-###-####',
        test: /297(\d{0,7})/g,
        valid: /^297(\d{7})$/g,
        count: 3,
        code: '+297',
        country: 'AW',
        name: 'Аруба',
    },
    {
        mask: '+994-##-###-##-##',
        test: /994(\d{0,9})/g,
        valid: /^994(\d{9})$/g,
        count: 3,
        code: '+994',
        country: 'AZ',
        name: 'Азербайджан',
    },
    {
        mask: '+387-##-#####',
        test: /387(\d{0,7})/g,
        valid: /^387(\d{7})$/g,
        count: 3,
        code: '+387',
        country: 'BA',
        name: 'Босния и Герцеговина',
    },
    {
        mask: '+387-##-####',
        test: /387(\d{0,6})/g,
        valid: /^387(\d{6})$/g,
        count: 3,
        code: '+387',
        country: 'BA',
        name: 'Босния и Герцеговина',
    },
    {
        mask: '+880-##-###-###',
        test: /880(\d{0,8})/g,
        valid: /^880(\d{8})$/g,
        count: 3,
        code: '+880',
        country: 'BD',
        name: 'Бангладеш',
    },
    {
        mask: '+226-##-##-####',
        test: /226(\d{0,8})/g,
        valid: /^226(\d{8})$/g,
        count: 3,
        code: '+226',
        country: 'BF',
        name: 'Буркина Фасо',
    },
    {
        mask: '+359(###)###-###',
        test: /359(\d{0,9})/g,
        valid: /^359(\d{9})$/g,
        count: 3,
        code: '+359',
        country: 'BG',
        name: 'Болгария',
    },
    {
        mask: '+973-####-####',
        test: /973(\d{0,8})/g,
        valid: /^973(\d{8})$/g,
        count: 3,
        code: '+973',
        country: 'BH',
        name: 'Бахрейн',
    },
    {
        mask: '+257-##-##-####',
        test: /257(\d{0,8})/g,
        valid: /^257(\d{8})$/g,
        count: 3,
        code: '+257',
        country: 'BI',
        name: 'Бурунди',
    },
    {
        mask: '+229-##-##-####',
        test: /229(\d{0,8})/g,
        valid: /^229(\d{8})$/g,
        count: 3,
        code: '+229',
        country: 'BJ',
        name: 'Бенин',
    },
    {
        mask: '+673-###-####',
        test: /673(\d{0,7})/g,
        valid: /^673(\d{7})$/g,
        count: 3,
        code: '+673',
        country: 'BN',
        name: 'Бруней-Даруссалам',
    },
    {
        mask: '+591-#-###-####',
        test: /591(\d{0,8})/g,
        valid: /^591(\d{8})$/g,
        count: 3,
        code: '+591',
        country: 'BO',
        name: 'Боливия',
    },
    {
        mask: '+975-#-###-###',
        test: /975(\d{0,7})/g,
        valid: /^975(\d{7})$/g,
        count: 3,
        code: '+975',
        country: 'BT',
        name: 'Бутан',
    },
    {
        mask: '+267-##-###-###',
        test: /267(\d{0,8})/g,
        valid: /^267(\d{8})$/g,
        count: 3,
        code: '+267',
        country: 'BW',
        name: 'Ботсвана',
    },
    {
        mask: '+375(##)###-##-##',
        test: /375(\d{0,9})/g,
        valid: /^375(\d{9})$/g,
        count: 3,
        code: '+375',
        country: 'BY',
        name: 'Беларусь (Белоруссия)',
    },
    {
        mask: '+501-###-####',
        test: /501(\d{0,7})/g,
        valid: /^501(\d{7})$/g,
        count: 3,
        code: '+501',
        country: 'BZ',
        name: 'Белиз',
    },
    {
        mask: '+243(###)###-###',
        test: /243(\d{0,9})/g,
        valid: /^243(\d{9})$/g,
        count: 3,
        code: '+243',
        country: 'CD',
        name: 'Дем. Респ. Конго (Киншаса)',
    },
    {
        mask: '+236-##-##-####',
        test: /236(\d{0,8})/g,
        valid: /^236(\d{8})$/g,
        count: 3,
        code: '+236',
        country: 'CF',
        name: 'Центральноафриканская Республика',
    },
    {
        mask: '+242-##-###-####',
        test: /242(\d{0,9})/g,
        valid: /^242(\d{9})$/g,
        count: 3,
        code: '+242',
        country: 'CG',
        name: 'Конго (Браззавиль)',
    },
    {
        mask: '+225-##-###-###',
        test: /225(\d{0,8})/g,
        valid: /^225(\d{8})$/g,
        count: 3,
        code: '+225',
        country: 'CI',
        name: 'Кот-д’Ивуар',
    },
    {
        mask: '+682-##-###',
        test: /682(\d{0,5})/g,
        valid: /^682(\d{5})$/g,
        count: 3,
        code: '+682',
        country: 'CK',
        name: 'Острова Кука',
    },
    {
        mask: '+237-####-####',
        test: /237(\d{0,8})/g,
        valid: /^237(\d{8})$/g,
        count: 3,
        code: '+237',
        country: 'CM',
        name: 'Камерун',
    },
    {
        mask: '+506-####-####',
        test: /506(\d{0,8})/g,
        valid: /^506(\d{8})$/g,
        count: 3,
        code: '+506',
        country: 'CR',
        name: 'Коста-Рика',
    },
    {
        mask: '+238(###)##-##',
        test: /238(\d{0,7})/g,
        valid: /^238(\d{7})$/g,
        count: 3,
        code: '+238',
        country: 'CV',
        name: 'Кабо-Верде',
    },
    {
        mask: '+599-###-####',
        test: /599(\d{0,7})/g,
        valid: /^599(\d{7})$/g,
        count: 3,
        code: '+599',
        country: 'CW',
        name: 'Кюрасао',
    },
    {
        mask: '+357-##-###-###',
        test: /357(\d{0,8})/g,
        valid: /^357(\d{8})$/g,
        count: 3,
        code: '+357',
        country: 'CY',
        name: 'Кипр',
    },
    {
        mask: '+420(###)###-###',
        test: /420(\d{0,9})/g,
        valid: /^420(\d{9})$/g,
        count: 3,
        code: '+420',
        country: 'CZ',
        name: 'Чехия',
    },
    {
        mask: '+253-##-##-##-##',
        test: /253(\d{0,8})/g,
        valid: /^253(\d{8})$/g,
        count: 3,
        code: '+253',
        country: 'DJ',
        name: 'Джибути',
    },
    {
        mask: '+213-##-###-####',
        test: /213(\d{0,9})/g,
        valid: /^213(\d{9})$/g,
        count: 3,
        code: '+213',
        country: 'DZ',
        name: 'Алжир',
    },
    {
        mask: '+593-##-###-####',
        test: /593(\d{0,9})/g,
        valid: /^593(\d{9})$/g,
        count: 3,
        code: '+593',
        country: 'EC',
        name: 'Эквадор',
    },
    {
        mask: '+593-#-###-####',
        test: /593(\d{0,8})/g,
        valid: /^593(\d{8})$/g,
        count: 3,
        code: '+593',
        country: 'EC',
        name: 'Эквадор',
    },
    {
        mask: '+372-####-####',
        test: /372(\d{0,8})/g,
        valid: /^372(\d{8})$/g,
        count: 3,
        code: '+372',
        country: 'EE',
        name: 'Эстония',
    },
    {
        mask: '+372-###-####',
        test: /372(\d{0,7})/g,
        valid: /^372(\d{7})$/g,
        count: 3,
        code: '+372',
        country: 'EE',
        name: 'Эстония',
    },
    {
        mask: '+291-#-###-###',
        test: /291(\d{0,7})/g,
        valid: /^291(\d{7})$/g,
        count: 3,
        code: '+291',
        country: 'ER',
        name: 'Эритрея',
    },
    {
        mask: '+251-##-###-####',
        test: /251(\d{0,9})/g,
        valid: /^251(\d{9})$/g,
        count: 3,
        code: '+251',
        country: 'ET',
        name: 'Эфиопия',
    },
    {
        mask: '+358(###)###-##-##',
        test: /358(\d{0,10})/g,
        valid: /^358(\d{10})$/g,
        count: 3,
        code: '+358',
        country: 'FI',
        name: 'Финляндия',
    },
    {
        mask: '+679-##-#####',
        test: /679(\d{0,7})/g,
        valid: /^679(\d{7})$/g,
        count: 3,
        code: '+679',
        country: 'FJ',
        name: 'Фиджи',
    },
    {
        mask: '+500-#####',
        test: /500(\d{0,5})/g,
        valid: /^500(\d{5})$/g,
        count: 3,
        code: '+500',
        country: 'FK',
        name: 'Фолклендские острова',
    },
    {
        mask: '+691-###-####',
        test: /691(\d{0,7})/g,
        valid: /^691(\d{7})$/g,
        count: 3,
        code: '+691',
        country: 'FM',
        name: 'Ф.Ш. Микронезии',
    },
    {
        mask: '+298-###-###',
        test: /298(\d{0,6})/g,
        valid: /^298(\d{6})$/g,
        count: 3,
        code: '+298',
        country: 'FO',
        name: 'Фарерские острова',
    },
    {
        mask: '+262-#####-####',
        test: /262(\d{0,9})/g,
        valid: /^262(\d{9})$/g,
        count: 3,
        code: '+262',
        country: 'FR',
        name: 'Майотта',
    },
    {
        mask: '+508-##-####',
        test: /508(\d{0,6})/g,
        valid: /^508(\d{6})$/g,
        count: 3,
        code: '+508',
        country: 'FR',
        name: 'Сен-Пьер и Микелон',
    },
    {
        mask: '+590(###)###-###',
        test: /590(\d{0,9})/g,
        valid: /^590(\d{9})$/g,
        count: 3,
        code: '+590',
        country: 'FR',
        name: 'Гваделупа',
    },
    {
        mask: '+241-#-##-##-##',
        test: /241(\d{0,7})/g,
        valid: /^241(\d{7})$/g,
        count: 3,
        code: '+241',
        country: 'GA',
        name: 'Габон',
    },
    {
        mask: '+995(###)###-###',
        test: /995(\d{0,9})/g,
        valid: /^995(\d{9})$/g,
        count: 3,
        code: '+995',
        country: 'GE',
        name: 'Грузия',
    },
    {
        mask: '+594-#####-####',
        test: /594(\d{0,9})/g,
        valid: /^594(\d{9})$/g,
        count: 3,
        code: '+594',
        country: 'GF',
        name: 'Фр. Гвиана',
    },
    {
        mask: '+233(###)###-###',
        test: /233(\d{0,9})/g,
        valid: /^233(\d{9})$/g,
        count: 3,
        code: '+233',
        country: 'GH',
        name: 'Гана',
    },
    {
        mask: '+350-###-#####',
        test: /350(\d{0,8})/g,
        valid: /^350(\d{8})$/g,
        count: 3,
        code: '+350',
        country: 'GI',
        name: 'Гибралтар',
    },
    {
        mask: '+299-##-##-##',
        test: /299(\d{0,6})/g,
        valid: /^299(\d{6})$/g,
        count: 3,
        code: '+299',
        country: 'GL',
        name: 'Гренландия',
    },
    {
        mask: '+220(###)##-##',
        test: /220(\d{0,7})/g,
        valid: /^220(\d{7})$/g,
        count: 3,
        code: '+220',
        country: 'GM',
        name: 'Гамбия',
    },
    {
        mask: '+224-##-###-###',
        test: /224(\d{0,8})/g,
        valid: /^224(\d{8})$/g,
        count: 3,
        code: '+224',
        country: 'GN',
        name: 'Гвинея',
    },
    {
        mask: '+240-##-###-####',
        test: /240(\d{0,9})/g,
        valid: /^240(\d{9})$/g,
        count: 3,
        code: '+240',
        country: 'GQ',
        name: 'Экваториальная Гвинея',
    },
    {
        mask: '+502-#-###-####',
        test: /502(\d{0,8})/g,
        valid: /^502(\d{8})$/g,
        count: 3,
        code: '+502',
        country: 'GT',
        name: 'Гватемала',
    },
    {
        mask: '+245-#-######',
        test: /245(\d{0,7})/g,
        valid: /^245(\d{7})$/g,
        count: 3,
        code: '+245',
        country: 'GW',
        name: 'Гвинея-Бисау',
    },
    {
        mask: '+592-###-####',
        test: /592(\d{0,7})/g,
        valid: /^592(\d{7})$/g,
        count: 3,
        code: '+592',
        country: 'GY',
        name: 'Гайана',
    },
    {
        mask: '+852-####-####',
        test: /852(\d{0,8})/g,
        valid: /^852(\d{8})$/g,
        count: 3,
        code: '+852',
        country: 'HK',
        name: 'Гонконг',
    },
    {
        mask: '+504-####-####',
        test: /504(\d{0,8})/g,
        valid: /^504(\d{8})$/g,
        count: 3,
        code: '+504',
        country: 'HN',
        name: 'Гондурас',
    },
    {
        mask: '+385-##-###-###',
        test: /385(\d{0,8})/g,
        valid: /^385(\d{8})$/g,
        count: 3,
        code: '+385',
        country: 'HR',
        name: 'Хорватия',
    },
    {
        mask: '+509-##-##-####',
        test: /509(\d{0,8})/g,
        valid: /^509(\d{8})$/g,
        count: 3,
        code: '+509',
        country: 'HT',
        name: 'Гаити',
    },
    {
        mask: '+62(8##)###-####',
        test: /628(\d{0,9})/g,
        valid: /^628(\d{9})$/g,
        count: 3,
        code: '+62(8)',
        country: 'ID',
        name: 'Индонезия',
    },
    {
        mask: '+62(8##)###-###',
        test: /628(\d{0,8})/g,
        valid: /^628(\d{8})$/g,
        count: 3,
        code: '+62(8)',
        country: 'ID',
        name: 'Индонезия',
    },
    {
        mask: '+62(8##)###-##-###',
        test: /628(\d{0,10})/g,
        valid: /^628(\d{10})$/g,
        count: 3,
        code: '+62(8)',
        country: 'ID',
        name: 'Индонезия',
    },
    {
        mask: '+353(###)###-###',
        test: /353(\d{0,9})/g,
        valid: /^353(\d{9})$/g,
        count: 3,
        code: '+353',
        country: 'IE',
        name: 'Ирландия',
    },
    {
        mask: '+972-#-###-####',
        test: /972(\d{0,8})/g,
        valid: /^972(\d{8})$/g,
        count: 3,
        code: '+972',
        country: 'IL',
        name: 'Израиль',
    },
    {
        mask: '+246-###-####',
        test: /246(\d{0,7})/g,
        valid: /^246(\d{7})$/g,
        count: 3,
        code: '+246',
        country: 'IO',
        name: 'Диего-Гарсия',
    },
    {
        mask: '+964(###)###-####',
        test: /964(\d{0,10})/g,
        valid: /^964(\d{10})$/g,
        count: 3,
        code: '+964',
        country: 'IQ',
        name: 'Ирак',
    },
    {
        mask: '+354-###-####',
        test: /354(\d{0,7})/g,
        valid: /^354(\d{7})$/g,
        count: 3,
        code: '+354',
        country: 'IS',
        name: 'Исландия',
    },
    {
        mask: '+962-#-####-####',
        test: /962(\d{0,9})/g,
        valid: /^962(\d{9})$/g,
        count: 3,
        code: '+962',
        country: 'JO',
        name: 'Иордания',
    },
    {
        mask: '+254-###-######',
        test: /254(\d{0,9})/g,
        valid: /^254(\d{9})$/g,
        count: 3,
        code: '+254',
        country: 'KE',
        name: 'Кения',
    },
    {
        mask: '+996(###)###-###',
        test: /996(\d{0,9})/g,
        valid: /^996(\d{9})$/g,
        count: 3,
        code: '+996',
        country: 'KG',
        name: 'Киргизия',
    },
    {
        mask: '+855-##-###-###',
        test: /855(\d{0,8})/g,
        valid: /^855(\d{8})$/g,
        count: 3,
        code: '+855',
        country: 'KH',
        name: 'Камбоджа',
    },
    {
        mask: '+269-##-#####',
        test: /269(\d{0,7})/g,
        valid: /^269(\d{7})$/g,
        count: 3,
        code: '+269',
        country: 'KM',
        name: 'Коморы',
    },
    {
        mask: '+850-##-###-###',
        test: /850(\d{0,8})/g,
        valid: /^850(\d{8})$/g,
        count: 3,
        code: '+850',
        country: 'KP',
        name: 'Корейская НДР',
    },
    {
        mask: '+850-###-####-###',
        test: /850(\d{0,10})/g,
        valid: /^850(\d{10})$/g,
        count: 3,
        code: '+850',
        country: 'KP',
        name: 'Корейская НДР',
    },
    {
        mask: '+850-###-###',
        test: /850(\d{0,6})/g,
        valid: /^850(\d{6})$/g,
        count: 3,
        code: '+850',
        country: 'KP',
        name: 'Корейская НДР',
    },
    {
        mask: '+850-####-####',
        test: /850(\d{0,8})/g,
        valid: /^850(\d{8})$/g,
        count: 3,
        code: '+850',
        country: 'KP',
        name: 'Корейская НДР',
    },
    {
        mask: '+850-####-#############',
        test: /850(\d{0,17})/g,
        valid: /^850(\d{17})$/g,
        count: 3,
        code: '+850',
        country: 'KP',
        name: 'Корейская НДР',
    },
    {
        mask: '+965-####-####',
        test: /965(\d{0,8})/g,
        valid: /^965(\d{8})$/g,
        count: 3,
        code: '+965',
        country: 'KW',
        name: 'Кувейт',
    },
    {
        mask: '+856-##-###-###',
        test: /856(\d{0,8})/g,
        valid: /^856(\d{8})$/g,
        count: 3,
        code: '+856',
        country: 'LA',
        name: 'Лаос',
    },
    {
        mask: '+961-##-###-###',
        test: /961(\d{0,8})/g,
        valid: /^961(\d{8})$/g,
        count: 3,
        code: '+961',
        country: 'LB',
        name: 'Ливан',
    },
    {
        mask: '+961-#-###-###',
        test: /961(\d{0,7})/g,
        valid: /^961(\d{7})$/g,
        count: 3,
        code: '+961',
        country: 'LB',
        name: 'Ливан',
    },
    {
        mask: '+423(###)###-####',
        test: /423(\d{0,10})/g,
        valid: /^423(\d{10})$/g,
        count: 3,
        code: '+423',
        country: 'LI',
        name: 'Лихтенштейн',
    },
    {
        mask: '+231-##-###-###',
        test: /231(\d{0,8})/g,
        valid: /^231(\d{8})$/g,
        count: 3,
        code: '+231',
        country: 'LR',
        name: 'Либерия',
    },
    {
        mask: '+266-#-###-####',
        test: /266(\d{0,8})/g,
        valid: /^266(\d{8})$/g,
        count: 3,
        code: '+266',
        country: 'LS',
        name: 'Лесото',
    },
    {
        mask: '+370(###)##-###',
        test: /370(\d{0,8})/g,
        valid: /^370(\d{8})$/g,
        count: 3,
        code: '+370',
        country: 'LT',
        name: 'Литва',
    },
    {
        mask: '+352(###)###-###',
        test: /352(\d{0,9})/g,
        valid: /^352(\d{9})$/g,
        count: 3,
        code: '+352',
        country: 'LU',
        name: 'Люксембург',
    },
    {
        mask: '+371-##-###-###',
        test: /371(\d{0,8})/g,
        valid: /^371(\d{8})$/g,
        count: 3,
        code: '+371',
        country: 'LV',
        name: 'Латвия',
    },
    {
        mask: '+218-##-###-###',
        test: /218(\d{0,8})/g,
        valid: /^218(\d{8})$/g,
        count: 3,
        code: '+218',
        country: 'LY',
        name: 'Ливия',
    },
    {
        mask: '+212-##-####-###',
        test: /212(\d{0,9})/g,
        valid: /^212(\d{9})$/g,
        count: 3,
        code: '+212',
        country: 'MA',
        name: 'Марокко',
    },
    {
        mask: '+377(###)###-###',
        test: /377(\d{0,9})/g,
        valid: /^377(\d{9})$/g,
        count: 3,
        code: '+377',
        country: 'MC',
        name: 'Монако',
    },
    {
        mask: '+377-##-###-###',
        test: /377(\d{0,8})/g,
        valid: /^377(\d{8})$/g,
        count: 3,
        code: '+377',
        country: 'MC',
        name: 'Монако',
    },
    {
        mask: '+373-####-####',
        test: /373(\d{0,8})/g,
        valid: /^373(\d{8})$/g,
        count: 3,
        code: '+373',
        country: 'MD',
        name: 'Молдова',
    },
    {
        mask: '+382-##-###-###',
        test: /382(\d{0,8})/g,
        valid: /^382(\d{8})$/g,
        count: 3,
        code: '+382',
        country: 'ME',
        name: 'Черногория',
    },
    {
        mask: '+261-##-##-#####',
        test: /261(\d{0,9})/g,
        valid: /^261(\d{9})$/g,
        count: 3,
        code: '+261',
        country: 'MG',
        name: 'Мадагаскар',
    },
    {
        mask: '+692-###-####',
        test: /692(\d{0,7})/g,
        valid: /^692(\d{7})$/g,
        count: 3,
        code: '+692',
        country: 'MH',
        name: 'Маршалловы Острова',
    },
    {
        mask: '+389-##-###-###',
        test: /389(\d{0,8})/g,
        valid: /^389(\d{8})$/g,
        count: 3,
        code: '+389',
        country: 'MK',
        name: 'Респ. Македония',
    },
    {
        mask: '+223-##-##-####',
        test: /223(\d{0,8})/g,
        valid: /^223(\d{8})$/g,
        count: 3,
        code: '+223',
        country: 'ML',
        name: 'Мали',
    },
    {
        mask: '+976-##-##-####',
        test: /976(\d{0,8})/g,
        valid: /^976(\d{8})$/g,
        count: 3,
        code: '+976',
        country: 'MN',
        name: 'Монголия',
    },
    {
        mask: '+853-####-####',
        test: /853(\d{0,8})/g,
        valid: /^853(\d{8})$/g,
        count: 3,
        code: '+853',
        country: 'MO',
        name: 'Макао',
    },
    {
        mask: '+596(###)##-##-##',
        test: /596(\d{0,9})/g,
        valid: /^596(\d{9})$/g,
        count: 3,
        code: '+596',
        country: 'MQ',
        name: 'Мартиника',
    },
    {
        mask: '+222-##-##-####',
        test: /222(\d{0,8})/g,
        valid: /^222(\d{8})$/g,
        count: 3,
        code: '+222',
        country: 'MR',
        name: 'Мавритания',
    },
    {
        mask: '+356-####-####',
        test: /356(\d{0,8})/g,
        valid: /^356(\d{8})$/g,
        count: 3,
        code: '+356',
        country: 'MT',
        name: 'Мальта',
    },
    {
        mask: '+230-###-####',
        test: /230(\d{0,7})/g,
        valid: /^230(\d{7})$/g,
        count: 3,
        code: '+230',
        country: 'MU',
        name: 'Маврикий',
    },
    {
        mask: '+960-###-####',
        test: /960(\d{0,7})/g,
        valid: /^960(\d{7})$/g,
        count: 3,
        code: '+960',
        country: 'MV',
        name: 'Мальдивские острова',
    },
    {
        mask: '+265-#-####-####',
        test: /265(\d{0,9})/g,
        valid: /^265(\d{9})$/g,
        count: 3,
        code: '+265',
        country: 'MW',
        name: 'Малави',
    },
    {
        mask: '+258-##-###-###',
        test: /258(\d{0,8})/g,
        valid: /^258(\d{8})$/g,
        count: 3,
        code: '+258',
        country: 'MZ',
        name: 'Мозамбик',
    },
    {
        mask: '+264-##-###-####',
        test: /264(\d{0,9})/g,
        valid: /^264(\d{9})$/g,
        count: 3,
        code: '+264',
        country: 'NA',
        name: 'Намибия',
    },
    {
        mask: '+687-##-####',
        test: /687(\d{0,6})/g,
        valid: /^687(\d{6})$/g,
        count: 3,
        code: '+687',
        country: 'NC',
        name: 'Новая Каледония',
    },
    {
        mask: '+227-##-##-####',
        test: /227(\d{0,8})/g,
        valid: /^227(\d{8})$/g,
        count: 3,
        code: '+227',
        country: 'NE',
        name: 'Нигер',
    },
    {
        mask: '+234(###)###-####',
        test: /234(\d{0,10})/g,
        valid: /^234(\d{10})$/g,
        count: 3,
        code: '+234',
        country: 'NG',
        name: 'Нигерия',
    },
    {
        mask: '+234-##-###-###',
        test: /234(\d{0,8})/g,
        valid: /^234(\d{8})$/g,
        count: 3,
        code: '+234',
        country: 'NG',
        name: 'Нигерия',
    },
    {
        mask: '+234-##-###-##',
        test: /234(\d{0,7})/g,
        valid: /^234(\d{7})$/g,
        count: 3,
        code: '+234',
        country: 'NG',
        name: 'Нигерия',
    },
    {
        mask: '+234(###)###-####',
        test: /234(\d{0,10})/g,
        valid: /^234(\d{10})$/g,
        count: 3,
        code: '+234',
        country: 'NG',
        name: 'Нигерия',
    },
    {
        mask: '+505-####-####',
        test: /505(\d{0,8})/g,
        valid: /^505(\d{8})$/g,
        count: 3,
        code: '+505',
        country: 'NI',
        name: 'Никарагуа',
    },
    {
        mask: '+977-##-###-###',
        test: /977(\d{0,8})/g,
        valid: /^977(\d{8})$/g,
        count: 3,
        code: '+977',
        country: 'NP',
        name: 'Непал',
    },
    {
        mask: '+674-###-####',
        test: /674(\d{0,7})/g,
        valid: /^674(\d{7})$/g,
        count: 3,
        code: '+674',
        country: 'NR',
        name: 'Науру',
    },
    {
        mask: '+683-####',
        test: /683(\d{0,4})/g,
        valid: /^683(\d{4})$/g,
        count: 3,
        code: '+683',
        country: 'NU',
        name: 'Ниуэ',
    },
    {
        mask: '+968-##-###-###',
        test: /968(\d{0,8})/g,
        valid: /^968(\d{8})$/g,
        count: 3,
        code: '+968',
        country: 'OM',
        name: 'Оман',
    },
    {
        mask: '+507-###-####',
        test: /507(\d{0,7})/g,
        valid: /^507(\d{7})$/g,
        count: 3,
        code: '+507',
        country: 'PA',
        name: 'Панама',
    },
    {
        mask: '+689-##-##-##',
        test: /689(\d{0,6})/g,
        valid: /^689(\d{6})$/g,
        count: 3,
        code: '+689',
        country: 'PF',
        name: 'Французская Полинезия (Таити)',
    },
    {
        mask: '+675(###)##-###',
        test: /675(\d{0,8})/g,
        valid: /^675(\d{8})$/g,
        count: 3,
        code: '+675',
        country: 'PG',
        name: 'Папуа-Новая Гвинея',
    },
    {
        mask: '+970-##-###-####',
        test: /970(\d{0,9})/g,
        valid: /^970(\d{9})$/g,
        count: 3,
        code: '+970',
        country: 'PS',
        name: 'Палестина',
    },
    {
        mask: '+351-##-###-####',
        test: /351(\d{0,9})/g,
        valid: /^351(\d{9})$/g,
        count: 3,
        code: '+351',
        country: 'PT',
        name: 'Португалия',
    },
    {
        mask: '+680-###-####',
        test: /680(\d{0,7})/g,
        valid: /^680(\d{7})$/g,
        count: 3,
        code: '+680',
        country: 'PW',
        name: 'Палау',
    },
    {
        mask: '+595(###)###-###',
        test: /595(\d{0,9})/g,
        valid: /^595(\d{9})$/g,
        count: 3,
        code: '+595',
        country: 'PY',
        name: 'Парагвай',
    },
    {
        mask: '+974-####-####',
        test: /974(\d{0,8})/g,
        valid: /^974(\d{8})$/g,
        count: 3,
        code: '+974',
        country: 'QA',
        name: 'Катар',
    },
    {
        mask: '+262-#####-####',
        test: /262(\d{0,9})/g,
        valid: /^262(\d{9})$/g,
        count: 3,
        code: '+262',
        country: 'RE',
        name: 'Реюньон',
    },
    {
        mask: '+381-##-###-####',
        test: /381(\d{0,9})/g,
        valid: /^381(\d{9})$/g,
        count: 3,
        code: '+381',
        country: 'RS',
        name: 'Сербия',
    },
    {
        mask: '+250(###)###-###',
        test: /250(\d{0,9})/g,
        valid: /^250(\d{9})$/g,
        count: 3,
        code: '+250',
        country: 'RW',
        name: 'Руанда',
    },
    {
        mask: '+966-#-###-####',
        test: /966(\d{0,8})/g,
        valid: /^966(\d{8})$/g,
        count: 3,
        code: '+966',
        country: 'SA',
        name: 'Саудовская Аравия',
    },
    {
        mask: '+677-###-####',
        test: /677(\d{0,7})/g,
        valid: /^677(\d{7})$/g,
        count: 3,
        code: '+677',
        country: 'SB',
        name: 'Соломоновы Острова',
    },
    {
        mask: '+677-#####',
        test: /677(\d{0,5})/g,
        valid: /^677(\d{5})$/g,
        count: 3,
        code: '+677',
        country: 'SB',
        name: 'Соломоновы Острова',
    },
    {
        mask: '+248-#-###-###',
        test: /248(\d{0,7})/g,
        valid: /^248(\d{7})$/g,
        count: 3,
        code: '+248',
        country: 'SC',
        name: 'Сейшелы',
    },
    {
        mask: '+249-##-###-####',
        test: /249(\d{0,9})/g,
        valid: /^249(\d{9})$/g,
        count: 3,
        code: '+249',
        country: 'SD',
        name: 'Судан',
    },
    {
        mask: '+290-####',
        test: /290(\d{0,4})/g,
        valid: /^290(\d{4})$/g,
        count: 3,
        code: '+290',
        country: 'SH',
        name: 'Остров Святой Елены',
    },
    {
        mask: '+290-####',
        test: /290(\d{0,4})/g,
        valid: /^290(\d{4})$/g,
        count: 3,
        code: '+290',
        country: 'SH',
        name: 'Тристан-да-Кунья',
    },
    {
        mask: '+386-##-###-###',
        test: /386(\d{0,8})/g,
        valid: /^386(\d{8})$/g,
        count: 3,
        code: '+386',
        country: 'SI',
        name: 'Словения',
    },
    {
        mask: '+421(###)###-###',
        test: /421(\d{0,9})/g,
        valid: /^421(\d{9})$/g,
        count: 3,
        code: '+421',
        country: 'SK',
        name: 'Словакия',
    },
    {
        mask: '+232-##-######',
        test: /232(\d{0,8})/g,
        valid: /^232(\d{8})$/g,
        count: 3,
        code: '+232',
        country: 'SL',
        name: 'Сьерра-Леоне',
    },
    {
        mask: '+378-####-######',
        test: /378(\d{0,10})/g,
        valid: /^378(\d{10})$/g,
        count: 3,
        code: '+378',
        country: 'SM',
        name: 'Сан-Марино',
    },
    {
        mask: '+221-##-###-####',
        test: /221(\d{0,9})/g,
        valid: /^221(\d{9})$/g,
        count: 3,
        code: '+221',
        country: 'SN',
        name: 'Сенегал',
    },
    {
        mask: '+252-##-###-###',
        test: /252(\d{0,8})/g,
        valid: /^252(\d{8})$/g,
        count: 3,
        code: '+252',
        country: 'SO',
        name: 'Сомали',
    },
    {
        mask: '+252-#-###-###',
        test: /252(\d{0,7})/g,
        valid: /^252(\d{7})$/g,
        count: 3,
        code: '+252',
        country: 'SO',
        name: 'Сомали',
    },
    {
        mask: '+252-#-###-###',
        test: /252(\d{0,7})/g,
        valid: /^252(\d{7})$/g,
        count: 3,
        code: '+252',
        country: 'SO',
        name: 'Сомали',
    },
    {
        mask: '+597-###-####',
        test: /597(\d{0,7})/g,
        valid: /^597(\d{7})$/g,
        count: 3,
        code: '+597',
        country: 'SR',
        name: 'Суринам',
    },
    {
        mask: '+597-###-###',
        test: /597(\d{0,6})/g,
        valid: /^597(\d{6})$/g,
        count: 3,
        code: '+597',
        country: 'SR',
        name: 'Суринам',
    },
    {
        mask: '+211-##-###-####',
        test: /211(\d{0,9})/g,
        valid: /^211(\d{9})$/g,
        count: 3,
        code: '+211',
        country: 'SS',
        name: 'Южный Судан',
    },
    {
        mask: '+239-##-#####',
        test: /239(\d{0,7})/g,
        valid: /^239(\d{7})$/g,
        count: 3,
        code: '+239',
        country: 'ST',
        name: 'Сан-Томе и Принсипи',
    },
    {
        mask: '+503-##-##-####',
        test: /503(\d{0,8})/g,
        valid: /^503(\d{8})$/g,
        count: 3,
        code: '+503',
        country: 'SV',
        name: 'Сальвадор',
    },
    {
        mask: '+963-##-####-###',
        test: /963(\d{0,9})/g,
        valid: /^963(\d{9})$/g,
        count: 3,
        code: '+963',
        country: 'SY',
        name: 'Сирийская арабская республика',
    },
    {
        mask: '+268-##-##-####',
        test: /268(\d{0,8})/g,
        valid: /^268(\d{8})$/g,
        count: 3,
        code: '+268',
        country: 'SZ',
        name: 'Свазиленд',
    },
    {
        mask: '+235-##-##-##-##',
        test: /235(\d{0,8})/g,
        valid: /^235(\d{8})$/g,
        count: 3,
        code: '+235',
        country: 'TD',
        name: 'Чад',
    },
    {
        mask: '+228-##-###-###',
        test: /228(\d{0,8})/g,
        valid: /^228(\d{8})$/g,
        count: 3,
        code: '+228',
        country: 'TG',
        name: 'Того',
    },
    {
        mask: '+992-##-###-####',
        test: /992(\d{0,9})/g,
        valid: /^992(\d{9})$/g,
        count: 3,
        code: '+992',
        country: 'TJ',
        name: 'Таджикистан',
    },
    {
        mask: '+690-####',
        test: /690(\d{0,4})/g,
        valid: /^690(\d{4})$/g,
        count: 3,
        code: '+690',
        country: 'TK',
        name: 'Токелау',
    },
    {
        mask: '+670-###-####',
        test: /670(\d{0,7})/g,
        valid: /^670(\d{7})$/g,
        count: 3,
        code: '+670',
        country: 'TL',
        name: 'Восточный Тимор',
    },
    {
        mask: '+993-#-###-####',
        test: /993(\d{0,8})/g,
        valid: /^993(\d{8})$/g,
        count: 3,
        code: '+993',
        country: 'TM',
        name: 'Туркменистан',
    },
    {
        mask: '+216-##-###-###',
        test: /216(\d{0,8})/g,
        valid: /^216(\d{8})$/g,
        count: 3,
        code: '+216',
        country: 'TN',
        name: 'Тунис',
    },
    {
        mask: '+676-#####',
        test: /676(\d{0,5})/g,
        valid: /^676(\d{5})$/g,
        count: 3,
        code: '+676',
        country: 'TO',
        name: 'Тонга',
    },
    {
        mask: '+886-#-####-####',
        test: /886(\d{0,9})/g,
        valid: /^886(\d{9})$/g,
        count: 3,
        code: '+886',
        country: 'TW',
        name: 'Тайвань',
    },
    {
        mask: '+886-####-####',
        test: /886(\d{0,8})/g,
        valid: /^886(\d{8})$/g,
        count: 3,
        code: '+886',
        country: 'TW',
        name: 'Тайвань',
    },
    {
        mask: '+255-##-###-####',
        test: /255(\d{0,9})/g,
        valid: /^255(\d{9})$/g,
        count: 3,
        code: '+255',
        country: 'TZ',
        name: 'Танзания',
    },
    {
        mask: '+380(##)###-##-##',
        test: /380(\d{0,9})/g,
        valid: /^380(\d{9})$/g,
        count: 3,
        code: '+380',
        country: 'UA',
        name: 'Украина',
    },
    {
        mask: '+256(###)###-###',
        test: /256(\d{0,9})/g,
        valid: /^256(\d{9})$/g,
        count: 3,
        code: '+256',
        country: 'UG',
        name: 'Уганда',
    },
    {
        mask: '+598-#-###-##-##',
        test: /598(\d{0,8})/g,
        valid: /^598(\d{8})$/g,
        count: 3,
        code: '+598',
        country: 'UY',
        name: 'Уругвай',
    },
    {
        mask: '+998-##-###-####',
        test: /998(\d{0,9})/g,
        valid: /^998(\d{9})$/g,
        count: 3,
        code: '+998',
        country: 'UZ',
        name: 'Узбекистан',
    },
    {
        mask: '+678-##-#####',
        test: /678(\d{0,7})/g,
        valid: /^678(\d{7})$/g,
        count: 3,
        code: '+678',
        country: 'VU',
        name: 'Вануату',
    },
    {
        mask: '+678-#####',
        test: /678(\d{0,5})/g,
        valid: /^678(\d{5})$/g,
        count: 3,
        code: '+678',
        country: 'VU',
        name: 'Вануату',
    },
    {
        mask: '+681-##-####',
        test: /681(\d{0,6})/g,
        valid: /^681(\d{6})$/g,
        count: 3,
        code: '+681',
        country: 'WF',
        name: 'Уоллис и Футуна',
    },
    {
        mask: '+685-##-####',
        test: /685(\d{0,6})/g,
        valid: /^685(\d{6})$/g,
        count: 3,
        code: '+685',
        country: 'WS',
        name: 'Самоа',
    },
    {
        mask: '+967-###-###-###',
        test: /967(\d{0,9})/g,
        valid: /^967(\d{9})$/g,
        count: 3,
        code: '+967',
        country: 'YE',
        name: 'Йемен',
    },
    {
        mask: '+967-#-###-###',
        test: /967(\d{0,7})/g,
        valid: /^967(\d{7})$/g,
        count: 3,
        code: '+967',
        country: 'YE',
        name: 'Йемен',
    },
    {
        mask: '+967-##-###-###',
        test: /967(\d{0,8})/g,
        valid: /^967(\d{8})$/g,
        count: 3,
        code: '+967',
        country: 'YE',
        name: 'Йемен',
    },
    {
        mask: '+260-##-###-####',
        test: /260(\d{0,9})/g,
        valid: /^260(\d{9})$/g,
        count: 3,
        code: '+260',
        country: 'ZM',
        name: 'Замбия',
    },
    {
        mask: '+263-#-######',
        test: /263(\d{0,7})/g,
        valid: /^263(\d{7})$/g,
        count: 3,
        code: '+263',
        country: 'ZW',
        name: 'Зимбабве',
    },
    {
        mask: '+93-##-###-####',
        test: /93(\d{0,9})/g,
        valid: /^93(\d{9})$/g,
        count: 2,
        code: '+93',
        country: 'AF',
        name: 'Афганистан',
    },
    {
        mask: '+54(###)###-####',
        test: /54(\d{0,10})/g,
        valid: /^54(\d{10})$/g,
        count: 2,
        code: '+54',
        country: 'AR',
        name: 'Аргентина',
    },
    {
        mask: '+43(###)###-#####',
        test: /43(\d{0,11})/g,
        valid: /^43(\d{11})$/g,
        count: 2,
        code: '+43',
        country: 'AT',
        name: 'Австрия',
    },
    {
        mask: '+61-#-####-####',
        test: /61(\d{0,9})/g,
        valid: /^61(\d{9})$/g,
        count: 2,
        code: '+61',
        country: 'AU',
        name: 'Австралия',
    },
    {
        mask: '+32(###)###-###',
        test: /32(\d{0,9})/g,
        valid: /^32(\d{9})$/g,
        count: 2,
        code: '+32',
        country: 'BE',
        name: 'Бельгия',
    },
    {
        mask: '+55(##)####-####',
        test: /55(\d{0,10})/g,
        valid: /^55(\d{10})$/g,
        count: 2,
        code: '+55',
        country: 'BR',
        name: 'Бразилия',
    },
    {
        mask: '+41-##-###-####',
        test: /41(\d{0,9})/g,
        valid: /^41(\d{9})$/g,
        count: 2,
        code: '+41',
        country: 'CH',
        name: 'Швейцария',
    },
    {
        mask: '+56-#-####-####',
        test: /56(\d{0,9})/g,
        valid: /^56(\d{9})$/g,
        count: 2,
        code: '+56',
        country: 'CL',
        name: 'Чили',
    },
    {
        mask: '+86(###)####-####',
        test: /86(\d{0,11})/g,
        valid: /^86(\d{11})$/g,
        count: 2,
        code: '+86',
        country: 'CN',
        name: 'Китайская Н.Р.',
    },
    {
        mask: '+86(###)####-###',
        test: /86(\d{0,10})/g,
        valid: /^86(\d{10})$/g,
        count: 2,
        code: '+86',
        country: 'CN',
        name: 'Китайская Н.Р.',
    },
    {
        mask: '+86-##-#####-#####',
        test: /86(\d{0,12})/g,
        valid: /^86(\d{12})$/g,
        count: 2,
        code: '+86',
        country: 'CN',
        name: 'Китайская Н.Р.',
    },
    {
        mask: '+57(###)###-####',
        test: /57(\d{0,10})/g,
        valid: /^57(\d{10})$/g,
        count: 2,
        code: '+57',
        country: 'CO',
        name: 'Колумбия',
    },
    {
        mask: '+53-#-###-####',
        test: /53(\d{0,8})/g,
        valid: /^53(\d{8})$/g,
        count: 2,
        code: '+53',
        country: 'CU',
        name: 'Куба',
    },
    {
        mask: '+49(####)###-####',
        test: /49(\d{0,11})/g,
        valid: /^49(\d{6,11})$/g,
        count: 2,
        code: '+49',
        country: 'DE',
        name: 'Германия',
    },
    {
        mask: '+49(###)###-####',
        test: /49(\d{0,10})/g,
        valid: /^49(\d{10})$/g,
        count: 2,
        code: '+49',
        country: 'DE',
        name: 'Германия',
    },
    {
        mask: '+49(###)##-####',
        test: /49(\d{0,9})/g,
        valid: /^49(\d{9})$/g,
        count: 2,
        code: '+49',
        country: 'DE',
        name: 'Германия',
    },
    {
        mask: '+49(###)##-###',
        test: /49(\d{0,8})/g,
        valid: /^49(\d{8})$/g,
        count: 2,
        code: '+49',
        country: 'DE',
        name: 'Германия',
    },
    {
        mask: '+49(###)##-##',
        test: /49(\d{0,7})/g,
        valid: /^49(\d{7})$/g,
        count: 2,
        code: '+49',
        country: 'DE',
        name: 'Германия',
    },
    {
        mask: '+49-###-###',
        test: /49(\d{0,6})/g,
        valid: /^49(\d{6})$/g,
        count: 2,
        code: '+49',
        country: 'DE',
        name: 'Германия',
    },
    {
        mask: '+45-##-##-##-##',
        test: /45(\d{0,8})/g,
        valid: /^45(\d{8})$/g,
        count: 2,
        code: '+45',
        country: 'DK',
        name: 'Дания',
    },
    {
        mask: '+20(###)###-####',
        test: /20(\d{0,10})/g,
        valid: /^20(\d{10})$/g,
        count: 2,
        code: '+20',
        country: 'EG',
        name: 'Египет',
    },
    {
        mask: '+34(###)###-###',
        test: /34(\d{0,9})/g,
        valid: /^34(\d{9})$/g,
        count: 2,
        code: '+34',
        country: 'ES',
        name: 'Испания',
    },
    {
        mask: '+33(###)###-###',
        test: /33(\d{0,9})/g,
        valid: /^33(\d{9})$/g,
        count: 2,
        code: '+33',
        country: 'FR',
        name: 'Франция',
    },
    {
        mask: '+30(###)###-####',
        test: /30(\d{0,10})/g,
        valid: /^30(\d{10})$/g,
        count: 2,
        code: '+30',
        country: 'GR',
        name: 'Греция',
    },
    {
        mask: '+36(###)###-###',
        test: /36(\d{0,9})/g,
        valid: /^36(\d{9})$/g,
        count: 2,
        code: '+36',
        country: 'HU',
        name: 'Венгрия',
    },
    {
        mask: '+62-##-###-##',
        test: /62(\d{0,7})/g,
        valid: /^62(\d{7})$/g,
        count: 2,
        code: '+62',
        country: 'ID',
        name: 'Индонезия',
    },
    {
        mask: '+62-##-###-###',
        test: /62(\d{0,8})/g,
        valid: /^62(\d{8})$/g,
        count: 2,
        code: '+62',
        country: 'ID',
        name: 'Индонезия',
    },
    {
        mask: '+62-##-###-####',
        test: /62(\d{0,9})/g,
        valid: /^62(\d{9})$/g,
        count: 2,
        code: '+62',
        country: 'ID',
        name: 'Индонезия',
    },
    {
        mask: '+91(####)###-###',
        test: /91(\d{0,10})/g,
        valid: /^91(\d{10})$/g,
        count: 2,
        code: '+91',
        country: 'IN',
        name: 'Индия',
    },
    {
        mask: '+98(###)###-####',
        test: /98(\d{0,10})/g,
        valid: /^98(\d{10})$/g,
        count: 2,
        code: '+98',
        country: 'IR',
        name: 'Иран',
    },
    {
        mask: '+39(###)####-###',
        test: /39(\d{0,10})/g,
        valid: /^39(\d{10})$/g,
        count: 2,
        code: '+39',
        country: 'IT',
        name: 'Италия',
    },
    {
        mask: '+81-##-####-####',
        test: /81(\d{0,10})/g,
        valid: /^81(\d{10})$/g,
        count: 2,
        code: '+81',
        country: 'JP',
        name: 'Япония',
    },
    {
        mask: '+81(###)###-###',
        test: /81(\d{0,9})/g,
        valid: /^81(\d{9})$/g,
        count: 2,
        code: '+81',
        country: 'JP',
        name: 'Япония',
    },
    {
        mask: '+82-##-###-#####',
        test: /82(\d{0,10})/g,
        valid: /^82(\d{9,10})$/g,
        count: 2,
        code: '+82',
        country: 'KR',
        name: 'Респ. Корея',
    },
    {
        mask: '+7(###)###-##-##',
        pretest: true,
        precode: '+7',
        test: /77(\d{0,9})/g,
        valid: /^77([0124567][0-8]\d{7})$/g,
        count: 1,
        code: '+7',
        country: 'KZ',
        name: 'Казахстан',
    },
    {
        mask: '+94-##-###-####',
        test: /94(\d{0,9})/g,
        valid: /^94(\d{9})$/g,
        count: 2,
        code: '+94',
        country: 'LK',
        name: 'Шри-Ланка',
    },
    {
        mask: '+95-##-###-###',
        test: /95(\d{0,8})/g,
        valid: /^95(\d{8})$/g,
        count: 2,
        code: '+95',
        country: 'MM',
        name: 'Бирма (Мьянма)',
    },
    {
        mask: '+95-#-###-###',
        test: /95(\d{0,7})/g,
        valid: /^95(\d{7})$/g,
        count: 2,
        code: '+95',
        country: 'MM',
        name: 'Бирма (Мьянма)',
    },
    {
        mask: '+95-###-###',
        test: /95(\d{0,6})/g,
        valid: /^95(\d{6})$/g,
        count: 2,
        code: '+95',
        country: 'MM',
        name: 'Бирма (Мьянма)',
    },
    {
        mask: '+52(###)###-####',
        test: /52(\d{0,10})/g,
        valid: /^52(\d{10})$/g,
        count: 2,
        code: '+52',
        country: 'MX',
        name: 'Мексика',
    },
    {
        mask: '+52-##-##-####',
        test: /52(\d{0,8})/g,
        valid: /^52(\d{8})$/g,
        count: 2,
        code: '+52',
        country: 'MX',
        name: 'Мексика',
    },
    {
        mask: '+60-##-###-####',
        test: /60(\d{0,9})/g,
        valid: /^60(\d{9})$/g,
        count: 2,
        code: '+60',
        country: 'MY',
        name: 'Малайзия',
    },
    {
        mask: '+60(###)###-###',
        test: /60(\d{0,9})/g,
        valid: /^60(\d{9})$/g,
        count: 2,
        code: '+60',
        country: 'MY',
        name: 'Малайзия',
    },
    {
        mask: '+60-##-###-###',
        test: /60(\d{0,8})/g,
        valid: /^60(\d{8})$/g,
        count: 2,
        code: '+60',
        country: 'MY',
        name: 'Малайзия',
    },
    {
        mask: '+60-#-###-###',
        test: /60(\d{0,7})/g,
        valid: /^60(\d{7})$/g,
        count: 2,
        code: '+60',
        country: 'MY',
        name: 'Малайзия',
    },
    {
        mask: '+31-##-###-####',
        test: /31(\d{0,9})/g,
        valid: /^31(\d{9})$/g,
        count: 2,
        code: '+31',
        country: 'NL',
        name: 'Нидерланды',
    },
    {
        mask: '+47(###)##-###',
        test: /47(\d{0,8})/g,
        valid: /^47(\d{8})$/g,
        count: 2,
        code: '+47',
        country: 'NO',
        name: 'Норвегия',
    },
    {
        mask: '+64(###)###-###',
        test: /64(\d{0,9})/g,
        valid: /^64(\d{9})$/g,
        count: 2,
        code: '+64',
        country: 'NZ',
        name: 'Новая Зеландия',
    },
    {
        mask: '+64-##-###-###',
        test: /64(\d{0,8})/g,
        valid: /^64(\d{8})$/g,
        count: 2,
        code: '+64',
        country: 'NZ',
        name: 'Новая Зеландия',
    },
    {
        mask: '+64(###)###-####',
        test: /64(\d{0,10})/g,
        valid: /^64(\d{10})$/g,
        count: 2,
        code: '+64',
        country: 'NZ',
        name: 'Новая Зеландия',
    },
    {
        mask: '+51(###)###-###',
        test: /51(\d{0,9})/g,
        valid: /^51(\d{9})$/g,
        count: 2,
        code: '+51',
        country: 'PE',
        name: 'Перу',
    },
    {
        mask: '+63(###)###-####',
        test: /63(\d{0,10})/g,
        valid: /^63(\d{10})$/g,
        count: 2,
        code: '+63',
        country: 'PH',
        name: 'Филиппины',
    },
    {
        mask: '+92(###)###-####',
        test: /92(\d{0,10})/g,
        valid: /^92(\d{10})$/g,
        count: 2,
        code: '+92',
        country: 'PK',
        name: 'Пакистан',
    },
    {
        mask: '+48(###)###-###',
        test: /48(\d{0,9})/g,
        valid: /^48(\d{9})$/g,
        count: 2,
        code: '+48',
        country: 'PL',
        name: 'Польша',
    },
    {
        mask: '+40-##-###-####',
        test: /40(\d{0,9})/g,
        valid: /^40(\d{9})$/g,
        count: 2,
        code: '+40',
        country: 'RO',
        name: 'Румыния',
    },
    {
        mask: '+46-##-###-####',
        test: /46(\d{0,9})/g,
        valid: /^46(\d{9})$/g,
        count: 2,
        code: '+46',
        country: 'SE',
        name: 'Швеция',
    },
    {
        mask: '+65-####-####',
        test: /65(\d{0,8})/g,
        valid: /^65(\d{8})$/g,
        count: 2,
        code: '+65',
        country: 'SG',
        name: 'Сингапур',
    },
    {
        mask: '+66-##-###-####',
        test: /66(\d{0,9})/g,
        valid: /^66(\d{9})$/g,
        count: 2,
        code: '+66',
        country: 'TH',
        name: 'Таиланд',
    },
    {
        mask: '+66-##-###-###',
        test: /66(\d{0,8})/g,
        valid: /^66(\d{8})$/g,
        count: 2,
        code: '+66',
        country: 'TH',
        name: 'Таиланд',
    },
    {
        mask: '+90(###)###-####',
        test: /90(\d{0,10})/g,
        valid: /^90(\d{10})$/g,
        count: 2,
        code: '+90',
        country: 'TR',
        name: 'Турция',
    },
    {
        mask: '+44-##-####-####',
        test: /44(\d{0,10})/g,
        valid: /^44(\d{10})$/g,
        count: 2,
        code: '+44',
        country: 'GB',
        name: 'Великобритания',
    },
    {
        mask: '+58(###)###-####',
        test: /58(\d{0,10})/g,
        valid: /^58(\d{10})$/g,
        count: 2,
        code: '+58',
        country: 'VE',
        name: 'Венесуэла',
    },
    {
        mask: '+84-##-####-###',
        test: /84(\d{0,9})/g,
        valid: /^84(\d{9})$/g,
        count: 2,
        code: '+84',
        country: 'VN',
        name: 'Вьетнам',
    },
    {
        mask: '+84(###)####-###',
        test: /84(\d{0,10})/g,
        valid: /^84(\d{10})$/g,
        count: 2,
        code: '+84',
        country: 'VN',
        name: 'Вьетнам',
    },
    {
        mask: '+27-##-###-####',
        test: /27(\d{0,9})/g,
        valid: /^27(\d{9})$/g,
        count: 2,
        code: '+27',
        country: 'ZA',
        name: 'Южно-Африканская Респ.',
    },
    {
        mask: '+7(###)###-##-##',
        pretest: true,
        precode: '+7',
        test: /7([12345689])(\d{0,9})/g,
        valid: /^7([12345689])(\d{9})$/g,
        count: 1,
        code: '+7',
        country: 'RU',
        name: 'Россия',
    },
    {
        areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
        mask: '+1(###)###-####',
        test: /1(\d{0,10})/g,
        valid: /^1(\d{10})$/g,
        count: 1,
        code: '+1',
        country: 'CA',
        name: 'Канада',
    },
    {
        mask: '+1(###)###-####',
        test: /1(\d{0,10})/g,
        valid: /^1(\d{10})$/g,
        count: 1,
        code: '+1',
        country: 'US',
        name: 'США',
    },
];
