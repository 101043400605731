import { documentType } from 'data';
import {
    ascend,
    compose,
    filter,
    includes,
    keys,
    map,
    mapObjIndexed,
    mergeDeepLeft,
    not,
    path,
    pathOr,
    pick,
    pluck,
    prop,
    propOr,
    reject,
    sortWith,
    values,
} from 'ramda';
import { createSelector } from 'reselect';
import { isOver14YearsOld } from 'utils/date';
import { isFading } from 'utils/fadingTime';
import fieldsByType from 'utils/fieldsByType';

import {
    getWarningKey,
    prepareDocumentData,
    shouldShowWarning,
} from './helpers';

import { getBirthdate, getIsShortProfile } from '../profile';

const passportFields = [
    documentType.INTERNAL_PASSPORT,
    documentType.INTERNATIONAL_PASSPORT,
];

export const getDocumentsIds = (options = {}) =>
    createSelector(
        state => state.documents.itemsById,
        getBirthdate,
        getIsShortProfile,
        (state, docsType = []) => docsType,
        (ids, birthday, isShortProfile, docsType) =>
            compose(
                pluck('id'),
                sortWith([
                    ascend(prop('sort')),
                    (a, b) => {
                        if (a.isExpired !== b.isExpired) {
                            return a.isExpired ? 1 : -1;
                        }

                        if (a.isExpired) {
                            return b.expireTimestamp - a.expireTimestamp;
                        }

                        return a.expireTimestamp - b.expireTimestamp;
                    },
                ]),
                reject(prop('isDeleting')),
                values,
                filter(item => {
                    if (options.onlyExpiredDate) {
                        return (
                            includes(item.type, docsType || []) &&
                            item.isExpired
                        );
                    }

                    if (options.onlyNotExpiredDate) {
                        return (
                            includes(item.type, docsType || []) &&
                            not(item.isExpired)
                        );
                    }

                    return includes(item.type, docsType || []);
                }),
                map(item =>
                    prepareDocumentData(item, { birthday, isShortProfile }),
                ),
            )(ids),
    );

export const getDocument = createSelector(
    id => id,
    (id, state) => state.documents.itemsById,
    (id, state) => getBirthdate(state),
    (id, state) => getIsShortProfile(state),
    (id, ids, birthday, isShortProfile) =>
        prepareDocumentData(ids[id], { birthday, isShortProfile }),
);

export const getDocumentsEdit = state => state.documents.isEdit;
export const getIsEdit = (id, state) => includes(id, state.documents.isEdit);

export const getNeedConfirmForRemove = (id, state) =>
    includes(id, state.documents.needConfirmForRemove);
export const getRemovingStatus = (id, state) =>
    path(['documents', 'itemsById', id, 'removingStatus'], state);

export const getWarnings = createSelector(
    state => pathOr({}, ['documents', 'itemsById'], state),
    state => pathOr({}, ['documents', 'warningsById'], state),
    (docs, warnings) =>
        compose(
            filter(({ type }) => {
                let hasNotExpiredType = false;

                mapObjIndexed(doc => {
                    if (
                        prop('type', doc) === type &&
                        !prop('isExpired', doc) &&
                        !warnings[prop('id', doc)]
                    ) {
                        hasNotExpiredType = true;
                    }
                }, docs);

                return !hasNotExpiredType;
            }),
            filter(
                doc =>
                    not(prop('isClosed', doc)) &&
                    shouldShowWarning(prop('shownAt', doc)),
            ),
            map(id =>
                mergeDeepLeft(
                    pathOr(
                        {},
                        [id, getWarningKey(prop(id, warnings))],
                        warnings,
                    ),
                    pick(
                        [
                            'type',
                            'number',
                            'series',
                            'expirationDate',
                            'id',
                            'status',
                        ],
                        propOr({}, id, docs),
                    ),
                ),
            ),
        )(keys(warnings)),
);

export const getIsDocsShowNewForm = (state, typeForm) =>
    state.documents[typeForm];

export const isInitialized = state => state.documents.isInitialized;
export const isLoaded = state => isFading(state.documents.fadingTime);
export const isLoading = state => state.documents.isLoading;
export const isError = state => state.documents.isError;

export const getInitialValues = createSelector(
    doc => doc,
    doc => {
        // eslint-disable-line complexity
        const rusCountryCode = 'RU';
        const isPassport = passportFields.includes(doc.type);
        const isBirthCertificate = doc.type === documentType.BIRTH_CERTIFICATE;
        const isRuCountry = isPassport || isBirthCertificate;

        return {
            ...doc,
            countryOfIssue:
                (isRuCountry ? rusCountryCode : doc.countryOfIssue) || '',
            seriesNumber: {
                number: doc.number,
                series: doc.series,
            },
        };
    },
);

export const getDocFormValues = createSelector(
    ({ type }) => type,
    ({ countryOfIssue }) => countryOfIssue || '',
    ({ number }) => number || '',
    ({ series }) => series || '',
    ({ dateOfIssue }) => dateOfIssue || '',
    ({ startDate }) => startDate || '',
    ({ destinationCountry }) => destinationCountry || '',
    ({ birthCountry }) => birthCountry || '',
    ({ placeOfBirth }) => placeOfBirth || '',
    (
        type,
        countryOfIssue,
        number,
        series,
        dateOfIssue,
        startDate,
        destinationCountry,
        birthCountry,
        placeOfBirth,
    ) => ({
        birthCountry,
        countryOfIssue,
        dateOfIssue,
        destinationCountry,
        number,
        placeOfBirth,
        series,
        startDate,
        type,
    }),
);

export const fieldsSelector = createSelector(
    type => type,
    type => ['type', 'countryOfIssue', ...fieldsByType[type]],
);
export const getCounter = (state, docsType) =>
    getDocumentsIds()(state, docsType).length;

export const getDocsIsArchiveActive = state =>
    pathOr(false, ['documents', 'isArchiveActive', 'documents'], state);
export const getVisaIsArchiveActive = state =>
    pathOr(false, ['documents', 'isArchiveActive', 'visa'], state);

export const getIsOver14YearsOld = createSelector(
    birthday => birthday,
    birthday => isOver14YearsOld(birthday),
);

export const getNewDocumentValues = createSelector(
    isVisa => isVisa,
    (isVisa, birthday) => birthday,
    (isVisa, birthday) => getIsOver14YearsOld(birthday),
    (isVisa, birthday, isOver14Years) => {
        if (isVisa) {
            return {
                type: documentType.VISA,
            };
        }

        return {
            type:
                !birthday || isOver14Years
                    ? documentType.INTERNAL_PASSPORT
                    : documentType.BIRTH_CERTIFICATE,
        };
    },
);
