import { useEffect, useState } from 'react';

const isDev = () =>
    process && process.env && process.env.NODE_ENV === 'development';

function checkBreakpoint(value, target) {
    if (!value || !target.value) {
        return false;
    }

    if (!target.allowedValues.includes(value)) {
        if (isDev()) {
            // eslint-disable-next-line no-console
            console.warn(
                `invalid breakpoint '${value}' received, expected one of [${target.allowedValues.join(
                    ', ',
                )}].`,
            );
        }

        return false;
    }

    return true;
}

function withCheck(cb) {
    return function withCheckInner(value) {
        if (!checkBreakpoint(value, this)) {
            return null;
        }

        return cb(value, this);
    };
}

const up = (value, target) => target[target.value].score > target[value].score;
const down = (value, target) =>
    target[target.value].score < target[value].score;
const only = (value, target) => target.value === value;

const { freeze } = Object;

/* eslint-disable sort-keys */
const refBreakpoint = {
    xs: freeze({ min: 0, max: 767, score: 1 }),
    sm: freeze({ min: 768, max: 959, score: 2 }),
    md: freeze({ min: 960, max: 1279, score: 3 }),
    lg: freeze({ min: 1280, score: 4 }),
    allowedValues: ['xs', 'sm', 'md', 'lg'],

    up: withCheck(up),
    down: withCheck(down),
    only: withCheck(only),
    between(from, to) {
        if (!checkBreakpoint(from, this) || !checkBreakpoint(to, this)) {
            return false;
        }

        if (this[from].score >= this[to].score) {
            if (isDev()) {
                // eslint-disable-next-line no-console
                console.warn(
                    `first breakpoint must be less than second one, got [${from} - ${to}]`,
                );
            }

            return false;
        }

        return this.up(from) && this.down(to);
    },
    value: null,
};
/* eslint-enable sort-keys */

const getBreakpoint = width => {
    const { xs, sm, md } = refBreakpoint;

    if (width <= xs.max) {
        return 'xs';
    }
    if (width >= sm.min && width <= sm.max) {
        return 'sm';
    }
    if (width >= md.min && width <= md.max) {
        return 'md';
    }

    return 'lg';
};

export function useBreakpoint() {
    const isClient = typeof window === 'object';

    const [breakpoint, setBreakpoint] = useState(refBreakpoint);

    if (isClient && !breakpoint.value) {
        breakpoint.value = getBreakpoint(window.innerWidth);
    }

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            if (getBreakpoint(window.innerWidth) !== breakpoint.value) {
                breakpoint.value = getBreakpoint(window.innerWidth);
                setBreakpoint({ ...breakpoint });
            }
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [breakpoint, isClient]);

    return breakpoint;
}
