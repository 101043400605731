import { propOr } from 'ramda';
import { createSelector } from 'reselect';
import { isFading } from 'utils/fadingTime';

export const isErrorTravelers = state => state.travelers.isError;
export const isFetchTravelers = state => state.travelers.isFetch;
export const isInitializedTravelers = state => state.travelers.isInitialized;
export const isLoadedTravelers = state => isFading(state.travelers.fadingTime);
export const isLoadingTravelers = state => state.travelers.isLoading;

export const getTravelers = state => state.travelers.list;
export const getHasTravelers = state => getTravelers(state).length > 0;
export const getErrors = state => state.travelers.errors;

export const getPage = state => state.travelers.page;
export const getSize = state => state.travelers.size;
export const getTotal = state => state.travelers.totalElements;

export const getLeftTravelers = createSelector(
    getPage,
    getSize,
    getTotal,
    (page, size, total) => total - page * size,
);

export const getHasMoreTravelers = state => state.travelers.hasMore;

export const getTraveler = createSelector(
    id => id,
    (id, state) => state.travelers.items,
    (id, items) => propOr({}, id, items),
);

export const getTravelerStatusView = createSelector(
    id => id,
    (id, state) => state.travelers.statusView,
    (id, status) =>
        propOr(
            {
                item: '',
                name: '',
                section: '',
            },
            id,
            status,
        ),
);

export const getTravelerStatusRemove = createSelector(
    id => id,
    (id, state) => state.travelers.statusRemove,
    (id, status) => propOr('', id, status),
);
