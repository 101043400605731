// Urdu
module.exports = [
    ['ٹ', 't'],
    ['ڈ', 'd'],
    ['ڑ', 'r'],
    ['ں', 'n'],
    ['ہ', 'h'],
    ['ھ', 'h'],
    ['ے', 'e'],
];
