import { isClient, isFunc } from '../utils';

const parseHash = () => {
    const { hash } = window.location;

    if (!hash || hash === '#contacts') {
        return [];
    }

    return hash.replace(/#/g, '').split(',');
};

class UrlStateService {
    ids = Object.create(null);

    registerListener() {
        if (isClient && window.addEventListener) {
            window.addEventListener('hashchange', this.hashChangeHandler);
        }
    }

    unregisterListener() {
        if (isClient && window.removeEventListener) {
            window.removeEventListener('hashchange', this.hashChangeHandler);
        }
    }

    checkListenerForClear() {
        if (!Object.keys(this.ids).length) {
            this.unregisterListener();
        }
    }

    hashChangeHandler = () => {
        const currentHash = parseHash();

        Object.entries(this.ids).forEach(([key, { onRemove }]) => {
            if (!currentHash.includes(key)) {
                if (isFunc(onRemove)) {
                    onRemove();
                }

                delete this.ids[key];
            }
        });

        this.checkListenerForClear();
    };

    set(key, { onRemove } = {}) {
        if (Object.keys(this.ids).length) {
            this.registerListener();
        }

        this.ids[key] = { id: key, onRemove };

        const currentHash = parseHash();

        if (!currentHash.includes(key)) {
            currentHash.push(key);
        }

        window.location.hash = `#${currentHash.join(',')}`;
    }

    remove(key) {
        if (!this.has(key)) {
            return;
        }

        delete this.ids[key];
        const currentHash = parseHash().filter(val => val !== key);
        const { location, history } = window;

        if (!currentHash.length) {
            history.replaceState(null, null, ' ');
        } else {
            location.hash = currentHash.length
                ? `#${currentHash.join(',')}`
                : '';
        }

        this.checkListenerForClear();
    }

    has(key) {
        return !!this.ids[key];
    }
}

export default new UrlStateService();
