import React from 'react';
import { useWindowSize } from 'hooks';
import { func, node, object, string } from 'prop-types';

import { Title as DSTitle } from '@s7/ui-kit';
import { ChevronNormalRight } from '@s7/ui-kit/icons';

import cx from './PaperTitle.sss';

export const PaperTitle = React.memo(function PaperTitle(props) {
    const { aside, children, className, onClick, titleProps } = props;

    const { isDesktop } = useWindowSize();
    const isMobile = !isDesktop && onClick;

    const Title = () => (
        <DSTitle as="p" level={isDesktop ? 3 : 5} {...titleProps}>
            {children}
        </DSTitle>
    );

    return isMobile ? (
        <button
            className={cx('root', className)}
            type="button"
            onClick={onClick}
        >
            <Title />
            <span className={cx('icon')}>
                <ChevronNormalRight />
            </span>
            {aside}
        </button>
    ) : (
        <div className={cx('root', className)}>
            <Title />
            {aside}
        </div>
    );
});

PaperTitle.propTypes = {
    aside: node,
    children: node,
    className: string,
    onClick: func,
    titleProps: object,
};

PaperTitle.defaultProps = {
    titleProps: {},
};
