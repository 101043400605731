import {
    assoc,
    assocPath,
    clone,
    dissoc,
    indexBy,
    omit,
    prop,
    without,
} from 'ramda';
import { createAction, createReducer } from 'redux-act';

import { resetFading, setFading } from '../../../utils/fadingTime';

export const initialState = {
    archive: {
        filters: {
            city: null,
            dates: [],
            passenger: null,
        },
        items: {},
        page: 0,
        size: 5,
        totalPage: 1,
    },
    bookings: {
        items: {},
        page: 0,
        size: 5,
        totalPage: 1,
    },
    certificates: {},
    deleting: [],
    deletingErrors: [],
    fadingArchiveTime: setFading(),
    fadingBookingsTime: setFading(),
    hasArchiveMore: false,
    hasBookingsMore: false,
    isArchiveError: false,
    isArchiveFetch: false,
    isArchiveLoading: false,
    isBookingsError: false,
    isBookingsFetch: true,
    isBookingsLoading: false,
    isTripLoading: false,
    trip: null,
    updating: [],
    updatingErrors: [],
};

export const fetchTrip = createAction('TRAVELS_FETCH_TRIP');
export const fetchTripSuccess = createAction('TRAVELS_FETCH_TRIP_SUCCESS');
export const fetchTripFailure = createAction('TRAVELS_FETCH_TRIP_FAILURE');

export const fetchBookings = createAction('travels/fetchBookings');
export const fetchBookingsSuccess = createAction(
    'travels/fetchBookingsSuccess',
);
export const fetchBookingsFailure = createAction(
    'travels/fetchBookingsFailure',
);

export const fetchArchive = createAction('travels/fetchArchive');
export const fetchArchiveSuccess = createAction('travels/fetchArchiveSuccess');
export const fetchArchiveFailure = createAction('travels/fetchArchiveFailure');

export const deleteTrip = createAction('travels/deleteTrip');
export const deleteTripSuccess = createAction('travels/deleteTripSuccess');
export const deleteTripFailure = createAction('travels/deleteTripFailure');

export const updateTrip = createAction('travels/updateTrip');
export const updateTripSuccess = createAction('travels/updateTripSuccess');
export const updateTripFailure = createAction('travels/updateTripFailure');

export const deleteCertificate = createAction('TRAVELS_DELETE_CERTIFICATE');
export const deleteCertificateSuccess = createAction(
    'TRAVELS_DELETE_CERTIFICATE_SUCCESS',
);
export const deleteCertificateFailure = createAction(
    'TRAVELS_DELETE_CERTIFICATE_FAILURE',
);

export const changeLanguage = createAction('travels/changeLanguage');

export const setArchiveFilterCity = createAction(
    'travels/setArchiveFilterCity',
);
export const setArchiveFilterPassenger = createAction(
    'travels/setArchiveFilterPassenger',
);
export const setArchiveFilterDates = createAction(
    'travels/setArchiveFilterDates',
);
export const resetArchiveFilter = createAction('travels/resetArchiveFilter');
export const setArchiveFilter = createAction('travels/setArchiveFilter');

const handleFetchTrip = state => ({
    ...state,
    isTripLoading: true,
});

const handleFetchTripSuccess = (state, { trip }) => ({
    ...assoc('trip', trip, state),
    isTripLoading: false,
});

const handleFetchTripFailure = state => ({
    ...state,
    isTripLoading: false,
});

const handleFetchBookings = (state, action = {}) => ({
    ...state,
    isBookingsError: false,
    isBookingsFetch: true,
    isBookingsLoading: state.bookings.page === 0 && !action.fetch,
    ...(action.fetch
        ? {
              bookings: clone(initialState.bookings),
          }
        : {}),
});

const handleFetchBookingsSuccess = (
    state,
    { trips, certificates, totalPage, number, totalElements },
) => {
    const newItems = {
        ...state.bookings.items,
        ...indexBy(prop('id'), trips),
    };
    const newCertificates = {
        ...state.certificates,
        ...indexBy(prop('id'), certificates),
    };

    return {
        ...state,
        bookings: {
            items: newItems,
            page: number,
            size: 5,
            totalElements,
        },
        certificates: newCertificates,
        fadingBookingsTime: resetFading(),
        hasBookingsMore: number < totalPage,
        isBookingsError: false,
        isBookingsFetch: false,
        isBookingsLoading: false,
    };
};

const handleFetchhBookingsFailure = state => ({
    ...state,
    fadingBookingsTime: resetFading(),
    isBookingsError: true,
    isBookingsFetch: false,
    isBookingsLoading: false,
});

const handleFetchArchive = (state, action = {}) => ({
    ...state,
    isArchiveError: false,
    isArchiveFetch: true,
    isArchiveLoading: state.archive.page === 0 && !action.fetch,
    ...(action.fetch
        ? {
              archive: clone(initialState.archive),
          }
        : {}),
});

const handleFetchArchiveSuccess = (
    state,
    { trips, certificates, totalPage, number, totalElements },
) => {
    const newItems = {
        ...state.archive.items,
        ...indexBy(prop('id'), trips),
    };
    const newCertificates = {
        ...state.certificates,
        ...indexBy(prop('id'), certificates),
    };

    return {
        ...state,
        archive: {
            ...state.archive,
            items: newItems,
            page: number,
            size: 5,
            totalElements,
        },
        certificates: newCertificates,
        fadingArchiveTime: resetFading(),
        hasArchiveMore: number < totalPage,
        isArchiveError: false,
        isArchiveFetch: false,
        isArchiveLoading: false,
    };
};

const handleFetchArchiveFailure = state => ({
    ...state,
    fadingArchiveTime: resetFading(),
    isArchiveError: true,
    isArchiveFetch: false,
    isArchiveLoading: false,
});

const handleChangeLanguage = state => ({
    ...state,
    archive: clone(initialState.archive),
    bookings: clone(initialState.bookings),
    hasArchiveMore: false,
    hasBookingsMore: false,
    isArchiveError: false,
    isArchiveFetch: false,
    isArchiveLoading: false,
    isBookingsError: false,
    isBookingsFetch: true,
    isBookingsLoading: false,
});

const handleDeleteTrip = (state, payload) => ({
    ...state,
    deleting: [...state.deleting, payload.id],
});

const handleDeleteTripSuccess = (state, id) => ({
    ...state,
    archive: {
        ...state.archive,
        items: omit([id], state.archive.items),
    },
    bookings: {
        ...state.bookings,
        items: omit([id], state.bookings.items),
    },
    deleting: without(id, state.deleting),
});

const handleDeleteTripFailure = (state, id) => ({
    ...state,
    deleting: without(id, state.deleting),
    deletingErrors: [...state.deletingErrors, id],
});

const handleUpdateTrip = (state, { id }) => ({
    ...state,
    updating: [...state.updating, id],
});

const handleUpdateTripSuccess = (state, { id, isActive, updatedTrip }) => ({
    ...assocPath(
        [isActive ? 'bookings' : 'archive', 'items', id],
        updatedTrip,
        state,
    ),
    updating: without(id, state.updating),
});

const handleUpdateTripFailure = (state, id) => ({
    ...state,
    updating: without(id, state.updating),
    updatingErrors: [...state.updatingErrors, id],
});

const handleDeleteCertificateSuccess = (state, id) => ({
    ...state,
    certificates: dissoc(id, state.certificates),
});

const handleDeleteCertificateFailure = state => state;

const handleSetArchiveFilterCity = (state, value) =>
    assocPath(['archive', 'filters', 'city'], value, state);
const handleSetArchiveFilterDates = (state, value) =>
    assocPath(['archive', 'filters', 'dates'], value || [], state);
const handleSetArchiveFilterPassenger = (state, value) =>
    assocPath(['archive', 'filters', 'passenger'], value, state);
const handleResetArchiveFilter = state =>
    assocPath(
        ['archive', 'filters'],
        clone(initialState.archive.filters),
        state,
    );
const handleSetArchiveFilter = (state, filter) =>
    assocPath(['archive', 'filters'], filter, state);

export default createReducer(
    {
        [changeLanguage]: handleChangeLanguage,
        [deleteCertificateFailure]: handleDeleteCertificateFailure,
        [deleteCertificateSuccess]: handleDeleteCertificateSuccess,
        [deleteTrip]: handleDeleteTrip,
        [deleteTripFailure]: handleDeleteTripFailure,
        [deleteTripSuccess]: handleDeleteTripSuccess,
        [fetchArchive]: handleFetchArchive,
        [fetchArchiveFailure]: handleFetchArchiveFailure,
        [fetchArchiveSuccess]: handleFetchArchiveSuccess,
        [fetchBookings]: handleFetchBookings,
        [fetchBookingsFailure]: handleFetchhBookingsFailure,
        [fetchBookingsSuccess]: handleFetchBookingsSuccess,
        [fetchTrip]: handleFetchTrip,
        [fetchTripFailure]: handleFetchTripFailure,
        [fetchTripSuccess]: handleFetchTripSuccess,
        [resetArchiveFilter]: handleResetArchiveFilter,
        [setArchiveFilter]: handleSetArchiveFilter,
        [setArchiveFilterCity]: handleSetArchiveFilterCity,
        [setArchiveFilterDates]: handleSetArchiveFilterDates,
        [setArchiveFilterPassenger]: handleSetArchiveFilterPassenger,
        [updateTrip]: handleUpdateTrip,
        [updateTripFailure]: handleUpdateTripFailure,
        [updateTripSuccess]: handleUpdateTripSuccess,
    },
    initialState,
);

export * from './selectors';
