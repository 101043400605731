import { documentType } from 'data';

const handlers = {
    [documentType.INTERNAL_PASSPORT]: number =>
        number.replace(/^(\d{4})(\d+)$/, '$1 $2'),
    [documentType.INTERNATIONAL_PASSPORT]: number =>
        number.replace(/^(\d{2})(\d+)$/, '$1 $2'),
};

export const formatDocNumber = ({ type, number }) => {
    const handler = handlers[type];

    return handler ? handler(number) : number;
};
