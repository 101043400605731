import { pathOr } from 'ramda';

const errorSendGA = (data, action, module) => {
    const error = pathOr(
        {},
        ['response', 'response', 'data', 'Errors', 'Error'],
        data,
    );
    const code = pathOr('schema', ['attributes', 'Type'], error);
    const value = pathOr('soap', ['attributes', 'ShortText'], error);
    const text = pathOr(
        'Schema error',
        ['response', 'response', 'data', 'Errors', 'Error', '$value'],
        data,
    );
    const label = `ERROR:server.${module}.error.${value}.${code}:${text}`;

    if (typeof window === 'object' && window.dataLayer) {
        window.dataLayer.push({
            event: 'GAevent',
            eventAction: action,
            eventCategory: 'Personal_Account Errors',
            eventInteraction: 1,
            eventLabel: label,
            eventUX: undefined,
        });
    }
};

export default errorSendGA;
