const noop = () => {};

function fallbackCopyTextToClipboard({ onFailure, onSuccess, text }) {
    const textArea = document.createElement('textarea');

    textArea.value = text;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');

        onSuccess();
    } catch (err) {
        onFailure(err);
    }

    document.body.removeChild(textArea);
}

export function copyText(
    text = '',
    { onFailure = noop, onSuccess = noop } = {},
) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard({ onFailure, onSuccess, text });

        return;
    }

    navigator.clipboard.writeText(text).then(onSuccess, onFailure);
}
