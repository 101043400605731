import {
    assoc,
    assocPath,
    compose,
    concat,
    contains,
    dissoc,
    dissocPath,
    indexBy,
    lensPath,
    map,
    merge,
    omit,
    over,
    prop,
    propOr,
    set,
    uniq,
    view,
    without,
} from 'ramda';
import { createAction, createReducer } from 'redux-act';

import { prepareDocumentDates } from './helpers';

import { resetFading, setFading } from '../../../utils/fadingTime';

const initialState = {
    fadingTime: setFading(),
    isArchiveActive: { documents: false, visa: false },
    isEdit: [],
    isError: false,
    isInitialized: false,
    isLoading: false,
    itemsById: {},
    needConfirmForRemove: [],
    showNewDocsForm: false,
    showNewVisaForm: false,
    warningsById: {},
};

export const fetch = createAction('documents/fetch');
export const fetchSuccess = createAction('documents/fetchSuccess');
export const fetchFailure = createAction('documents/fetchFailure');

export const save = createAction('documents/saveDocument');
export const saveSuccess = createAction('documents/saveDocumentSuccess');
export const saveFailure = createAction('documents/saveDocumentFailure');

export const remove = createAction('documents/remove');
export const removeSuccess = createAction('documents/removeSuccess');
export const removeFailure = createAction('documents/removeFailure');
export const removeHideMessage = createAction('documents/removeHideMessage');

export const changeConfirmationRemove = createAction(
    'documents/changeConfirmationRemove',
);

export const setEdit = createAction('documents/setEdit');
export const addNew = createAction('documents/addNew');
export const closeNew = createAction('documents/closeNew');
export const changeDocsArchiveActive = createAction(
    'documents/changeDocsArchiveActive',
);
export const changeVisaArchiveActive = createAction(
    'documents/changeVisaArchiveActive',
);

export const closeWarning = createAction('documents/closeWarning');

const handleFetch = state => ({
    ...state,
    isError: false,
    isLoading: true,
});

const handleFetchSuccess = (state, payload) => {
    const documents = propOr([], 'documents', payload);

    const items = map(prepareDocumentDates, documents);

    const itemsById = indexBy(prop('id'), items);

    return {
        ...state,
        fadingTime: resetFading(),
        isError: false,
        isInitialized: true,
        isLoading: false,
        itemsById,
        warningsById: payload.warnings,
    };
};

const handleFetchFailure = state => ({
    ...state,
    isError: true,
    isInitialized: true,
    isLoading: false,
});

const handleSaveSuccess = (state, payload) => {
    const doc = prepareDocumentDates(payload);

    const documentById = indexBy(prop('id'), [doc]);

    return {
        ...state,
        itemsById: {
            ...state.itemsById,
            ...documentById,
        },
        showNewDocsForm: false,
        showNewVisaForm: false,
    };
};

// common lenses for remove
const lensNeedConfirmForRemove = lensPath(['needConfirmForRemove']);

const handleRemove = (state, payload) => {
    const lensItem = lensPath(['itemsById', payload.id, 'removingStatus']);

    return set(lensItem, 'pending', state);
};

const handleRemoveSuccess = (state, payload) => {
    const lensItem = lensPath(['itemsById', payload.id, 'removingStatus']);
    const lensEdit = lensPath(['isEdit']);

    return compose(
        set(lensEdit, []),
        set(lensNeedConfirmForRemove, []),
        set(lensItem, 'success'),
    )(state);
};

const handleRemoveHideMessage = (state, payload = {}) => {
    const { id, isError } = payload;
    const lensItemById = lensPath(['itemsById', id]);
    const newItem = dissoc('removingStatus', view(lensItemById, state));

    if (isError) {
        return set(lensItemById, newItem, state);
    }

    return dissocPath(['itemsById', id], state);
};

const handleRemoveFailure = (state, payload) => {
    const lensItem = lensPath(['itemsById', payload.id, 'removingStatus']);

    return set(lensItem, 'failure', state);
};

const handleSetEdit = (state, payload) => {
    const { id, status } = payload;
    const lens = lensPath(['isEdit']);

    return compose(
        assoc('warningsById', payload.warningsById),
        over(lensNeedConfirmForRemove, editable => {
            if (!status && contains(id, editable)) {
                return without(id, editable);
            }

            return editable;
        }),
        over(lens, editable => {
            if (status) {
                return concat([id], editable);
            }

            return without(id || '', editable);
        }),
    )(state);
};

const handleChangeConfirmationRemove = (state, payload) => {
    const { id, needConfirmForRemove } = payload;

    if (needConfirmForRemove) {
        return over(
            lensNeedConfirmForRemove,
            editable => uniq(concat([id], editable)),
            state,
        );
    }

    return over(
        lensNeedConfirmForRemove,
        editable => without([id], editable),
        state,
    );
};

const mergeResult = stateForm => (state, typeForm) =>
    merge(state, { [typeForm]: stateForm });

const handleCloseWarning = (state, payload) => {
    return assoc(
        'warningsById',
        omit(payload.ids, propOr({}, 'warningsById', state)),
        state,
    );
};

const handleChangeDocsArchiveActive = (state, payload) =>
    assocPath(['isArchiveActive', 'documents'], payload.isActive, state);

const handleChangeVisaArchiveActive = (state, payload) =>
    assocPath(['isArchiveActive', 'visa'], payload.isActive, state);

export default createReducer(
    {
        [addNew]: mergeResult(true),
        [changeConfirmationRemove]: handleChangeConfirmationRemove,
        [changeDocsArchiveActive]: handleChangeDocsArchiveActive,
        [changeVisaArchiveActive]: handleChangeVisaArchiveActive,
        [closeNew]: mergeResult(false),
        [closeWarning]: handleCloseWarning,
        [fetch]: handleFetch,
        [fetchFailure]: handleFetchFailure,
        [fetchSuccess]: handleFetchSuccess,
        [remove]: handleRemove,
        [removeFailure]: handleRemoveFailure,
        [removeHideMessage]: handleRemoveHideMessage,
        [removeSuccess]: handleRemoveSuccess,
        [saveSuccess]: handleSaveSuccess,
        [setEdit]: handleSetEdit,
    },
    initialState,
);

export {
    fieldsSelector,
    getCounter,
    getDocsIsArchiveActive,
    getDocument,
    getDocumentsIds,
    getInitialValues,
    getIsDocsShowNewForm,
    getDocumentsEdit,
    getIsEdit,
    getNeedConfirmForRemove,
    getRemovingStatus,
    getVisaIsArchiveActive,
    getWarnings,
    isError,
    isInitialized,
    isLoaded,
    isLoading,
    getDocFormValues,
    getNewDocumentValues,
    getIsOver14YearsOld,
} from './selectors';
