import { getCast, requestCast } from 'utils/cast';
import { getGeo, requestGeo } from 'utils/geo';
import { getLanguage } from 'utils/lang';
import { getUserAgent } from 'utils/userAgent';

import sessionService from '../session';

export default async function interceptor(config) {
    const geo = getGeo();
    const cast = getCast();
    const userAgent = getUserAgent();
    const language = getLanguage();
    const fcmToken = localStorage.getItem('fcm_token');
    const isMbeRequest = config.url.includes('/mbe/api');

    if (userAgent) {
        config.headers['X-User-Agent'] = userAgent;
    }

    if (geo) {
        config.headers['X-Geo'] = geo;
    } else {
        requestGeo();
    }

    if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
        if (cast) {
            config.headers['x-cast'] = cast;
        } else {
            requestCast();
        }
    }

    const header = /^.*\/?hermes\/.+$/.test(config.url)
        ? 'x-ps-language'
        : 'X-Language';

    config.headers[header] =
        isMbeRequest && !['ru', 'en'].includes(language) ? 'en' : language;

    if (fcmToken) {
        config.headers['x-pushid'] = fcmToken;
    }

    if (config.url.indexOf('%userId%') >= 0) {
        const resourceId = sessionService.getResourceId();

        if (resourceId) {
            config.url = config.url.replace('%userId%', resourceId);
        } else {
            return false;
        }
    }

    return config;
}
