import { useEffect, useRef } from 'react';
import { scrollerTo } from 'utils/scroller';

const elementVisibleInViewport = (el, partiallyVisible = false) => {
    if (!el) {
        return null;
    }
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;

    return partiallyVisible
        ? ((top > 0 && top < innerHeight) ||
              (bottom > 0 && bottom < innerHeight)) &&
              ((left > 0 && left < innerWidth) ||
                  (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

const scrollFunc = name => {
    setTimeout(() => {
        scrollerTo(`scrollTo-${name}`);
    }, 0);
};

export function useScroll({
    inViewport,
    element,
    listenMount,
    listenUpdate,
    listenAction,
}) {
    const isClient = typeof window === 'object';

    const isMounted = useRef(false);

    useEffect(() => {
        if (isClient && !isMounted.current && listenMount && listenAction) {
            if (inViewport && element && !elementVisibleInViewport(element)) {
                scrollFunc(listenAction);
            } else {
                scrollFunc(listenAction);
            }
        }

        isMounted.current = true;
    }, [element, inViewport, isClient, listenAction, listenMount]);

    useEffect(() => {
        if (isClient && isMounted.current && listenUpdate && listenAction) {
            if (inViewport && element && !elementVisibleInViewport(element)) {
                scrollFunc(listenAction);
            } else {
                scrollFunc(listenAction);
            }
        }
    });
}
