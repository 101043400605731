import { createSelector } from 'reselect';

import {
    filterByB2bUser,
    filterByPage,
    filterByProfileType,
    filterByUserAirport,
    filterByViewed,
    sortByCreateAt,
    sortByType,
    sortByWeight,
} from './helpers';

import {
    getCustomerValue as getProfileType,
    getHomeAirport,
    hasCompany,
} from '../profile';

const sortByPriority = (a, b) => {
    return sortByType(a, b) || sortByWeight(a, b) || sortByCreateAt(a, b);
};

const getAlerts = state => state.alerts.items || [];

export const getIsAlertsLoading = state => state.alerts.isLoading;
export const getIsAlertsLoaded = state => state.alerts.isLoaded;
export const getAlertsError = state => state.alerts.error;
export const getSortedAlerts = createSelector(
    getAlerts,
    state => getHomeAirport(state),
    state => getProfileType(state),
    state => hasCompany(state),
    (alerts, homeAirport, profileType, isCompany) =>
        [...alerts]
            .sort(sortByPriority)
            .filter(
                item =>
                    filterByPage(item) &&
                    filterByUserAirport(item, homeAirport) &&
                    filterByProfileType(item, profileType) &&
                    filterByB2bUser(item, isCompany) &&
                    filterByViewed(item),
            ),
);
