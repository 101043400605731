const { prod, isQa } = require('./stages');
const { isClient } = require('./ssr');

const isE2EStage = isClient && window.location.hostname.indexOf('cloud') >= 0;

const COOKIES_OPTIONS = {
    domain: '.s7.ru',
    maxAge: 2 ** 40 - 1,
    path: '/',
};

if (prod || (isQa && !isE2EStage)) {
    COOKIES_OPTIONS.secure = true;
}

module.exports = COOKIES_OPTIONS;
