import { find } from 'ramda';

import { phoneMasks } from '../../data';

export const validatePhone = value => {
    if (!value) {
        return false;
    }

    const phone = value.replace(/[^\d]/g, '');
    // eslint-disable-next-line no-shadow
    const code = find(theCode => phone.search(theCode.test) === 0)(phoneMasks);

    if (!code) {
        return false;
    }

    return code.valid.test(phone) || code.valid.test(phone);
};
