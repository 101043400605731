import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

const PRECISION = 'day';

export const getIsDateCorrect = (value, format = DD_MM_YYYY) => {
    const date = moment(value, format, true);

    return date.format(format) === value;
};

export const inRangeOf2To12Years = (value, format = DD_MM_YYYY) => {
    if (!getIsDateCorrect(value, format)) {
        return false;
    }

    return (
        moment(value, format)
            .add(2, 'years')
            .isSameOrBefore(moment(), PRECISION) &&
        moment(value, format)
            .add(12, 'years')
            .isSameOrAfter(moment(), PRECISION)
    );
};

export const isOver12YearsOld = (value, format = DD_MM_YYYY) => {
    if (!getIsDateCorrect(value, format)) {
        return false;
    }

    return moment(value, format).add(12, 'years').isBefore(moment(), PRECISION);
};

export const isOver14YearsOld = (value, format = DD_MM_YYYY) => {
    if (!getIsDateCorrect(value, format)) {
        return false;
    }

    return moment(value, format).add(14, 'years').isBefore(moment(), PRECISION);
};

export const isOver18YearsOld = (value, format = DD_MM_YYYY) => {
    if (!getIsDateCorrect(value, format)) {
        return false;
    }

    return moment(value, format).add(18, 'years').isBefore(moment(), PRECISION);
};

export function getTime() {
    return new Date().getTime();
}
