import React from 'react';
import { node, number, object, oneOf, string } from 'prop-types';

import cx from './ContentLoader.sss';
import { ContentLoaderBase } from './ContentLoaderBase';

const ContentLoader = ({
    as: Component,
    children,
    childrenProps,
    height,
    width,
    ...rest
}) => (
    <ContentLoaderBase
        backgroundColor="#ECEFF5"
        className={cx('root')}
        foregroundColor="#FFFFFF"
        speed={1.5}
        title={null}
        {...{
            height,
            style: {
                height,
                width,
            },
            width,
            ...rest,
        }}
    >
        {children || (
            <Component
                {...{
                    height,
                    width,
                    ...childrenProps,
                }}
            />
        )}
    </ContentLoaderBase>
);

ContentLoader.propTypes = {
    as: string,
    children: node,
    childrenProps: object,
    height: oneOf([number, string]),
    width: oneOf([number, string]),
};

ContentLoader.defaultProps = {
    as: 'rect',
    childrenProps: {
        rx: 4,
        x: 0,
        y: 0,
    },
};

export default React.memo(ContentLoader);
