import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    API_PACKAGES_ACTIVATE_STATUS,
    API_PACKAGES_EXPIRATION,
    API_PACKAGES_LOYALTY_CARD,
    API_PACKAGES_PROLONGATION,
    API_PACKAGES_PURCHASE,
    API_PACKAGES_TRANSFER,
    API_PACKAGES_UPGRADE,
} from 'consts';
import { SessionService } from 'services';
import {
    fetch as fetchAirlines,
    isError as isErrorAirlines,
    isLoaded as isLoadedAirlines,
    isLoading as isLoadingAirlines,
} from 'store/modules/airlines';
import {
    fetchAllAlerts,
    getAlertsError,
    getIsAlertsLoaded,
    getIsAlertsLoading,
} from 'store/modules/alerts';
import {
    fetch as fetchCards,
    fetchAllCards,
    isAllCardsError,
    isAllCardsLoaded,
    isAllCardsLoading,
    isError as isErrorCards,
    isLoaded as isLoadedCards,
    isLoading as isLoadingCards,
} from 'store/modules/cards';
import {
    fetch as fetchCountries,
    isError as isErrorCountries,
    isLoaded as isLoadedCountries,
    isLoading as isLoadingCountries,
} from 'store/modules/countries';
import {
    fetch as fetchDocuments,
    isError as isErrorDocuments,
    isLoaded as isLoadedDocuments,
    isLoading as isLoadingDocuments,
} from 'store/modules/documents';
import {
    fetch as fetchLoyalty,
    fetchJwt,
    getAvailableMilesOperations,
    isError as isErrorLoyalty,
    isJwtError,
    isJwtInitialized,
    isJwtLoading,
    isLoaded as isLoadedLoyalty,
    isLoading as isLoadingLoyalty,
} from 'store/modules/loyalty';
import {
    fetchActivateStatusPackages as fetchPackagesActivateStatus,
    fetchCharityAccounts,
    fetchExpirationPackages as fetchPackagesExpiration,
    fetchLoyaltyCardOrders,
    fetchPackagesLoyaltyCard,
    fetchProfileAddresses,
    fetchPurchasePackages as fetchPackagesPurchase,
    fetchStatusPackages as fetchPackagesProlongation,
    fetchTransferPackages as fetchPackagesTransfer,
    fetchUpgradePackages as fetchPackagesUpgrade,
    getAllowedMilesActions,
    getCharityAccountsHasError as isErrorCharityAccounts,
    getCharityAccountsIsLoaded as isLoadedCharityAccounts,
    getCharityAccountsIsLoading as isLoadingCharityAccounts,
    getLoyaltyCardOrdersIsError as isErrorLoyaltyCardOrders,
    getLoyaltyCardOrdersIsLoaded as isLoadedLoyaltyCardOrders,
    getLoyaltyCardOrdersIsLoading as isLoadingLoyaltyCardOrders,
    getPackagesHasError,
    getPackagesIsLoaded,
    getPackagesIsLoading,
    getPackagesNotification,
    getProfileAddressesIsError as isErrorProfileAddresses,
    getProfileAddressesIsLoaded as isLoadedProfileAddresses,
    getProfileAddressesIsLoading as isLoadingProfileAddresses,
} from 'store/modules/miles';
import {
    fetchPets,
    getIsPetsLoaded,
    getIsPetsLoading,
    getPetsError,
} from 'store/modules/pets';
import {
    fetch as fetchProfile,
    getIsFullProfile,
    isError as isErrorProfile,
    isLoaded as isLoadedProfile,
    isLoading as isLoadingProfile,
} from 'store/modules/profile';
import {
    fetchPromo,
    isErrorPromo,
    isLoadedPromo,
    isLoadingPromo,
} from 'store/modules/promo';
import {
    fetchPurchases,
    getIsPurchasesError as isErrorPurchases,
    getIsPurchasesLoaded as isLoadedPurchases,
    getIsPurchasesLoading as isLoadingPurchases,
} from 'store/modules/purchases';
import {
    fetchQRCodes,
    getIsQRCodesLoaded,
    getIsQRCodesLoading,
    getQRCodesError,
} from 'store/modules/qrcodes';
import {
    fetchAchievements,
    fetchStatistics,
    getIsAchievementsError as isErrorAchievements,
    getIsAchievementsLoading as isLoadingAchievements,
    getIsStatisticsError as isErrorStatistics,
    getIsStatisticsLoading as isLoadingStatistics,
    isLoadedAchievements,
    isLoadedStatistics,
} from 'store/modules/statistics';
import {
    fetch as fetchTransactions,
    isError as isErrorTransactions,
    isLoaded as isLoadedTransactions,
    isLoading as isLoadingTransactions,
} from 'store/modules/transactions';
import {
    fetchTravelers,
    isErrorTravelers,
    isLoadedTravelers,
    isLoadingTravelers,
} from 'store/modules/travelers';
import {
    fetchArchive as fetchBookingsArchive,
    fetchBookings,
    isErrorArchive as isErrorBookingsArchive,
    isErrorBookings,
    isLoadedArchive as isLoadedBookingsArchive,
    isLoadedBookings,
    isLoadingArchive as isLoadingBookingsArchive,
    isLoadingBookings,
} from 'store/modules/travels';

const apiFetch = {
    achievements: fetchAchievements,
    airlines: fetchAirlines,
    alerts: fetchAllAlerts,
    allCards: fetchAllCards,
    bookings: fetchBookings,
    bookingsArchive: fetchBookingsArchive,
    cards: fetchCards,
    charityAccounts: fetchCharityAccounts,
    countries: fetchCountries,
    documents: fetchDocuments,
    jwt: fetchJwt,
    loyalty: fetchLoyalty,
    loyaltyCardOrders: fetchLoyaltyCardOrders,
    packagesActivateStatus: fetchPackagesActivateStatus,
    packagesExpiration: fetchPackagesExpiration,
    packagesLoyaltyCard: fetchPackagesLoyaltyCard,
    packagesProlongation: fetchPackagesProlongation,
    packagesPurchase: fetchPackagesPurchase,
    packagesTransfer: fetchPackagesTransfer,
    packagesUpgrade: fetchPackagesUpgrade,
    pets: fetchPets,
    profile: fetchProfile,
    profileAddresses: fetchProfileAddresses,
    promo: fetchPromo,
    purchases: fetchPurchases,
    qrcodes: fetchQRCodes,
    statistics: fetchStatistics,
    transactions: fetchTransactions,
    travelers: fetchTravelers,
};

export function useApi(modules = [], force = false) {
    const isClient = typeof window === 'object';
    const isAuth = SessionService.isAuth();
    const dispatch = useDispatch();
    const isFullProfile = useSelector(getIsFullProfile);
    const availableMilesOperations = useSelector(getAvailableMilesOperations);
    const allowedMilesOperations = useSelector(getAllowedMilesActions);

    const api = {
        achievements: {
            error: useSelector(isErrorAchievements),
            loaded: useSelector(isLoadedAchievements),
            loading: useSelector(isLoadingAchievements),
        },
        airlines: {
            error: useSelector(isErrorAirlines),
            loaded: useSelector(isLoadedAirlines),
            loading: useSelector(isLoadingAirlines),
            ssr: true,
            unauthorised: true,
        },
        alerts: {
            error: useSelector(getAlertsError),
            loaded: useSelector(getIsAlertsLoaded),
            loading: useSelector(getIsAlertsLoading),
            unauthorised: true,
        },
        allCards: {
            error: useSelector(isAllCardsError),
            loaded: useSelector(isAllCardsLoaded),
            loading: useSelector(isAllCardsLoading),
        },
        bookings: {
            error: useSelector(isErrorBookings),
            loaded: useSelector(isLoadedBookings),
            loading: useSelector(isLoadingBookings),
        },
        bookingsArchive: {
            error: useSelector(isErrorBookingsArchive),
            loaded: useSelector(isLoadedBookingsArchive),
            loading: useSelector(isLoadingBookingsArchive),
        },
        cards: {
            error: useSelector(isErrorCards),
            loaded: useSelector(isLoadedCards),
            loading: useSelector(isLoadingCards),
        },
        charityAccounts: {
            error: useSelector(isErrorCharityAccounts),
            full: true,
            loaded: useSelector(isLoadedCharityAccounts),
            loading: useSelector(isLoadingCharityAccounts),
        },
        countries: {
            error: useSelector(isErrorCountries),
            loaded: useSelector(isLoadedCountries),
            loading: useSelector(isLoadingCountries),
            ssr: true,
            unauthorised: true,
        },
        documents: {
            error: useSelector(isErrorDocuments),
            loaded: useSelector(isLoadedDocuments),
            loading: useSelector(isLoadingDocuments),
        },
        jwt: {
            error: useSelector(isJwtError),
            full: true,
            loaded: useSelector(isJwtInitialized),
            loading: useSelector(isJwtLoading),
        },
        loyalty: {
            error: useSelector(isErrorLoyalty),
            full: true,
            loaded: useSelector(isLoadedLoyalty),
            loading: useSelector(isLoadingLoyalty),
        },
        loyaltyCardOrders: {
            error: useSelector(isErrorLoyaltyCardOrders),
            loaded: useSelector(isLoadedLoyaltyCardOrders),
            loading: useSelector(isLoadingLoyaltyCardOrders),
        },
        packagesActivateStatus: {
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_ACTIVATE_STATUS),
            ),
            isRestrict: !availableMilesOperations.isActivateStatus,
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_ACTIVATE_STATUS),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_ACTIVATE_STATUS),
            ),
            waitLoyalty: true,
        },
        packagesExpiration: {
            allowError:
                useSelector(state =>
                    getPackagesNotification(state, API_PACKAGES_EXPIRATION),
                ).code === 'provider.disabled',
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_EXPIRATION),
            ),
            isRestrict:
                !availableMilesOperations.isExpiration &&
                !allowedMilesOperations.isExpiration,
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_EXPIRATION),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_EXPIRATION),
            ),
            waitLoyalty: true,
        },
        packagesLoyaltyCard: {
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_LOYALTY_CARD),
            ),
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_LOYALTY_CARD),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_LOYALTY_CARD),
            ),
            waitLoyalty: true,
        },
        packagesProlongation: {
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_PROLONGATION),
            ),
            isRestrict: !availableMilesOperations.isProlongation,
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_PROLONGATION),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_PROLONGATION),
            ),
            waitLoyalty: true,
        },
        packagesPurchase: {
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_PURCHASE),
            ),
            isRestrict: !availableMilesOperations.isPurchase,
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_PURCHASE),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_PURCHASE),
            ),
            waitLoyalty: true,
        },
        packagesTransfer: {
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_TRANSFER),
            ),
            isRestrict: !availableMilesOperations.isTransfer,
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_TRANSFER),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_TRANSFER),
            ),
            waitLoyalty: true,
        },
        packagesUpgrade: {
            error: useSelector(state =>
                getPackagesHasError(state, API_PACKAGES_UPGRADE),
            ),
            isRestrict: !availableMilesOperations.isUpgrade,
            loaded: useSelector(state =>
                getPackagesIsLoaded(state, API_PACKAGES_UPGRADE),
            ),
            loading: useSelector(state =>
                getPackagesIsLoading(state, API_PACKAGES_UPGRADE),
            ),
            waitLoyalty: true,
        },
        pets: {
            error: useSelector(getPetsError),
            loaded: useSelector(getIsPetsLoaded),
            loading: useSelector(getIsPetsLoading),
        },
        profile: {
            error: useSelector(isErrorProfile),
            loaded: useSelector(isLoadedProfile),
            loading: useSelector(isLoadingProfile),
            ssr: true,
        },
        profileAddresses: {
            error: useSelector(isErrorProfileAddresses),
            loaded: useSelector(isLoadedProfileAddresses),
            loading: useSelector(isLoadingProfileAddresses),
        },
        promo: {
            error: useSelector(isErrorPromo),
            loaded: useSelector(isLoadedPromo),
            loading: useSelector(isLoadingPromo),
        },
        purchases: {
            error: useSelector(isErrorPurchases),
            loaded: useSelector(isLoadedPurchases),
            loading: useSelector(isLoadingPurchases),
        },
        qrcodes: {
            error: useSelector(getQRCodesError),
            loaded: useSelector(getIsQRCodesLoaded),
            loading: useSelector(getIsQRCodesLoading),
        },
        statistics: {
            error: useSelector(isErrorStatistics),
            loaded: useSelector(isLoadedStatistics),
            loading: useSelector(isLoadingStatistics),
        },
        transactions: {
            error: useSelector(isErrorTransactions),
            full: true,
            loaded: useSelector(isLoadedTransactions),
            loading: useSelector(isLoadingTransactions),
        },
        travelers: {
            error: useSelector(isErrorTravelers),
            loaded: useSelector(isLoadedTravelers),
            loading: useSelector(isLoadingTravelers),
        },
    };

    const modulesLoad = [];
    const modulesStatus = {};

    modules.forEach(name => {
        if (!api[name]) {
            return;
        }
        const {
            allowError,
            error,
            fetchEveryTime,
            full,
            loaded,
            loading,
            ssr,
            isRestrict,
            unauthorised,
            waitLoyalty,
        } = api[name];

        if (
            fetchEveryTime ||
            (!(loaded || allowError) &&
                !loading &&
                (!error || force) &&
                (isClient || ssr) &&
                (isFullProfile || !full) &&
                !isRestrict &&
                (!waitLoyalty || api.loyalty.loaded) &&
                (unauthorised || isAuth))
        ) {
            modulesLoad.push(name);
        }

        modulesStatus[name] = {
            error,
            loaded,
            loading,
        };
    });

    const load = modulesLoad.shift();

    useEffect(() => {
        if (load) {
            dispatch(apiFetch[load]());
        }
    }, [dispatch, load]);

    return useMemo(() => {
        return {
            isClient,
            isFullProfile,
            modules: modulesStatus,
        };
    }, [isClient, isFullProfile, modulesStatus]);
}
