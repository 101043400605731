import { getLocalValue, setLocalValue } from './storage';
import Bowser from './userAgentParser/bowser';

const PREVIOUS_USER_AGENT_KEY = 'previous-user-agent';
const FORMATTED_USER_AGENT_KEY = 'x-user-agent';

export const parse = userAgent => {
    const browser = Bowser.getParser(userAgent);

    const browserInfo = browser.getBrowser();
    const osInfo = browser.getOS();

    const userAgentInfoAvailable =
        browserInfo.name && browserInfo.version && osInfo.name;

    if (userAgentInfoAvailable) {
        return `MyProfile;${browserInfo.name};${browserInfo.version};${osInfo.name}`;
    }

    return userAgent;
};

export const parseAndSave = userAgent => {
    const parsedUserAgent = parse(userAgent);

    setLocalValue(FORMATTED_USER_AGENT_KEY, parsedUserAgent);
    setLocalValue(PREVIOUS_USER_AGENT_KEY, userAgent);

    return parsedUserAgent;
};

export const getUserAgent = () => {
    const formattedUserAgent = getLocalValue(FORMATTED_USER_AGENT_KEY);
    const { userAgent } = navigator;

    if (!formattedUserAgent) {
        return parseAndSave(userAgent);
    }

    const previousUserAgent = getLocalValue(PREVIOUS_USER_AGENT_KEY);

    if (previousUserAgent !== userAgent) {
        return parseAndSave(userAgent);
    }

    return formattedUserAgent;
};
