import { createAction, createReducer } from 'redux-act';

const initialState = {
    isError: false,
    isLoaded: false,
    isLoading: false,
};

export const fetchAllAlerts = createAction('alerts/fetchAllAlerts');
export const fetchAllAlertsSuccess = createAction(
    'alerts/fetchAllAlertsSuccess',
);
export const fetchAllAlertsFailure = createAction(
    'alerts/fetchAllAlertsFailure',
);

const handleFetchAllAlerts = state => ({ ...state, isLoading: true });

const handleFetchAllAlertsFailure = (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
});

const handleFetchAllAlertsSuccess = (state, items) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    items,
});

export default createReducer(
    {
        [fetchAllAlerts]: handleFetchAllAlerts,
        [fetchAllAlertsFailure]: handleFetchAllAlertsFailure,
        [fetchAllAlertsSuccess]: handleFetchAllAlertsSuccess,
    },
    initialState,
);

export * from './selectors';
