const documentType = {
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    INTERNAL_PASSPORT: 'INTERNAL_PASSPORT',
    INTERNATIONAL_PASSPORT: 'INTERNATIONAL_PASSPORT',
    MILITARY_CARD: 'MILITARY_CARD',
    OTHER: 'OTHER',
    VISA: 'VISA',
};

const documentMaskByType = {
    BIRTH_CERTIFICATE_NUMBER: '999999',
    INTERNAL_PASSPORT: '9999 999999',
    INTERNATIONAL_PASSPORT: '99 9999999',
};

const documentTypesEnum = Object.values(documentType);

const contactType = {
    ADDRESS: 'ADDRESS',
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
};

module.exports = {
    contactType,
    documentMaskByType,
    documentType,
    documentTypesEnum,
};
