import React, { PureComponent } from 'react';
import { node, string } from 'prop-types';
import Text from 'ui-kit/atoms/Text';

import cx from './SimpleLink.sss';

export default class SimpleLink extends PureComponent {
    static propTypes = {
        children: node,
        href: string,
        theme: string,
    };

    static defaultProps = {
        theme: 'black',
    };

    render() {
        const { children, theme } = this.props;

        return (
            <Text as="div" color={theme}>
                <div
                    className={cx('simpleLink', {
                        [`simpleLink_${theme}`]: theme,
                    })}
                >
                    {children}
                </div>
            </Text>
        );
    }
}
