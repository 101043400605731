export default [
    {
        name: 'INTERNAL_PASSPORT',
        title: 'com.profile.documents.docs.name.passport',
    },
    {
        name: 'INTERNATIONAL_PASSPORT',
        title: 'com.profile.documents.docs.name.outpassport',
    },
    {
        name: 'VISA',
        title: 'com.profile.documents.visa.name',
    },
    {
        name: 'BIRTH_CERTIFICATE',
        title: 'com.profile.documents.docs.name.birth',
    },
    {
        name: 'OTHER',
        title: 'com.profile.documents.docs.name.other',
    },
];
