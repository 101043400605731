import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment';

const DD_MM_YYYY = DATE_FORMATS.DD.MM.YYYY_dot;

export const splitTripsAndCertificates = items =>
    items.reduce(
        ([tripsAccum, certsAccum], trip) => {
            if (!trip.tripItems.length) {
                return [tripsAccum, certsAccum];
            }

            if (
                trip.tripItems.every(
                    tripItem => tripItem.type === 'CERTIFICATE',
                )
            ) {
                certsAccum.push({ ...trip.tripItems[0], id: trip.id });
            } else {
                tripsAccum.push(trip);
            }

            return [tripsAccum, certsAccum];
        },
        [[], []],
    );

export const isTripDateSatisfiesFilterDate = (
    [startDateISOString, endDateISOString] = [],
    [filterStartDateString, filterEndDateString] = [],
) => {
    const startDate = moment(startDateISOString);
    const endDate = moment(endDateISOString);

    const filterStartDate = moment(filterStartDateString, DD_MM_YYYY);
    const filterEndDate = moment(filterEndDateString, DD_MM_YYYY);

    let result = true;

    if (filterStartDateString && !filterEndDateString) {
        result = filterStartDate.isBetween(startDate, endDate, 'day', '[]');
    } else {
        result =
            startDate.isBetween(filterStartDate, filterEndDate, 'day', '[]') ||
            endDate.isBetween(filterStartDate, filterEndDate, 'day', '[]');
    }

    return result;
};
