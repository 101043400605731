import Router from 'next/router';
import { endsWith } from 'ramda';

export const removeUrlParams = params => {
    if (params) {
        const { asPath, query } = Router;

        let newAsPath = asPath;

        (Array.isArray(params) ? params : [params]).forEach(param => {
            if (query[param]) {
                newAsPath = newAsPath.replace(`${param}=${query[param]}`, '');
            }
        });

        if (endsWith('?', newAsPath)) {
            newAsPath = newAsPath.slice(0, -1);
        }

        Router.replace(newAsPath);
    }
};
