import jwtDecode from 'jwt-decode';

import getCastPromise from '@s7/login-widget/utils/getCast';

import { stage } from './stages';
import {
    getSessionValue,
    removeSessionValue,
    setSessionValue,
} from './storage';

import { CastService } from '../services';

const CAST_SESSION_KEY = 'x-cast';
const CAST_CLEANUP_FLAG_KEY = 'x-cast-cleanup';
const CAST_REQUESTED_FLAG_KEY = 'x-cast-requested';

const cleanupCast = () => {
    removeSessionValue(CAST_SESSION_KEY);

    if (getSessionValue(CAST_CLEANUP_FLAG_KEY) !== 'true') {
        setSessionValue(CAST_CLEANUP_FLAG_KEY, 'true');
        removeSessionValue(CAST_REQUESTED_FLAG_KEY);
    }
};

export const getCast = () => {
    let cast = getSessionValue(CAST_SESSION_KEY);

    try {
        const data = jwtDecode(cast);

        if (data.hardware.availableScreenResolution) {
            cleanupCast();
            cast = null;
        }
    } catch (e) {
        cleanupCast();
        cast = null;
    }

    if (cast && getSessionValue(CAST_CLEANUP_FLAG_KEY)) {
        removeSessionValue(CAST_CLEANUP_FLAG_KEY);
    }

    return cast;
};

const setCast = jwt => {
    setSessionValue(CAST_SESSION_KEY, jwt);
};

let castLock = false;

export const requestCast = () => {
    if (castLock) {
        return;
    }

    const cast = getCast();

    const fetchCast = () =>
        getCastPromise(CastService.sign, stage)
            .then(jwt => {
                setCast(jwt);
                castLock = false;
            })
            .catch(() => {
                castLock = false;
            });

    if (!cast) {
        castLock = true;
        fetchCast();
    }
};
