import { omit } from 'ramda';
import { createAction, createReducer } from 'redux-act';

const initialState = {
    error: null,
    isLoaded: false,
    isLoading: false,
    items: {},
    processing: {},
};

export const fetchQRCodes = createAction('qrcodes/fetchQRCodes');
export const fetchQRCodesSuccess = createAction('qrcodes/fetchQRCodesSuccess');
export const fetchQRCodesFailure = createAction('qrcodes/fetchQRCodesFailure');

export const saveQRCode = createAction('qrcodes/saveQRCode');
export const saveQRCodeSuccess = createAction('qrcodes/saveQRCodeSuccess');
export const saveQRCodeFailure = createAction('qrcodes/saveQRCodeFailure');

export const deleteQRCode = createAction('qrcodes/deleteQRCode');
export const deleteQRCodeSuccess = createAction('qrcodes/deleteQRCodeSuccess');
export const deleteQRCodeFailure = createAction('qrcodes/deleteQRCodeFailure');

export const statuses = {
    deleting: 'deleting',
    loading: 'loading',
};

export const types = {
    default: 'DEFAULT',
    vaccination: 'VACCINATION',
};

const handleFetchQRCodes = state => ({ ...state, isLoading: true });
const handleFetchQRCodesSuccess = (state, { items }) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    items,
});
const handleFetchQRCodesFailure = (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
});

const handleSaveQRCode = (state, { id }) => ({
    ...state,
    items: { ...state.items, [id]: state.items[id] || { value: '' } },
    processing: { ...state.processing, [id]: statuses.loading },
});
const handleSaveQRCodeSuccess = (state, { id, qrcode }) => ({
    ...state,
    items: { ...omit([id], state.items), [qrcode.id]: qrcode },
    processing: omit([id], state.processing),
});
const handleSaveQRCodeFailure = (state, { id, doNotRemoveItem }) => ({
    ...state,
    items: doNotRemoveItem ? state.items : omit([id], state.items),
    processing: omit([id], state.processing),
});

const handleDeleteQRCode = (state, { id }) => ({
    ...state,
    processing: { ...state.processing, [id]: statuses.deleting },
});
const handleDeleteQRCodeSuccess = (state, { id }) => ({
    ...state,
    items: omit([id], state.items),
    processing: omit([id], state.processing),
});
const handleDeleteQRCodeFailure = (state, { id }) => ({
    ...state,
    processing: omit([id], state.processing),
});

export default createReducer(
    {
        [deleteQRCode]: handleDeleteQRCode,
        [deleteQRCodeFailure]: handleDeleteQRCodeFailure,
        [deleteQRCodeSuccess]: handleDeleteQRCodeSuccess,
        [fetchQRCodes]: handleFetchQRCodes,
        [fetchQRCodesFailure]: handleFetchQRCodesFailure,
        [fetchQRCodesSuccess]: handleFetchQRCodesSuccess,
        [saveQRCode]: handleSaveQRCode,
        [saveQRCodeFailure]: handleSaveQRCodeFailure,
        [saveQRCodeSuccess]: handleSaveQRCodeSuccess,
    },
    initialState,
);
export * from './selectors';
