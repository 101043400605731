import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { node, object } from 'prop-types';

export const Portal = forwardRef(function Portal(
    { children, container = document.body, ...props },
    ref,
) {
    return ReactDOM.createPortal(
        <div {...props} ref={ref}>
            {children}
        </div>,
        container,
    );
});

Portal.propTypes = {
    children: node,
    container: object,
};
