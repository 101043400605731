import moment from 'moment';
import { forEach, path, type } from 'ramda';

const differenceProps = (ways, prev, next) => {
    let difference = false;

    forEach(way => {
        const find = type(way) === 'Array' ? way : [way];
        const prevProps = path(find, prev);
        const nextProps = path(find, next);

        if (moment.isMoment(nextProps) && moment.isMoment(prevProps)) {
            difference = !prevProps.isSame(nextProps) || difference;
        } else {
            difference = path(find, prev) !== path(find, next) || difference;
        }
    }, ways);

    return difference;
};

export default differenceProps;
