export const HASH_PROFILE_PERSONAL = 'personal';
export const HASH_PROFILE_EMAIL = 'email';
export const HASH_PROFILE_PHONE = 'phone';
export const HASH_PROFILE_DOCUMENTS = 'documents';
export const HASH_PROFILE_VISA = 'visa';
export const HASH_PROFILE_FAMILY = 'family';
export const HASH_PROFILE_TRAVELERS = 'travelers';
export const HASH_PROFILE_B2B = 'b2b';
export const HASH_PROFILE_CARDS = 'cards';
export const HASH_PROFILE_CONTACTS = 'contacts';
export const HASH_CONTACT_INFORMATION = 'contact-information';
export const HASH_EDIT_PHONE = 'edit-phone';
export const HASH_EDIT_EMAIL = 'edit-email';

export const HASH_MULTIPLE_PROFILE = [
    `#${HASH_PROFILE_DOCUMENTS}`,
    `#${HASH_PROFILE_VISA}`,
    `#${HASH_PROFILE_FAMILY}`,
    `#${HASH_PROFILE_TRAVELERS}`,
    `#${HASH_PROFILE_CARDS}`,
];

export const HASH_SCROLL_PROFILE = [
    ...HASH_MULTIPLE_PROFILE,
    `#${HASH_PROFILE_PERSONAL}`,
    `#${HASH_PROFILE_EMAIL}`,
    `#${HASH_PROFILE_PHONE}`,
    `#${HASH_PROFILE_B2B}`,
];
