import React, { useEffect, useRef } from 'react';
import { bool, node, oneOf } from 'prop-types';

export const ScrollIntoView = ({
    behavior = 'smooth',
    block = 'start',
    children,
    disableScroll = false,
    inline = 'nearest',
}) => {
    const ref = useRef();

    useEffect(() => {
        if (!disableScroll) {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.scrollIntoView({ behavior, block, inline });

                    const { scrolledY } = window;

                    if (scrolledY) {
                        window.scroll(0, scrolledY - 80);
                    }
                }
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={ref}>{children}</div>;
};

const alignments = ['start', 'center', 'end', 'nearest'];

ScrollIntoView.propTypes = {
    behavior: oneOf(['auto', 'smooth']),
    block: oneOf(alignments),
    children: node,
    disableScroll: bool,
    inline: oneOf(alignments),
};
