export const memberType = {
    HEAD: 'HEAD',
    SUB: 'SUB',
};

export const memberStatus = {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
    DELETION_PENDING: 'DELETION_PENDING',
    PENDING: 'PENDING',
};
