import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { difference } from 'ramda';

import {
    fetch as fetchLanguages,
    getDictionary,
    getLoaded,
} from '../store/modules/languages';

export function useLanguages(languages = []) {
    const dispatch = useDispatch();
    const loaded = useSelector(getLoaded);
    const dictionary = useSelector(getDictionary);
    const notLoadedLanguages = difference(['global', ...languages], loaded);

    useEffect(() => {
        notLoadedLanguages.forEach(lang => {
            dispatch(fetchLanguages(lang));
        });
    }, [dispatch, notLoadedLanguages]);

    return dictionary;
}
