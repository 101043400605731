import { Cookies } from 'react-cookie';
import { COOKIES } from 'consts/cookies';
import { propOr } from 'ramda';

import cookieOptions from '../utils/cookiesOptions';
import { getType, isArray, isObject } from '../utils/type';

const cookies = new Cookies();

const USER_COOKIES = [
    COOKIES.LANG,
    COOKIES.SSDCP,
    COOKIES.SSDGE,
    COOKIES.SSDKL,
    COOKIES.SSDMB,
    COOKIES.SSDTI,
    COOKIES.SSDTP,
    COOKIES.SSDWS,
    COOKIES.SSDZQ,
];

const isUserCookie = cookie => USER_COOKIES.includes(cookie);

const getName = cookie =>
    isUserCookie(cookie) ? COOKIES[cookie.toLocaleUpperCase()] : cookie;

const completeOptions = (options, withDefault = true) =>
    options && withDefault ? { ...cookieOptions, ...options } : cookieOptions;

const get = (cookie, options) => cookies.get(getName(cookie), options);

const getAll = options => cookies.getAll(options);

const set = (cookie, value, options, withDefaultOptions) => {
    cookies.set(
        getName(cookie),
        value,
        completeOptions(options, withDefaultOptions),
    );
};

const remove = (cookie, options, withDefaultOptions) => {
    cookies.remove(
        getName(cookie),
        completeOptions(options, withDefaultOptions),
    );
};

const checkType = (value, checker, type) => {
    if (!checker(value)) {
        // eslint-disable-next-line no-console
        console.error(
            `argument must be "${type}" type, but got "${getType(value)}"`,
        );

        return false;
    }

    return true;
};

const checkArray = keys => checkType(keys, isArray, 'Array');

const setValues = (values, options, withDefaultOptions) => {
    if (!checkType(values, isObject, 'Object')) {
        return;
    }

    const keys = Object.keys(values);

    keys.forEach(key => {
        set(
            key,
            isObject(values[key]) ? values[key].value : values[key],
            propOr(options, 'options', values[key]),
            withDefaultOptions,
        );
    });
};

const getValues = (keys, options) => {
    if (!checkArray(keys)) {
        return;
    }

    // eslint-disable-next-line consistent-return
    return keys.reduce((acc, key) => {
        acc[key] = get(
            isObject(keys[key]) ? keys[key].value : key,
            propOr(options, 'options', keys[key]),
        );

        return acc;
    }, {});
};

const removeValues = (keys, options, withDefaultOptions) => {
    if (!checkArray(keys)) {
        return;
    }

    keys.forEach(key => {
        remove(
            isObject(keys[key]) ? keys[key].value : key,
            propOr(options, 'options', keys[key]),
            withDefaultOptions,
        );
    });
};

export const cookieService = {
    get,
    getAll,
    getValues,
    remove,
    removeValues,
    set,
    setValues,
};
