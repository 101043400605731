import { DATE_FORMATS } from 'consts/dateFormats';
import moment from 'moment-timezone';
import {
    any,
    compose,
    filter,
    find,
    length,
    map,
    propOr,
    reduce,
    sort,
    unnest,
} from 'ramda';
import { createSelector } from 'reselect';

import { isFading } from '../../../utils/fadingTime';
import { getLocale } from '../languages';

const getItems = state => state.countries.items;

// common
const sortCountries = sort(({ title: a }, { title: b }) => {
    if (a > b) {
        return 1;
    }
    if (a === b) {
        return 0;
    }

    return -1;
});
const hasChild = filter(item => length(item.childrens) > 0);
const hasNames = filter(item => !!item.names);

const getRawCities = createSelector(getItems, items =>
    compose(
        unnest,
        map(item => item.childrens),
        hasChild,
    )(items),
);

export const getRawCitiesWithName = createSelector(
    [getRawCities, getLocale],
    (children, locale) =>
        compose(
            map(item => ({
                ...item,
                names: item.names[locale] || item.names.en || item.names.ru,
            })),
            hasNames,
        )(children),
);

export const getCountries = createSelector(
    [getItems, getLocale],
    (items, locale) => {
        const ru = 'RU';

        return compose(
            sort((next, prev) => {
                const a = `${next.name === ru ? '!' : ''}${
                    next.title
                }`.toLowerCase();
                const b = `${prev.name === ru ? '!' : ''}${
                    prev.title
                }`.toLowerCase();

                if (a > b) {
                    return 1;
                }

                if (a === b) {
                    return 0;
                }

                return -1;
            }),
            filter(item => !!item.name),
            map(item => {
                const name =
                    item.names[locale] || item.names.en || item.names.ru;

                return {
                    isoAlpha3: item.isoAlpha3,
                    label: `${name} (${item.iso})`,
                    name: item.iso,
                    shortCode: item.iso,
                    title: `${name} (${item.iso})`,
                    value: item.iso,
                    visa: !!item.needVisa,
                    visible: !!item.isoAlpha3,
                };
            }),
        )(items);
    },
);

export const getCard = (id, state) => state.cards.itemsById[id];

export const getCountry = ({ countries: { items }, ...state }, code) =>
    compose(
        names => names[getLocale(state)] || names.en || names.ru,
        propOr({}, 'names'),
        find(
            ({ ibeCode, childrens }) =>
                ibeCode === code ||
                any(
                    ({ ibeCode: cityCode, childrens: airports }) =>
                        cityCode === code ||
                        any(
                            ({ ibeCode: airportCode }) => airportCode === code,
                            airports,
                        ),
                    childrens,
                ),
        ),
    )(items);

export const getCity = ({ countries: { items }, ...state }, code) =>
    compose(
        names => names[getLocale(state)] || names.en || names.ru,
        propOr({}, 'names'),
        find(
            ({ ibeCode, childrens }) =>
                ibeCode === code ||
                any(
                    ({ ibeCode: airportCode }) => airportCode === code,
                    childrens,
                ),
        ),
        unnest,
        map(item => item.childrens),
    )(items);

export const getCities = createSelector(getRawCitiesWithName, children =>
    compose(
        sortCountries,
        map(({ iata, names }) => ({
            label: names,
            name: iata,
            title: names,
            value: iata,
        })),
    )(children),
);

export const getAirports = createSelector(
    [getItems, getLocale],
    (items, locale) => {
        const getName = item => item.names[locale];
        const transform = (name, currentProps, parentProps) =>
            compose(
                unnest,
                map(item =>
                    map(
                        subItem => ({
                            ...subItem,
                            ...(parentProps
                                ? reduce(
                                      (acc, parentProp) => {
                                          acc[parentProp] = item[parentProp];

                                          return acc;
                                      },
                                      {},
                                      parentProps,
                                  )
                                : {}),
                            ...(currentProps
                                ? reduce(
                                      (acc, currentProp) => {
                                          acc[currentProp.name] =
                                              item[currentProp.prop];

                                          return acc;
                                      },
                                      {},
                                      currentProps,
                                  )
                                : {}),
                            [name.value]: name.getter(item),
                        }),
                        item.childrens,
                    ),
                ),
            );

        return compose(
            sortCountries,
            map(item => ({
                airport: getName(item),
                city: item.city,
                cityKeywords: item.cityKeywords,
                cityWeight: item.cityWeight,
                country: item.country,
                countryKeywords: item.countryKeywords,
                countryWeight: item.countryWeight,
                label: `${item.city}, ${getName(item)}, ${item.country}, (${
                    item.iata
                })`,
                name: item.iata,
                title: `${item.city}, ${getName(item)}, ${item.country}, (${
                    item.iata
                })`,
                value: item.iata,
            })),
            hasNames,
            transform(
                { getter: getName, value: 'city' },
                [
                    { name: 'cityWeight', prop: 'weight' },
                    { name: 'cityKeywords', prop: 'keywords' },
                ],
                ['country', 'countryWeight', 'countryKeywords'],
            ),
            hasChild,
            transform({ getter: getName, value: 'country' }, [
                { name: 'countryWeight', prop: 'weight' },
                { name: 'countryKeywords', prop: 'keywords' },
            ]),
            hasChild,
        )(items);
    },
);

export const getAirport = ({ countries: { items }, ...state }, code) =>
    compose(
        names => names[getLocale(state)] || names.en || names.ru,
        propOr({}, 'names'),
        find(({ ibeCode }) => ibeCode === code),
        unnest,
        map(item => item.childrens),
        unnest,
        map(item => item.childrens),
    )(items);

export const getCityTimeZone = ({ countries: { items } }, code) => {
    const tz = compose(
        propOr('Europe/Moscow', 'timeZone'),
        find(
            ({ ibeCode, childrens }) =>
                ibeCode === code ||
                any(
                    ({ ibeCode: airportCode }) => airportCode === code,
                    childrens,
                ),
        ),
        unnest,
        map(item => item.childrens),
    )(items);

    if (tz.indexOf('GMT') >= 0) {
        const timeZones = moment.tz.names();

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const i in timeZones) {
            const formated = moment
                .tz(timeZones[i])
                .format(DATE_FORMATS.Z)
                .split(':')[0]
                .replace('+0', '+')
                .replace('-0', '-');

            if (`GMT${formated}` === tz) {
                return timeZones[i];
            }
        }
    }

    return tz;
};

export const isInitialized = state => state.countries.isInitialized;
export const isLoaded = state => isFading(state.countries.fadingTime);
export const isLoading = state => state.countries.isLoading;
export const isError = state => state.countries.isError;
