import React, { PureComponent } from 'react';
import { bool, elementType, func, node, oneOf, string } from 'prop-types';

import cx from './Button.sss';

import Icon from '../../../components/Icon';
import Loader from '../Loader';

export default class Button extends PureComponent {
    static propTypes = {
        borderRadius: oneOf(['small', 'large', 'large_bottom']),
        children: node,
        color: oneOf(['green']),
        disabled: bool,
        icon: string,
        isLoading: bool,
        onClick: func,
        size: oneOf(['slim', 'medium', 'large']),
        tag: elementType,
        theme: oneOf(['primary', 'outline', 'border', 'danger', 'dark']),
        type: oneOf(['button', 'submit', 'reset']),
        width: oneOf(['auto', 'full']),
    };

    static defaultProps = {
        isLoading: false,
        onClick: () => {},
        tag: 'button',
        type: 'button',
    };

    render() {
        const {
            children,
            tag,
            theme,
            size,
            width,
            color,
            borderRadius,
            icon,
            isLoading,
            ...props
        } = this.props;

        const Tag = tag || 'button';

        return (
            <Tag
                className={cx('Button', {
                    Button_loading: isLoading,
                    [`Button_theme_${theme}`]: theme,
                    [`Button_size_${size}`]: size,
                    [`Button_width_${width}`]: width,
                    [`Button_color_${color}`]: color,
                    [`Button_border_radius_${borderRadius}`]: borderRadius,
                })}
                {...props}
                disabled={props.disabled || isLoading}
            >
                <span className={cx('inner')}>{children}</span>

                {icon && (
                    <span className={cx('icon')}>
                        <Icon
                            height={undefined}
                            icon={icon}
                            width={undefined}
                        />
                    </span>
                )}

                <span className={cx('loader')}>
                    <Loader theme={color} />
                </span>
            </Tag>
        );
    }
}
