export const modalIds = {
    ADDRESS_EDIT: 'addressEdit',

    AVATAR_CONFIRMATION: 'avatarConfirmation',
    AVATAR_DELETION: 'avatarDeletion',
    AVATAR_ERROR: 'avatarError',

    GROUP_ACCOUNT_DELETION: 'familyDeletion',
    GROUP_ACCOUNT_INVITATION: 'familyInvitation',
    GROUP_ACCOUNT_LEAVING: 'familyLeaving',

    MILES_BUY: 'milesBuy',
    MILES_CERTIFICATE: 'milesCertificate',
    MILES_EXPIRATION: 'milesExpiration',
    MILES_RECOVER: 'milesRecover',
    MILES_RECOVER_NEW_REQUEST: 'milesRecoverNewRequest',
    MILES_RECOVER_RULES: 'milesRecoverRules',
    MILES_STATUS: 'milesStatus',
    MILES_TRANSFER: 'milesTransfer',
    MILES_UPGRADE: 'milesUpgrade',

    PERSONAL_DATA_EDIT_ERROR: 'personalDataEditError',

    QR_MODAL: 'qr-modal',

    SOCIAL: 'social',
};
