import React, { PureComponent } from 'react';
import { node } from 'prop-types';

import cx from './Alert.sss';

import Icon from '../../components/Icon';

export default class Alert extends PureComponent {
    static propTypes = {
        children: node,
    };

    render() {
        const { children, ...props } = this.props;

        return (
            <div className={cx('error')} {...props}>
                <div className={cx('error-title')}>
                    <div className={cx('error-title-icon')}>
                        <Icon icon="error" />
                    </div>
                    {children}
                </div>
            </div>
        );
    }
}
